import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EVENTS, TEVENTS_PERIOD } from './useOnGoingEvent.type';

dayjs.extend(utc);
dayjs.extend(timezone);

export const EVENTS_PERIOD: TEVENTS_PERIOD = {
  [EVENTS.LOGIN_BONUS_2408]: {
    since: dayjs('2024-08-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-08-31 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.LOGIN_BONUS_2409]: {
    since: dayjs('2024-09-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-30 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.LOGIN_BONUS_2410]: {
    since: dayjs('2024-10-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-10-31 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CHARGE_DOUBLE_2408]: {
    since: dayjs('2024-08-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-08-03 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CHARGE_DOUBLE_2409]: {
    since: dayjs('2024-09-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-03 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CHARGE_DOUBLE_2410]: {
    since: dayjs('2024-10-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-10-03 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.CATEGORY_TOOLTIP]: {
    since: dayjs('2024-03-01 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-04-25 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.DAILY_BONUS_2024]: {
    since: dayjs('2024-06-17 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-06-23 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.MOON_GREETING_PRE]: {
    since: dayjs('2024-09-09 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-11 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.MOON_GREETING_240914]: {
    since: dayjs('2024-09-12 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-14 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.MOON_GREETING_240917]: {
    since: dayjs('2024-09-15 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-17 23:59:59').tz('Asia/Seoul'),
  },
  [EVENTS.MOON_GREETING_240920]: {
    since: dayjs('2024-09-18 00:00:00').tz('Asia/Seoul'),
    until: dayjs('2024-09-20 23:59:59').tz('Asia/Seoul'),
  },
};

const generateInitialEventsState = () => {
  const result = [];
  const now = dayjs(); // 로컬 시간 세팅(현재시간)
  for (const EVENT_VALUE of Object.keys(EVENTS_PERIOD)) {
    const isBetween =
      now.isSame(EVENTS_PERIOD[EVENT_VALUE].since) ||
      now.isSame(EVENTS_PERIOD[EVENT_VALUE].until) ||
      (now.isAfter(EVENTS_PERIOD[EVENT_VALUE].since) && now.isBefore(EVENTS_PERIOD[EVENT_VALUE].until));
    isBetween && result.push(EVENT_VALUE);
  }
  return result;
};

export const INITIAL_ONGOING_EVENT_STATE = {
  onGoingEvents: generateInitialEventsState(),
};
