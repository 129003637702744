import { Dayjs } from 'dayjs';

export enum EVENTS {
  LOGIN_BONUS_2408 = 'login_bonus_2408',
  LOGIN_BONUS_2409 = 'login_bonus_2409',
  LOGIN_BONUS_2410 = 'login_bonus_2410',
  CHARGE_DOUBLE_2408 = 'charge_double_2408',
  CHARGE_DOUBLE_2409 = 'charge_double_2409',
  CHARGE_DOUBLE_2410 = 'charge_double_2410',
  CATEGORY_TOOLTIP = 'category_tooltip',
  DAILY_BONUS_2024 = 'daily_bonus_2024',
  MOON_GREETING_240914 = 'moon_greeting_240914',
  MOON_GREETING_240917 = 'moon_greeting_240917',
  MOON_GREETING_240920 = 'moon_greeting_240920',
  MOON_GREETING_PRE = 'moon_greeting_pre',
}
export type TEVENTS_PERIOD = {
  [key in EVENTS]: {
    since: Dayjs;
    until: Dayjs;
  };
};
export type TSetterKeys = `set${Capitalize<string & keyof IOnGoingEventState>}`;

export interface IOnGoingEventState {
  onGoingEvents: EVENTS[];
}
export interface IOnGoingEventSetState {
  setOnGoingEvents: (onGoingEvents: EVENTS[]) => void;
}
