import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

export enum AcceptStatusEnum {
  Approve = 'approve',
  Leave = 'leave',
  Reject = 'reject',
  Request = 'request',
}

export type Acon3dBanner = {
  __typename?: 'Acon3dBanner';
  /** 배너 등록일자 (복수 배너시 정렬용) */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** 상품 링크 */
  href?: Maybe<Scalars['String']['output']>;
  /** 배너 ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** 배너 이미지 주소 */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** 텍스트 */
  text?: Maybe<Scalars['String']['output']>;
  /** 표시 타입 */
  type?: Maybe<Scalars['String']['output']>;
};

export type Acon3dBook = {
  __typename?: 'Acon3dBook';
  id?: Maybe<Scalars['Int']['output']>;
  /** isbn */
  isbn?: Maybe<Scalars['String']['output']>;
};

export type Acon3dBrand = {
  __typename?: 'Acon3dBrand';
  /** 브랜드 코드 */
  code?: Maybe<Scalars['String']['output']>;
  /** 브랜드 ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** 브랜드 이름(번역) */
  name?: Maybe<Scalars['String']['output']>;
};

export type Acon3dCategory = {
  __typename?: 'Acon3dCategory';
  /** 고도 카테고리 코드 */
  code?: Maybe<Scalars['String']['output']>;
  /** 카테고리 ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** 카테고리 이름(번역) */
  name?: Maybe<Scalars['String']['output']>;
};

export type Acon3dFunding = {
  __typename?: 'Acon3dFunding';
  deliveryDate: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  goalAmount: Scalars['Int']['output'];
  soldAmount: Scalars['Int']['output'];
  soldVolume: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  status: FundingStatus;
};

export type Acon3dLinkedProduct = {
  __typename?: 'Acon3dLinkedProduct';
  /** 확장자 */
  extensions: Array<Scalars['String']['output']>;
  /** 상품 번호 */
  id: Scalars['Int']['output'];
};

export type Acon3dModelConfig = {
  __typename?: 'Acon3dModelConfig';
  /** 모델컨피그 ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** 이름(번역) */
  name?: Maybe<Scalars['String']['output']>;
};

export type Acon3dPackage = {
  __typename?: 'Acon3dPackage';
  children: Array<Acon3dProduct>;
  parent?: Maybe<Acon3dProduct>;
  type: Scalars['String']['output'];
};

export type Acon3dProduct = {
  __typename?: 'Acon3dProduct';
  /** 상품의 응용프로그램들 */
  applications?: Maybe<Array<ApplicationEntity>>;
  /** 상품카드 뱃지 이름들(번역) */
  badgeNames: Array<Maybe<Scalars['String']['output']>>;
  banners?: Maybe<Array<Acon3dBanner>>;
  /** 책 상품 정보 */
  book?: Maybe<Acon3dBook>;
  brand: Acon3dBrand;
  categories?: Maybe<Array<Acon3dCategory>>;
  category?: Maybe<CategoryOutputV4>;
  content?: Maybe<Scalars['String']['output']>;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  /** 상품상세 이미지 URL */
  detailImageUrl?: Maybe<Scalars['String']['output']>;
  /** 세트 상품을 허브에 정보 전달해주기 위한 docId */
  docId?: Maybe<Scalars['String']['output']>;
  /** 확장자 */
  extensions: Array<Scalars['String']['output']>;
  /** 펀딩 정보. null이면 펀딩 대상이 아님 */
  funding?: Maybe<Acon3dFunding>;
  /** 유저가 상품을 주문한 적이 있는지 여부 */
  hasOrdered?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  /** ACON ONLY 여부 */
  isAconOnly: Scalars['Boolean']['output'];
  /** 성인상품 여부 */
  isAdultOnly: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  /** 클래스 상품 여부 */
  isLecture: Scalars['Boolean']['output'];
  /** 세일 여부 */
  isOnSale: Scalars['Boolean']['output'];
  /** 언어 */
  languageCode: LanguageCodeEnum;
  licenses: Array<Maybe<AssetLicensePublicOutput>>;
  /** 연관 상품, product_related 테이블 */
  linkedProducts?: Maybe<Array<Acon3dLinkedProduct>>;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String']['output'];
  modelConfigs?: Maybe<Array<Acon3dModelConfig>>;
  /** 리스트 노출 여부 */
  onDisplay: Scalars['Boolean']['output'];
  /** 판매 여부 */
  onSale: Scalars['Boolean']['output'];
  /** 판매 시작일 */
  onSaleFrom?: Maybe<Scalars['DateTime']['output']>;
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int']['output'];
  /** 세트 상품 정보. null이면 세트 상품에 해당되지 않음 */
  packages?: Maybe<Array<Maybe<Acon3dPackage>>>;
  /** 상품 정가 */
  price: Scalars['Int']['output'];
  /** 진행 중인 프로모션 정보 */
  promotionEndDate?: Maybe<Scalars['DateTime']['output']>;
  promotionInfo?: Maybe<PromotionOutput>;
  /** 우주최저가/혜성특가 등의 특수 프로모션에 해당될 경우 요청하는 필드 */
  promotions?: Maybe<Array<Acon3dPromotion>>;
  /** 상품 정가 (개정) */
  regularPrice?: Maybe<Scalars['Int']['output']>;
  /** 출시일 */
  released?: Maybe<Scalars['DateTime']['output']>;
  /** 리뷰 수 */
  reviewCount: Scalars['Int']['output'];
  /** 상품 할인가 */
  salePrice: Scalars['Int']['output'];
  /** 검색 일치 점수 */
  score?: Maybe<Scalars['Float']['output']>;
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String']['output'];
  /** 번역된 상품명 */
  title: Scalars['String']['output'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']['output']>;
  /** 조회 수 */
  viewCount: Scalars['Int']['output'];
};

export type Acon3dPromotion = {
  __typename?: 'Acon3dPromotion';
  /** 프로모션 뱃지(번역본) */
  badges?: Maybe<Array<Acon3dPromotionBadge>>;
  /** 현재 차수 */
  currentRound?: Maybe<Scalars['Int']['output']>;
  /** 프로모션 아이디(acon.promotion) */
  id: Scalars['Int']['output'];
  /** 남은 일수 */
  remainingDays?: Maybe<Scalars['Int']['output']>;
  /** 혜택 라운드별 상세 정보 */
  rounds?: Maybe<Array<Acon3dPromotionRound>>;
  /** 시작일자(예정 포함), acon.promotion.reserve_date */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type Acon3dPromotionBadge = {
  __typename?: 'Acon3dPromotionBadge';
  /** 프로모션 뱃지 내용 */
  content?: Maybe<Scalars['String']['output']>;
  /** 프로모션 뱃지 ID */
  id?: Maybe<Scalars['Int']['output']>;
  /** 프로모션 뱃지 언어 */
  languageCode?: Maybe<LanguageCodeEnum>;
  /** 프로모션 뱃지 타입: point | coupon | null */
  type?: Maybe<Scalars['String']['output']>;
};

export type Acon3dPromotionRound = {
  __typename?: 'Acon3dPromotionRound';
  /** 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  roundNo?: Maybe<Scalars['Int']['output']>;
  /** 할인가 */
  salePrice?: Maybe<Scalars['Int']['output']>;
  /** 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type AconNotification = {
  __typename?: 'AconNotification';
  /** acon notification 고유번호 */
  id: Scalars['Int']['output'];
  /** 고도몰 회원번호 */
  memNo: Scalars['Int']['output'];
  /** notification message */
  message?: Maybe<Scalars['String']['output']>;
  /** notification type */
  type: Scalars['String']['output'];
};

export type AconSnsMemberInput = {
  accessToken: Scalars['String']['input'];
  /** y or n */
  agreementInfoFl?: InputMaybe<Scalars['String']['input']>;
  cellPhone?: InputMaybe<Scalars['String']['input']>;
  countryName?: InputMaybe<Scalars['String']['input']>;
  languageCode: LanguageCodeEnum;
  /** y or n */
  maillingFl?: InputMaybe<Scalars['String']['input']>;
  /** y or n */
  privateApprovalFl?: InputMaybe<Scalars['String']['input']>;
  relatedJob?: InputMaybe<Array<Scalars['String']['input']>>;
  /** y or n */
  smsFl?: InputMaybe<Scalars['String']['input']>;
  snsType: SnsTypeEnum;
};

export type AdminMultiDocument = {
  __typename?: 'AdminMultiDocument';
  assetId?: Maybe<Scalars['Int']['output']>;
  brandName: Scalars['String']['output'];
  /** 최종 상태변환 일시 */
  changed?: Maybe<Scalars['DateTime']['output']>;
  game?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  imgsrc?: Maybe<Scalars['Boolean']['output']>;
  isAdultOnly?: Maybe<Scalars['Boolean']['output']>;
  lecture?: Maybe<Scalars['Boolean']['output']>;
  /** 아직 담당자가 지정되지 않았을 수 있으므로 nullable */
  manager?: Maybe<Scalars['String']['output']>;
  /** 노출 시작 시각. null이면 미노출 */
  onSaleFrom?: Maybe<Scalars['DateTime']['output']>;
  /** 검토/수정요청 일시 */
  requested?: Maybe<Scalars['DateTime']['output']>;
  status: Document_Status;
  titles: Array<DocumentTitle>;
  /** 전체 다큐먼트 수 */
  total: Scalars['Int']['output'];
  webtoon?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminSingleDocumentInput = {
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 고도몰상품번호 */
  assetId?: InputMaybe<Scalars['Int']['input']>;
  /** 전자책 상품의 정보 */
  book?: InputMaybe<DocumentBookInput>;
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<DocumentCategoryInput>>;
  changeHistories?: InputMaybe<Array<InputMaybe<DocumentChangeHistoryInput>>>;
  completed?: InputMaybe<Scalars['DateTime']['input']>;
  contentBody?: InputMaybe<Scalars['String']['input']>;
  contentHead?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<DocumentCopyrightInput>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  fileComponents?: InputMaybe<Array<InputMaybe<DocumentFileComponentInput>>>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  fileUpdates?: InputMaybe<Array<InputMaybe<DocumentFileUpdateInput>>>;
  game?: InputMaybe<Scalars['Boolean']['input']>;
  /** 다큐먼트 ID */
  id: Scalars['String']['input'];
  imgsrc?: InputMaybe<Scalars['Boolean']['input']>;
  /** 산업분야 목록; id만; */
  industries?: InputMaybe<Array<DocumentIndustryInput>>;
  isAdultOnly?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  language?: InputMaybe<Language_Code>;
  lecture?: InputMaybe<Scalars['Boolean']['input']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: InputMaybe<Array<LicenseTypeOptionInput>>;
  mCompleted?: InputMaybe<Scalars['DateTime']['input']>;
  mRequested?: InputMaybe<Scalars['DateTime']['input']>;
  mainCoordination?: InputMaybe<ImageCropCoordinationInput>;
  mainImage?: InputMaybe<Scalars['String']['input']>;
  mainOrigin?: InputMaybe<Scalars['String']['input']>;
  memos?: InputMaybe<Array<InputMaybe<DocumentMemoInput>>>;
  message?: InputMaybe<Scalars['String']['input']>;
  originalLanguage?: InputMaybe<Language_Code>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceChanged?: InputMaybe<Scalars['DateTime']['input']>;
  requested?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Document_Status>;
  subCoordination?: InputMaybe<ImageCropCoordinationInput>;
  subImage?: InputMaybe<Scalars['String']['input']>;
  subOrigin?: InputMaybe<Scalars['String']['input']>;
  /** 태그 목록; id만; */
  tags?: InputMaybe<Array<DocumentTagInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
  webtoon?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AdminSingleDocumentOutput = {
  __typename?: 'AdminSingleDocumentOutput';
  /** 응용프로그램 목록 */
  applications: Array<Scalars['Int']['output']>;
  /** 고도몰상품번호 */
  assetId?: Maybe<Scalars['Int']['output']>;
  book?: Maybe<DocumentBookOutput>;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Int']['output']>;
  brandNames?: Maybe<Array<Maybe<SingleDocumentBrandName>>>;
  /** 카테고리 정보 */
  categories: Array<CategoryOutputV4>;
  changeHistories?: Maybe<Array<Maybe<DocumentChangeHistoryOutput>>>;
  completed?: Maybe<Scalars['DateTime']['output']>;
  contentBody?: Maybe<Scalars['String']['output']>;
  contentHead?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<DocumentCopyrightOutput>;
  created?: Maybe<Scalars['DateTime']['output']>;
  /** 확장자 목록 */
  extensions: Array<Scalars['Int']['output']>;
  fileComponents?: Maybe<Array<Maybe<DocumentFileComponentOutput>>>;
  fileName?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  fileUpdates?: Maybe<Array<Maybe<DocumentFileUpdateOutput>>>;
  game?: Maybe<Scalars['Boolean']['output']>;
  /** 다큐먼트 ID */
  id: Scalars['String']['output'];
  imgsrc?: Maybe<Scalars['Boolean']['output']>;
  /** 산업분야 목록 */
  industries: Array<IndustryOutput>;
  isAdultOnly?: Maybe<Scalars['Boolean']['output']>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  language?: Maybe<Language_Code>;
  lecture?: Maybe<Scalars['Boolean']['output']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: Maybe<Array<LicenseTypeOptionOutput>>;
  mCompleted?: Maybe<Scalars['DateTime']['output']>;
  mRequested?: Maybe<Scalars['DateTime']['output']>;
  mainCoordination?: Maybe<ImageCropCoordinationOutput>;
  mainImage?: Maybe<Scalars['String']['output']>;
  mainOrigin?: Maybe<Scalars['String']['output']>;
  memos?: Maybe<Array<Maybe<DocumentMemoOutput>>>;
  message?: Maybe<Scalars['String']['output']>;
  originalLanguage?: Maybe<Language_Code>;
  price?: Maybe<Scalars['Float']['output']>;
  priceChanged?: Maybe<Scalars['DateTime']['output']>;
  promotion?: Maybe<OpenrunPromotionAdminOutput>;
  requested?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<Document_Status>;
  subCoordination?: Maybe<ImageCropCoordinationOutput>;
  subImage?: Maybe<Scalars['String']['output']>;
  subOrigin?: Maybe<Scalars['String']['output']>;
  /** 태그 목록 */
  tags: Array<TagOutput>;
  title?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
  webtoon?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminUpdatePartnerBrandInput = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  cnName?: InputMaybe<Scalars['String']['input']>;
  enName?: InputMaybe<Scalars['String']['input']>;
  godoBrandCd?: InputMaybe<Scalars['String']['input']>;
  isExistBrand?: InputMaybe<Scalars['Boolean']['input']>;
  jpName?: InputMaybe<Scalars['String']['input']>;
  koName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  created?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminUserModel = {
  __typename?: 'AdminUserModel';
  /** 생성 일자 */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** 관리자 이메일 */
  email: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['String']['output'];
  /** 관리자 명 */
  name: Scalars['String']['output'];
  /** 수정 일자 */
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type ApplicationEntity = {
  __typename?: 'ApplicationEntity';
  /** 응용프로그램 ID */
  id: Scalars['Int']['output'];
  /** 응용프로그램 이름 */
  name: Scalars['String']['output'];
};

export type ApplicationOutput = {
  __typename?: 'ApplicationOutput';
  /** 응용프로그램 갯수; 일반적인 쿼리에서는 요청할 일이 없으며, query productApplications()에서 사용 */
  count: Scalars['Int']['output'];
  /** 응용프로그램 ID */
  id: Scalars['Int']['output'];
  /** 응용프로그램 이름 */
  name: Scalars['String']['output'];
};

export type ApplicationPaginatedOutput = {
  __typename?: 'ApplicationPaginatedOutput';
  data: Array<ApplicationOutput>;
  pagination: PaginationOutput;
};

export type ApplyItems = {
  __typename?: 'ApplyItems';
  /** 혜택 적용 설정 값 */
  value?: Maybe<Scalars['String']['output']>;
};

export type ApplyType = {
  __typename?: 'ApplyType';
  /** 혜택 적용 설정 코드 */
  code?: Maybe<Scalars['String']['output']>;
  /** 혜택 적용 설정 코드 ID */
  id?: Maybe<Scalars['Float']['output']>;
  /** 혜택 적용 설정 코드 명 */
  name?: Maybe<Scalars['String']['output']>;
};

export type Asset = {
  __typename?: 'Asset';
  brand: BrandEntity;
  document?: Maybe<SingleDocumentOutput>;
  extensions: Array<Scalars['Int']['output']>;
  funding?: Maybe<AssetFunding>;
  i18n?: Maybe<AssetI18n>;
  i18ns: Array<AssetI18n>;
  id: Scalars['Int']['output'];
  isAdultOnly: Scalars['Boolean']['output'];
  onSaleFrom?: Maybe<Scalars['DateTime']['output']>;
  price: Scalars['Float']['output'];
  salePrice: Scalars['Float']['output'];
  title: Scalars['String']['output'];
};

export type AssetApplicationOutput = {
  __typename?: 'AssetApplicationOutput';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AssetCategoryOutput = {
  __typename?: 'AssetCategoryOutput';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AssetExtensionOutput = {
  __typename?: 'AssetExtensionOutput';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AssetFunding = {
  __typename?: 'AssetFunding';
  deliveryDate: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  goalAmount: Scalars['Int']['output'];
  soldAmount: Scalars['Int']['output'];
  soldVolume: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
  status: FundingStatus;
};

export type AssetFundingInput = {
  assetId: Scalars['Int']['input'];
  /** 상품 전달일 */
  deliveryDate: Scalars['DateTime']['input'];
  /** 펀딩 종료일 */
  endDate: Scalars['DateTime']['input'];
  /** 펀딩 목표 금액 */
  goalAmount: Scalars['Int']['input'];
  /** 펀딩 시작일 */
  startDate: Scalars['DateTime']['input'];
};

export type AssetI18n = {
  __typename?: 'AssetI18n';
  copyright: DocumentCopyrightOutput;
  fileComponents: Array<AssetI18nFileComponent>;
  fileUpdates: Array<AssetI18nFileUpdate>;
  language: Language_Code;
  title: Scalars['String']['output'];
};

export type AssetI18nFileComponent = {
  __typename?: 'AssetI18nFileComponent';
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
};

export type AssetI18nFileUpdate = {
  __typename?: 'AssetI18nFileUpdate';
  date?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AssetI18nOutput = {
  __typename?: 'AssetI18nOutput';
  contentBody?: Maybe<Scalars['String']['output']>;
  contentHead?: Maybe<Scalars['String']['output']>;
  copyright: DocumentCopyrightOutput;
  fileComponents: Array<AssetI18nFileComponent>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  language: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AssetIndustryOutput = {
  __typename?: 'AssetIndustryOutput';
  description: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AssetLicenseOutput = {
  __typename?: 'AssetLicenseOutput';
  id: Scalars['Int']['output'];
  licenseTypeId: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
};

export type AssetLicensePublicOutput = {
  __typename?: 'AssetLicensePublicOutput';
  /** 라이선스 타입 코드 */
  code: Scalars['String']['output'];
  /** 고유 아이디 */
  id: Scalars['Int']['output'];
  /** 라이선스 타입 아이디 */
  licenseTypeId: Scalars['Int']['output'];
  /** 라이선스의 가격 */
  price: Scalars['Int']['output'];
  /** 해당 라이선스 사용 가능 수. 0 인 경우 무제한 */
  usableCount: Scalars['Int']['output'];
};

export type AssetOutput = {
  __typename?: 'AssetOutput';
  applications: Array<AssetApplicationOutput>;
  brandId: Scalars['Int']['output'];
  categories: Array<AssetCategoryOutput>;
  created: Scalars['DateTime']['output'];
  extensions: Array<AssetExtensionOutput>;
  fileName: Scalars['String']['output'];
  filePath: Scalars['String']['output'];
  game?: Maybe<Scalars['Boolean']['output']>;
  i18n: AssetI18nOutput;
  id: Scalars['Int']['output'];
  imgsrc?: Maybe<Scalars['Boolean']['output']>;
  industries: Array<AssetIndustryOutput>;
  isAdultOnly: Scalars['Boolean']['output'];
  language: Language_Code;
  lecture?: Maybe<Scalars['Boolean']['output']>;
  licenses: Array<AssetLicenseOutput>;
  mainImage: Scalars['String']['output'];
  onSaleFrom?: Maybe<Scalars['DateTime']['output']>;
  orderCount: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  promotionEnd?: Maybe<Scalars['DateTime']['output']>;
  promotionName?: Maybe<Scalars['String']['output']>;
  promotionStart?: Maybe<Scalars['DateTime']['output']>;
  released?: Maybe<Scalars['DateTime']['output']>;
  salePrice: Scalars['Float']['output'];
  subImage: Scalars['String']['output'];
  tags: Array<AssetTagOutput>;
  title: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
  webtoon?: Maybe<Scalars['Boolean']['output']>;
};

export type AssetTagOutput = {
  __typename?: 'AssetTagOutput';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AuthType = {
  __typename?: 'AuthType';
  type: Scalars['String']['output'];
};

export enum Brands_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Brands_Input_Sort_Key {
  CreatedAt = 'CREATED_AT',
}

export enum Brand_Output_Brand_Vip_Type {
  Vip = 'VIP',
  Vvip = 'VVIP',
}

export enum Brand_Output_Update_Output_Type {
  Acon3DExclusive = 'ACON3D_EXCLUSIVE',
  Discount = 'DISCOUNT',
  Funding = 'FUNDING',
  NewProduct = 'NEW_PRODUCT',
  Openrun = 'OPENRUN',
  TwinkleSale = 'TWINKLE_SALE',
  VipOrVvip = 'VIP_OR_VVIP',
}

export type BadgeI18n = {
  __typename?: 'BadgeI18n';
  /** 언어 코드 */
  lang: Scalars['String']['output'];
  /** language table 고유번호 */
  langId?: Maybe<Scalars['Float']['output']>;
  /** 뱃지 내용 */
  text: Scalars['String']['output'];
};

export type BadgeI18nInput = {
  /** 언어 코드 */
  lang: Scalars['String']['input'];
  /** 뱃지 내용 */
  text: Scalars['String']['input'];
};

export type BadgeI18nModel = {
  __typename?: 'BadgeI18nModel';
  /** badge table 고유번호 */
  badgeId: Scalars['Float']['output'];
  /** 뱃지 고유 ID */
  id: Scalars['Float']['output'];
  /** language table 고유번호 */
  langId: Scalars['Float']['output'];
  language?: Maybe<Language>;
  /** 뱃지 내용 */
  text: Scalars['String']['output'];
};

export type BadgeProductModel = {
  __typename?: 'BadgeProductModel';
  /** badge table 고유번호 */
  badgeId: Scalars['Float']['output'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['String']['output'];
  /** 뱃지 고유 ID */
  id: Scalars['Float']['output'];
};

export type BadgeReturn = {
  __typename?: 'BadgeReturn';
  /** 뱃지 노출날짜 : 끝날짜 */
  endDate: Scalars['DateTime']['output'];
  /** 고도몰 상품 번호 배열 */
  godoGoodsNos: Array<Scalars['String']['output']>;
  /** badge table 고유번호 */
  id?: Maybe<Scalars['Float']['output']>;
  /** 자동 생성 여부 */
  isAutoCreate?: Maybe<Scalars['Boolean']['output']>;
  /** 뱃지 노출날짜 : 시작날짜 */
  startDate: Scalars['DateTime']['output'];
  /** 상태 */
  status?: Maybe<Scalars['String']['output']>;
  /** 상품 뱃지 문구 */
  texts: Array<BadgeI18n>;
};

export type BandBanner = {
  __typename?: 'BandBanner';
  /** 배경 색 */
  backgroundColor: Scalars['String']['output'];
  /** 닫기 색 */
  closeColor: Scalars['String']['output'];
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 띠 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 띠 배너 클릭시 새창 여부 */
  isBlankHref: Scalars['Boolean']['output'];
  /** 띠 배너 종료 노출 타입 */
  isClosedShow: BandBannerClosed;
  /** 배너를 표시할 다국어몰 */
  language?: Maybe<Language>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 띠 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']['output']>;
  /** 띠 배너 타입 */
  type?: Maybe<BandBannerType>;
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type BandBannerClosed = {
  __typename?: 'BandBannerClosed';
  /** 띠 배너 종료 노출 타입 코드 */
  code: Scalars['String']['output'];
  /** 띠 배너 종료 노출 타입 ID */
  id: Scalars['Float']['output'];
  /** 띠 배너 종료 노출 타입 명 */
  name: Scalars['String']['output'];
};

export type BandBannerItems = {
  __typename?: 'BandBannerItems';
  /** 배경 색 */
  backgroundColor: Scalars['String']['output'];
  /** 닫기 색 */
  closeColor: Scalars['String']['output'];
  /** 종료 노출 타입 */
  closedShow: Scalars['String']['output'];
  /** 종료일자 */
  endDate: Scalars['DateTime']['output'];
  /** 링크 URL */
  href: Scalars['String']['output'];
  /** 띠 배너 텍스트 */
  id: Scalars['Int']['output'];
  /** 새창 띄우기 여부 */
  isBlank: Scalars['Boolean']['output'];
  /** 시작일자 */
  startDate: Scalars['DateTime']['output'];
  /** 띠 배너 텍스트 */
  text: Scalars['String']['output'];
};

export type BandBannerType = {
  __typename?: 'BandBannerType';
  /** 띠 배너 타입 코드 */
  code: Scalars['Float']['output'];
  /** 띠 배너 타입 ID */
  id: Scalars['Float']['output'];
  /** 띠 배너 타입 명 */
  name: Scalars['String']['output'];
};

export type Bank = {
  __typename?: 'Bank';
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type BankOutput = {
  __typename?: 'BankOutput';
  /** 고유 아이디 */
  id: Scalars['Int']['output'];
  /** 은행명 */
  name: Scalars['String']['output'];
};

export type Banner = {
  __typename?: 'Banner';
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFile>;
  /** 배너를 표시할 다국어몰 */
  language?: Maybe<Language>;
  /** 배너를 표시 또는 숨길 상품들 */
  selectedProducts?: Maybe<Array<Product>>;
  /** 선택된 상품만 표시 또는 선택된 상품만 숨기기 */
  showOnlyForSelectedProducts?: Maybe<Scalars['Boolean']['output']>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']['output']>;
  /** 배너타입(표시위치) */
  type?: Maybe<BannerType>;
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type BannerClone = {
  __typename?: 'BannerClone';
  /** 배너를 표시 또는 숨길 상품들 */
  assets?: Maybe<Array<Asset>>;
  /** 배너 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 배너 클릭시 이동 링크 */
  href?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFileClone>;
  languageCode: LanguageCodeEnum;
  /** 선택된 상품만 표시 또는 선택된 상품만 숨기기 */
  showOnlyForSelectedProducts?: Maybe<Scalars['Boolean']['output']>;
  /** 배너 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 배너 바디 텍스트 */
  text?: Maybe<Scalars['String']['output']>;
  /** 배너타입(표시위치) */
  type?: Maybe<BannerTypeClone>;
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type BannerInfo = {
  __typename?: 'BannerInfo';
  /** 배너 목록 */
  bannerList: Array<BannerItem>;
  /** 슬라이드 효과 */
  effect: Scalars['String']['output'];
  /** 배너 높이 */
  height: Scalars['Int']['output'];
  /** 네비게이션 버튼 여부 */
  isNaviButton: Scalars['Boolean']['output'];
  /** 배너 사이즈에 따른 px or % (true : px, false: %) */
  isPx: Scalars['Boolean']['output'];
  /** 사이드 버튼 여부 */
  isSideButton: Scalars['Boolean']['output'];
  /** 네비게이션 버튼 활성화 되었을 때의 색상 */
  naviButtonActiveColor: Scalars['String']['output'];
  /** 네비게이션 버튼 비활성화 되었을 때의 색상 */
  naviButtonColor: Scalars['String']['output'];
  /** 네비게이션 버튼 크기 */
  naviButtonSize: Scalars['Int']['output'];
  /** 네비게이션 버튼 유형 */
  naviButtonType: Scalars['String']['output'];
  /** 사이드 버튼 색상 */
  sideButtonColor: Scalars['String']['output'];
  /** 슬라이드 속도 */
  speed: Scalars['Int']['output'];
  /** 슬라이드 시간 */
  time: Scalars['Int']['output'];
  /** 배너 너비 */
  width: Scalars['Int']['output'];
};

export type BannerItem = {
  __typename?: 'BannerItem';
  /** 색상 */
  color?: Maybe<Scalars['String']['output']>;
  /** 이미지 URL */
  imageUrl: Scalars['String']['output'];
  /** a 태그 옵션의 _blank 여부 */
  isBlank: Scalars['Boolean']['output'];
  /** 링크 URL */
  linkUrl: Scalars['String']['output'];
  /** 기기 타입 */
  type: Scalars['String']['output'];
};

export type BannerType = {
  __typename?: 'BannerType';
  /** 배너 타입 코드(b1, b2, ...) */
  code: Scalars['String']['output'];
  /** 배너 안에 표시되는 내용의 타입(text, image, ...) */
  displayType: Scalars['String']['output'];
  /** 배너 타입 id */
  id: Scalars['Float']['output'];
  /** 배너 타입에 따른 표시 위치 */
  name: Scalars['String']['output'];
};

export type BannerTypeClone = {
  __typename?: 'BannerTypeClone';
  /** 배너 타입 코드(b1, b2, ...) */
  code: Scalars['String']['output'];
  /** 배너 안에 표시되는 내용의 타입(text, image, ...) */
  displayType: Scalars['String']['output'];
  /** 배너 타입 id */
  id: Scalars['Int']['output'];
  /** 배너 타입에 따른 표시 위치 */
  name: Scalars['String']['output'];
};

export type BenefitApply = {
  __typename?: 'BenefitApply';
  /** 포인트 혜택 적용 타입 코드 */
  code: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 포인트 혜택 적용 타입 명 */
  name: Scalars['String']['output'];
};

export type BenefitApplyClone = {
  __typename?: 'BenefitApplyClone';
  /** 포인트 혜택 적용 타입 코드 */
  code: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 포인트 혜택 적용 타입 명 */
  name: Scalars['String']['output'];
};

export type BenefitBurden = {
  __typename?: 'BenefitBurden';
  /** 부담도 */
  burden?: Maybe<Scalars['String']['output']>;
  /** 이름 */
  name: Scalars['String']['output'];
  /** sno */
  sno: Scalars['String']['output'];
};

export type BenefitLimit = {
  __typename?: 'BenefitLimit';
  /** 브랜드 */
  brand?: Maybe<Brand>;
  /** 브랜드 id */
  brandId?: Maybe<Scalars['Float']['output']>;
  /** 카테고리 */
  category?: Maybe<Category>;
  /** 카테고리 */
  categoryId?: Maybe<Scalars['Float']['output']>;
  /** 제한 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 상품 번호들 */
  goodsNo?: Maybe<Scalars['String']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 제한 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 구분 (brand, category, goods) */
  type: Scalars['String']['output'];
};

export type BenefitList = {
  __typename?: 'BenefitList';
  /** 할인률 */
  discountRate?: Maybe<Scalars['Int']['output']>;
  /** 종료 일자 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 차수 */
  roundNo?: Maybe<Scalars['Int']['output']>;
  /** 할인 가격 */
  salePrice?: Maybe<Scalars['String']['output']>;
  /** 시작 일자 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type BenefitType = {
  __typename?: 'BenefitType';
  /** 포인트 혜택 타입 코드 */
  code: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 포인트 혜택 타입 명 */
  name: Scalars['String']['output'];
};

export type BenefitTypeClone = {
  __typename?: 'BenefitTypeClone';
  /** 포인트 혜택 타입 코드 */
  code: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 포인트 혜택 타입 명 */
  name: Scalars['String']['output'];
};

export enum BestProductType {
  All = 'all',
  Day = 'day',
  Month = 'month',
  Week = 'week',
}

export type Brand = {
  __typename?: 'Brand';
  /** 고도몰 브랜드코드 */
  godoBrandCd?: Maybe<Scalars['String']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 게임 브랜드 여부 */
  isGameBrand: Scalars['Boolean']['output'];
  /** 웹툰 브랜드 여부 */
  isWebtoonBrand: Scalars['Boolean']['output'];
  item?: Maybe<Array<BrandI18n>>;
  name: Scalars['String']['output'];
  /** 상품 타입 */
  productType?: Maybe<ProductType>;
};

export type BrandItemArgs = {
  langCode?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type BrandCategoryReturn = {
  __typename?: 'BrandCategoryReturn';
  code: Scalars['String']['output'];
  isLink: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type BrandClone = {
  __typename?: 'BrandClone';
  brandPickIds: Array<Scalars['Int']['output']>;
  godoBrandCd?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  introductionLang?: Maybe<LanguageCodeEnum>;
  modified?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  photo?: Maybe<UploadFileClone>;
  showBrandReview: Scalars['Boolean']['output'];
  useBrandPick: Scalars['Boolean']['output'];
};

export type BrandEntity = {
  __typename?: 'BrandEntity';
  brandPickCount: Scalars['Int']['output'];
  brandPickIds: Array<Scalars['Int']['output']>;
  godoBrandCd?: Maybe<Scalars['String']['output']>;
  i18ns: Array<BrandI18nEntity>;
  id: Scalars['Int']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  introductionLang?: Maybe<LanguageCodeEnum>;
  modified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<UploadFileClone>;
  showBrandReview: Scalars['Boolean']['output'];
  useBrandPick: Scalars['Boolean']['output'];
};

export type BrandI18n = {
  __typename?: 'BrandI18n';
  /** 부모 브랜드 ID  */
  brandId: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  language?: Maybe<Language>;
  name: Scalars['String']['output'];
};

export type BrandI18nClone = {
  __typename?: 'BrandI18nClone';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  languageCode: LanguageCodeEnum;
  name?: Maybe<Scalars['String']['output']>;
};

export type BrandI18nEntity = {
  __typename?: 'BrandI18nEntity';
  introduction?: Maybe<Scalars['String']['output']>;
  languageCode: LanguageCodeEnum;
  name?: Maybe<Scalars['String']['output']>;
};

export type BrandI18nOutput = {
  __typename?: 'BrandI18nOutput';
  brandId: Scalars['Int']['output'];
  introduction?: Maybe<Scalars['String']['output']>;
  languageCode: LanguageCodeEnum;
  name?: Maybe<Scalars['String']['output']>;
};

export type BrandOutput = {
  __typename?: 'BrandOutput';
  /** 브랜드 코드(6자리); nullable; */
  code?: Maybe<Scalars['String']['output']>;
  /** 브랜드 코드(6자리); nullable; */
  godoBrandCd?: Maybe<Scalars['String']['output']>;
  i18ns: Array<BrandI18nOutput>;
  /** 브랜드 ID */
  id: Scalars['Int']['output'];
  /** 브랜드 ACON3D 전속 여부 */
  isAcon3DExclusive: Scalars['Boolean']['output'];
  /** 브랜드 이름 */
  name: Scalars['String']['output'];
  photoInfo?: Maybe<FileOutput>;
  productCount: Scalars['Int']['output'];
  update?: Maybe<BrandOutputUpdateOutput>;
  vipInfo?: Maybe<Brand_Output_Brand_Vip_Type>;
};

export type BrandOutputUpdateOutput = {
  __typename?: 'BrandOutputUpdateOutput';
  /** 타입 */
  type: Brand_Output_Update_Output_Type;
  /** type === "DISCOUNT"인 경우만 해당, 최대 할인율 */
  value?: Maybe<Scalars['Int']['output']>;
};

export type BrandPaginatedOutput = {
  __typename?: 'BrandPaginatedOutput';
  data: Array<BrandOutput>;
  pagination: PaginationOutput;
};

export type BrandPaginatedResponse = {
  __typename?: 'BrandPaginatedResponse';
  count: Scalars['Int']['output'];
  rows: Array<BrandEntity>;
};

export type BrandPick = {
  __typename?: 'BrandPick';
  assets: Array<Asset>;
  isOn: Scalars['Boolean']['output'];
};

export type BrandProductsWithCategoriesOutput = {
  __typename?: 'BrandProductsWithCategoriesOutput';
  /** 카테고리 정보 */
  categories: Array<Acon3dCategory>;
  /** 상품 번호(ProductNo) */
  id: Scalars['Int']['output'];
};

export type BrandPromotionOutput = {
  __typename?: 'BrandPromotionOutput';
  /** 취소일자 */
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  /** 생성(등록)일자, 디폴트소팅필드 */
  createdAt: Scalars['DateTime']['output'];
  /** acon.brand_promotion.id */
  id: Scalars['Int']['output'];
  items: Array<PromotionItemOutput>;
  promotion: PromotionOutput;
  /** 프로모션 id (acon.promote.id) */
  promotionId: Scalars['Int']['output'];
};

export type BrandPublicOutput = {
  __typename?: 'BrandPublicOutput';
  /** 브랜드 코드 */
  code: Scalars['String']['output'];
  /** 브랜드 ID */
  id: Scalars['Int']['output'];
  /** 독점 여부 */
  isExclusive: Scalars['Boolean']['output'];
  /** 브랜드 이름(번역) */
  name: Scalars['String']['output'];
};

export type BrandsInput_Condition = {
  /** 이름(EQUAL 조건) */
  exactName?: InputMaybe<Scalars['String']['input']>;
  /** 이름(LIKE 조건) */
  name?: InputMaybe<Scalars['String']['input']>;
  /** 승인 상태 */
  statuses?: InputMaybe<Array<AcceptStatusEnum>>;
  /** 테스트 브랜드 포함 여부 */
  withTest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandsInput_Sort = {
  /** 정렬 방법 */
  criterion: Brands_Input_Sort_Criterion;
  /** 정렬 기준 필드 */
  key: Brands_Input_Sort_Key;
};

export enum Categories_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Categories_Input_Sort_Key {
  Code = 'CODE',
}

export enum Clips_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Clips_Sort_Key {
  CreatedAt = 'CREATED_AT',
}

export type CancelBrandPromotionInput = {
  /** BrandPromotion의 id */
  id: Scalars['Int']['input'];
};

export type CancelInquiryInput = {
  /** Inquiry ID */
  id: Scalars['Int']['input'];
};

export type CartInfo = {
  __typename?: 'CartInfo';
  /** 중복 상품 여부 */
  duplicationGoods: Scalars['Boolean']['output'];
  /** 상품 이미지 */
  goodsImage: Scalars['String']['output'];
  /** 상품 명 */
  goodsNm: Scalars['String']['output'];
  /** 상품 번호 */
  goodsNo: Scalars['String']['output'];
  /** 중복 구매 상품 여부 */
  isOverlap: Scalars['Boolean']['output'];
  /** 회원 할인 정보 */
  memberDcInfo: Scalars['String']['output'];
  /** 포인트 정보 */
  mileageInfo: Scalars['String']['output'];
  /** 옵션 번호 */
  optionSno: Scalars['String']['output'];
  /** 구매 불가 여부 */
  orderPossible: Scalars['Boolean']['output'];
  /** 결제 수단 설정 여부 */
  payLimitFl: Scalars['Boolean']['output'];
  /** 상품 금액 정보 */
  price: OrderPrice;
  /** 금액 정보 */
  priceInfo: Scalars['String']['output'];
  product?: Maybe<Acon3dProduct>;
  /** 장바구니 일련번호 */
  sno: Scalars['String']['output'];
};

export type CartItem = {
  __typename?: 'CartItem';
  /** 브랜드 코드 */
  brandCd: Scalars['String']['output'];
  /** 브랜드 명 */
  brandName: Scalars['String']['output'];
  /** 확장자들 */
  extensions: Array<Scalars['String']['output']>;
  /** 상품번호 */
  goodsNo: Scalars['Int']['output'];
  /** 이미지 URL */
  imageUrl: Scalars['String']['output'];
  /** 리스트 표시 여부 * 신규추가 */
  isEndSales: Scalars['Boolean']['output'];
  /** 주문 여부 */
  isOrder: Scalars['Boolean']['output'];
  /** 판매 여부 */
  isSale: Scalars['Boolean']['output'];
  /** 지급되어야 할 최대 포인트 */
  point: Scalars['Int']['output'];
  /** 판매가 */
  price: Scalars['Int']['output'];
  /** 정가 */
  realPrice: Scalars['Int']['output'];
  /** sno */
  sno: Scalars['Int']['output'];
  /** 상품 제목 */
  title: Scalars['String']['output'];
};

export type CartOutput = {
  __typename?: 'CartOutput';
  productNo: Scalars['Int']['output'];
};

export type CashItem = {
  __typename?: 'CashItem';
  /** 잔여 캐시 액수 */
  afterDeposit: Scalars['Int']['output'];
  /** 캐시 지급 내용 */
  contents: Scalars['String']['output'];
  /** 캐시 지급 액수 */
  deposit: Scalars['Int']['output'];
  /** 결제 수단 */
  payMethod?: Maybe<Scalars['String']['output']>;
  /** 캐시 지급 일자 */
  regDate: Scalars['String']['output'];
  /** 결제 상태 */
  status?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 정보 */
  vBank?: Maybe<VBank>;
};

export type CashList = {
  __typename?: 'CashList';
  /** 에이콘 캐시 내역 목록 */
  items: Array<CashItem>;
  /** 에이콘 캐시 내역 전체 갯수 */
  totalCount: Scalars['Int']['output'];
};

export type CategoriesInput_Condition = {
  /** 카테고리 코드 배열 */
  codes: Array<Scalars['String']['input']>;
  /** true일 경우 isVisible: true인 카테고리만 반환 */
  visibleOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoriesInput_Sort = {
  criterion: Categories_Input_Sort_Criterion;
  key: Categories_Input_Sort_Key;
};

export type Category = {
  __typename?: 'Category';
  creator?: Maybe<Scalars['Float']['output']>;
  godoCateCd?: Maybe<Scalars['String']['output']>;
  godoSno?: Maybe<Scalars['Float']['output']>;
  i18n: Array<CategoryI18n>;
  /** 카테고리 고유 ID */
  id: Scalars['Float']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Scalars['Float']['output']>;
  sortNo?: Maybe<Scalars['Float']['output']>;
  state: Scalars['String']['output'];
  /** 대분류 */
  type: CategoryTypeEnum;
  updater?: Maybe<Scalars['Float']['output']>;
};

export type CategoryAtom = {
  __typename?: 'CategoryAtom';
  /** 카테고리 코드 */
  code: Scalars['String']['output'];
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
};

export type CategoryClone = {
  __typename?: 'CategoryClone';
  /** 등록 관리자 ID */
  creator?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 카테고리 코드 */
  godoCateCd?: Maybe<Scalars['String']['output']>;
  /** 고도몰 카테고리 sno(시리얼 넘버) */
  godoSno?: Maybe<Scalars['Int']['output']>;
  i18n: CategoryI18nClone;
  /** 카테고리 번역 상세 */
  i18ns?: Maybe<Array<CategoryI18nClone>>;
  id: Scalars['Int']['output'];
  /** 메모 */
  memo?: Maybe<Scalars['String']['output']>;
  /** 부모 카테고리 ID */
  parent?: Maybe<Scalars['Int']['output']>;
  /** 정렬용 번호 */
  sortNo?: Maybe<Scalars['Int']['output']>;
  /** 상태: liveon/standby */
  state: Scalars['String']['output'];
  /** 대분류: toonstyle/realistic */
  type: CategoryTypeEnum;
  /** 갱신 관리자 ID */
  updater?: Maybe<Scalars['Int']['output']>;
};

export type CategoryGoods = {
  cateCd: Scalars['String']['input'];
  goodsNo: Array<Scalars['Float']['input']>;
};

export type CategoryI18n = {
  __typename?: 'CategoryI18n';
  anchor?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Float']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  creator?: Maybe<Scalars['Float']['output']>;
  file?: Maybe<CategoryI18nFile>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  isBlankAnchor?: Maybe<Scalars['Float']['output']>;
  isShowResultCount: Scalars['Float']['output'];
  language?: Maybe<Language>;
  languageCode: Scalars['String']['output'];
  map?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updater?: Maybe<Scalars['Float']['output']>;
};

export type CategoryI18nClone = {
  __typename?: 'CategoryI18nClone';
  /** 허브 카테고리 배너 경로: 링크 */
  anchor?: Maybe<Scalars['String']['output']>;
  categoryId: Scalars['Int']['output'];
  /** 허브 카테고리 배너 색상 */
  color?: Maybe<Scalars['String']['output']>;
  /** 등록 관리자 ID */
  creator?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** 링크 새 창으로 열기 여부 */
  isBlankAnchor?: Maybe<Scalars['Boolean']['output']>;
  /** 상품 목록 결과 표시 여부 */
  isShowResultCount: Scalars['Boolean']['output'];
  languageCode: LanguageCodeEnum;
  /** 허브 카테고리 배너 경로: 이미지맵 */
  map?: Maybe<Scalars['String']['output']>;
  /** 카테고리 이름(번역) */
  name: Scalars['String']['output'];
  /** 갱신 관리자 ID */
  updater?: Maybe<Scalars['Int']['output']>;
};

export type CategoryI18nFile = {
  __typename?: 'CategoryI18nFile';
  categoryI18nId: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  uploadFile?: Maybe<UploadFile>;
  uploadFileId: Scalars['Float']['output'];
};

export type CategoryMolecule = {
  __typename?: 'CategoryMolecule';
  /** 1차 카테고리 */
  primary: CategoryAtom;
  /** 2차 카테고리  */
  secondaries: Array<CategoryAtom>;
};

export type CategoryNode = {
  __typename?: 'CategoryNode';
  /** 카테고리 코드 */
  code: Scalars['String']['output'];
  /** 카테고리 아이디 */
  id: Scalars['Int']['output'];
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 상위 카테고리 */
  parent?: Maybe<Scalars['Int']['output']>;
};

export type CategoryOutput = {
  __typename?: 'CategoryOutput';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** 하위 카테고리 */
  subs?: Maybe<Array<Maybe<CategoryOutput>>>;
};

export type CategoryOutputV2 = {
  __typename?: 'CategoryOutputV2';
  categories: Array<CategoryMolecule>;
  /** 카테고리 분류 장르별=GENRE, 주제별=THEME */
  type: Scalars['String']['output'];
};

export type CategoryOutputV4 = {
  __typename?: 'CategoryOutputV4';
  children: Array<CategoryOutputV4>;
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type CategoryPublicOutput = {
  __typename?: 'CategoryPublicOutput';
  /** 고도 카테고리 코드 */
  code: Scalars['String']['output'];
  /** 카테고리 ID */
  id: Scalars['Int']['output'];
  /** 카테고리 이름(번역) */
  name: Scalars['String']['output'];
};

export enum CategoryTypeEnum {
  Realistic = 'realistic',
  Toonstyle = 'toonstyle',
}

/** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
export type ChangePenNameInput = {
  /** null | personToTeam | teamToPerson */
  changeType?: InputMaybe<ChangeTypeEnum>;
  /** 기존 필명으로 작품을 사용함 여부 */
  isOldPenNameRetained?: InputMaybe<Scalars['Boolean']['input']>;
  /** 필명/작품명 */
  penName: Scalars['String']['input'];
  /** 팀/회사명 */
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export enum ChangeTypeEnum {
  PersonToTeam = 'personToTeam',
  TeamToPerson = 'teamToPerson',
}

export type CheckHasAdultProducts_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 검색어 */
  input?: InputMaybe<Scalars['String']['input']>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']['input']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']['input']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']['input']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ChildCategoriesInput = {
  languageCode: LanguageCodeEnum;
  parentCateCd?: InputMaybe<Scalars['String']['input']>;
};

export type Clip = {
  __typename?: 'Clip';
  created?: Maybe<Scalars['DateTime']['output']>;
  godoGoodsNo: Scalars['Int']['output'];
  godoMemNo: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  product: ProductOutput;
};

export type ClipProductArgs = {
  language: LanguageCodeEnum;
};

export type ClipInquiryInput = {
  /** Inquiry ID */
  inquiryId: Scalars['Int']['input'];
  /** Is currently clipped */
  isClipped: Scalars['Boolean']['input'];
};

export type CompleteOpenrunPromotionInput = {
  /** document id(uuid) */
  id: Scalars['String']['input'];
  /** 프로모션 시작일 */
  since: Scalars['DateTime']['input'];
};

export type CouponApplyCategory = {
  __typename?: 'CouponApplyCategory';
  /** 카테고리 명 */
  name: Scalars['String']['output'];
  /** 카테고리 번호 */
  no: Scalars['String']['output'];
};

export type CouponApplyGoods = {
  __typename?: 'CouponApplyGoods';
  /** 상품명 */
  goodsNm: Scalars['String']['output'];
  /** 상품 번호 */
  goodsNo: Scalars['String']['output'];
};

export type CouponBanner = {
  __typename?: 'CouponBanner';
  /** 조건1 */
  conditionFirst: Scalars['String']['output'];
  /** 조건2 */
  conditionSecond: Scalars['String']['output'];
  /** 쿠폰 번호 */
  couponNo: Scalars['Int']['output'];
  /** 노출 종료 시간 */
  endDate: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 배너 바디 이미지 */
  image?: Maybe<UploadFile>;
  /** 언어 정보 */
  language: Language;
  /** 노출 시작 시간 */
  startDate: Scalars['DateTime']['output'];
  /** 제목 */
  title: Scalars['String']['output'];
};

export type CouponBenefit = {
  __typename?: 'CouponBenefit';
  /** 쿠폰 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<CouponBenefitApply>>;
  /** 쿠폰 혜택 적용 설정 */
  applyType?: Maybe<BenefitApply>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<CouponBenefitBoardInfo>>;
  /** 쿠폰 혜택 생성일 */
  created: Scalars['DateTime']['output'];
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 쿠폰 혜택을 적용할 다국어몰 */
  language?: Maybe<Language>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<CouponBenefitOrderInfo>>;
  /** 쿠폰 헤택 유형 */
  type?: Maybe<BenefitType>;
  /** 쿠폰 혜택 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type CouponBenefitApply = {
  __typename?: 'CouponBenefitApply';
  couponBenefitId: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 적용 설정 값 */
  value: Scalars['String']['output'];
};

export type CouponBenefitBoardInfo = {
  __typename?: 'CouponBenefitBoardInfo';
  couponBenefitId: Scalars['Float']['output'];
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String']['output'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String']['output'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 회원 ID */
  userId: Scalars['String']['output'];
};

export type CouponBenefitItem = {
  __typename?: 'CouponBenefitItem';
  /** 쿠폰 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<ApplyItems>>;
  /** 쿠폰 혜택 적용 설정 */
  applyType?: Maybe<ApplyType>;
  /** 고도몰 쿠폰 명 */
  godoCouponName?: Maybe<Scalars['String']['output']>;
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']['output']>;
  /** 고도몰 쿠폰 등록일 */
  godoCouponRegDt?: Maybe<Scalars['String']['output']>;
  /** 고유 ID */
  id: Scalars['Int']['output'];
  /** 쿠폰 혜택을 적용할 다국어몰 */
  language?: Maybe<Lang>;
  /** 쿠폰 헤택 유형 */
  type?: Maybe<Type>;
};

export type CouponBenefitOrderInfo = {
  __typename?: 'CouponBenefitOrderInfo';
  couponBenefitId: Scalars['Float']['output'];
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String']['output'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean']['output'];
  /** 회원 ID */
  userId: Scalars['String']['output'];
};

export type CouponBurden = {
  __typename?: 'CouponBurden';
  /** 부담도 */
  burden?: Maybe<Scalars['String']['output']>;
  /** 이름 */
  name: Scalars['String']['output'];
  /** sno */
  sno: Scalars['String']['output'];
};

export type CouponItem = {
  __typename?: 'CouponItem';
  /** 쿠폰 사용 가능 카테고리 목록 */
  couponApplyCategory?: Maybe<Array<CouponApplyCategory>>;
  /** 쿠폰 사용 가능 상품 목록 */
  couponApplyGoods?: Maybe<Array<CouponApplyGoods>>;
  /** 쿠폰 사용 가능 타입 (category, brand, goods) */
  couponApplyProductType: Scalars['String']['output'];
  /** 쿠폰 혜택 */
  couponBenefit: Scalars['Int']['output'];
  /** 쿠폰 혜택 타입 (percent, fix) */
  couponBenefitType: Scalars['String']['output'];
  /** 쿠폰 종류(sale, add, deposit) */
  couponKindType: Scalars['String']['output'];
  /** 쿠폰 최대 혜택 */
  couponMaxBenefit: Scalars['Int']['output'];
  /** 최소 주문 금액 */
  couponMinOrderPrice: Scalars['Int']['output'];
  /** 최소 주문 금액 기준 (product, order), default: product */
  couponMinOrderType?: Maybe<Scalars['String']['output']>;
  /** 쿠폰 ID */
  couponNo: Scalars['Int']['output'];
  /** 쿠폰 사용 가능 종료 시간 */
  endDate: Scalars['DateTime']['output'];
  /** 쿠폰 중복 사용 가능 여부 */
  isCouponApplyDuplicate: Scalars['Boolean']['output'];
  /** 쿠폰 최대 혜택 제한 존재 여부 */
  isCouponMaxBenefit: Scalars['Boolean']['output'];
  /** 유저 쿠폰 ID */
  memberCouponNo: Scalars['Int']['output'];
  /** 쿠폰 사용가능 상태 (YES, USE_CART, USE_ORDER, EXPIRATION_START_PERIOD, EXPIRATION_END_PERIOD) */
  memberCouponUsable?: Maybe<Scalars['String']['output']>;
  /** 쿠폰명 */
  name: Scalars['String']['output'];
  /** 쿠폰 발급된 시간 */
  regDt: Scalars['DateTime']['output'];
  /** 쿠폰 사용 가능 시작 시간 */
  startDate: Scalars['DateTime']['output'];
};

export type CouponItems = {
  __typename?: 'CouponItems';
  /** 고도몰 쿠폰 명 */
  godoCouponName?: Maybe<Scalars['String']['output']>;
  /** 고도몰 쿠폰 고유 ID */
  godoCouponNo?: Maybe<Scalars['String']['output']>;
  /** 고도몰 쿠폰 등록일 */
  godoCouponRegDt?: Maybe<Scalars['String']['output']>;
  /** 고도몰 쿠폰 남은 수량 */
  remaining?: Maybe<Scalars['Int']['output']>;
};

export type CouponList = {
  __typename?: 'CouponList';
  /** 쿠폰 내역 목록 */
  items: Array<CouponItem>;
  /** 쿠폰 내역 전체 갯수 */
  totalCount: Scalars['Int']['output'];
};

export type CreateApplicationInput = {
  name: Scalars['String']['input'];
};

export type CreateClipInput = {
  godoGoodsNo: Scalars['Int']['input'];
};

export type CreateEventInput = {
  endDate: Scalars['DateTime']['input'];
  /** 이벤트가 진행되는 몰들을 ,로 구분하여 입력합니다. ex) ko,en,zh */
  malls: Scalars['String']['input'];
  name: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type CreateExtensionConfigInput = {
  name: Scalars['String']['input'];
};

export type CreateGiftInput = {
  benefitDeposit: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isOpen: Scalars['Boolean']['input'];
  languageCode: LanguageCodeEnum;
  price: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type CreateInquiryAnswerInput = {
  /** Answer content */
  answer: Scalars['String']['input'];
  /** Inquiry ID */
  inquiryId: Scalars['Int']['input'];
};

export type CreateInquiryMemoInput = {
  /** Inquiry ID */
  inquiryId: Scalars['Int']['input'];
  /** Memo content */
  memo: Scalars['String']['input'];
};

export type CreateOrderMemoInput = {
  memo: Scalars['String']['input'];
  orderId: Scalars['String']['input'];
};

export type CreatePartnerBrandInput = {
  /** 중국어 표시이름 */
  cnName?: InputMaybe<Scalars['String']['input']>;
  /** 영어 표시이름 */
  enName?: InputMaybe<Scalars['String']['input']>;
  /** 고도몰 브랜드 코드 */
  godoBrandCd?: InputMaybe<Scalars['String']['input']>;
  /** 일본어 표시이름 */
  jpName?: InputMaybe<Scalars['String']['input']>;
  /** 한국어 표시이름 */
  koName?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartnerInput = {
  /** 계정(이메일) */
  account: Scalars['String']['input'];
  /** 회사명 */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** 연락처 */
  contact: Scalars['String']['input'];
  /** 추가 연락처 */
  contactAdditional?: InputMaybe<Scalars['String']['input']>;
  /** 가입언어 */
  joinLanguage: LanguageCodeEnum;
  /** 이름 */
  name: Scalars['String']['input'];
  /** 비밀번호 */
  password: Scalars['String']['input'];
  reference?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 정산정보 */
  settle: CreatePartnerSettleInput;
  snsUrl?: InputMaybe<Scalars['String']['input']>;
  /** 사업자 구분 */
  subType?: InputMaybe<Scalars['String']['input']>;
  /** 사용자 구분(개인, 회사) */
  type: PartnerTypeEnum;
};

export type CreatePartnerSettleFileInput = {
  bankBook?: InputMaybe<StoredFileInput>;
  idCard?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
  sign?: InputMaybe<StoredFileInput>;
};

export type CreatePartnerSettleInput = {
  /** 은행 구분 아이디 */
  bank?: InputMaybe<Scalars['Int']['input']>;
  /** 계좌번호 */
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /** 예금주 */
  bankAccountOwner?: InputMaybe<Scalars['String']['input']>;
  /** 은행 이름 */
  bankName?: InputMaybe<Scalars['String']['input']>;
  /** 국가코드 */
  country: Scalars['String']['input'];
  /** 개인식별번호(주민번호) */
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>;
  /** 계약서 언어 */
  signLang: LanguageCodeEnum;
};

export type CreatePopupInput = {
  /** 버튼 문구 */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** 연결 URL */
  connectedUrl?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean']['input'];
  /** 팝업 이미지 아이디 */
  image?: InputMaybe<PopupUploadFileInput>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  /** 일시 정지용 */
  isStopped?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<LanguageCodeEnum>;
  location?: InputMaybe<PopupLocationV2>;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** 하위 페이지 url */
  subLocations: Array<PopupSubLocationInput>;
  subText?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  urls: Array<CreatePopupUrlInput>;
};

export type CreatePopupUrlInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  popupId?: InputMaybe<Scalars['Int']['input']>;
  subLocation?: InputMaybe<PopupSubLocationEnum>;
  urls: Array<UrlsInput>;
};

export type CurrentPackageOutput = {
  __typename?: 'CurrentPackageOutput';
  brand: Scalars['String']['output'];
  children: PackageChildren;
  parent: Asset;
};

export enum Document_Status {
  Complete = 'COMPLETE',
  CompleteOnOpen = 'COMPLETE_ON_OPEN',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  DraftOnOpen = 'DRAFT_ON_OPEN',
  InReview = 'IN_REVIEW',
  InReviewOnOpen = 'IN_REVIEW_ON_OPEN',
  OnSale = 'ON_SALE',
  OnSaleOnOpen = 'ON_SALE_ON_OPEN',
  Rejected = 'REJECTED',
  RejectedOnOpen = 'REJECTED_ON_OPEN',
  Requested = 'REQUESTED',
  RequestedOnOpen = 'REQUESTED_ON_OPEN',
}

export type DeleteExtensionConfigInput = {
  id: Scalars['Int']['input'];
};

export type DeleteGiftInput = {
  id: Scalars['Int']['input'];
};

export type DeleteOpenrunPromotionInput = {
  /** 브랜드 ID */
  brandId: Scalars['Int']['input'];
  /** document id(uuid) */
  id: Scalars['String']['input'];
};

export type DeletePopupInput = {
  id: Scalars['Int']['input'];
};

export type DeletePromotionConceptInput = {
  id: Scalars['Int']['input'];
};

export type DeletePromotionInput = {
  id: Scalars['Int']['input'];
};

export type DeletePromotionTwinkleAssetInput = {
  /** acon.promote.id */
  promotionId: Scalars['Int']['input'];
  /** acon.promote_item.id */
  promotionItemId: Scalars['Int']['input'];
};

export type DescendantsOfRegularCategoryInput = {
  languageCode: LanguageCodeEnum;
  regularCategoryType: RegularCategoryType;
};

export type DisplayAssetInfoOutput = {
  __typename?: 'DisplayAssetInfoOutput';
  title: Scalars['String']['output'];
};

export type DisplayAssetWithItemsOutput = {
  __typename?: 'DisplayAssetWithItemsOutput';
  assets: ProductPaginatedOutput;
  displayInfo: DisplayAssetInfoOutput;
};

export type DisplayBrandInfoOutput = {
  __typename?: 'DisplayBrandInfoOutput';
  title: Scalars['String']['output'];
};

export type DisplayBrandWithItemsOutput = {
  __typename?: 'DisplayBrandWithItemsOutput';
  brands: BrandPaginatedOutput;
  displayInfo: DisplayBrandInfoOutput;
};

export type DisplayPromotionItem = {
  __typename?: 'DisplayPromotionItem';
  /** 작가명 */
  brandName: Scalars['String']['output'];
  /** 항목 ID */
  id: Scalars['Int']['output'];
  /** 진행기간 */
  periods: Array<Periods>;
  status: Array<Scalars['String']['output']>;
  /** 작품명 */
  title: Scalars['String']['output'];
};

export type DocumentBookInput = {
  isbn: Scalars['String']['input'];
};

export type DocumentBookOutput = {
  __typename?: 'DocumentBookOutput';
  isbn: Scalars['String']['output'];
};

export type DocumentCategoryInput = {
  code: Scalars['String']['input'];
};

export type DocumentChangeHistoryInput = {
  date: Scalars['DateTime']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  person: Scalars['String']['input'];
  status: Document_Status;
};

export type DocumentChangeHistoryOutput = {
  __typename?: 'DocumentChangeHistoryOutput';
  date: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
  person: Scalars['String']['output'];
  status: Document_Status;
};

export type DocumentCommonInput = {
  /** 파일변경 정보 */
  fileUpdate?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentCompleteInput = {
  documentCommonInput: DocumentCommonInput;
  documentFundingInput?: InputMaybe<DocumentFundingInput>;
};

export type DocumentCopyrightInput = {
  commercialSources?: InputMaybe<Scalars['String']['input']>;
  hasRealLogo?: InputMaybe<Scalars['String']['input']>;
  isOriginal?: InputMaybe<Scalars['Boolean']['input']>;
  warehouseSources?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentCopyrightOutput = {
  __typename?: 'DocumentCopyrightOutput';
  commercialSources?: Maybe<Scalars['String']['output']>;
  hasRealLogo?: Maybe<Scalars['String']['output']>;
  isOriginal?: Maybe<Scalars['Boolean']['output']>;
  warehouseSources?: Maybe<Scalars['String']['output']>;
};

export type DocumentFileComponentInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
};

export type DocumentFileComponentOutput = {
  __typename?: 'DocumentFileComponentOutput';
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
};

export type DocumentFileUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentFileUpdateOutput = {
  __typename?: 'DocumentFileUpdateOutput';
  date?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DocumentFundingInput = {
  /** 상품 전달일 */
  deliveryDate: Scalars['DateTime']['input'];
  /** 펀딩 종료일 */
  endDate: Scalars['DateTime']['input'];
  /** 펀딩 목표 금액 */
  goalAmount: Scalars['Int']['input'];
  /** 펀딩 시작일 */
  startDate: Scalars['DateTime']['input'];
};

export type DocumentIndustryInput = {
  id: Scalars['Int']['input'];
};

export type DocumentMemoInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  memoId?: InputMaybe<Scalars['Int']['input']>;
};

export type DocumentMemoOutput = {
  __typename?: 'DocumentMemoOutput';
  content?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Scalars['String']['output']>;
  memoId?: Maybe<Scalars['Int']['output']>;
};

export type DocumentTagInput = {
  id: Scalars['Int']['input'];
};

export type DocumentTitle = {
  __typename?: 'DocumentTitle';
  language?: Maybe<Language_Code>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum DocumentsOrderColumn {
  Created = 'CREATED',
  Requested = 'REQUESTED',
  StatusChanged = 'STATUS_CHANGED',
}

export type DocumentsOrderCriteria = {
  documentsOrderColumn?: InputMaybe<DocumentsOrderColumn>;
  orderBy?: InputMaybe<OrderBy>;
};

export type DownloadCount = {
  __typename?: 'DownloadCount';
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 부여된 다운로드 횟수 */
  givenCount: Scalars['Int']['output'];
  /** 상품 번호 */
  goodsNo: Scalars['Int']['output'];
  /** id */
  id: Scalars['Int']['output'];
  logs?: Maybe<Array<DownloadLog>>;
  /** 주문 번호 */
  orderNo: Scalars['String']['output'];
  /** 수정자 */
  updator: Scalars['Int']['output'];
  /** 유저 id */
  userId: Scalars['String']['output'];
  /** 유저 고유 번호 */
  userNo: Scalars['Int']['output'];
  /** 유저 분류 (personal, enterprise, admin) */
  userType: Scalars['String']['output'];
};

export type DownloadCountInfo = {
  __typename?: 'DownloadCountInfo';
  /** goods number */
  goodsNo: Scalars['Int']['output'];
  /** maximum download count */
  limit: Scalars['Int']['output'];
  /** order number */
  orderNo: Scalars['Float']['output'];
  /** currently remained download count */
  remained: Scalars['Int']['output'];
  /** user ID */
  userId: Scalars['String']['output'];
};

export type DownloadLog = {
  __typename?: 'DownloadLog';
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 생성자 */
  creator?: Maybe<Scalars['Int']['output']>;
  /** 다운로드 카운트 id */
  downloadCountId: Scalars['Float']['output'];
  /** 제공된 다운로드 url */
  downloadUrl: Scalars['String']['output'];
  /** 상품 번호 */
  goodsNo: Scalars['Int']['output'];
  /** id */
  id: Scalars['Int']['output'];
  /** 다운로드 횟수 차감 여부 */
  isDeduction: Scalars['Boolean']['output'];
  /** 제공된 모델 파일 id */
  modelFileId: Scalars['Int']['output'];
  /** 주문 번호 */
  orderNo: Scalars['String']['output'];
  /** 요청 ip */
  requestIp: Scalars['String']['output'];
  /** 유저 id */
  userId: Scalars['String']['output'];
  /** 유저 고유 번호 */
  userNo: Scalars['Int']['output'];
};

export type DownloadLogResponse = {
  __typename?: 'DownloadLogResponse';
  /** 다운로드 카운터 객체 */
  downloadCounts: Array<DownloadCount>;
  /** 가져온 갯수 */
  totalCount: Scalars['Int']['output'];
};

export enum EPromotionDiscountType {
  Fixed = 'FIXED',
  Percent = 'PERCENT',
}

export enum EPromotionStatus {
  Draft = 'DRAFT',
  Ended = 'ENDED',
  InProgress = 'IN_PROGRESS',
  Ready = 'READY',
}

export enum ESearchPromotionKey {
  AssetId = 'ASSET_ID',
  AssetTitle = 'ASSET_TITLE',
  BrandName = 'BRAND_NAME',
  PromotionTitle = 'PROMOTION_TITLE',
}

export type EventBannerItems = {
  __typename?: 'EventBannerItems';
  /** 첫번째 배너 내용 텍스트 */
  bannerTextFirst: Scalars['String']['output'];
  /** 두번쨰 배너 내용 텍스트 */
  bannerTextSecond: Scalars['String']['output'];
  /** 세번째 배너 내용 텍스트 */
  bannerTextThird: Scalars['String']['output'];
  /** 노출 종료 일자 */
  endDate: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Int']['output'];
  /** 배너 이미지 */
  image: UploadFile;
  /** 언어 */
  language: Language;
  /** 정렬순서 */
  order: Scalars['Int']['output'];
  /** 링크 설정 텍스트 */
  settingLink: Scalars['String']['output'];
  /** 노출 시작 일자 */
  startDate: Scalars['DateTime']['output'];
};

export type ExtensionConfig = {
  __typename?: 'ExtensionConfig';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ExtensionOutput = {
  __typename?: 'ExtensionOutput';
  /** 확장자 갯수; 일반적인 쿼리에서는 요청할 일이 없으며, query productExtensions()에서 사용; */
  count: Scalars['Int']['output'];
  /** 확장자 ID */
  id: Scalars['Int']['output'];
  /** 확장자 이름 */
  name: Scalars['String']['output'];
};

export type ExtensionPaginatedOutput = {
  __typename?: 'ExtensionPaginatedOutput';
  data: Array<ExtensionOutput>;
  pagination: PaginationOutput;
};

export type Faq = {
  __typename?: 'Faq';
  /** FAQ 내용(바디) */
  body?: Maybe<Scalars['String']['output']>;
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 고정글인 경우 고정 순서(번호) */
  fixed?: Maybe<Scalars['Float']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** FAQ를 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** FAQ 제목 */
  title?: Maybe<Scalars['String']['output']>;
  /** FAQ 타입(노하우, 가이드, ...) */
  type?: Maybe<FaqType>;
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type FaqAconModel = {
  __typename?: 'FaqAconModel';
  /** ACONFAQ 내용(바디) */
  body: Scalars['String']['output'];
  /** 생성일 */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** ACONFAQ를 표시할 다국어몰 */
  language: Language;
  /** 표시 순서(번호) */
  order: Scalars['Float']['output'];
  /** ACONFAQ 질문형 제목 (에이콘 상세 페이지에서 노출) */
  subtitle: Scalars['String']['output'];
  /** ACONFAQ 제목 */
  title: Scalars['String']['output'];
  /** ACONFAQ 타입(노하우, 가이드, ...) */
  type: FaqAconTypeModel;
  /** 수정일 */
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type FaqAconTypeI18nModel = {
  __typename?: 'FaqAconTypeI18nModel';
  id: Scalars['Float']['output'];
  language: Language;
  name?: Maybe<Scalars['String']['output']>;
};

export type FaqAconTypeModel = {
  __typename?: 'FaqAconTypeModel';
  /** ACONFAQ 타입 코드(usage, product, ...) */
  code: Scalars['String']['output'];
  /** ACONFAQ 타입의 각 언어별 이름 */
  i18n?: Maybe<Array<FaqAconTypeI18nModel>>;
  /** ACONFAQ 타입 id */
  id: Scalars['Float']['output'];
};

export type FaqCreatorModel = {
  __typename?: 'FaqCreatorModel';
  /** 내용 */
  body: Scalars['String']['output'];
  /** 생성일 */
  created?: Maybe<Scalars['DateTime']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 언어 */
  lang: Scalars['String']['output'];
  /** 표시 순서(번호) */
  order: Scalars['Float']['output'];
  /** 질문형 제목 */
  subtitle: Scalars['String']['output'];
  /** 제목 */
  title: Scalars['String']['output'];
  /** ACONFAQ 타입(노하우, 가이드, ...) */
  type: FaqCreatorTypeModel;
  /** 수정일 */
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type FaqCreatorTypeI18nModel = {
  __typename?: 'FaqCreatorTypeI18nModel';
  id: Scalars['Float']['output'];
  lang: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type FaqCreatorTypeModel = {
  __typename?: 'FaqCreatorTypeModel';
  /** code */
  code: Scalars['String']['output'];
  /** ACONFAQ 타입의 각 언어별 이름 */
  i18n?: Maybe<Array<FaqCreatorTypeI18nModel>>;
  /** id */
  id: Scalars['Float']['output'];
};

export type FaqType = {
  __typename?: 'FaqType';
  /** FAQ 타입 코드(knowhow, guide, ...) */
  code: Scalars['String']['output'];
  /** FAQ 타입 id */
  id: Scalars['Float']['output'];
  /** FAQ 타입 이름(노하우, 가이드, ...) */
  name: Scalars['String']['output'];
};

export type FetchPromotionsSearchInput = {
  conceptId?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<ESearchPromotionKey>;
  /** string | number | null */
  keyword?: InputMaybe<Scalars['JSON']['input']>;
  since?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<EPromotionStatus>;
  until?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FileInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Float']['input']>;
};

export type FileOutput = {
  __typename?: 'FileOutput';
  /** 파일명 */
  fileName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** 파일 위치 */
  path?: Maybe<Scalars['String']['output']>;
  /** 파일 크기 */
  size?: Maybe<Scalars['Int']['output']>;
};

export enum FundingStatus {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  Unready = 'UNREADY',
}

export type Get = {
  __typename?: 'Get';
  id: Scalars['Float']['output'];
  message?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  userId: Scalars['Float']['output'];
};

export type GetBrandOrderProductItemsAssetInput = {
  /** 상품코드(아이디) */
  assetId?: InputMaybe<Scalars['Int']['input']>;
};

export type GetBrandOrderProductItemsInput = {
  /** 상품 검색 조건 */
  asset?: InputMaybe<GetBrandOrderProductItemsAssetInput>;
  /** 무료 상품 여부 */
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  /** 환불(취소)여부 */
  isRefunded?: InputMaybe<Scalars['Boolean']['input']>;
  /** 주문 검색 조건 */
  order?: InputMaybe<GetBrandOrderProductItemsOrderInput>;
};

export type GetBrandOrderProductItemsOrderInput = {
  /** 라이선스 검색 조건 */
  license?: InputMaybe<GetBrandOrderProductItemsOrderLicenseInput>;
  /** 기간 검색 조건 */
  period?: InputMaybe<GetBrandOrderProductItemsOrderPeriodInput>;
};

export type GetBrandOrderProductItemsOrderLicenseInput = {
  /** 라이선스 검색 대상 */
  field?: InputMaybe<GetOrderLicenseNameField>;
  /** 라이선스 검색 값 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** 라이선스 타입 */
  type?: InputMaybe<OrderLicenseType>;
};

export type GetBrandOrderProductItemsOrderPeriodInput = {
  /** 기간 검색 대상 */
  field: GetOrderProductPeriodField;
  /** 시작일시 */
  since: Scalars['DateTime']['input'];
  /** 종료일시 */
  until: Scalars['DateTime']['input'];
};

export type GetClipsProductsCondition = {
  /** 상품 판매 상태 */
  isOnSale?: InputMaybe<Scalars['Boolean']['input']>;
  /** 할인 여부 */
  isPromote?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetClipsSort = {
  /** 정렬 방법 */
  criterion: Clips_Sort_Criterion;
  /** 정렬 기준 키 */
  key: Clips_Sort_Key;
};

export enum GetOrderLicenseNameField {
  Author = 'author',
  Project = 'project',
}

export enum GetOrderProductPeriodField {
  CreatedAt = 'createdAt',
  SettledAt = 'settledAt',
}

export type Gift = {
  __typename?: 'Gift';
  benefitDeposit: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isOpen: Scalars['Boolean']['output'];
  languageCode: LanguageCodeEnum;
  paybackPoint: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type GodoBanner = {
  __typename?: 'GodoBanner';
  /** 링크 */
  anchor?: Maybe<Scalars['String']['output']>;
  /** 링크 새 창으로 열기 여부 */
  anchorTarget: Scalars['Boolean']['output'];
  /** 색상 */
  color?: Maybe<Scalars['String']['output']>;
  /** 이미지 경로 */
  image?: Maybe<Scalars['String']['output']>;
  /** 이미지 맵 */
  map?: Maybe<Scalars['String']['output']>;
};

export type GodoCategory = {
  __typename?: 'GodoCategory';
  /** 고도몰 카테고리 코드 */
  id: Scalars['String']['output'];
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 다국어 데이터 */
  trans: Array<GodoTrans>;
  /** 카테고리 대분류 */
  type?: Maybe<Scalars['String']['output']>;
};

export type GodoCategoryOutput = {
  __typename?: 'GodoCategoryOutput';
  /** 카테고리 코드 */
  code: Scalars['String']['output'];
  /**
   * 링크 여부
   * @deprecated 사용하지 않음. 필요해질 경우 DB에 별도의 필드를 만들 것
   */
  isLink: Scalars['Boolean']['output'];
  /**
   * 표시 여부
   * @deprecated 사용하지 않음. 필요해질 경우 DB에 별도의 필드를 만들 것
   */
  isVisible: Scalars['Boolean']['output'];
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
};

export type GodoCosmicDeal = {
  __typename?: 'GodoCosmicDeal';
  /** 혜택명 */
  benefitNm: Scalars['String']['output'];
  /** 할인금액 */
  goodsPrice: Scalars['Int']['output'];
  /** 혜택할인 종료기간 */
  periodDiscountEnd: Scalars['DateTime']['output'];
  /** 혜택할인 시작기간 */
  periodDiscountStart: Scalars['DateTime']['output'];
  /** sno */
  sno: Scalars['Int']['output'];
};

export type GodoDisplayCategory = {
  __typename?: 'GodoDisplayCategory';
  /** 카테고리 코드 */
  code: Scalars['String']['output'];
  /** 링크 여부 */
  isLink: Scalars['Boolean']['output'];
  /** 표시 여부 */
  isVisible: Scalars['Boolean']['output'];
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
};

export type GodoDisplayProductModel = {
  __typename?: 'GodoDisplayProductModel';
  /** 고도 표시 ID  */
  godoDisplayId: Scalars['Float']['output'];
  /** 상품 번호 */
  goodsNo: Scalars['Int']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 정렬 순서 */
  sortNo: Scalars['Int']['output'];
};

export type GodoMainGropTrans = {
  __typename?: 'GodoMainGropTrans';
  /** 상태 */
  lang: Scalars['String']['output'];
  /** 이름 */
  name: Scalars['String']['output'];
};

export type GodoMainGroup = {
  __typename?: 'GodoMainGroup';
  /** 이름 */
  name: Scalars['String']['output'];
  /** 상태 */
  sno: Scalars['Int']['output'];
  /** 하위 항목들 */
  trans: Array<GodoMainGropTrans>;
};

export type GodoPopupInfo = {
  __typename?: 'GodoPopupInfo';
  /** 오늘 하루 보지 않음 기능 사용여부 */
  content: Scalars['String']['output'];
  /** 팝업 높이(ex: 100px / 50%) */
  height: Scalars['String']['output'];
  /** 오늘 하루 보지 않음 기능 사용여부 */
  isUseTodayHide: Scalars['Boolean']['output'];
  /** 오늘하루 보지않음 텍스트 위치 */
  todayHideAlign: Scalars['String']['output'];
  /** 오늘하루 보지않음 배경색상 */
  todayHideBgColor: Scalars['String']['output'];
  /** 오늘하루 보지않음 폰트색상 */
  todayHideTextColor: Scalars['String']['output'];
  /** 팝업 너비 (ex: 100px / 50%) */
  width: Scalars['String']['output'];
};

export type GodoProductBrand = {
  __typename?: 'GodoProductBrand';
  brandCd: Scalars['String']['output'];
  /** 정렬 순서 (거의 쓰지 않음) */
  fixSort: Scalars['Int']['output'];
  godoProductId: Scalars['Int']['output'];
  /** 상품 정렬 순서 */
  goodsSort: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type GodoProductBrandModel = {
  __typename?: 'GodoProductBrandModel';
  brandCd: Scalars['String']['output'];
  fixSort: Scalars['Float']['output'];
  /** 고도 상품 ID  */
  godoProductId: Scalars['Float']['output'];
  goodsSort: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
};

export type GodoProductCategory = {
  __typename?: 'GodoProductCategory';
  cateCd: Scalars['String']['output'];
  categoryDetail?: Maybe<CategoryClone>;
  fixSort: Scalars['Int']['output'];
  godoProductId: Scalars['Int']['output'];
  goodsSort: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type GodoProductCategoryModel = {
  __typename?: 'GodoProductCategoryModel';
  cateCd: Scalars['String']['output'];
  categoryDetail?: Maybe<Category>;
  fixSort: Scalars['Float']['output'];
  /** 고도 상품 ID  */
  godoProductId: Scalars['Float']['output'];
  goodsSort: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
};

export type GodoTrans = {
  __typename?: 'GodoTrans';
  /** 배너 데이터 */
  banner: GodoBanner;
  /** 언어 코드 */
  lang: Scalars['String']['output'];
  /** 카테고리 이름 */
  name: Scalars['String']['output'];
  /** 검색 결과 창 표시 여부 */
  visibleResultStats: Scalars['Boolean']['output'];
};

export type GodoUserFriend = {
  __typename?: 'GodoUserFriend';
  /** 추천 코드 */
  code: Scalars['String']['output'];
  /** 고도몰 사용자 ID */
  godoMemNo: Scalars['Int']['output'];
  /** 코드 */
  godoUserInvitationCode: GodoUserInvitationCode;
  /** UUID */
  id: Scalars['String']['output'];
  /** 구매여부 */
  isOrder: Scalars['Boolean']['output'];
  /** 사용자 언어 */
  langCode: Scalars['String']['output'];
};

export type GodoUserInvitationCode = {
  __typename?: 'GodoUserInvitationCode';
  /** 추천 코드 */
  code: Scalars['String']['output'];
  /** 친구들 */
  friends?: Maybe<Array<GodoUserFriend>>;
  /** 고도몰 사용자 ID */
  godoMemNo: Scalars['Int']['output'];
  /** 언어코드 */
  langCode: Scalars['String']['output'];
};

export type GoodsBuyers = {
  __typename?: 'GoodsBuyers';
  /** 상품 id */
  goodsNo: Scalars['Float']['output'];
  /** 주문자 그룹 이름 */
  groupNm: Scalars['String']['output'];
  /** 주문 정보 메일 */
  orderEmail: Scalars['String']['output'];
  /** 주문 정보 이름 */
  orderName: Scalars['String']['output'];
  /** 주문 id */
  orderNo: Scalars['String']['output'];
};

export type GqlRegional = {
  __typename?: 'GqlRegional';
  EN?: Maybe<Scalars['String']['output']>;
  JA?: Maybe<Scalars['String']['output']>;
  KO?: Maybe<Scalars['String']['output']>;
  ZH?: Maybe<Scalars['String']['output']>;
};

export type GqlRegionalInput = {
  EN: Scalars['String']['input'];
  JA: Scalars['String']['input'];
  KO: Scalars['String']['input'];
  ZH: Scalars['String']['input'];
};

export type GqlRegionalInputNullable = {
  EN?: InputMaybe<Scalars['String']['input']>;
  JA?: InputMaybe<Scalars['String']['input']>;
  KO?: InputMaybe<Scalars['String']['input']>;
  ZH?: InputMaybe<Scalars['String']['input']>;
};

export type Group = {
  __typename?: 'Group';
  auth: Array<GroupAuth>;
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type GroupAuth = {
  __typename?: 'GroupAuth';
  authType: AuthType;
  id: Scalars['Float']['output'];
  menu: Menu;
};

export type GroupOutput = {
  __typename?: 'GroupOutput';
  /** ID */
  id: Scalars['Int']['output'];
  /** 그룹명 */
  name: Scalars['String']['output'];
};

export type HideAssetsInput = {
  /** asset id를 배열로 */
  assetIds: Array<Scalars['Int']['input']>;
};

export type HubAdminPost = {
  __typename?: 'HubAdminPost';
  /** 고정글인 경우 순서 */
  anchoringOrder?: Maybe<Scalars['Int']['output']>;
  body: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  next?: Maybe<HubAdminPost>;
  prev?: Maybe<HubAdminPost>;
  title: Scalars['String']['output'];
  typeId: Scalars['Int']['output'];
  typeName: Scalars['String']['output'];
};

export type HubAdminPosts = {
  __typename?: 'HubAdminPosts';
  /** 고정글 */
  anchors: Array<HubAdminPost>;
  /** 일반글 */
  regulars: Array<HubAdminPost>;
};

export type HubAssetOutput = {
  __typename?: 'HubAssetOutput';
  id: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type IGodoOrderGoodsNo = {
  __typename?: 'IGodoOrderGoodsNo';
  /** 고도몰 상품번호 */
  goodsNo: Scalars['String']['output'];
  /** 가상계좌 여부 */
  isVirtualAccount: Scalars['Boolean']['output'];
  /** 고도몰 주문번호 */
  orderNo: Scalars['String']['output'];
  /** 주문 상태 */
  orderStatus: OrderStatusEnum;
  /** 주문 생성날짜 */
  regDt: Scalars['String']['output'];
};

export enum Industries_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Industries_Input_Sort_Key {
  Id = 'ID',
}

export type ImageCropCoordinationInput = {
  height: Scalars['Float']['input'];
  width: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type ImageCropCoordinationOutput = {
  __typename?: 'ImageCropCoordinationOutput';
  height: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type ImageKeys = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type IndustriesInput_Condition = {
  /** ID 배열 */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IndustriesInput_Sort = {
  /** 요청 정렬 방법 */
  criterion: Industries_Input_Sort_Criterion;
  /** 요청 정렬 기준 */
  key: Industries_Input_Sort_Key;
};

export type IndustryOutput = {
  __typename?: 'IndustryOutput';
  /** 산업분야 ID; asset_industry.id; */
  id: Scalars['Int']['output'];
  /** 산업분야 이름 */
  name: Scalars['String']['output'];
};

export type IndustryPaginatedOutput = {
  __typename?: 'IndustryPaginatedOutput';
  data: Array<IndustryOutput>;
  pagination: PaginationOutput;
};

export type Inquiry = {
  __typename?: 'Inquiry';
  /** 문의 답변 */
  answer?: Maybe<InquiryAnswer>;
  /** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
  changePenName?: Maybe<InquiryChangePenName>;
  content: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  godoMemNo?: Maybe<Scalars['String']['output']>;
  godoUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  inquiryAcon3dProduct?: Maybe<Acon3dProduct>;
  /** 결제-주문/결제(G1) */
  inquiryOrders?: Maybe<Array<Maybe<InquiryOrder>>>;
  /** 상품상세문의(F1) */
  inquiryProduct?: Maybe<TempAconProduct>;
  /** 스크랩 여부 */
  isClipped?: Maybe<Scalars['Boolean']['output']>;
  /** 회원 여부 */
  isMember: Scalars['Boolean']['output'];
  languageCode: LanguageCodeEnum;
  /** 관리자 메모 */
  memos: Array<InquiryMemo>;
  refundAccount?: Maybe<InquiryRefundAccount>;
  /** 상태: unread | read | answered */
  status: InquiryStatusEnum;
  /** 문의유형 소분류 */
  subType?: Maybe<InquirySubTypeEnum>;
  /** 문의유형 대분류 */
  type: InquiryTypeEnum;
  updated?: Maybe<Scalars['DateTime']['output']>;
  uploadFiles: Array<UploadFile>;
};

export type InquiryInquiryAcon3dProductArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
};

export type InquiryAnswer = {
  __typename?: 'InquiryAnswer';
  adminUser: AdminUser;
  answer: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type InquiryChangePenName = {
  __typename?: 'InquiryChangePenName';
  /** 변경할 사용권 유형: null(ChangeMyself) | personToTeam | teamToPerson */
  changeType?: Maybe<ChangeTypeEnum>;
  /** 기존 필명으로 작품을 사용함 여부 */
  isOldPenNameRetained: Scalars['Boolean']['output'];
  /** 필명/작품명 */
  penName: Scalars['String']['output'];
  /** 팀/회사명 */
  teamName?: Maybe<Scalars['String']['output']>;
};

export type InquiryInput = {
  /** 이용-필명/작품명 변경(D1), 이용-사용권 변경(E1) */
  changePenName?: InputMaybe<ChangePenNameInput>;
  /** 기본값은 빈 문자열 */
  content?: InputMaybe<Scalars['String']['input']>;
  /** 답변 받을 이메일 */
  email?: InputMaybe<Scalars['String']['input']>;
  /** 결제-주문/결제(G1), 해당하지 않으면 null */
  inquiryOrders?: InputMaybe<Array<InputMaybe<InquiryOrderInput>>>;
  /** 상품상세문의(F1) */
  inquiryProduct?: InputMaybe<InquiryProductInput>;
  languageCode: LanguageCodeEnum;
  /** 결제-취소/환불(H1-H3) */
  refundAccount?: InputMaybe<RefundAccountInput>;
  /** 문의 소분류 */
  subType?: InputMaybe<InquirySubTypeEnum>;
  /** 세금계산서(B2) */
  taxes?: InputMaybe<TaxesInput>;
  /** 문의 대분류 */
  type: InquiryTypeEnum;
  /** 첨부파일. 없으면 빈 배열을 보냅니다 */
  uploadFiles: Array<Scalars['Int']['input']>;
};

export type InquiryMemo = {
  __typename?: 'InquiryMemo';
  adminUser: AdminUser;
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  memo: Scalars['String']['output'];
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type InquiryOrder = {
  __typename?: 'InquiryOrder';
  godoGoodsDetails: Array<InquiryOrderGoodsDetail>;
  godoOrderNo: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type InquiryOrderGoodsDetail = {
  __typename?: 'InquiryOrderGoodsDetail';
  godoGoodsNo: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  orderStatus: Scalars['Float']['output'];
};

/** Subtype of InquiryOrderInput */
export type InquiryOrderGoodsDetailInput = {
  godoGoodsNo: Scalars['String']['input'];
  orderStatus: OrderStatusEnum;
};

/** 결제-주문/결제(G1) */
export type InquiryOrderInput = {
  godoGoodsDetails: Array<InquiryOrderGoodsDetailInput>;
  godoOrderNo: Scalars['String']['input'];
};

/** 상품상세문의(F1) */
export type InquiryProductInput = {
  /** 상품 고도몰 번호 */
  goodsNo: Scalars['Int']['input'];
};

export type InquiryRefundAccount = {
  __typename?: 'InquiryRefundAccount';
  accountHolder: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bankName: Scalars['String']['output'];
};

export enum InquiryStatusEnum {
  Answered = 'answered',
  Cancelled = 'cancelled',
  Read = 'read',
  Unread = 'unread',
}

export enum InquirySubTypeEnum {
  Abler = 'abler',
  AblurError = 'ablurError',
  AblurOld = 'ablurOld',
  AblurOther = 'ablurOther',
  AblurProgram = 'ablurProgram',
  AblurRefund = 'ablurRefund',
  AblurSubscribe = 'ablurSubscribe',
  CancelAndRefund = 'cancelAndRefund',
  ChangeOrderLicense = 'changeOrderLicense',
  ChangePenName = 'changePenName',
  CopyrightAndOrderLicense = 'copyrightAndOrderLicense',
  Improvement = 'improvement',
  OrderAndPayment = 'orderAndPayment',
  Other = 'other',
  ProductError = 'productError',
  ProductInfo = 'productInfo',
  SiteError = 'siteError',
}

export enum InquiryTypeEnum {
  Ablur = 'ablur',
  CollaborationProposal = 'collaborationProposal',
  Payment = 'payment',
  Product = 'product',
  Service = 'service',
  Taxes = 'taxes',
  Utilization = 'utilization',
}

export type IssueEventCouponInput = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export enum KeywordTypeEnum {
  Contents = 'contents',
  Goods = 'goods',
  User = 'user',
}

export enum Language_Code {
  En = 'EN',
  Ja = 'JA',
  Ko = 'KO',
  Zh = 'ZH',
}

export type Lang = {
  __typename?: 'Lang';
  /** 언어 코드 */
  code?: Maybe<Scalars['String']['output']>;
  /** 언어 ID */
  id?: Maybe<Scalars['Float']['output']>;
};

export type Language = {
  __typename?: 'Language';
  /** 언어 코드(ko, en, cn, jp) */
  code: Scalars['String']['output'];
  /** 언어 id */
  id: Scalars['Float']['output'];
  /** 언어 이름(한국어, 영어, ...) */
  name: Scalars['String']['output'];
};

export type LanguageClone = {
  __typename?: 'LanguageClone';
  /** 언어 코드(ko, en, cn, jp) */
  code: Scalars['String']['output'];
  /** 언어 id */
  id: Scalars['Float']['output'];
  /** 언어 이름(한국어, 영어, ...) */
  name: Scalars['String']['output'];
};

export enum LanguageCodeEnum {
  En = 'en',
  Ja = 'ja',
  Ko = 'ko',
  Zh = 'zh',
}

export type LanguageTextInput = {
  lang: LanguageCodeEnum;
  text: Scalars['String']['input'];
};

export type LicenseClone = {
  __typename?: 'LicenseClone';
  companyName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  linkedOrderProductItems?: Maybe<Array<OrderProductItemClone>>;
  memNo: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: OrderProductClone;
  orderId: Scalars['String']['output'];
  status: LicenseStatus;
  type: LicenseType;
};

export enum LicenseStatus {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Ready = 'ready',
}

export enum LicenseType {
  Company = 'company',
  Personal = 'personal',
}

export type LicenseTypeOptionInput = {
  /** 라이선스 타입 아이디 */
  licenseTypeId: Scalars['Int']['input'];
  /** 라이선스의 가격 */
  price: Scalars['Float']['input'];
};

export type LicenseTypeOptionOutput = {
  __typename?: 'LicenseTypeOptionOutput';
  /** 라이선스 타입 아이디 */
  licenseTypeId: Scalars['Int']['output'];
  /** 라이선스의 가격 */
  price: Scalars['Float']['output'];
};

export type LicenseTypeOutput = {
  __typename?: 'LicenseTypeOutput';
  /** 라이선스 타입 코드 */
  code: Scalars['String']['output'];
  /** 고유 아이디 */
  id: Scalars['Int']['output'];
  /** 해당 라이선스 사용 가능 수. 0 인 경우 무제한 */
  usableCount: Scalars['Int']['output'];
};

export type LicenseUpdateHistory = {
  __typename?: 'LicenseUpdateHistory';
  adminUser: AdminUser;
  /** 데이터 전체 JSON */
  contents?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  orderId: Scalars['String']['output'];
};

export type ListDocumentOutput = {
  __typename?: 'ListDocumentOutput';
  assetId?: Maybe<Scalars['Int']['output']>;
  created: Scalars['DateTime']['output'];
  game?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  imgsrc?: Maybe<Scalars['Boolean']['output']>;
  isAdultOnly?: Maybe<Scalars['Boolean']['output']>;
  language: Language_Code;
  lecture?: Maybe<Scalars['Boolean']['output']>;
  mainImage?: Maybe<Scalars['String']['output']>;
  requested?: Maybe<Scalars['DateTime']['output']>;
  status: Partner_Document_Status;
  title?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  webtoon?: Maybe<Scalars['Boolean']['output']>;
};

export type MainGroup = {
  __typename?: 'MainGroup';
  /** 고도몰 sno */
  godoSno: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  item?: Maybe<Array<MainGroupI18n>>;
  name: Scalars['String']['output'];
};

export type MainGroupGoods = {
  goodsNo: Array<Scalars['Float']['input']>;
  sno: Scalars['Float']['input'];
};

export type MainGroupI18n = {
  __typename?: 'MainGroupI18n';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  language?: Maybe<Language>;
  /** 부모 메인그룹 ID  */
  mainGroupId: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type MainProductListV2Return = {
  __typename?: 'MainProductListV2Return';
  /** 상품 리스트 */
  productList: Array<Scalars['Int']['output']>;
  /** 고도몰 메인구좌 관리번호 */
  sno: Scalars['String']['output'];
  /** 경로 */
  source: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type MemberDepositDetailOutput = {
  __typename?: 'MemberDepositDetailOutput';
  giftDeposit: Scalars['Float']['output'];
  purchaseDeposit: Scalars['Float']['output'];
};

export type MemberGiftHistory = {
  __typename?: 'MemberGiftHistory';
  changeAmount: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  giftResidualAmount: Scalars['Int']['output'];
  memNo: Scalars['Int']['output'];
  orderProduct?: Maybe<OrderProductClone>;
  totalResidualAmount: Scalars['Int']['output'];
};

export type Menu = {
  __typename?: 'Menu';
  name: Scalars['String']['output'];
};

export type ModelConfig = {
  __typename?: 'ModelConfig';
  i18n: Array<ModelConfigI18n>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 이름 */
  name: Scalars['String']['output'];
};

export type ModelConfigI18n = {
  __typename?: 'ModelConfigI18n';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  language?: Maybe<Language>;
  /** 모델구성 id */
  modelConfigId: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type ModelConfigI18nClone = {
  __typename?: 'ModelConfigI18nClone';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  abort: Scalars['Boolean']['output'];
  acceptTermRenew: Scalars['Boolean']['output'];
  adminStoreBrandIntroductions: Array<BrandI18nClone>;
  adminStoreBrandPhoto?: Maybe<UploadFileClone>;
  attendanceEvent: Scalars['Boolean']['output'];
  augustAttendanceEvent: Scalars['Boolean']['output'];
  authenticate: Scalars['Boolean']['output'];
  cancelBrandPromotion: Scalars['Boolean']['output'];
  /** Cancel one inquiry */
  cancelInquiry: Inquiry;
  changePartnerSettleStatus: Scalars['Boolean']['output'];
  changePasswordPartner: UserOutput;
  changeReviewStatus: Scalars['Boolean']['output'];
  /** Clip/Unclip an Inquiry */
  clipInquiry: Inquiry;
  complete: Scalars['Boolean']['output'];
  completeOpenrunPromotion: OpenrunPromotionAdminOutput;
  createAconNotification?: Maybe<AconNotification>;
  createAdminPartner: Scalars['Boolean']['output'];
  createApplication: ApplicationEntity;
  createBadge: Scalars['Boolean']['output'];
  createBandBanner: BandBanner;
  createBanner: BannerClone;
  /** 사업자 정보가 맞는지 확인하고 등록하는 메소드 입니다. */
  createBusiness: Scalars['Boolean']['output'];
  createCategory: Category;
  createClip?: Maybe<Clip>;
  createCouponBenefit: CouponBenefit;
  createEvent: Scalars['Boolean']['output'];
  /** 이벤트 배너를 생성하는 메소드입니다. */
  createEventBanner: Scalars['Boolean']['output'];
  createExtensionConfig: ExtensionConfig;
  createFaq: Faq;
  createFaqAcon: FaqAconModel;
  createFaqCreator: FaqCreatorModel;
  /** 친구를 추가하는 뮤테이션 */
  createFriend: Scalars['Boolean']['output'];
  createGift: Gift;
  createInquiry: Inquiry;
  createInquiryAnswer: Inquiry;
  createInquiryMemo: Inquiry;
  createNotice: Notice;
  createOrderMemo: OrderAdminMemo;
  createPartner: UserOutput;
  createPointBenefit: PointBenefit;
  createPopup: PopupV2;
  createTermAgree: Scalars['Boolean']['output'];
  deleteAconNotification: Scalars['Int']['output'];
  deleteAconfaq: Scalars['Boolean']['output'];
  deleteBadge: Scalars['Boolean']['output'];
  deleteBandBanner: Scalars['Boolean']['output'];
  deleteBanner: Scalars['Boolean']['output'];
  deleteCartCache: Scalars['Boolean']['output'];
  deleteCategory: Scalars['Boolean']['output'];
  deleteCouponBenefit: Scalars['Boolean']['output'];
  deleteDocument: Scalars['Boolean']['output'];
  /** 이벤트 배너를 삭제하는 메소드 입니다. */
  deleteEventBanner: Scalars['Boolean']['output'];
  deleteExtensionConfig: Scalars['Int']['output'];
  deleteFaq: Scalars['Boolean']['output'];
  deleteFaqAcon: Scalars['Boolean']['output'];
  deleteFaqCreator: Scalars['Boolean']['output'];
  deleteGift: Scalars['Boolean']['output'];
  deleteMainGroup: Scalars['Boolean']['output'];
  deleteMemo: Scalars['Boolean']['output'];
  deleteMyReview: Scalars['Boolean']['output'];
  deleteNotice: Scalars['Boolean']['output'];
  deleteOpenrunPromotion: Scalars['Boolean']['output'];
  deletePointBenefit: Scalars['Boolean']['output'];
  deletePopup: Scalars['Int']['output'];
  deletePromotion: Scalars['Boolean']['output'];
  deletePromotionConcept: Scalars['Boolean']['output'];
  deletePromotionItem: Scalars['Boolean']['output'];
  deleteReviewReply: Scalars['Boolean']['output'];
  deny: Scalars['Boolean']['output'];
  februaryAttendanceEvent: Scalars['Boolean']['output'];
  fixFaq: Faq;
  fixNotice: Notice;
  godoPasswordChange: Scalars['Boolean']['output'];
  hideAssets: Scalars['Boolean']['output'];
  increaseDownloadCount?: Maybe<DownloadCount>;
  issueEventCoupon: Scalars['Boolean']['output'];
  julyAttendanceEvent: Scalars['Boolean']['output'];
  juneAttendanceEvent: Scalars['Boolean']['output'];
  leavePartner: Scalars['Boolean']['output'];
  manuallyRegisterPromotion?: Maybe<PromotionOutput>;
  mayAttendanceEvent: Scalars['Boolean']['output'];
  memo: Scalars['Boolean']['output'];
  memoV2: DocumentMemoOutput;
  modify: Scalars['Boolean']['output'];
  modifyMemo: Scalars['Boolean']['output'];
  modifyMemoV2: DocumentMemoOutput;
  octoberAttendanceEvent: Scalars['Boolean']['output'];
  pack: Scalars['Boolean']['output'];
  passwordChange: Scalars['Boolean']['output'];
  passwordChangeForAnon: Scalars['Boolean']['output'];
  passwordChangeSendEmail: Scalars['Boolean']['output'];
  /** 장바구니엝 제품을 추가하는 메소드 */
  productAddCart: Scalars['Boolean']['output'];
  /** 장바구니에 상품 목록을 추가하는 메소드 입니다. */
  productItemsAddCart: Scalars['Boolean']['output'];
  readNotification: Scalars['Boolean']['output'];
  registerAssetFunding: AssetFunding;
  registerBrandPromotion: BrandPromotionOutput;
  registerOpenrunPromotion: OpenrunPromotionHubOutput;
  registerPartnerMemo: UserMemoOutput;
  registerPromotion: PromotionOutput;
  registerReviewReply: ReviewReply;
  registerReviewV2?: Maybe<Review>;
  registerSnsMember: Scalars['Boolean']['output'];
  registerSnsMemberV2: RegisterSnsMemberOutput;
  reject: Scalars['Boolean']['output'];
  rejectOpenrunPromotion: OpenrunPromotionAdminOutput;
  remakeContract: Scalars['Boolean']['output'];
  removeClip: Scalars['Int']['output'];
  removeClips: Scalars['Int']['output'];
  removePromotions: Scalars['Boolean']['output'];
  reorderAconfaq: FaqAconModel;
  /** 이벤트 배너 순서를 변경하는 메소드 입니다. */
  reorderEventBanner: Scalars['Boolean']['output'];
  reorderFaqAcon: FaqAconModel;
  reorderFaqCreator: FaqCreatorModel;
  request: Scalars['Boolean']['output'];
  requestPartnerRevision: Scalars['Boolean']['output'];
  requestPartnerSettle: Scalars['Boolean']['output'];
  retry: Scalars['Boolean']['output'];
  revise: Scalars['Boolean']['output'];
  save: Scalars['Boolean']['output'];
  saveCategory: Scalars['Boolean']['output'];
  saveConfig: Scalars['Boolean']['output'];
  saveContentBody: Scalars['Boolean']['output'];
  saveContentHead: Scalars['Boolean']['output'];
  saveCopyright: Scalars['Boolean']['output'];
  saveFile: Scalars['Boolean']['output'];
  saveMainImage: Scalars['Boolean']['output'];
  savePromotion: PromotionOutput;
  saveSubImage: Scalars['Boolean']['output'];
  saveTitle: Scalars['Boolean']['output'];
  scrapReview?: Maybe<Review>;
  sendAuthenticationCode: Scalars['Boolean']['output'];
  septemberAttendanceEvent: Scalars['Boolean']['output'];
  setBenefitBurdenItem: Scalars['Boolean']['output'];
  setBrand: Scalars['Boolean']['output'];
  setCouponBurdenItem: Scalars['Boolean']['output'];
  setFavoriteReview?: Maybe<Scalars['Int']['output']>;
  setMainGroup: Scalars['Boolean']['output'];
  /** 생품의 생세페이지 - 파일엝서 띰이터 기록용으로 호출하는 mutation 메소드입니다. */
  setProductFileView: Scalars['Boolean']['output'];
  setSearchKeyword: Scalars['Boolean']['output'];
  showAssets: Scalars['Boolean']['output'];
  storeBrandIntroduction: Array<BrandI18nClone>;
  storeBrandPhoto: UploadFileClone;
  storePromotionConcept: PromotionConceptOutput;
  suspendPromotion: PromotionOutput;
  syncBrands: Scalars['Boolean']['output'];
  syncCategory: Array<Category>;
  syncExchangeRate: Scalars['Boolean']['output'];
  syncMainGroups: Scalars['Boolean']['output'];
  updateAconfaq: FaqAconModel;
  updateAdminBrandPick: BrandClone;
  updateAdminBrandReview: BrandClone;
  updateApplication: Scalars['Int']['output'];
  updateAssetFunding: AssetFunding;
  updateBadge: Scalars['Boolean']['output'];
  updateBandBanner: BandBanner;
  updateBanner: BannerClone;
  updateBrandPick: BrandClone;
  updateBrandReview: BrandClone;
  updateCategory: Scalars['Boolean']['output'];
  updateCouponBenefit: CouponBenefit;
  /** 이벤트 배너를 수정하는 메소드입니다. */
  updateEventBanner: Scalars['Boolean']['output'];
  updateExtensionConfig: ExtensionConfig;
  updateFaq: Faq;
  updateFaqAcon: FaqAconModel;
  updateFaqCreator: FaqCreatorModel;
  updateGift: Gift;
  updateGodoHitCount: Scalars['Boolean']['output'];
  /** Modify the inquiry answer */
  updateInquiryAnswer: InquiryAnswer;
  updateNotice: Notice;
  /** 주문 저작권 정보를 수정하는 함수입니다. */
  updateOrderLicense: Scalars['Boolean']['output'];
  updatePartnerAdmin: UserOutput;
  updatePartnerSettleAdmin: UserSettleOutput;
  updatePopup: PopupV2;
  updatePromotion: PromotionOutput;
  updatePromotionConcept: PromotionConceptOutput;
  updateReviewReply: ReviewReply;
  updateReviewV2?: Maybe<Review>;
  uploadBlobStorage: UploadFile;
  uploadEditorImage: Scalars['String']['output'];
  uploadGoodsAtCategoryBatch?: Maybe<Scalars['String']['output']>;
  uploadGoodsAtMainGroupBatch?: Maybe<Scalars['String']['output']>;
};

export type MutationAbortArgs = {
  input: DocumentCommonInput;
};

export type MutationAcceptTermRenewArgs = {
  planId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationAdminStoreBrandIntroductionsArgs = {
  brandId: Scalars['Int']['input'];
  introductions: Array<LanguageTextInput>;
};

export type MutationAdminStoreBrandPhotoArgs = {
  brandId: Scalars['Int']['input'];
  photo?: InputMaybe<ProfilePhoto>;
};

export type MutationAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  isWeekend?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: Scalars['String']['input'];
};

export type MutationAugustAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationAuthenticateArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
};

export type MutationCancelBrandPromotionArgs = {
  input: CancelBrandPromotionInput;
};

export type MutationCancelInquiryArgs = {
  input: CancelInquiryInput;
};

export type MutationChangePartnerSettleStatusArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  status: PartnerSettleStatusEnum;
};

export type MutationChangePasswordPartnerArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type MutationChangeReviewStatusArgs = {
  id: Scalars['Int']['input'];
  status: Scalars['String']['input'];
};

export type MutationClipInquiryArgs = {
  input: ClipInquiryInput;
};

export type MutationCompleteArgs = {
  input: DocumentCompleteInput;
};

export type MutationCompleteOpenrunPromotionArgs = {
  input: CompleteOpenrunPromotionInput;
};

export type MutationCreateAconNotificationArgs = {
  memNo?: InputMaybe<Scalars['Int']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type MutationCreateAdminPartnerArgs = {
  account: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type MutationCreateBadgeArgs = {
  endDate: Scalars['DateTime']['input'];
  godoGoodsNos: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  isAutoCreate?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['DateTime']['input'];
  texts: Array<BadgeI18nInput>;
};

export type MutationCreateBandBannerArgs = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  closeColor?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  href?: InputMaybe<Scalars['String']['input']>;
  isBlankHref?: InputMaybe<Scalars['Boolean']['input']>;
  isClosedShowId?: InputMaybe<Scalars['Int']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateBannerArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  href?: InputMaybe<Scalars['String']['input']>;
  imageKeys?: InputMaybe<ImageKeys>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  selectedProductsId?: InputMaybe<Array<Scalars['Int']['input']>>;
  showOnlyForSelectedProducts?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateBusinessArgs = {
  bizName: Scalars['String']['input'];
  bizNum: Scalars['String']['input'];
  openDate: Scalars['String']['input'];
  representativeName: Scalars['String']['input'];
};

export type MutationCreateCategoryArgs = {
  creator?: InputMaybe<Scalars['Float']['input']>;
  memo: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentCategoryId?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  type: Scalars['String']['input'];
  updater?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationCreateClipArgs = {
  createClipInput: CreateClipInput;
};

export type MutationCreateCouponBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']['input']>>;
  applyTypeId?: InputMaybe<Scalars['Int']['input']>;
  godoCouponNo?: InputMaybe<Scalars['String']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateEventArgs = {
  createEventInput: CreateEventInput;
};

export type MutationCreateEventBannerArgs = {
  bannerTextFirst: Scalars['String']['input'];
  bannerTextSecond: Scalars['String']['input'];
  bannerTextThird: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  imageKey?: InputMaybe<ImageKeys>;
  langId: Scalars['Int']['input'];
  settingLink: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MutationCreateExtensionConfigArgs = {
  input: CreateExtensionConfigInput;
};

export type MutationCreateFaqArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateFaqAconArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
};

export type MutationCreateFaqCreatorArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
};

export type MutationCreateFriendArgs = {
  code: Scalars['String']['input'];
  godoMemNo: Scalars['Int']['input'];
  langCode: Scalars['String']['input'];
};

export type MutationCreateGiftArgs = {
  createGiftInput: CreateGiftInput;
};

export type MutationCreateInquiryArgs = {
  input: InquiryInput;
};

export type MutationCreateInquiryAnswerArgs = {
  input: CreateInquiryAnswerInput;
};

export type MutationCreateInquiryMemoArgs = {
  input: CreateInquiryMemoInput;
};

export type MutationCreateNoticeArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreateOrderMemoArgs = {
  input: CreateOrderMemoInput;
};

export type MutationCreatePartnerArgs = {
  brand: CreatePartnerBrandInput;
  file: CreatePartnerSettleFileInput;
  user: CreatePartnerInput;
};

export type MutationCreatePointBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']['input']>>;
  applyTypeId?: InputMaybe<Scalars['Int']['input']>;
  defaultApply?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  payPoint?: InputMaybe<Scalars['String']['input']>;
  pointUnit?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationCreatePopupArgs = {
  createPopupInput: CreatePopupInput;
};

export type MutationCreateTermAgreeArgs = {
  sign: FileInput;
  term: FileInput;
};

export type MutationDeleteAconNotificationArgs = {
  type: Array<Scalars['String']['input']>;
};

export type MutationDeleteAconfaqArgs = {
  id: Scalars['Int']['input'];
  oldOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteBadgeArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteBandBannerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteBannerArgs = {
  id: Scalars['Float']['input'];
};

export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['Float']['input'];
};

export type MutationDeleteCouponBenefitArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeleteDocumentArgs = {
  document_id: Scalars['String']['input'];
};

export type MutationDeleteEventBannerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteExtensionConfigArgs = {
  input: DeleteExtensionConfigInput;
};

export type MutationDeleteFaqArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteFaqAconArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteFaqCreatorArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteGiftArgs = {
  deleteGiftInput: DeleteGiftInput;
};

export type MutationDeleteMainGroupArgs = {
  mainGroupId: Scalars['Int']['input'];
};

export type MutationDeleteMemoArgs = {
  input: DocumentMemoInput;
};

export type MutationDeleteMyReviewArgs = {
  id: Scalars['Int']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationDeleteNoticeArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDeleteOpenrunPromotionArgs = {
  input: DeleteOpenrunPromotionInput;
};

export type MutationDeletePointBenefitArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationDeletePopupArgs = {
  deletePopupInput: DeletePopupInput;
};

export type MutationDeletePromotionArgs = {
  input: DeletePromotionInput;
};

export type MutationDeletePromotionConceptArgs = {
  input: DeletePromotionConceptInput;
};

export type MutationDeletePromotionItemArgs = {
  input: DeletePromotionTwinkleAssetInput;
};

export type MutationDeleteReviewReplyArgs = {
  id: Scalars['Int']['input'];
};

export type MutationDenyArgs = {
  input: DocumentCommonInput;
};

export type MutationFebruaryAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationFixFaqArgs = {
  fixed?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type MutationFixNoticeArgs = {
  fixed?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type MutationGodoPasswordChangeArgs = {
  key: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationHideAssetsArgs = {
  input: HideAssetsInput;
};

export type MutationIncreaseDownloadCountArgs = {
  id: Scalars['Int']['input'];
};

export type MutationIssueEventCouponArgs = {
  issueEventCouponInput: IssueEventCouponInput;
};

export type MutationJulyAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationJuneAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationLeavePartnerArgs = {
  id: Scalars['Int']['input'];
};

export type MutationManuallyRegisterPromotionArgs = {
  id: Scalars['Int']['input'];
  passcode: Scalars['String']['input'];
};

export type MutationMayAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationMemoArgs = {
  input: DocumentMemoInput;
};

export type MutationMemoV2Args = {
  input: DocumentMemoInput;
};

export type MutationModifyArgs = {
  input: DocumentCommonInput;
};

export type MutationModifyMemoArgs = {
  input: DocumentMemoInput;
};

export type MutationModifyMemoV2Args = {
  input: DocumentMemoInput;
};

export type MutationOctoberAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationPackArgs = {
  input: PackageInput;
};

export type MutationPasswordChangeArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type MutationPasswordChangeForAnonArgs = {
  account: Scalars['String']['input'];
  brandName: Scalars['String']['input'];
};

export type MutationPasswordChangeSendEmailArgs = {
  lang: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationProductAddCartArgs = {
  goodsNo: Scalars['String']['input'];
};

export type MutationProductItemsAddCartArgs = {
  goodsNos: Array<Scalars['String']['input']>;
};

export type MutationReadNotificationArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRegisterAssetFundingArgs = {
  input: AssetFundingInput;
};

export type MutationRegisterBrandPromotionArgs = {
  input: RegisterBrandPromotionInput;
};

export type MutationRegisterOpenrunPromotionArgs = {
  input: RegisterOpenrunPromotionInput;
};

export type MutationRegisterPartnerMemoArgs = {
  data: RegisterPartnerMemoInput;
  id: Scalars['Int']['input'];
};

export type MutationRegisterPromotionArgs = {
  input: RegisterPromotionInput;
};

export type MutationRegisterReviewReplyArgs = {
  contents: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  languageCode: Scalars['String']['input'];
  reviewId: Scalars['Int']['input'];
};

export type MutationRegisterReviewV2Args = {
  input: RegisterReviewInput;
};

export type MutationRegisterSnsMemberArgs = {
  input: AconSnsMemberInput;
};

export type MutationRegisterSnsMemberV2Args = {
  input: AconSnsMemberInput;
};

export type MutationRejectArgs = {
  input: DocumentCommonInput;
};

export type MutationRejectOpenrunPromotionArgs = {
  input: RejectOpenrunPromotionInput;
};

export type MutationRemakeContractArgs = {
  userId: Scalars['Int']['input'];
};

export type MutationRemoveClipArgs = {
  removeClipInput: RemoveClipInput;
};

export type MutationRemoveClipsArgs = {
  removeClipsInput: RemoveClipsInput;
};

export type MutationRemovePromotionsArgs = {
  ids: Array<Scalars['Int']['input']>;
};

export type MutationReorderAconfaqArgs = {
  id: Scalars['Int']['input'];
  langId?: InputMaybe<Scalars['Int']['input']>;
  newOrder?: InputMaybe<Scalars['Int']['input']>;
  oldOrder?: InputMaybe<Scalars['Int']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReorderEventBannerArgs = {
  id: Scalars['Int']['input'];
  newOrder: Scalars['Int']['input'];
  oldOrder: Scalars['Int']['input'];
};

export type MutationReorderFaqAconArgs = {
  id: Scalars['Int']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
  newOrder: Scalars['Int']['input'];
  oldOrder: Scalars['Int']['input'];
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationReorderFaqCreatorArgs = {
  id: Scalars['Int']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
  newOrder: Scalars['Int']['input'];
  oldOrder: Scalars['Int']['input'];
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationRequestArgs = {
  input: DocumentCommonInput;
};

export type MutationRequestPartnerRevisionArgs = {
  user: RequestPartnerRevisionInput;
};

export type MutationRequestPartnerSettleArgs = {
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  bankAccountOwner?: InputMaybe<Scalars['String']['input']>;
  bankBook?: InputMaybe<StoredFileInput>;
  bankId?: InputMaybe<Scalars['Int']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  idCard?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
};

export type MutationRetryArgs = {
  input: DocumentCommonInput;
};

export type MutationReviseArgs = {
  input: DocumentCommonInput;
};

export type MutationSaveArgs = {
  input: SingleDocumentInput;
};

export type MutationSaveCategoryArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveConfigArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveContentBodyArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveContentHeadArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveCopyrightArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveFileArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveMainImageArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSavePromotionArgs = {
  input: SavePromotionInput;
};

export type MutationSaveSubImageArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationSaveTitleArgs = {
  input: AdminSingleDocumentInput;
};

export type MutationScrapReviewArgs = {
  id: Scalars['Int']['input'];
};

export type MutationSendAuthenticationCodeArgs = {
  email: Scalars['String']['input'];
  lang: Scalars['String']['input'];
};

export type MutationSeptemberAttendanceEventArgs = {
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
};

export type MutationSetBenefitBurdenItemArgs = {
  burden?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetBrandArgs = {
  brandId: Scalars['Int']['input'];
  cnName?: InputMaybe<Scalars['String']['input']>;
  enName?: InputMaybe<Scalars['String']['input']>;
  jpName?: InputMaybe<Scalars['String']['input']>;
  koName?: InputMaybe<Scalars['String']['input']>;
};

export type MutationSetCouponBurdenItemArgs = {
  burden?: InputMaybe<Scalars['Int']['input']>;
  sno?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationSetFavoriteReviewArgs = {
  reviewId: Scalars['Int']['input'];
};

export type MutationSetMainGroupArgs = {
  cnName?: InputMaybe<Scalars['String']['input']>;
  enName?: InputMaybe<Scalars['String']['input']>;
  jpName?: InputMaybe<Scalars['String']['input']>;
  koName?: InputMaybe<Scalars['String']['input']>;
  mainGroupId: Scalars['Int']['input'];
};

export type MutationSetProductFileViewArgs = {
  goodsNo: Scalars['String']['input'];
  lang: Scalars['String']['input'];
};

export type MutationSetSearchKeywordArgs = {
  creator?: InputMaybe<Scalars['Float']['input']>;
  keyword: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  updater?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationShowAssetsArgs = {
  input: ShowAssetsInput;
};

export type MutationStoreBrandIntroductionArgs = {
  introduction: Scalars['String']['input'];
  lang: LanguageCodeEnum;
};

export type MutationStoreBrandPhotoArgs = {
  photo?: InputMaybe<ProfilePhoto>;
};

export type MutationStorePromotionConceptArgs = {
  input: StorePromotionConceptInput;
};

export type MutationSuspendPromotionArgs = {
  input: SuspendPromotionInput;
};

export type MutationUpdateAconfaqArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  langId?: InputMaybe<Scalars['Int']['input']>;
  subtitle?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateAdminBrandPickArgs = {
  assetIds: Array<Scalars['Int']['input']>;
  brandId: Scalars['Int']['input'];
  isOn: Scalars['Boolean']['input'];
};

export type MutationUpdateAdminBrandReviewArgs = {
  brandId: Scalars['Int']['input'];
  isOn: Scalars['Boolean']['input'];
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type MutationUpdateAssetFundingArgs = {
  input: AssetFundingInput;
};

export type MutationUpdateBadgeArgs = {
  endDate: Scalars['DateTime']['input'];
  godoGoodsNos: Array<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  isAutoCreate?: InputMaybe<Scalars['Boolean']['input']>;
  startDate: Scalars['DateTime']['input'];
  texts: Array<BadgeI18nInput>;
};

export type MutationUpdateBandBannerArgs = {
  backgroundColor?: InputMaybe<Scalars['String']['input']>;
  closeColor?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  href?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  isBlankHref?: InputMaybe<Scalars['Boolean']['input']>;
  isClosedShowId?: InputMaybe<Scalars['Int']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateBannerArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  href?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  imageKeys?: InputMaybe<ImageKeys>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  selectedProductsId?: InputMaybe<Array<Scalars['Int']['input']>>;
  showOnlyForSelectedProducts?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateBrandPickArgs = {
  assetIds: Array<Scalars['Int']['input']>;
  isOn: Scalars['Boolean']['input'];
};

export type MutationUpdateBrandReviewArgs = {
  isOn: Scalars['Boolean']['input'];
};

export type MutationUpdateCategoryArgs = {
  creator?: InputMaybe<Scalars['Float']['input']>;
  i18n: Array<SetCategoryI18nInput>;
  id: Scalars['Float']['input'];
  memo: Scalars['String']['input'];
  parent?: InputMaybe<Scalars['Float']['input']>;
  state: Scalars['String']['input'];
  type?: InputMaybe<CategoryTypeEnum>;
  updater?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdateCouponBenefitArgs = {
  applyList?: InputMaybe<Array<Scalars['String']['input']>>;
  applyTypeId?: InputMaybe<Scalars['Int']['input']>;
  godoCouponNo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  langId?: InputMaybe<Scalars['Int']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateEventBannerArgs = {
  bannerTextFirst: Scalars['String']['input'];
  bannerTextSecond: Scalars['String']['input'];
  bannerTextThird: Scalars['String']['input'];
  endDate: Scalars['DateTime']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  imageKey?: InputMaybe<ImageKeys>;
  langId: Scalars['Int']['input'];
  settingLink: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MutationUpdateExtensionConfigArgs = {
  input: UpdateExtensionConfigInput;
};

export type MutationUpdateFaqArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  langId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateFaqAconArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
};

export type MutationUpdateFaqCreatorArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
  subtitle: Scalars['String']['input'];
  title: Scalars['String']['input'];
  typeId: Scalars['Int']['input'];
};

export type MutationUpdateGiftArgs = {
  updateGiftInput: UpdateGiftInput;
};

export type MutationUpdateGodoHitCountArgs = {
  goodsNo: Scalars['String']['input'];
};

export type MutationUpdateInquiryAnswerArgs = {
  input: UpdateInquiryAnswerInput;
};

export type MutationUpdateNoticeArgs = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  langId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type MutationUpdateOrderLicenseArgs = {
  adminEmail?: InputMaybe<Scalars['String']['input']>;
  names: Array<Scalars['String']['input']>;
  orderNo: Scalars['Float']['input'];
  projects?: InputMaybe<Array<OrderLicenseProjects>>;
  type: Scalars['String']['input'];
};

export type MutationUpdatePartnerAdminArgs = {
  data: UpdatePartnerAdminInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdatePartnerSettleAdminArgs = {
  data: UpdatePartnerSettleAdminInput;
  id: Scalars['Int']['input'];
};

export type MutationUpdatePopupArgs = {
  updatePopupInput: UpdatePopupInput;
};

export type MutationUpdatePromotionArgs = {
  input: UpdatePromotionInput;
};

export type MutationUpdatePromotionConceptArgs = {
  input: UpdatePromotionConceptInput;
};

export type MutationUpdateReviewReplyArgs = {
  contents: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  languageCode: Scalars['String']['input'];
  reviewId: Scalars['Int']['input'];
};

export type MutationUpdateReviewV2Args = {
  input: UpdateReviewInput;
};

export type MutationUploadBlobStorageArgs = {
  inputs: UploadBlobStorageInput;
};

export type MutationUploadEditorImageArgs = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationUploadGoodsAtCategoryBatchArgs = {
  data: Array<CategoryGoods>;
};

export type MutationUploadGoodsAtMainGroupBatchArgs = {
  data: Array<MainGroupGoods>;
};

export type MyOrderReturn = {
  __typename?: 'MyOrderReturn';
  /** 최대/잔여 다운로드 횟수 정보 */
  downloadCountInfo?: Maybe<DownloadCountInfo>;
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Int']['output'];
  /** 고도몰 주문번호 */
  godoOrderNo: Scalars['Float']['output'];
  /** 몰 언어 정보 */
  languageCode: LanguageCodeEnum;
  /** 회원 번호 */
  memNo: Scalars['Int']['output'];
  /** 상품 주문 상태 */
  orderGoodsStatus: OrderDisplayStatusEnum;
  /** 결제 가격 */
  price: Scalars['Int']['output'];
  /** 상품 카드 정보 */
  product?: Maybe<Acon3dProduct>;
  /** 주문 체결 날짜 */
  regDt: Scalars['String']['output'];
  /** 리뷰 작성 여부 */
  reviewId?: Maybe<Scalars['Int']['output']>;
  /** 회원 아이디 */
  userId: Scalars['String']['output'];
};

export type Notice = {
  __typename?: 'Notice';
  /** 공지사항 내용(바디) */
  body?: Maybe<Scalars['String']['output']>;
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 고정글인 경우 고정 순서(번호) */
  fixed?: Maybe<Scalars['Float']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 공지사항을 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** 공지사항 제목 */
  title?: Maybe<Scalars['String']['output']>;
  /** 공지사항 타입(공지, 이벤트, 일반, ...) */
  type?: Maybe<NoticeType>;
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type NoticeType = {
  __typename?: 'NoticeType';
  /** 공지사항 타입 코드(notice, event, normal, ...) */
  code: Scalars['String']['output'];
  /** 공지사항 타입 id */
  id: Scalars['Float']['output'];
  /** 공지사항 타입 이름(공지, 이벤트, 일반, ...) */
  name: Scalars['String']['output'];
};

export enum Ordered_Products_Input_Condition_Order_State {
  Confirmed = 'CONFIRMED',
  Settled = 'SETTLED',
}

export enum Ordered_Products_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Ordered_Products_Input_Sort_Key {
  CreatedAt = 'CREATED_AT',
  OrderCount = 'ORDER_COUNT',
}

export type OpenrunPromotionAdminOutput = {
  __typename?: 'OpenrunPromotionAdminOutput';
  /** 오픈런 신청 도큐먼트 ID (document.id) */
  id: Scalars['String']['output'];
  /** 거절 사유, 승인상태가 거절시 필수 */
  rejectReason?: Maybe<Scalars['String']['output']>;
  /** 오픈런 승인 상태(승인, 진행중, 거절, 심사중, 종료...) */
  status: OpenrunStatusEnum;
};

export type OpenrunPromotionHubOutput = {
  __typename?: 'OpenrunPromotionHubOutput';
  /** 오픈런 신청 상품 ID (asset.id) */
  assetId?: Maybe<Scalars['Int']['output']>;
  /** 오픈런 신청 상품명 */
  assetName?: Maybe<Scalars['String']['output']>;
  /** 오픈런 신청 도큐먼트 ID (document.id) */
  id: Scalars['String']['output'];
  /** 거절 사유, 승인상태가 거절시 필수 */
  rejectReason?: Maybe<Scalars['String']['output']>;
  since?: Maybe<Scalars['DateTime']['output']>;
  /** 오픈런 승인 상태(승인(completed), 진행중(opened), 거절(rejected), 심사중(in_review), 종료(closed)...) */
  status: OpenrunStatusEnum;
  until?: Maybe<Scalars['DateTime']['output']>;
};

export type OpenrunPromotionHubPaginatedOutput = {
  __typename?: 'OpenrunPromotionHubPaginatedOutput';
  data: Array<OpenrunPromotionHubOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
  /** 오픈런 상태별 총 개수 */
  stateSpecificTotal: Array<StateSpecificTotal>;
};

export enum OpenrunStatusEnum {
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  InReview = 'IN_REVIEW',
  Opened = 'OPENED',
  Rejected = 'REJECTED',
}

export type OrderAdminMemo = {
  __typename?: 'OrderAdminMemo';
  adminUser: AdminUser;
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  memo: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type OrderAdminRefundModalReturn = {
  __typename?: 'OrderAdminRefundModalReturn';
  orderNo: Scalars['String']['output'];
  refundCash: Scalars['Int']['output'];
  refundPoint: Scalars['Int']['output'];
  refundPrice: Scalars['Int']['output'];
  settleKind: Scalars['String']['output'];
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderCouponItems = {
  __typename?: 'OrderCouponItems';
  /** 쿠폰 사용 가능 카테고리 목록 */
  couponApplyCategory?: Maybe<Array<CouponApplyCategory>>;
  /** 쿠폰 사용 가능 상품 목록 */
  couponApplyGoods?: Maybe<Array<CouponApplyGoods>>;
  /** 쿠폰 사용 가능 타입 (category, brand, goods) */
  couponApplyProductType: Scalars['String']['output'];
  /** 쿠폰 혜택 */
  couponBenefit: Scalars['Int']['output'];
  /** 쿠폰 혜택 타입 (percent, fix) */
  couponBenefitType: Scalars['String']['output'];
  /** 쿠폰 종류(sale, add, deposit) */
  couponKindType: Scalars['String']['output'];
  /** 쿠폰 최대 혜택 */
  couponMaxBenefit: Scalars['Int']['output'];
  /** 최소 주문 금액 */
  couponMinOrderPrice: Scalars['Int']['output'];
  /** 최소 주문 금액 기준 (product, order), default: product */
  couponMinOrderType?: Maybe<Scalars['String']['output']>;
  /** 쿠폰 ID */
  couponNo: Scalars['Int']['output'];
  /** 쿠폰 할인 가격 (주문 쿠폰) */
  discountPrice?: Maybe<Scalars['Int']['output']>;
  /** 쿠폰 사용 가능 종료 시간 */
  endDate: Scalars['DateTime']['output'];
  /** 사용 가능 여부 */
  isAvailable: Scalars['Boolean']['output'];
  /** 쿠폰 중복 사용 가능 여부 */
  isCouponApplyDuplicate: Scalars['Boolean']['output'];
  /** 쿠폰 최대 혜택 제한 존재 여부 */
  isCouponMaxBenefit: Scalars['Boolean']['output'];
  /** 상품 적용 쿠폰 여부 */
  isProductCoupon: Scalars['Boolean']['output'];
  /** 유저 쿠폰 ID */
  memberCouponNo: Scalars['Int']['output'];
  /** 쿠폰명 */
  name: Scalars['String']['output'];
  /** 적용 가능한 상품 목록 */
  products?: Maybe<Array<OrderCouponProducts>>;
  /** 쿠폰 발급된 시간 */
  regDt: Scalars['DateTime']['output'];
  /** 쿠폰 사용 가능 시작 시간 */
  startDate: Scalars['DateTime']['output'];
};

export type OrderCouponProducts = {
  __typename?: 'OrderCouponProducts';
  /** 상품 할인 가격 */
  discountPrice: Scalars['String']['output'];
  /** 상품 고유 번호 */
  godoGoodsNo: Scalars['String']['output'];
};

export type OrderCouponReturn = {
  __typename?: 'OrderCouponReturn';
  appliedProductName?: Maybe<Scalars['String']['output']>;
  couponBenefit: Scalars['String']['output'];
  couponName: Scalars['String']['output'];
  couponType: Scalars['String']['output'];
};

export type OrderData = {
  __typename?: 'OrderData';
  /** 추가 필드 사용 여부 (y, n) */
  addFieldConf: Scalars['String']['output'];
  /** 장바구니 추가 상품 개수 */
  cartAddGoodsCnt: Scalars['Int']['output'];
  /** 장바구니 상품 개수 */
  cartGoodsCnt: Scalars['Int']['output'];
  /** 주문 상품 정보 */
  cartInfo: Array<CartInfo>;
  /** 쿠폰 적용 타입 (all, member, coupon ...) */
  chooseCouponMemberUseType: Scalars['String']['output'];
  /** 마일리지 / 쿠폰 선택 (y, n) */
  chooseMileageCoupon: Scalars['String']['output'];
  /** 이메일 타입 */
  emailDomain: Array<Scalars['String']['output']>;
  isGlobal: Scalars['Boolean']['output'];
  /** 상품쿠폰 주문서 제한여부 */
  productCouponChangeLimitType: Scalars['String']['output'];
  /** token */
  token: Scalars['String']['output'];
  /** 상품 쿠폰 총 할인 금액 */
  totalCouponGoodsDcPrice: Scalars['Int']['output'];
  /** 상품 쿠폰 총 적립 마일리지 */
  totalCouponGoodsMileage: Scalars['Int']['output'];
  /** 회원 총 할인 금액 */
  totalMemberDcPrice: Scalars['Int']['output'];
  /** 그룹별 회원 중복 총 할인 금액 */
  totalMemberOverlapDcPrice: Scalars['Int']['output'];
  /** 총 결제 금액 (예정) */
  totalSettlePrice: Scalars['Int']['output'];
  /** 회원 정보 */
  userInfo: UserInfo;
};

export enum OrderDisplayStatusEnum {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Completed = 'COMPLETED',
  DepositStandby = 'DEPOSIT_STANDBY',
  InPreparation = 'IN_PREPARATION',
  OnDelivery = 'ON_DELIVERY',
  PaymentAborted = 'PAYMENT_ABORTED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentTried = 'PAYMENT_TRIED',
  RefundAsked = 'REFUND_ASKED',
  RefundCompleted = 'REFUND_COMPLETED',
  ReturnAsked = 'RETURN_ASKED',
}

export type OrderDto = {
  __typename?: 'OrderDto';
  /** 상품 금액 */
  amount: Scalars['Int']['output'];
  /** 주문 시 입력한 전화번호 */
  cellPhone?: Maybe<Scalars['String']['output']>;
  /** 주문 시 입력한 이메일 */
  email?: Maybe<Scalars['String']['output']>;
  /** 고도몰 장바구니 sno 배열 */
  godoCartSno: Array<Scalars['String']['output']>;
  /** 고도몰 주문번호 */
  godoOrderId: Scalars['String']['output'];
  /** 주문번호 */
  id: Scalars['String']['output'];
  /** 주문 몰 */
  langCode: Scalars['String']['output'];
  /** 주문 몰 */
  memNo: Scalars['Int']['output'];
  /** 주문 시 입력한 이름 */
  name?: Maybe<Scalars['String']['output']>;
  /** 결제 정보 */
  payment?: Maybe<OrderPaymentDto>;
  /** 주문 상태 */
  status?: Maybe<Scalars['String']['output']>;
};

export type OrderEventHistory = {
  __typename?: 'OrderEventHistory';
  createdAt: Scalars['DateTime']['output'];
  event: OrderEventTypeEnum;
  godoGoodsNo?: Maybe<Scalars['Int']['output']>;
  orderId: Scalars['String']['output'];
};

export type OrderEventHistoryOutput = {
  __typename?: 'OrderEventHistoryOutput';
  /** 이벤트 발생일시 */
  createdAt: Scalars['DateTime']['output'];
  /** 발생한 이벤트 */
  event: OrderEventTypeEnum;
  /** 상품번호(전체 주문에 대한 히스토리인 경우 0) */
  godoGoodsNo?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  /** 주문 id */
  orderId: Scalars['String']['output'];
};

export enum OrderEventTypeEnum {
  Confirm = 'confirm',
  Download = 'download',
  Refund = 'refund',
  Settle = 'settle',
}

export type OrderGift = {
  __typename?: 'OrderGift';
  amount: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  expirationDate: Scalars['DateTime']['output'];
  gift: Gift;
  id: Scalars['String']['output'];
  inputRecipientCellPhone: Scalars['String']['output'];
  inputRecipientName: Scalars['String']['output'];
  langCode: Scalars['String']['output'];
  memberGiftHistories: Array<MemberGiftHistory>;
  orderPayment: OrderPayment;
  recipientMemNo?: Maybe<Scalars['Float']['output']>;
  recipientUserId?: Maybe<Scalars['String']['output']>;
  redemptionDate?: Maybe<Scalars['DateTime']['output']>;
  sendMessage: Scalars['String']['output'];
  senderMemNo: Scalars['Float']['output'];
  senderName: Scalars['String']['output'];
  senderUserId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type OrderGoodsPriceInfoArgs = {
  goodsNos: Array<Scalars['Int']['input']>;
  orderNo: Scalars['String']['input'];
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  /** 에러 메세지 */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** 성공 여부 */
  isSuccess?: Maybe<Scalars['Boolean']['output']>;
  /** 주문 데이터 */
  orderData?: Maybe<OrderData>;
};

export type OrderLicenseAuthorOutput = {
  __typename?: 'OrderLicenseAuthorOutput';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orderLicenseId: Scalars['Int']['output'];
};

export type OrderLicenseModel = {
  __typename?: 'OrderLicenseModel';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 필명 정보 */
  names?: Maybe<Array<OrderLicenseNameModel>>;
  /** 주문번호 */
  orderNo: Scalars['String']['output'];
  /** 프로젝트 정보 */
  projects?: Maybe<Array<OrderLicenseProjectModel>>;
  /** 유형 */
  type: Scalars['String']['output'];
  /** 사용권 버전 */
  versions?: Maybe<Array<OrderLicenseVersionModel>>;
};

export type OrderLicenseNameModel = {
  __typename?: 'OrderLicenseNameModel';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 필명 OR 작품 / 프로젝트명 */
  name: Scalars['String']['output'];
  /** 주문의 사용권 ID  */
  orderLicenseId: Scalars['Float']['output'];
};

export type OrderLicenseOutput = {
  __typename?: 'OrderLicenseOutput';
  /** 창작자/회사명 */
  authors: Array<OrderLicenseAuthorOutput>;
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  /** 주문번호 */
  orderNo: Scalars['String']['output'];
  /** 작품 */
  project: Array<OrderLicenseProjectOutput>;
  /** 라이선트 종류 */
  type: OrderLicenseType;
};

export type OrderLicenseProjectGoodsModel = {
  __typename?: 'OrderLicenseProjectGoodsModel';
  /** 상품번호 */
  goodsNo: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 주문 사용권 프로젝트의 ID */
  orderLicenseProjectId: Scalars['Float']['output'];
};

export type OrderLicenseProjectModel = {
  __typename?: 'OrderLicenseProjectModel';
  /** 프로젝트 상품들 */
  goods?: Maybe<Array<OrderLicenseProjectGoodsModel>>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 작품 / 프로젝트 명 */
  name: Scalars['String']['output'];
  /** 주문 사용권 ID */
  orderLicenseId: Scalars['Float']['output'];
};

export type OrderLicenseProjectOutput = {
  __typename?: 'OrderLicenseProjectOutput';
  /** 적용 상품 코드 */
  goodsNos: Array<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  orderLicenseId: Scalars['Int']['output'];
};

export type OrderLicenseProjects = {
  /** 상품번호 */
  goodsNo: Array<Scalars['String']['input']>;
  /** 필명 */
  name: Scalars['String']['input'];
};

export enum OrderLicenseType {
  Company = 'company',
  Enterprise_0 = 'enterprise_0',
  Enterprise_5 = 'enterprise_5',
  Personal = 'personal',
}

export type OrderLicenseVersionModel = {
  __typename?: 'OrderLicenseVersionModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']['output']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 데이터 전체 JSON */
  contents?: Maybe<Scalars['String']['output']>;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 버전 Major */
  major?: Maybe<Scalars['Float']['output']>;
  /** 버전 Minor */
  minor?: Maybe<Scalars['Float']['output']>;
  /** 주문 사용권 ID */
  orderLicenseId: Scalars['Float']['output'];
  /** 수정 일자 */
  updated: Scalars['DateTime']['output'];
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  confirmDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  payMethod: Scalars['String']['output'];
  receiptUrl?: Maybe<Scalars['String']['output']>;
  vbankCode?: Maybe<Scalars['String']['output']>;
  vbankHolder?: Maybe<Scalars['String']['output']>;
  vbankIssuedAt?: Maybe<Scalars['DateTime']['output']>;
  vbankName?: Maybe<Scalars['String']['output']>;
  vbankNum?: Maybe<Scalars['String']['output']>;
};

export type OrderPaymentDto = {
  __typename?: 'OrderPaymentDto';
  /** 입금 완료 일자 */
  confirmDate?: Maybe<Scalars['DateTime']['output']>;
  /** 결제 통화 단위 정보 */
  currency: Scalars['String']['output'];
  /** 결제 수단 */
  payMethod: Scalars['String']['output'];
  /** 영수증 확인 URL (현금영수증, 매출전표) */
  receiptUrl?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 은행 코드 */
  vbankCode?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 예금자 명 */
  vbankHolder?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 송금 일자 */
  vbankIssuedAt?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 입금 은행 */
  vbankName?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 입금 계좌 번호 */
  vbankNum?: Maybe<Scalars['String']['output']>;
};

export type OrderPaymentReturn = {
  __typename?: 'OrderPaymentReturn';
  /** 에이콘 자체 할인 금액 */
  aconDiscountPrice: Scalars['Int']['output'];
  couponDiscountPrice: Scalars['Int']['output'];
  /** 결제 금액 */
  payPrice: Scalars['Int']['output'];
  /** 총 할인금액 */
  totalDiscountPrice: Scalars['Int']['output'];
  /** 상품금액 */
  totalPrice: Scalars['Int']['output'];
  useCash: Scalars['Int']['output'];
  usePoint: Scalars['Int']['output'];
};

export type OrderPrice = {
  __typename?: 'OrderPrice';
  /** 쿠폰 할인 금액 */
  couponGoodsDcPrice: Scalars['Float']['output'];
  /** 정가 */
  fixedPrice: Scalars['Float']['output'];
  /** 상품 할인 금액 */
  goodsDcPrice: Scalars['Float']['output'];
  /** 상품별 가격 (상품 가격 + 옵션 가격 + 텍스트 옵션 가격 + 추가 상품 가격) */
  goodsPriceSubtotal: Scalars['Float']['output'];
  /** 상품 합계 금액 */
  goodsPriceSum: Scalars['Float']['output'];
  /** 회원 할인 금액 */
  memberDcPrice: Scalars['Float']['output'];
  /** 회원 중복 할인 금액 */
  memberOverlapDcPrice: Scalars['Float']['output'];
};

export type OrderProductClone = {
  __typename?: 'OrderProductClone';
  amount: Scalars['Float']['output'];
  cellPhone: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  godoOrderId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  langCode: LanguageCodeEnum;
  licenseUpdateHistories: Array<LicenseUpdateHistory>;
  licenses?: Maybe<Array<LicenseClone>>;
  memId: Scalars['String']['output'];
  memNo: Scalars['Int']['output'];
  memos?: Maybe<Array<OrderAdminMemo>>;
  name: Scalars['String']['output'];
  orderProductItems: Array<OrderProductItemClone>;
  status: OrderStatus;
  useAconCash: Scalars['Int']['output'];
  useGiftDeposit: Scalars['Int']['output'];
  usePoint: Scalars['Int']['output'];
};

export type OrderProductItemAggregation = {
  __typename?: 'OrderProductItemAggregation';
  /** 판매 금액 총합 */
  amount: Scalars['Int']['output'];
  /** 환불 건수 */
  refundedCount: Scalars['Int']['output'];
};

export type OrderProductItemClone = {
  __typename?: 'OrderProductItemClone';
  acon3dProduct: Acon3dProduct;
  fixedPrice: Scalars['Int']['output'];
  godoGoodsNo: Scalars['Int']['output'];
  goodsDcPrice: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isCanceled: Scalars['Boolean']['output'];
  isDownloaded: Scalars['Boolean']['output'];
  isRefunded: Scalars['Boolean']['output'];
  price: Scalars['Int']['output'];
  salePrice: Scalars['Int']['output'];
};

export type OrderProductItemOutput = {
  __typename?: 'OrderProductItemOutput';
  /** 프로모션 브랜드 부담도 */
  benefitBurden: Scalars['Int']['output'];
  /** 프로모션 브랜드 부담 금액 */
  benefitBurdenPrice: Scalars['Int']['output'];
  /** 상품 쿠폰 브랜드 부담도 */
  couponGoodsBurden: Scalars['Int']['output'];
  /** 상품 쿠폰 브랜드 부담 금액 */
  couponGoodsBurdenPrice: Scalars['Int']['output'];
  /** 상품 쿠폰 할인 금액 */
  couponGoodsDcPrice: Scalars['Int']['output'];
  /** 주문 쿠폰 브랜드 부담도 */
  couponOrderBurden: Scalars['Int']['output'];
  /** 주문 쿠폰 브랜드 부담 금액, 현재 항상 0 */
  couponOrderBurdenPrice: Scalars['Int']['output'];
  /** 주문 쿠폰 할인 n분 금액 */
  divisionCouponOrderDcPrice: Scalars['Int']['output'];
  /** 상품코드(assetId) */
  godoGoodsNo: Scalars['Int']['output'];
  /** 프로모션 할인 금액 */
  goodsDcPrice: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  /** 결제 미완료 상태에서 취소 여부 */
  isCanceled: Scalars['Boolean']['output'];
  /** 다운로드 여부 */
  isDownloaded: Scalars['Boolean']['output'];
  /** 환불 여부 */
  isRefunded: Scalars['Boolean']['output'];
  order: OrderProductOutput;
  /** 결제금액 */
  price: Scalars['Int']['output'];
  product: ProductOutput;
  /** 환불 일시 */
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  /** 판매금액 */
  salePrice: Scalars['Int']['output'];
  /** 브랜드 부담 총액 */
  totalBurdenPrice: Scalars['Int']['output'];
};

export type OrderProductItemOutputProductArgs = {
  lang: LanguageCodeEnum;
};

export type OrderProductItemPaginatedOutput = {
  __typename?: 'OrderProductItemPaginatedOutput';
  /** 집계 항목 */
  aggregation: OrderProductItemAggregation;
  data: Array<OrderProductItemOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type OrderProductOutput = {
  __typename?: 'OrderProductOutput';
  /** 결제금액 */
  amount: Scalars['Float']['output'];
  /** 구매자 전화번호 */
  cellPhone?: Maybe<Scalars['String']['output']>;
  /** 주문자 국가 */
  country?: Maybe<Scalars['String']['output']>;
  /** 주문일시 */
  createdAt: Scalars['DateTime']['output'];
  /** 구매자 이메일 */
  email: Scalars['String']['output'];
  /** 주문번호 */
  godoOrderId: Scalars['String']['output'];
  histories: Array<OrderEventHistoryOutput>;
  /** 주문 ID */
  id: Scalars['String']['output'];
  /** 언어코드 */
  langCode: LanguageCodeEnum;
  license: OrderLicenseOutput;
  /** 구매자 아이디 */
  memId: Scalars['String']['output'];
  /** 구매자 고유 번호 */
  memNo: Scalars['Int']['output'];
  /** 구매자 명 */
  name: Scalars['String']['output'];
  /** 주문 상태 */
  status: OrderStatus;
  /** 사용 예치금 */
  useAconCash: Scalars['Int']['output'];
  /** 사용 선물 예치금 */
  useGiftDeposit: Scalars['Int']['output'];
  /** 사용 포인트 */
  usePoint: Scalars['Int']['output'];
};

export type OrderProductOutputHistoriesArgs = {
  events?: InputMaybe<Array<OrderEventTypeEnum>>;
};

export type OrderProductReturn = {
  __typename?: 'OrderProductReturn';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['Int']['output'];
  /** 상품 명 */
  title: Scalars['String']['output'];
};

export enum OrderStatus {
  Cancel = 'cancel',
  Confirm = 'confirm',
  Fail = 'fail',
  PartialCancel = 'partialCancel',
  Ready = 'ready',
  Settled = 'settled',
  Waiting = 'waiting',
}

export enum OrderStatusEnum {
  CanceledByAdmin = 'CANCELED_BY_ADMIN',
  CanceledByCustomer = 'CANCELED_BY_CUSTOMER',
  Completed = 'COMPLETED',
  DepositStandby = 'DEPOSIT_STANDBY',
  InPreparation = 'IN_PREPARATION',
  OnDelivery = 'ON_DELIVERY',
  PaymentAborted = 'PAYMENT_ABORTED',
  PaymentCompleted = 'PAYMENT_COMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentTried = 'PAYMENT_TRIED',
  RefundAsked = 'REFUND_ASKED',
  RefundCompleted = 'REFUND_COMPLETED',
  ReturnAsked = 'RETURN_ASKED',
}

export type OrderViewDto = {
  __typename?: 'OrderViewDto';
  /** 은행 */
  bank: Scalars['String']['output'];
  /** 계좌 */
  bankAccount: Scalars['String']['output'];
  /** 예금주 */
  bankHolder: Scalars['String']['output'];
  /** 입금자 */
  bankSender: Scalars['String']['output'];
  cash?: Maybe<Scalars['String']['output']>;
  /** 주문 상세 사용권들 */
  license?: Maybe<OrderLicenseModel>;
  /** 주문 정보 */
  order?: Maybe<OrderDto>;
  /** 주문일자 */
  orderDate: Scalars['DateTime']['output'];
  /** 주문 상세 상품들 */
  orderProducts: Array<OrderViewProductDto>;
  /** pg사 실패사유 */
  pgFailReason: Scalars['String']['output'];
  pgSettleCd: Array<Scalars['String']['output']>;
  pgSettleNm: Array<Scalars['String']['output']>;
  /** 영수증 */
  receipt?: Maybe<OrderViewReceiptDto>;
  receiptFl: Scalars['String']['output'];
  settleKind: Scalars['String']['output'];
  /** 결제방법 */
  settleMethod: Scalars['String']['output'];
  /** 결제 */
  settleName: Scalars['String']['output'];
  /** 결제금액 */
  settlePrice: Scalars['Int']['output'];
  /** 영수증 */
  settleReceipt: Scalars['String']['output'];
  /** 주문상태 */
  status: Scalars['String']['output'];
  tax?: Maybe<Scalars['String']['output']>;
  /** 배송비-쿠폰 할인금 */
  totalCouponDeliveryDcPrice: Scalars['Int']['output'];
  /** 주문-쿠폰 할인금 */
  totalCouponGoodsDcPrice: Scalars['Int']['output'];
  /** 상품-쿠폰 할인금 */
  totalCouponOrderDcPrice: Scalars['Int']['output'];
  /** 총 할인금액 */
  totalGoodsDcPrice: Scalars['Int']['output'];
  /** 상품 총 합계 */
  totalGoodsPrice: Scalars['Int']['output'];
  /** 사용된 예치금 */
  useDeposit: Scalars['Int']['output'];
  /** 사용된 마일리지 */
  useMileage: Scalars['Int']['output'];
  /** 사용자 정보 */
  user: OrderViewUserInfoDto;
};

export type OrderViewProductDto = {
  __typename?: 'OrderViewProductDto';
  /** 상품번호 */
  goodsNo: Scalars['Float']['output'];
  /** 이미지 URL */
  imageUrl: Scalars['String']['output'];
  /** 판매 금액 */
  price: Scalars['Float']['output'];
  product?: Maybe<Acon3dProduct>;
  /**
   * 정가
   * @deprecated realPrice -> price, price -> salePrice로 대체
   */
  realPrice: Scalars['Float']['output'];
  /** 판매가 */
  salePrice: Scalars['Int']['output'];
  /** 상태 */
  status: Scalars['String']['output'];
  statusStr: Scalars['String']['output'];
};

export type OrderViewReceiptDto = {
  __typename?: 'OrderViewReceiptDto';
  cashFl?: Maybe<Scalars['String']['output']>;
  limitDateFl?: Maybe<Scalars['String']['output']>;
  particularFl?: Maybe<Scalars['String']['output']>;
  periodDay?: Maybe<Scalars['String']['output']>;
  periodFl?: Maybe<Scalars['String']['output']>;
  taxFl?: Maybe<Scalars['String']['output']>;
  taxInvoiceLimitDate?: Maybe<Scalars['String']['output']>;
};

export type OrderViewUserInfoDto = {
  __typename?: 'OrderViewUserInfoDto';
  /** 휴대폰 번호 */
  cellPhone: Scalars['String']['output'];
  /** 이메일 */
  email: Scalars['String']['output'];
  /** 사용자 이름 */
  name: Scalars['String']['output'];
};

export type OrderedProductsInput_Condition = {
  /** 성인상품 여부; null일 경우 모든 종류의 상품을 리턴; */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 상품판매 여부; null일 경우 모든 상품 리턴; */
  isOnSale?: InputMaybe<Scalars['Boolean']['input']>;
  /** 주문 상태 배열 */
  orderStates?: InputMaybe<Array<Ordered_Products_Input_Condition_Order_State>>;
  /** 상품판매가 최저(이상) */
  salePriceAbove?: InputMaybe<Scalars['Float']['input']>;
  /** 상품판매가 최고(미만) */
  salePriceUnder?: InputMaybe<Scalars['Float']['input']>;
  /** 주문 일자 범위 시작일 */
  since: Scalars['DateTime']['input'];
  /** 주문 일자 범위 종료일 */
  until: Scalars['DateTime']['input'];
};

export type OrderedProductsInput_Sort = {
  criterion: Ordered_Products_Input_Sort_Criterion;
  key: Ordered_Products_Input_Sort_Key;
};

export enum Partner_Document_Status {
  Denied = 'DENIED',
  Draft = 'DRAFT',
  DraftOnOpen = 'DRAFT_ON_OPEN',
  InReview = 'IN_REVIEW',
  InReviewOnOpen = 'IN_REVIEW_ON_OPEN',
  OnSale = 'ON_SALE',
  Rejected = 'REJECTED',
  RejectedOnOpen = 'REJECTED_ON_OPEN',
  Suspended = 'SUSPENDED',
}

export enum Preset_Brands_Input_Preset {
  KeyAccount = 'KEY_ACCOUNT',
}

export enum Preset_Products_Preset {
  BrandWeek = 'BRAND_WEEK',
  En_1 = 'EN_1',
  Ja_1 = 'JA_1',
  Ko_1 = 'KO_1',
  Recommended = 'RECOMMENDED',
  ResourceBank_1 = 'RESOURCE_BANK_1',
  ResourceBank_2 = 'RESOURCE_BANK_2',
}

export enum Products_Input_Condition_Industry {
  Game = 'GAME',
  Webtoon = 'WEBTOON',
}

export enum Products_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Products_Sort_Key {
  Id = 'ID',
  OrderAmount = 'ORDER_AMOUNT',
  OrderCount = 'ORDER_COUNT',
  PromotionStartDate = 'PROMOTION_START_DATE',
  ReleasedAt = 'RELEASED_AT',
  SalePrice = 'SALE_PRICE',
}

export enum Promotion_Concept_Codes {
  Flashsale = 'flashsale',
  Openrun = 'openrun',
}

export type PackageChildren = {
  __typename?: 'PackageChildren';
  selected: Array<Asset>;
  unselected: Array<Asset>;
};

export type PackageInput = {
  childrenIds: Array<Scalars['Int']['input']>;
  parentId: Scalars['Int']['input'];
};

export type PackageOutput = {
  __typename?: 'PackageOutput';
  children: Array<Asset>;
  parent: Asset;
};

export type PaginatedBrandPromotionArgsSearch = {
  doNotUseThisField?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginatedBrandPromotionOutput = {
  __typename?: 'PaginatedBrandPromotionOutput';
  data: Array<BrandPromotionOutput>;
  pagination: PaginatedBrandPromotionOutputPaginationOutput;
};

export type PaginatedBrandPromotionOutputPaginationOutput = {
  __typename?: 'PaginatedBrandPromotionOutputPaginationOutput';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedDetailReview = {
  __typename?: 'PaginatedDetailReview';
  nextCursor?: Maybe<Scalars['Int']['output']>;
  node?: Maybe<Review>;
  nth?: Maybe<Scalars['Int']['output']>;
  prevCursor?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedPromotionOutput = {
  __typename?: 'PaginatedPromotionOutput';
  data: Array<PromotionOutput>;
  pagination: PaginatedPromotionOutputPaginationOutput;
};

export type PaginatedPromotionOutputPaginationOutput = {
  __typename?: 'PaginatedPromotionOutputPaginationOutput';
  limit: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginatedReview = {
  __typename?: 'PaginatedReview';
  edge?: Maybe<ReviewEdge>;
  nodes?: Maybe<Array<Review>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedReviewsWithCount = {
  __typename?: 'PaginatedReviewsWithCount';
  count: Scalars['Int']['output'];
  rows: Array<Review>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Float']['output'];
  lastPage: Scalars['Float']['output'];
  perPage: Scalars['Float']['output'];
  totalCount: Scalars['Float']['output'];
};

export type PaginationInput = {
  /** 페이지 출력량 */
  limit: Scalars['Int']['input'];
  /** 요청 페이지 */
  page: Scalars['Int']['input'];
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  /** 현재 페이지 */
  currentPage: Scalars['Int']['output'];
  /** 마지막 페이지 */
  lastPage: Scalars['Int']['output'];
  /** 페이지당 수량 */
  perPage: Scalars['Int']['output'];
  /** 총 수량 */
  totalCount: Scalars['Int']['output'];
};

export enum PartnerExcelDocumentClassEnum {
  User = 'USER',
  UserSettle = 'USER_SETTLE',
}

export enum PartnerMemoTypeEnum {
  User = 'USER',
  UserSettle = 'USER_SETTLE',
}

export type PartnerPlanInput = {
  /** 전속 여부 */
  isExclusive: Scalars['Boolean']['input'];
  /** 파트너십 종류 */
  planType: Scalars['Int']['input'];
};

export enum PartnerSettleSortColumn {
  RequestDate = 'REQUEST_DATE',
}

export enum PartnerSettleStatusEnum {
  Approve = 'approve',
  None = 'none',
  Reject = 'reject',
  Request = 'request',
  Rerequest = 'rerequest',
}

export type PartnerSettlesSortCriteria = {
  orderBy?: InputMaybe<OrderBy>;
  userSettleOrderColumn?: InputMaybe<PartnerSettleSortColumn>;
};

export enum PartnerTypeEnum {
  Business = 'Business',
  Personal = 'Personal',
}

export type PaymentPoint = {
  __typename?: 'PaymentPoint';
  /** 구매 완료 기본 지급 포인트 */
  defaultPaymentPoint: Scalars['Int']['output'];
  /** 리뷰 작성 기본 지급 포인트 */
  defaultWriteReviewPoint: Scalars['Int']['output'];
  /** 총 포인트 혜택 */
  godoGoodsNo: Scalars['Int']['output'];
  /** 총 포인트 혜택 */
  point: Scalars['Int']['output'];
};

export type Periods = {
  __typename?: 'Periods';
  /** 종료일자 */
  endDate: Scalars['DateTime']['output'];
  /** 시작일자 */
  startDate: Scalars['DateTime']['output'];
};

export type PointBenefit = {
  __typename?: 'PointBenefit';
  /** 포인트 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<PointBenefitApply>>;
  /** 포인트 혜택 적용 설정 */
  applyType?: Maybe<BenefitApply>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<PointBenefitBoardInfo>>;
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 기본 설정 여부 */
  defaultApply?: Maybe<Scalars['Boolean']['output']>;
  /** 지급 내용 */
  description?: Maybe<Scalars['String']['output']>;
  /** 포인트 혜택 적용 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 프로모션에서 자동 생성한 혜택 여부 */
  isAutoCreatedByPromotion?: Maybe<Scalars['Boolean']['output']>;
  /** 포인트 혜택을 적용할 다국어몰 */
  language?: Maybe<Language>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<PointBenefitOrderInfo>>;
  /** 포인트 지급 */
  payPoint: Scalars['String']['output'];
  /** 포인트 지급 설정 */
  pointUnit: Scalars['String']['output'];
  /** 포인트 혜택 수량 */
  quantity?: Maybe<Scalars['String']['output']>;
  /** 포인트 혜택 적용 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 포인트 헤택 타입 */
  type?: Maybe<BenefitType>;
  typeId: Scalars['Float']['output'];
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
  /** 포인트 혜택 사용 개수 */
  useCount?: Maybe<Scalars['Float']['output']>;
};

export type PointBenefitApply = {
  __typename?: 'PointBenefitApply';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  pointBenefitId: Scalars['Float']['output'];
  /** 적용 설정 값 */
  value: Scalars['String']['output'];
};

export type PointBenefitApplyClone = {
  __typename?: 'PointBenefitApplyClone';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float']['output'];
  /** 적용 설정 값 */
  value: Scalars['String']['output'];
};

export type PointBenefitBoardInfo = {
  __typename?: 'PointBenefitBoardInfo';
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String']['output'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String']['output'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean']['output'];
  /** 지급 포인트 */
  payPoint: Scalars['Float']['output'];
  pointBenefitId: Scalars['Float']['output'];
  /** 회원 ID */
  userId: Scalars['String']['output'];
};

export type PointBenefitBoardInfoClone = {
  __typename?: 'PointBenefitBoardInfoClone';
  /** 고도몰 게시판 고유 타입 */
  godoBdId: Scalars['String']['output'];
  /** 고도몰 댓글 고유 ID */
  godoCommentSno: Scalars['String']['output'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 게시판의 게시글 ID */
  godoSno: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean']['output'];
  /** 지급 포인트 */
  payPoint: Scalars['Float']['output'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float']['output'];
  /** 회원 ID */
  userId: Scalars['String']['output'];
};

export type PointBenefitClone = {
  __typename?: 'PointBenefitClone';
  /** 포인트 혜택 설정 값 리스트 */
  applyList?: Maybe<Array<PointBenefitApplyClone>>;
  /** 포인트 혜택 적용 설정 */
  applyType?: Maybe<BenefitApplyClone>;
  /** 포인트 혜택 설정 값 리스트 */
  boardInfo?: Maybe<Array<PointBenefitBoardInfoClone>>;
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 기본 설정 여부 */
  defaultApply?: Maybe<Scalars['Boolean']['output']>;
  /** 지급 내용 */
  description?: Maybe<Scalars['String']['output']>;
  /** 포인트 혜택 적용 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  /** 프로모션에서 자동 생성한 혜택 여부 */
  isAutoCreatedByPromotion?: Maybe<Scalars['Boolean']['output']>;
  /** 포인트 혜택을 적용할 다국어몰 */
  language?: Maybe<LanguageClone>;
  /** 포인트 혜택 설정 값 리스트 */
  orderInfo?: Maybe<Array<PointBenefitOrderInfoClone>>;
  /** 포인트 지급 */
  payPoint: Scalars['String']['output'];
  /** 포인트 지급 설정 */
  pointUnit: Scalars['String']['output'];
  /** 포인트 혜택 수량 */
  quantity?: Maybe<Scalars['String']['output']>;
  /** 포인트 혜택 적용 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 포인트 헤택 타입 */
  type?: Maybe<BenefitTypeClone>;
  typeId: Scalars['Float']['output'];
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
  /** 포인트 혜택 사용 개수 */
  useCount?: Maybe<Scalars['Float']['output']>;
};

export type PointBenefitOrderInfo = {
  __typename?: 'PointBenefitOrderInfo';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String']['output'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean']['output'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean']['output'];
  /** 지급 포인트 */
  payPoint: Scalars['Float']['output'];
  pointBenefitId: Scalars['Float']['output'];
  /** 회원 ID */
  userId: Scalars['String']['output'];
};

export type PointBenefitOrderInfoClone = {
  __typename?: 'PointBenefitOrderInfoClone';
  /** 고도몰 상품 번호 */
  godoGoodsNo: Scalars['String']['output'];
  /** 고도몰 회원 고유 번호 */
  godoMemNo?: Maybe<Scalars['Int']['output']>;
  /** 고도몰 주문 ID */
  godoOrderId: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 구매 완료 여부 */
  isComplete: Scalars['Boolean']['output'];
  /** 포인트 회수 여부 */
  isRecover: Scalars['Boolean']['output'];
  /** 지급 포인트 */
  payPoint: Scalars['Float']['output'];
  pointBenefit: PointBenefitClone;
  pointBenefitId: Scalars['Float']['output'];
  /** 회원 ID */
  userId: Scalars['String']['output'];
};

export type PointItem = {
  __typename?: 'PointItem';
  /** 남은 포인트 액수 */
  afterPoint: Scalars['Int']['output'];
  /** 포인트 지급 내용 */
  contents: Scalars['String']['output'];
  /** 포인트 만료 일자 */
  deleteScheduleDate: Scalars['String']['output'];
  /** 포인트 지급 액수 */
  point: Scalars['Int']['output'];
  /** 포인트 지급 일자 */
  regDate: Scalars['String']['output'];
};

export type PointList = {
  __typename?: 'PointList';
  /** 포인트 지급 내역 목록 */
  items: Array<PointItem>;
  /** 지급 내역 전체 갯수 */
  totalCount: Scalars['Int']['output'];
};

export type Popup = {
  __typename?: 'Popup';
  /** 팝업 오늘하루 숨기기 버튼 표시 여부 */
  allowHide: Scalars['Boolean']['output'];
  /** 팝업 내용(바디) */
  body?: Maybe<Scalars['String']['output']>;
  /** 생성일 */
  created: Scalars['DateTime']['output'];
  /** 팝업 표시 종료일 */
  endDate: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 팝업를 표시할 다국어허브 */
  language?: Maybe<Language>;
  /** 팝업 표시 url */
  location?: Maybe<Array<PopupLocation>>;
  /** 팝업 표시 시작일 */
  startDate: Scalars['DateTime']['output'];
  /** 팝업 제목 */
  title?: Maybe<Scalars['String']['output']>;
  /** 수정일 */
  updated: Scalars['DateTime']['output'];
};

export type PopupHistory = {
  __typename?: 'PopupHistory';
  status: PopupStatus;
  updated: Scalars['DateTime']['output'];
  updater: Scalars['String']['output'];
};

export type PopupLocation = {
  __typename?: 'PopupLocation';
  /** 고유 id */
  id: Scalars['Float']['output'];
  /** 팝업 id */
  popupId: Scalars['Float']['output'];
  /** 팝업을 표시할 url */
  url: Scalars['String']['output'];
};

export enum PopupLocationV2 {
  Acon3d = 'acon3d',
  PartnerHub = 'partnerHub',
}

export enum PopupOrderCol {
  Created = 'created',
  EndDate = 'endDate',
  StartDate = 'startDate',
}

export type PopupOutput = {
  __typename?: 'PopupOutput';
  /** 버튼 문구 */
  buttonText?: Maybe<Scalars['String']['output']>;
  /** 연결 URL */
  connectedUrl?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  /** 등록자 */
  creator: AdminUser;
  /** 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** 팝업 이미지 아이디 */
  image?: Maybe<UploadFile>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen: Scalars['Boolean']['output'];
  /** 일시 정지용 */
  isStopped?: Maybe<Scalars['Boolean']['output']>;
  /** 언어 */
  language: LanguageCodeEnum;
  /** 위치 */
  location: PopupLocationV2;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean']['output'];
  popupHistories: Array<PopupHistory>;
  /** 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 하위 페이지 url */
  subLocations: Array<PopupSubLocation>;
  /** 서브 텍스트 */
  subText?: Maybe<Scalars['String']['output']>;
  /** 팝업 텍스트 */
  text?: Maybe<Scalars['String']['output']>;
  /** 팝업 제목(관리용) */
  title: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  /** 수정자 */
  updater: AdminUser;
  /** 하위 페이지 url */
  urls: Array<PopupUrlOutput>;
};

export enum PopupStatus {
  End = 'end',
  Open = 'open',
  Pause = 'pause',
  Standby = 'standby',
  TemporarySaved = 'temporarySaved',
  UnPause = 'unPause',
}

export type PopupSubLocation = {
  __typename?: 'PopupSubLocation';
  id: Scalars['Int']['output'];
  /** 소속된 팝업 */
  popup: PopupV2;
  /** 하위 위치(메인 구분용) */
  subLocation: PopupSubLocationEnum;
};

export enum PopupSubLocationEnum {
  Game = 'game',
  Toon = 'toon',
}

export type PopupSubLocationInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  popupId?: InputMaybe<Scalars['Int']['input']>;
  subLocation: PopupSubLocationEnum;
};

export type PopupUploadFileInput = {
  id: Scalars['Int']['input'];
};

export type PopupUrl = {
  __typename?: 'PopupUrl';
  id: Scalars['Int']['output'];
  /** 하위페이지 노출여부 */
  isSubPageIncluded: Scalars['Boolean']['output'];
  /** 소속된 팝업 */
  popup: PopupV2;
  /** 하위 위치(메인 구분용) */
  subLocation?: Maybe<PopupSubLocationEnum>;
  /** 하위 페이지 url */
  url: Scalars['String']['output'];
};

export type PopupUrlOutput = {
  __typename?: 'PopupUrlOutput';
  id?: Maybe<Scalars['Int']['output']>;
  popupId?: Maybe<Scalars['Int']['output']>;
  subLocation?: Maybe<PopupSubLocationEnum>;
  urls: Array<UrlsOutput>;
};

export type PopupV2 = {
  __typename?: 'PopupV2';
  /** 버튼 문구 */
  buttonText?: Maybe<Scalars['String']['output']>;
  /** 버튼 연결 링크 */
  connectedUrl?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  /** 등록자 */
  creator: AdminUser;
  /** 노출 종료일 */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** 바로 노출 */
  exposeImmediately: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  /** 팝업 이미지 아이디 */
  image?: Maybe<UploadFile>;
  /** 임시 저장용(노출 시킬건지 말건지) */
  isOpen: Scalars['Boolean']['output'];
  /** 일시 정지용 */
  isStopped?: Maybe<Scalars['Boolean']['output']>;
  /** 언어 */
  language: LanguageCodeEnum;
  /** 팝업을 노출할 서비스 */
  location: PopupLocationV2;
  /** 종료일 없음 */
  noEndDate: Scalars['Boolean']['output'];
  popupHistories?: Maybe<Array<PopupHistory>>;
  /** 노출 시작일 */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** 하위 페이지 url */
  subLocations?: Maybe<Array<PopupSubLocation>>;
  /** 서브 텍스트 */
  subText?: Maybe<Scalars['String']['output']>;
  /** 팝업 텍스트 */
  text?: Maybe<Scalars['String']['output']>;
  /** 팝업 제목(관리용) */
  title: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  /** 수정자 */
  updater: AdminUser;
  /** 하위 페이지 url */
  urls?: Maybe<Array<PopupUrl>>;
};

export type Product = {
  __typename?: 'Product';
  /** 상품 응용프로그램 필드 */
  applications?: Maybe<Array<ApplicationEntity>>;
  /** 해당 상품에 표시(또는 숨기기)할 배너들 */
  banners?: Maybe<Array<Banner>>;
  brand: Brand;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Float']['output']>;
  /** 상품 카테고리 */
  categories?: Maybe<Array<Category>>;
  /** 상품 의견 리스트 */
  comments?: Maybe<Array<ProductComment>>;
  /** 수수료 */
  commission?: Maybe<Scalars['Float']['output']>;
  /** 상품 본문 */
  contents?: Maybe<Scalars['String']['output']>;
  /** 저작권 */
  copyright?: Maybe<Scalars['String']['output']>;
  /** 상품 설명 */
  description?: Maybe<Scalars['String']['output']>;
  /** 문서 ID */
  docId: Scalars['String']['output'];
  /** 동적 요소 */
  dynamicItem?: Maybe<Scalars['String']['output']>;
  /** 상품 파일 구성 */
  fileConfigList?: Maybe<Array<ProductFileConfig>>;
  /** 상품 파일 확장자 */
  fileExtensionList?: Maybe<Array<ProductFileExtension>>;
  /** 상품 파일 업데이트 내역 */
  fileUpdateList?: Maybe<Array<ProductFileUpdate>>;
  /** 고도몰 상품번호 */
  godoGoodsNo?: Maybe<Scalars['Int']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 상품 이미지 리스트 */
  imageList?: Maybe<Array<Maybe<ProductImage>>>;
  /** 삭제여부 */
  isDelete: Scalars['Boolean']['output'];
  /** 수정요청 여부 */
  isEdit: Scalars['Boolean']['output'];
  /** 검색 키워드 */
  keywords?: Maybe<Scalars['String']['output']>;
  language: Language;
  languageCode: Scalars['String']['output'];
  /** 업로드 한 파일 */
  model?: Maybe<UploadFile>;
  /** 모델구성 */
  modelConfigList?: Maybe<Array<ModelConfig>>;
  /** 모델 파일 ID */
  modelFileId?: Maybe<Scalars['Float']['output']>;
  /** 모델 파일 명 */
  modelFileName?: Maybe<Scalars['String']['output']>;
  /** 상품 오픈일 */
  openDate?: Maybe<Scalars['DateTime']['output']>;
  originLanguage: Language;
  /** 상품 금액 */
  price?: Maybe<Scalars['Float']['output']>;
  /** 연관상품 */
  related?: Maybe<Array<ProductRelated>>;
  /** 검토 요청일 */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** 상품 할인가 */
  salePrice?: Maybe<Scalars['Float']['output']>;
  /** 상품 상태 */
  status: Scalars['String']['output'];
  /** 상품 제목 */
  title: Scalars['String']['output'];
  /** 파일 버전 */
  versions?: Maybe<Array<ProductVersion>>;
};

export type ProductBookInfoOutput = {
  __typename?: 'ProductBookInfoOutput';
  /** isbn */
  isbn?: Maybe<Scalars['String']['output']>;
};

export type ProductCardsSummary = {
  __typename?: 'ProductCardsSummary';
  /** 상품 정보 */
  products: Array<Acon3dProduct>;
  sort?: Maybe<ProductListSort>;
  /** 사용 여부 */
  toggle?: Maybe<Scalars['Boolean']['output']>;
};

export type ProductComment = {
  __typename?: 'ProductComment';
  created: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 메세지 */
  message?: Maybe<Scalars['String']['output']>;
  productId: Scalars['Float']['output'];
  /** 메세지 유형 */
  type?: Maybe<Scalars['String']['output']>;
};

export type ProductDateInfoOutput = {
  __typename?: 'ProductDateInfoOutput';
  /** 상품 최초 노출일 */
  releasedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductExtensionsInput_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 검색어 */
  input?: InputMaybe<Scalars['String']['input']>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']['input']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']['input']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']['input']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProductFileConfig = {
  __typename?: 'ProductFileConfig';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  productId: Scalars['Float']['output'];
  size: Scalars['Float']['output'];
};

export type ProductFileExtension = {
  __typename?: 'ProductFileExtension';
  extensionConfig: ExtensionConfig;
  extensionConfigId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  productId: Scalars['Int']['output'];
};

export type ProductFileUpdate = {
  __typename?: 'ProductFileUpdate';
  created: Scalars['DateTime']['output'];
  /** 업데이트 일자 */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  productId: Scalars['Float']['output'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  created: Scalars['DateTime']['output'];
  /** 크롭 높이 */
  cropHeight?: Maybe<Scalars['Float']['output']>;
  /** 크롭 너비 */
  cropWidth?: Maybe<Scalars['Float']['output']>;
  /** 크롭 x 위치 */
  cropX?: Maybe<Scalars['Float']['output']>;
  /** 크롭 y 위치 */
  cropY?: Maybe<Scalars['Float']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  item?: Maybe<UploadFile>;
  productId: Scalars['Float']['output'];
  /** 이미지 유형 */
  type: Scalars['String']['output'];
  uploadFileId?: Maybe<Scalars['Float']['output']>;
};

export type ProductImageInfoOutput = {
  __typename?: 'ProductImageInfoOutput';
  main: ProductImageOutput;
  sub: ProductImageOutput;
};

export type ProductImageOutput = {
  __typename?: 'ProductImageOutput';
  url: Scalars['String']['output'];
};

export type ProductLang = {
  __typename?: 'ProductLang';
  /** 브랜드 명 */
  creator: Scalars['String']['output'];
  /** 브랜드 명 */
  itemNum: Scalars['Int']['output'];
  /** 제목 */
  title: Scalars['String']['output'];
};

export type ProductListFilterInput = {
  applications?: InputMaybe<Array<Scalars['String']['input']>>;
  extensions?: InputMaybe<Array<Scalars['String']['input']>>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductListOptionInput = {
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductListReturn = {
  __typename?: 'ProductListReturn';
  /** 카테고리 코드 */
  cateCd?: Maybe<Scalars['String']['output']>;
  /** 개수 */
  count?: Maybe<Scalars['Float']['output']>;
  /** 비슷한 작품 항목들 */
  items?: Maybe<Array<ProductViewCardList>>;
};

export enum ProductListSort {
  HigherPrice = 'HIGHER_PRICE',
  LowerPrice = 'LOWER_PRICE',
  Newest = 'NEWEST',
  Popularity = 'POPULARITY',
  Recommended = 'RECOMMENDED',
}

export type ProductOutput = {
  __typename?: 'ProductOutput';
  /** 상품 사용 가능 프로그램 정보 */
  applications: Array<AssetApplicationOutput>;
  /** 책 정보 */
  bookInfo: ProductBookInfoOutput;
  /** 상품 브랜드 정보 */
  brandInfo: BrandOutput;
  /** 상품 일자 정보 */
  dateInfo: ProductDateInfoOutput;
  /** 상품 확장자 정보 */
  extensions: Array<ExtensionOutput>;
  id: Scalars['Int']['output'];
  /** 상품 이미지 정보 */
  imageInfo: ProductImageInfoOutput;
  isAdultOnly: Scalars['Boolean']['output'];
  /** 상품 가격 정보 */
  priceInfo: ProductPriceInfoOutput;
  /** 상품 프로모션 정보; 해당하지 않으면 null; */
  promotionInfo?: Maybe<ProductPromotionInfoOutput>;
  /** 상품 태그 정보 */
  tags: Array<TagOutput>;
  title: Scalars['String']['output'];
};

export type ProductPaginatedOutput = {
  __typename?: 'ProductPaginatedOutput';
  data: Array<ProductOutput>;
  pagination: PaginationOutput;
};

export type ProductPriceInfoOutput = {
  __typename?: 'ProductPriceInfoOutput';
  /** 상품 정가 */
  price: Scalars['Float']['output'];
  /** 상품 판매가 */
  salePrice: Scalars['Float']['output'];
};

export type ProductPromotionInfoOutput = {
  __typename?: 'ProductPromotionInfoOutput';
  /** 프로모션 시작일자 */
  since: Scalars['DateTime']['output'];
  /** 프로모션 종료일자 */
  until: Scalars['DateTime']['output'];
};

export type ProductRelated = {
  __typename?: 'ProductRelated';
  from: Scalars['Float']['output'];
  to: Scalars['Float']['output'];
};

export type ProductTagsInput_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 검색어 */
  input?: InputMaybe<Scalars['String']['input']>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']['input']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']['input']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']['input']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum ProductType {
  Game = 'GAME',
  Webtoon = 'WEBTOON',
}

export enum ProductTypes {
  Game = 'GAME',
  Imgsrc = 'IMGSRC',
  Lecture = 'LECTURE',
  Webtoon = 'WEBTOON',
}

export type ProductVersion = {
  __typename?: 'ProductVersion';
  /** 데이터 전체 */
  contents?: Maybe<Scalars['String']['output']>;
  /** 버전 생성일자 */
  created: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 버전 Major */
  major?: Maybe<Scalars['Float']['output']>;
  /** 버전 Minor */
  minor?: Maybe<Scalars['Float']['output']>;
  /** 오픈된 상품정보 */
  openProductInfo?: Maybe<Product>;
  /** 상품 ID */
  productId?: Maybe<Scalars['Float']['output']>;
  /** 선택된 상품정보 */
  productInfo?: Maybe<Product>;
};

export type ProductViewCardList = {
  __typename?: 'ProductViewCardList';
  /** 브랜드 명 */
  brand: Scalars['String']['output'];
  /** 브랜드 코드 */
  brandCd: Scalars['String']['output'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float']['output'];
  /** 이미지 URL */
  imageUrl: Scalars['String']['output'];
  /** 가격 */
  price: Scalars['String']['output'];
  /** 가격 */
  realPrice: Scalars['String']['output'];
  /** 제목 */
  title: Scalars['String']['output'];
};

export type ProductsConditionInput = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']['input']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']['input']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']['input']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ProduktPublicOutput = {
  __typename?: 'ProduktPublicOutput';
  badgeNames: Array<Scalars['String']['output']>;
  brand: BrandPublicOutput;
  categories: Array<CategoryPublicOutput>;
  content?: Maybe<Scalars['String']['output']>;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  extensions: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** 성인상품 여부 */
  isAdultOnly: Scalars['Boolean']['output'];
  licenses: Array<AssetLicensePublicOutput>;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String']['output'];
  /** 리스트 노출 여부 */
  onDisplay: Scalars['Boolean']['output'];
  /** 판매 여부 */
  onSale: Scalars['Boolean']['output'];
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int']['output'];
  /** 상품 정가 */
  price: Scalars['Int']['output'];
  /** 진행 중인 프로모션 정보 */
  promotionEndDate?: Maybe<Scalars['DateTime']['output']>;
  /** 리뷰 수 */
  reviewCount: Scalars['Int']['output'];
  /** 상품 할인가 */
  salePrice: Scalars['Int']['output'];
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String']['output'];
  /** 번역된 상품명 */
  title: Scalars['String']['output'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']['output']>;
  /** 조회 수 */
  viewCount: Scalars['Int']['output'];
};

export type ProfilePhoto = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PromotedProductOutput = {
  __typename?: 'PromotedProductOutput';
  applications?: Maybe<Array<ApplicationEntity>>;
  /** 상품카드 뱃지 이름들(번역) */
  badgeNames: Array<Maybe<Scalars['String']['output']>>;
  brand: Acon3dBrand;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  /** 확장자 */
  extensions: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** ACON ONLY 여부 */
  isAconOnly: Scalars['Boolean']['output'];
  isExclusive: Scalars['Boolean']['output'];
  /** 언어 */
  languageCode: LanguageCodeEnum;
  /** 대표 이미지 URL */
  mainImageUrl: Scalars['String']['output'];
  /** 주문 수(인기순 정렬) */
  orderCount: Scalars['Int']['output'];
  /** 상품 정가 */
  price: Scalars['Int']['output'];
  promotion: PromotionOutput;
  /** 리뷰 수 */
  reviewCount: Scalars['Int']['output'];
  /** 상품 할인가 */
  salePrice: Scalars['Int']['output'];
  /** 서브 이미지 URL */
  subImageUrl: Scalars['String']['output'];
  /** 번역된 상품명 */
  title: Scalars['String']['output'];
  /** 갱신 일자 */
  updated?: Maybe<Scalars['DateTime']['output']>;
  /** 조회 수 */
  viewCount: Scalars['Int']['output'];
};

export type Promotion = {
  __typename?: 'Promotion';
  /** 프로모션 뱃지 리스트 */
  badgeList?: Maybe<Array<PromotionBadge>>;
  /** 프로모션 혜택 리스트 */
  benefitList?: Maybe<Array<PromotionBenefit>>;
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 프로모션 이미지 리스트 */
  imageList?: Maybe<Array<PromotionImage>>;
  /** 리뷰 작성 시 포인트 지급 혜택을 자동 생성할 지 여부 */
  isAutoCreateReviewBenefit?: Maybe<Scalars['Boolean']['output']>;
  /** 프로모션 언어 리스트 */
  languageList?: Maybe<Array<PromotionLang>>;
  /** 프로모션이 저장되는 시점에 해당되는 상품의 브랜드 명입니다. */
  productBrandName: Scalars['String']['output'];
  /** 프로모션이 저장되는 시점에 해당되는 상품의 제목입니다. */
  productTitle: Scalars['String']['output'];
  /** 예약날짜 */
  reserveDate?: Maybe<Scalars['DateTime']['output']>;
  /** 프로모션의 타입입니다. (cosmic, comet) */
  type: Scalars['String']['output'];
};

export type PromotionAvailabilityOutput = {
  __typename?: 'PromotionAvailabilityOutput';
  /** total이 null인 경우 제한이 없으므로 자연스럽게 null이며, 그 외에는 available = total - current */
  available?: Maybe<Scalars['Int']['output']>;
  /** 현재 보유중인 promote_item의 갯수로 최저값은 0이고 null이 될 수 없음  */
  current: Scalars['Int']['output'];
  /** null인 경우 무제한 */
  total?: Maybe<Scalars['Int']['output']>;
};

export type PromotionBadge = {
  __typename?: 'PromotionBadge';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  item?: Maybe<Array<PromotionBadgeI18n>>;
  promotionId: Scalars['Float']['output'];
  /** 혜택 종류(point, coupon) */
  type: Scalars['String']['output'];
};

export type PromotionBadgeI18n = {
  __typename?: 'PromotionBadgeI18n';
  /** 제공 가격 */
  amount: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  language?: Maybe<Language>;
  /** 프로모션 뱃지 id */
  promotionBadgeId: Scalars['Float']['output'];
};

export type PromotionBenefit = {
  __typename?: 'PromotionBenefit';
  amount: Scalars['Float']['output'];
  /** 할인된 결과물에 대한 금액 */
  discount: Scalars['Float']['output'];
  /** 스냅샷된 할인 단위 */
  discountUnit: Scalars['String']['output'];
  /** 스냅샷된 혜택 종료일자 */
  endDate: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 스냅샷된 혜택 명 */
  name: Scalars['String']['output'];
  promotionId: Scalars['Float']['output'];
  /** 스냅샷된 혜택 시작일자 */
  startDate: Scalars['DateTime']['output'];
  /** 혜택 유형 (1,2,3이 존재) */
  type: Scalars['Int']['output'];
};

export type PromotionConceptOutput = {
  __typename?: 'PromotionConceptOutput';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
  titleRegional?: Maybe<GqlRegional>;
};

export type PromotionImage = {
  __typename?: 'PromotionImage';
  created: Scalars['DateTime']['output'];
  /** 크롭 높이 */
  cropHeight?: Maybe<Scalars['Float']['output']>;
  /** 크롭 너비 */
  cropWidth?: Maybe<Scalars['Float']['output']>;
  /** 크롭 x 위치 */
  cropX?: Maybe<Scalars['Float']['output']>;
  /** 크롭 y 위치 */
  cropY?: Maybe<Scalars['Float']['output']>;
  /** 고유 ID */
  id: Scalars['Float']['output'];
  originUploadFile?: Maybe<UploadFile>;
  originUploadFileId: Scalars['Float']['output'];
  promotionId: Scalars['Float']['output'];
  resizeUploadFile?: Maybe<UploadFile>;
  resizeUploadFileId: Scalars['Float']['output'];
  /** 이미지 유형 */
  type: Scalars['Float']['output'];
};

export type PromotionItemInput = {
  discountType?: InputMaybe<EPromotionDiscountType>;
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['Int']['input'];
  since?: InputMaybe<Scalars['DateTime']['input']>;
  until?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromotionItemOutput = {
  __typename?: 'PromotionItemOutput';
  asset?: Maybe<Asset>;
  discountType?: Maybe<EPromotionDiscountType>;
  discountValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  registeredAt?: Maybe<Scalars['DateTime']['output']>;
  since?: Maybe<Scalars['DateTime']['output']>;
  suspendedAt?: Maybe<Scalars['DateTime']['output']>;
  until?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionItemOutputAssetArgs = {
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromotionLang = {
  __typename?: 'PromotionLang';
  /** 고유 ID */
  id: Scalars['Float']['output'];
  language: Language;
  promotionId: Scalars['Float']['output'];
};

export type PromotionOutput = {
  __typename?: 'PromotionOutput';
  availability: PromotionAvailabilityOutput;
  concept: PromotionConceptOutput;
  description: GqlRegional;
  discountType?: Maybe<EPromotionDiscountType>;
  discountValue?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  inheritance?: Maybe<Scalars['Boolean']['output']>;
  issuer: GqlRegional;
  items: Array<PromotionItemOutput>;
  registeredAt?: Maybe<Scalars['DateTime']['output']>;
  since?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EPromotionStatus>;
  suspendedAt?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  until?: Maybe<Scalars['DateTime']['output']>;
};

export type PromotionOutputItemsArgs = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
};

export type PromotionProductOutput = {
  __typename?: 'PromotionProductOutput';
  /** 언어 */
  languageCode: LanguageCodeEnum;
  product: ProductOutput;
  promotion: PromotionOutput;
};

export type Query = {
  __typename?: 'Query';
  GODO_EVENT_ORDER_COMPLETE: Scalars['Boolean']['output'];
  GODO_EVENT_ORDER_REFUND: Scalars['Boolean']['output'];
  GODO_EVENT_ORDER_SETTLE: Scalars['Boolean']['output'];
  GODO_UPDATE_ISREFUNDED: Scalars['Boolean']['output'];
  aconNotifications: Array<AconNotification>;
  adminAsset: AssetOutput;
  adminBrandAssets: Array<Asset>;
  adminBrandPick: BrandPick;
  adminDocument?: Maybe<AdminSingleDocumentOutput>;
  adminDocuments: Array<AdminMultiDocument>;
  adminDownload: Scalars['String']['output'];
  adminInquiries: Array<Inquiry>;
  adminInquiry: Inquiry;
  adminInquiryCount: Scalars['Int']['output'];
  adminOrderCouponInfo: Array<OrderCouponReturn>;
  adminOrderEventHistory: Array<OrderEventHistory>;
  adminOrderGoodsPriceInfo: OrderAdminRefundModalReturn;
  adminOrderPaymentInfo: OrderPaymentReturn;
  adminTranslate: Array<Translated>;
  adultProductCardsSummary: ProductCardsSummary;
  asset: Asset;
  assetAdminDownload: Scalars['String']['output'];
  assetFileInfo: Asset;
  assets: Array<Asset>;
  banks: Array<Bank>;
  benefitProducts: Array<Acon3dProduct>;
  bestProducts: Array<Scalars['Int']['output']>;
  brand?: Maybe<Brand>;
  brandAssets: Array<Asset>;
  brandPick: BrandPick;
  brandPickSummary: ProductCardsSummary;
  brandProductsWithCategories: Array<BrandProductsWithCategoriesOutput>;
  brandPromotion: BrandPromotionOutput;
  brandPromotions: PaginatedBrandPromotionOutput;
  brandReviews: PaginatedReviewsWithCount;
  brands: BrandPaginatedOutput;
  /** 유저의 장바구니 갯수를 가져오는 쿼리. 장바구니 아이콘에 숫자를 표시하고, 상품에서 장바구니에 담았는지 여부를 표현하기 위해 사용합니다.이전의 query godoUserCart()를 대체하는 쿼리입니다. */
  cart: Array<CartOutput>;
  /** 장바구니 상품 목록을 가져오는 메소드 입니다. */
  carts: Array<CartItem>;
  categories: Array<GodoCategory>;
  categoriesV2: Array<CategoryOutputV4>;
  categoriesV3: Array<CategoryOutputV2>;
  checkExistMember: Scalars['Boolean']['output'];
  checkHasAdultProducts: Scalars['Boolean']['output'];
  checkIssuanceTempPassword: Scalars['Boolean']['output'];
  /** 회원이 사업자인지 확인하는 메소드 입니다. */
  checkedBusiness: Scalars['Boolean']['output'];
  checkedPasswordChange: Scalars['Boolean']['output'];
  childCategories: Array<CategoryNode>;
  clips: Array<Clip>;
  clipsV2: Array<Clip>;
  compare: Array<AdminSingleDocumentOutput>;
  createPartnerPlan: Scalars['Boolean']['output'];
  descendantsOfRegularCategory: Array<CategoryNode>;
  displayAssetWithItems: DisplayAssetWithItemsOutput;
  displayBrandWithItems: DisplayBrandWithItemsOutput;
  document: SingleDocumentOutput;
  documents: Array<ListDocumentOutput>;
  downloadContract: Scalars['String']['output'];
  exchangeRate: Scalars['Float']['output'];
  existsPartner: Scalars['Boolean']['output'];
  faq?: Maybe<HubAdminPost>;
  faqAcon?: Maybe<FaqAconModel>;
  faqAconCount: Scalars['Float']['output'];
  faqAconTypes: Array<FaqAconTypeModel>;
  faqAcons: Array<FaqAconModel>;
  /** 입점 faq 항목들을 가져오는 쿼리 */
  faqCreator?: Maybe<FaqCreatorModel>;
  /** 입점 faq 카테고리 항목들을 가져오는 쿼리 */
  faqCreatorCategories?: Maybe<Array<FaqCreatorModel>>;
  /** 입점 faq 항목들을 개수를 가져오는 쿼리 */
  faqCreatorCount: Scalars['Int']['output'];
  /** 입점 faq 유형들을 가져오는 메소드입니다. */
  faqCreatorTypes: Array<FaqCreatorTypeModel>;
  /** 입점 faq 항목들을 가져오는 쿼리 */
  faqCreators: Array<FaqCreatorModel>;
  faqs: HubAdminPosts;
  fetchPromotion: PromotionOutput;
  fetchPromotions: PaginatedPromotionOutput;
  fileByTempPath?: Maybe<Scalars['String']['output']>;
  fileInfo: UserFileInfoOutput;
  /** 친구 수를 가져오는 쿼리 */
  friendsCount: Scalars['Int']['output'];
  fundingProductNos: Array<Scalars['Int']['output']>;
  getAdminOrder: OrderProductClone;
  getAdminOrders: Array<OrderProductClone>;
  getAdminOrdersCount: Scalars['Int']['output'];
  getAdminReview: Review;
  getAdminReviews: Array<Review>;
  getAdminReviewsCount: Scalars['Int']['output'];
  getApplications: Array<ApplicationEntity>;
  getAttendanceEventDates: Array<Scalars['Float']['output']>;
  getBadge?: Maybe<BadgeReturn>;
  getBadgeCount: Scalars['Float']['output'];
  getBadges: Array<BadgeReturn>;
  getBandBanner?: Maybe<BandBanner>;
  getBandBannerClosed: Array<BandBannerClosed>;
  getBandBannerForPage?: Maybe<Array<BandBannerItems>>;
  getBandBannerTypes: Array<BandBannerType>;
  getBandBanners: Array<BandBanner>;
  getBandBannersTotalCount: Scalars['Float']['output'];
  getBanner?: Maybe<BannerClone>;
  getBannerTypes: Array<BannerTypeClone>;
  getBanners: Array<BannerClone>;
  getBannersByGodoNo?: Maybe<Array<BannerClone>>;
  getBannersTotalCount: Scalars['Float']['output'];
  getBenefitBurdenCount: Scalars['Float']['output'];
  getBenefitBurdenList: Array<BenefitBurden>;
  getBrand: BrandEntity;
  getBrandForAdmin: BrandEntity;
  getBrandOrderProductItems: OrderProductItemPaginatedOutput;
  getBrandOrderProductItemsExcel: Scalars['String']['output'];
  getBrandSettlementOrderExcel: Scalars['String']['output'];
  getBrandSettlements: SettlementPaginatedOutput;
  getBrands: Array<Brand>;
  getBrandsForAdmin: BrandPaginatedResponse;
  getBrandsNotHavingUser: Array<Brand>;
  getCashList?: Maybe<CashList>;
  getCategories: Array<Category>;
  getCategoriesByLang: Array<Category>;
  getCategory?: Maybe<GodoCategory>;
  getCounts: Scalars['Int']['output'];
  getCouponBenefit?: Maybe<CouponBenefitItem>;
  getCouponBenefits?: Maybe<Array<CouponBenefitItem>>;
  getCouponBenefitsTotalCount: Scalars['Float']['output'];
  getCouponBurdenCount: Scalars['Float']['output'];
  getCouponBurdenList?: Maybe<Array<CouponBurden>>;
  getCouponItems?: Maybe<Array<CouponItems>>;
  getCouponList: CouponList;
  getDownloadLogs: DownloadLogResponse;
  /** 이벤트 배너 항목을 가져오는 메소드 입니다. */
  getEventBannerItem?: Maybe<EventBannerItems>;
  /** 이벤트 배너 목록을 가져오는 메소드 입니다. */
  getEventBannerItems?: Maybe<Array<EventBannerItems>>;
  /** 이벤트 배너 총 갯수를 가져오는 메소드입니다. */
  getEventBannerTotalCount: Scalars['Float']['output'];
  getExtensionConfigs?: Maybe<Array<ExtensionConfig>>;
  getFaq?: Maybe<Faq>;
  getFaqTypes: Array<FaqType>;
  getFaqs: Array<Faq>;
  getFaqsTotalCount: Scalars['Float']['output'];
  getGodoBannerInfo: BannerInfo;
  getGodoBrandName: Scalars['String']['output'];
  getGodoPopup?: Maybe<Array<GodoPopupInfo>>;
  getGoodsBuyers: Array<GoodsBuyers>;
  getItem?: Maybe<CouponBanner>;
  getItems?: Maybe<Array<CouponBanner>>;
  getLanguages: Array<Language>;
  getMainGroups: Array<MainGroup>;
  getModelConfigs?: Maybe<Array<ModelConfig>>;
  getMyOrders: Array<MyOrderReturn>;
  getNotice?: Maybe<Notice>;
  getNoticeTypes: Array<NoticeType>;
  getNotices: Array<Notice>;
  getNoticesTotalCount: Scalars['Float']['output'];
  getOrderBenefitPoint: Scalars['Float']['output'];
  getOrderCouponItems?: Maybe<Array<OrderCouponItems>>;
  getOrderInfo: OrderInfo;
  getOrderNoWithoutReview?: Maybe<Scalars['String']['output']>;
  getPaymentPoint?: Maybe<Array<PaymentPoint>>;
  getPhotoReview: PaginatedDetailReview;
  getPhotoReviewTotalCount: Scalars['Int']['output'];
  getPhotoReviews: PaginatedReview;
  getPointBenefit?: Maybe<PointBenefit>;
  getPointBenefitApply: Array<BenefitApply>;
  getPointBenefitTypes: Array<BenefitType>;
  getPointBenefits?: Maybe<Array<PointBenefit>>;
  getPointBenefitsTotalCount: Scalars['Float']['output'];
  getPointLimitPolicy: BenefitLimit;
  getPointLimitPolicyCount: Scalars['Int']['output'];
  getPointLimitPolicyList: Array<BenefitLimit>;
  getPointList: PointList;
  getPopupsHere: Array<PopupV2>;
  getProductLang: Array<ProductLang>;
  getPromotedProducts: Array<PromotedProductOutput>;
  getPromotion: Promotion;
  getPromotionConcept: PromotionConceptOutput;
  getPromotionConcepts: Array<PromotionConceptOutput>;
  getPromotionCount: Scalars['Int']['output'];
  getPromotionProducts: Array<PromotionProductOutput>;
  getPromotions?: Maybe<Array<DisplayPromotionItem>>;
  getReviewPointItems?: Maybe<Array<PointBenefit>>;
  getSearchKeywords: Array<SearchKeyword>;
  getSettleAllListV2?: Maybe<Array<Settle>>;
  getSettleAllPrice: Scalars['Float']['output'];
  getSettleCount: SettleCount;
  getSettleListV2?: Maybe<Array<Settle>>;
  getSettleV2: SettleDetail;
  getTerm: Term;
  getTermAll: Array<Term>;
  /** 포인트 합산을 가져오는 쿼리 */
  getTotalInvitationPoint: Scalars['Int']['output'];
  getTransGodoCategoryChildItems: Array<GodoCategoryOutput>;
  getTransGodoCategoryItems: Array<GodoDisplayCategory>;
  getValidationGoodsNo?: Maybe<Scalars['Boolean']['output']>;
  gift: Gift;
  gifts: Array<Gift>;
  /** 고띄몰의 정의띘어 있는 혜성특가 리스트를 가져오는 메소드입니다. */
  godoCometDealList?: Maybe<Array<GodoCosmicDeal>>;
  /** 고띄몰의 정의띘어 있는 우주최저가 리스트를 가져오는 메소드입니다. */
  godoCosmicDealList?: Maybe<Array<GodoCosmicDeal>>;
  /** 문의 페이지에서 자기 자신의 주문별 상품 조회에 사용될 메소드 입니다. */
  godoMyOrders: Array<IGodoOrderGoodsNo>;
  /** 사용자 초대코드를 가져오는 쿼리 */
  godoUserInvitationCode: Scalars['String']['output'];
  /** 사용자 명을 가져오는 쿼리 */
  godoUserName: Scalars['String']['output'];
  /** 탈퇴(현재는 이벤트 발행용) */
  hackOut: Scalars['Boolean']['output'];
  hasNewCoupon: Scalars['Boolean']['output'];
  hasNewPoint: Scalars['Boolean']['output'];
  hasOpenrunPromotedProducts: Scalars['Boolean']['output'];
  hasOrdered: Scalars['Boolean']['output'];
  haveRegisteredPromotion: Scalars['Boolean']['output'];
  /** hot 모델 구좌에 표시될 상품 목록, 가장 적합한 상품부터 상품 id를 count 개수만큼 반환 */
  hotProducts: Array<Scalars['Int']['output']>;
  hubDownload: Scalars['String']['output'];
  ifPaidMember: Scalars['Boolean']['output'];
  industries: IndustryPaginatedOutput;
  inquiries: Array<Inquiry>;
  inquiry: Inquiry;
  inquiryCount: Scalars['Int']['output'];
  /** 초대 코드로 해당하는 초대코드의 언어를 가져오는 쿼리 */
  invitationLangCode?: Maybe<Scalars['String']['output']>;
  isDuplicateBrandName: Scalars['Boolean']['output'];
  isEqualPassword: Scalars['Boolean']['output'];
  isExistPromotion: Scalars['Boolean']['output'];
  isFirstDownload: Scalars['Boolean']['output'];
  isParticipatedEvent: Scalars['Boolean']['output'];
  /** 상품의 상세페이지에서 업데이트 불릿 여부를 가져오는 메소드입니다. */
  isUpdate: Scalars['Boolean']['output'];
  licenseTypes: Array<LicenseTypeOutput>;
  mainGroups: Array<GodoMainGroup>;
  /** 메인페이지 구좌의 상품 목록을 가져오는 메소드 입니다. */
  mainProductListV2: MainProductListV2Return;
  me: UserOutput;
  memberDepositDetail: MemberDepositDetailOutput;
  memberEventPeriodPaidAmount: Scalars['Float']['output'];
  memberMoonWelcomeEventPeriodPaidAmount: Scalars['Float']['output'];
  memberPeriodPaidAmount: Scalars['Float']['output'];
  memberResidualGiftDeposit: Scalars['Float']['output'];
  myAssets: Array<HubAssetOutput>;
  myBrand: BrandEntity;
  notice?: Maybe<HubAdminPost>;
  notifications: Array<Get>;
  openrunPromotions: OpenrunPromotionHubPaginatedOutput;
  orderGoodsWithExchangeRate: Scalars['String']['output'];
  /** 주문 시 입력한 필명 정보를 가져오는 쿼리 */
  orderLicense?: Maybe<OrderLicenseModel>;
  /** 주문 상품을 가져오는 쿼리 */
  orderProducts?: Maybe<Array<OrderProductReturn>>;
  orderViewV2: OrderViewDto;
  orderedProductCards: Array<Maybe<Acon3dProduct>>;
  orderedProducts: ProductPaginatedOutput;
  /** 다른 상품들을 가져오는 메소드입니다. */
  otherProductList: RelateProductListOutput;
  package: CurrentPackageOutput;
  packages?: Maybe<Array<Maybe<PackageOutput>>>;
  paginateProductCards: TempProductPaginatedOutput;
  partner: UserOutput;
  partnerAcceptStatusNumber: Scalars['Float']['output'];
  partnerExcelDownloadHistories: UserExcelDownloadHistoryPaginatedOutput;
  partnerPlan?: Maybe<UserPlanOutput>;
  partnerSettle: UserSettleOutput;
  partnerSettleAdmin?: Maybe<UserSettleOutput>;
  partnerSettles: UserSettlePaginatedOutput;
  partnerSettlesExcel: Scalars['String']['output'];
  partners: UserPaginatedOutput;
  partnersExcel: Scalars['String']['output'];
  popup: PopupOutput;
  popupCount: Scalars['Int']['output'];
  popups: Array<PopupOutput>;
  presetBrands: BrandPaginatedOutput;
  presetProducts: ProductPaginatedOutput;
  product: ProductOutput;
  productCards: Array<Maybe<Acon3dProduct>>;
  productCardsSummary: ProductCardsSummary;
  productContent: Scalars['String']['output'];
  productExtensions: ExtensionPaginatedOutput;
  productTags: TagPaginatedOutput;
  products: ProductPaginatedOutput;
  receivedGift: OrderGift;
  receivedGifts: Array<OrderGift>;
  recentOrdersV2: Array<RecentOrder>;
  refresh: UserOutput;
  review?: Maybe<Review>;
  reviews: Array<Review>;
  sasUrl: Scalars['String']['output'];
  searchApplicationsV2: ApplicationPaginatedOutput;
  searchBrandsV2: BrandPaginatedOutput;
  searchExtensionsV2: ExtensionPaginatedOutput;
  searchKeywordList: Array<SearchKeywordListReturn>;
  searchProducts: ProductPaginatedOutput;
  searchProductsV2: ProductPaginatedOutput;
  searchTagsV2: TagPaginatedOutput;
  sentGift: OrderGift;
  sentGifts: Array<OrderGift>;
  settle?: Maybe<UserSettle>;
  settlement: Scalars['String']['output'];
  settlementExcel: Scalars['String']['output'];
  settlementExcelDownloadHistory: Array<SettlementExcelDownloadHistory>;
  settlementExportHistories: SettlementExportHistoryPaginatedOutput;
  settlementOrderExcel: Scalars['String']['output'];
  sign: ResultAndUser;
  signedUser: UserOutput;
  /** 비슷한 작품들을 가져오는 메소드입니다. */
  similarProductList: RelateProductListOutput;
  syncCategory: Array<BrandCategoryReturn>;
  tags: TagPaginatedOutput;
  tempReviews: PaginatedReview;
  termAgree?: Maybe<UserTermAgreeOutput>;
  /** 비슷한 작품들을 가져오는 메소드입니다. */
  togetherProductListRecommendation: ProductListReturn;
  unfilteredProductCards: Array<Maybe<Acon3dProduct>>;
};

export type QueryGodo_Event_Order_CompleteArgs = {
  lang: Scalars['String']['input'];
  orderNo: Scalars['String']['input'];
};

export type QueryGodo_Event_Order_RefundArgs = {
  goodsNos: Array<Scalars['String']['input']>;
  orderNo: Scalars['String']['input'];
};

export type QueryGodo_Event_Order_SettleArgs = {
  goodsNo: Array<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  orderNo: Scalars['String']['input'];
};

export type QueryGodo_Update_IsrefundedArgs = {
  goodsNos: Array<Scalars['String']['input']>;
  orderNo: Scalars['String']['input'];
};

export type QueryAdminAssetArgs = {
  id: Scalars['Int']['input'];
  language: Language_Code;
};

export type QueryAdminBrandAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminBrandPickArgs = {
  brandId: Scalars['Float']['input'];
};

export type QueryAdminDocumentArgs = {
  id: Scalars['String']['input'];
  language: Language_Code;
};

export type QueryAdminDocumentsArgs = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
  game?: InputMaybe<Scalars['Boolean']['input']>;
  imgsrc?: InputMaybe<Scalars['Boolean']['input']>;
  industries?: InputMaybe<Array<DocumentIndustryInput>>;
  isOpenrunPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  lecture?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  manager?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  onView?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<DocumentsOrderCriteria>;
  requestedEnd?: InputMaybe<Scalars['DateTime']['input']>;
  requestedStart?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<Document_Status>>>;
  statusChangedEnd?: InputMaybe<Scalars['DateTime']['input']>;
  statusChangedStart?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  webtoon?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAdminDownloadArgs = {
  id: Scalars['String']['input'];
};

export type QueryAdminInquiriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  godoMemNo?: InputMaybe<Scalars['String']['input']>;
  isClipped?: InputMaybe<Scalars['Boolean']['input']>;
  isMember?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderCol?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryAdminInquiryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAdminInquiryCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  godoMemNo?: InputMaybe<Scalars['String']['input']>;
  isClipped?: InputMaybe<Scalars['Boolean']['input']>;
  isMember?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderCol?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryAdminOrderCouponInfoArgs = {
  orderNo: Scalars['String']['input'];
};

export type QueryAdminOrderEventHistoryArgs = {
  orderId: Scalars['String']['input'];
};

export type QueryAdminOrderGoodsPriceInfoArgs = {
  input: OrderGoodsPriceInfoArgs;
};

export type QueryAdminOrderPaymentInfoArgs = {
  orderNo: Scalars['String']['input'];
};

export type QueryAdminTranslateArgs = {
  lang: LanguageCodeEnum;
  text: Scalars['String']['input'];
  toLangs: Array<LanguageCodeEnum>;
};

export type QueryAdultProductCardsSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ProductListSort>;
};

export type QueryAssetArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAssetAdminDownloadArgs = {
  id: Scalars['Int']['input'];
};

export type QueryAssetFileInfoArgs = {
  id: Scalars['Int']['input'];
  language: Language_Code;
};

export type QueryAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBenefitProductsArgs = {
  lang: Scalars['String']['input'];
  productType?: InputMaybe<ProductType>;
};

export type QueryBestProductsArgs = {
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  isAdultProductInclude?: InputMaybe<Scalars['Boolean']['input']>;
  isFreeProductInclude?: InputMaybe<Scalars['Boolean']['input']>;
  type: BestProductType;
};

export type QueryBrandAssetsArgs = {
  brandId?: InputMaybe<Scalars['Int']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBrandPickSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductListSort>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBrandProductsWithCategoriesArgs = {
  brandCode: Scalars['String']['input'];
  languageCode: LanguageCodeEnum;
};

export type QueryBrandPromotionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryBrandPromotionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<PaginatedBrandPromotionArgsSearch>;
};

export type QueryBrandReviewsArgs = {
  brandId: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBrandsArgs = {
  condition: BrandsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: BrandsInput_Sort;
};

export type QueryCartsArgs = {
  lang: Scalars['String']['input'];
  noCache?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCategoriesV2Args = {
  condition: CategoriesInput_Condition;
  language: Language_Code;
  sort: CategoriesInput_Sort;
};

export type QueryCategoriesV3Args = {
  languageCode: LanguageCodeEnum;
};

export type QueryCheckExistMemberArgs = {
  accessToken: Scalars['String']['input'];
  snsType: SnsTypeEnum;
};

export type QueryCheckHasAdultProductsArgs = {
  condition: CheckHasAdultProducts_Condition;
  language: Language_Code;
};

export type QueryCheckIssuanceTempPasswordArgs = {
  aconMemberId: Scalars['Int']['input'];
  snsType: SnsTypeEnum;
};

export type QueryCheckedPasswordChangeArgs = {
  key: Scalars['String']['input'];
};

export type QueryChildCategoriesArgs = {
  childCategoriesInput: ChildCategoriesInput;
};

export type QueryClipsV2Args = {
  pagination?: InputMaybe<PaginationInput>;
  productsCondition?: InputMaybe<GetClipsProductsCondition>;
  sort: GetClipsSort;
};

export type QueryCompareArgs = {
  id: Scalars['String']['input'];
  language: Language_Code;
};

export type QueryCreatePartnerPlanArgs = {
  isExclusive: Scalars['Boolean']['input'];
  type: Scalars['Float']['input'];
};

export type QueryDescendantsOfRegularCategoryArgs = {
  descendantsOfRegularCategoryInput: DescendantsOfRegularCategoryInput;
};

export type QueryDisplayAssetWithItemsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  type: Scalars['String']['input'];
};

export type QueryDisplayBrandWithItemsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  type: Scalars['String']['input'];
};

export type QueryDocumentArgs = {
  id: Scalars['String']['input'];
};

export type QueryDocumentsArgs = {
  assetId?: InputMaybe<Scalars['Int']['input']>;
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  content?: InputMaybe<Scalars['String']['input']>;
  createdInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestedInterval?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  status?: InputMaybe<Array<InputMaybe<Partner_Document_Status>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDownloadContractArgs = {
  userId: Scalars['Int']['input'];
};

export type QueryExchangeRateArgs = {
  languageCode: LanguageCodeEnum;
};

export type QueryExistsPartnerArgs = {
  account: Scalars['String']['input'];
};

export type QueryFaqArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFaqAconArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFaqAconCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  searchType?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFaqAconTypesArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFaqAconsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchType?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFaqCreatorArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFaqCreatorCategoriesArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFaqCreatorCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  searchType?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFaqCreatorTypesArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFaqCreatorsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  searchType?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryFetchPromotionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryFetchPromotionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<FetchPromotionsSearchInput>;
};

export type QueryFileByTempPathArgs = {
  tempPath: Scalars['String']['input'];
};

export type QueryFileInfoArgs = {
  id: Scalars['Float']['input'];
};

export type QueryFundingProductNosArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryGetAdminOrderArgs = {
  orderNo: Scalars['String']['input'];
};

export type QueryGetAdminOrdersArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']['input']>;
  godoMemId?: InputMaybe<Scalars['String']['input']>;
  godoOrderEmail?: InputMaybe<Scalars['String']['input']>;
  godoOrderNo?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryGetAdminOrdersCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  godoGoodsNo?: InputMaybe<Scalars['Int']['input']>;
  godoMemId?: InputMaybe<Scalars['String']['input']>;
  godoOrderEmail?: InputMaybe<Scalars['String']['input']>;
  godoOrderNo?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryGetAdminReviewArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetAdminReviewsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  isCollectScrap?: InputMaybe<Scalars['Boolean']['input']>;
  isImageUrl?: InputMaybe<Scalars['Boolean']['input']>;
  isWithDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordType?: InputMaybe<KeywordTypeEnum>;
  originalLanguageCode?: InputMaybe<LanguageCodeEnum>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAdminReviewsCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  isCollectScrap?: InputMaybe<Scalars['Boolean']['input']>;
  isImageUrl?: InputMaybe<Scalars['Boolean']['input']>;
  isWithDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordType?: InputMaybe<KeywordTypeEnum>;
  originalLanguageCode?: InputMaybe<LanguageCodeEnum>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetAttendanceEventDatesArgs = {
  eventId: Scalars['Float']['input'];
};

export type QueryGetBadgeArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetBadgesArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetBandBannerArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetBandBannerForPageArgs = {
  lang: Scalars['String']['input'];
};

export type QueryGetBandBannersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetBannerArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGetBannersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetBannersByGodoNoArgs = {
  godoGoodsNo: Scalars['Float']['input'];
  lang: Scalars['String']['input'];
};

export type QueryGetBenefitBurdenListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetBrandArgs = {
  brandCode: Scalars['String']['input'];
  lang: LanguageCodeEnum;
};

export type QueryGetBrandForAdminArgs = {
  brandId: Scalars['Int']['input'];
};

export type QueryGetBrandOrderProductItemsArgs = {
  condition?: InputMaybe<GetBrandOrderProductItemsInput>;
  pagination: PaginationInput;
};

export type QueryGetBrandOrderProductItemsExcelArgs = {
  condition?: InputMaybe<GetBrandOrderProductItemsInput>;
  lang: LanguageCodeEnum;
};

export type QueryGetBrandSettlementOrderExcelArgs = {
  lang: LanguageCodeEnum;
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type QueryGetBrandSettlementsArgs = {
  pagination: PaginationInput;
};

export type QueryGetBrandsForAdminArgs = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  profileModifiedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  profileModifiedStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type QueryGetCashListArgs = {
  endDate: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type QueryGetCategoriesByLangArgs = {
  lang: Scalars['String']['input'];
};

export type QueryGetCategoryArgs = {
  godoCateCd: Scalars['String']['input'];
};

export type QueryGetCountsArgs = {
  lang: Scalars['String']['input'];
  viewOnlyActiveCoupons: Scalars['Boolean']['input'];
};

export type QueryGetCouponBenefitArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetCouponBenefitsArgs = {
  langId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetCouponBenefitsTotalCountArgs = {
  langId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetCouponBurdenListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetCouponItemsArgs = {
  couponName?: InputMaybe<Scalars['String']['input']>;
  couponNos?: InputMaybe<Array<Scalars['String']['input']>>;
  couponRegDt?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetCouponListArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetDownloadLogsArgs = {
  goodsNo?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderNo?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetEventBannerItemArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetEventBannerItemsArgs = {
  isInprogressEvent: Scalars['Boolean']['input'];
  lang: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetEventBannerTotalCountArgs = {
  isInprogressEvent: Scalars['Boolean']['input'];
  lang: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetFaqArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetFaqsArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetFaqsTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetGodoBannerInfoArgs = {
  bannerCode: Scalars['Float']['input'];
};

export type QueryGetGodoBrandNameArgs = {
  brandCode?: InputMaybe<Scalars['String']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetGodoPopupArgs = {
  url: Scalars['String']['input'];
};

export type QueryGetGoodsBuyersArgs = {
  goodsNo: Scalars['Float']['input'];
};

export type QueryGetItemArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetItemsArgs = {
  lang: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  viewOnlyActiveCoupons: Scalars['Boolean']['input'];
};

export type QueryGetMyOrdersArgs = {
  languageCode: LanguageCodeEnum;
};

export type QueryGetNoticeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetNoticesArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetNoticesTotalCountArgs = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetOrderBenefitPointArgs = {
  godoOrderNo: Scalars['String']['input'];
};

export type QueryGetOrderCouponItemsArgs = {
  couponApplyOrderNo?: InputMaybe<Scalars['String']['input']>;
  godoCartSnos?: InputMaybe<Array<Scalars['String']['input']>>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetOrderInfoArgs = {
  godoCartNos?: InputMaybe<Array<Scalars['String']['input']>>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetOrderNoWithoutReviewArgs = {
  godoGoodsNo: Scalars['String']['input'];
};

export type QueryGetPaymentPointArgs = {
  godoGoodsNo?: InputMaybe<Array<Scalars['Int']['input']>>;
  godoPrice?: InputMaybe<Array<Scalars['Int']['input']>>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPhotoReviewArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  godoCateCd?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPhotoReviewTotalCountArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  godoCateCd?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPhotoReviewsArgs = {
  cursor?: InputMaybe<Scalars['Int']['input']>;
  godoCateCd?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPointBenefitArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPointBenefitsArgs = {
  langId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPointBenefitsTotalCountArgs = {
  langId?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGetPointLimitPolicyArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPointLimitPolicyListArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type QueryGetPointListArgs = {
  endDate: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  startDate: Scalars['String']['input'];
};

export type QueryGetPopupsHereArgs = {
  origin: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type QueryGetProductLangArgs = {
  godoGoodsNo?: InputMaybe<Array<Scalars['Int']['input']>>;
  lang: Scalars['String']['input'];
};

export type QueryGetPromotedProductsArgs = {
  code: Scalars['String']['input'];
  lang: LanguageCodeEnum;
  take: Scalars['Int']['input'];
};

export type QueryGetPromotionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPromotionConceptArgs = {
  id: Scalars['Int']['input'];
};

export type QueryGetPromotionCountArgs = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetPromotionProductsArgs = {
  code: Scalars['String']['input'];
  lang: LanguageCodeEnum;
  take: Scalars['Int']['input'];
};

export type QueryGetPromotionsArgs = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSettleAllListV2Args = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  paymentEndDate?: InputMaybe<Scalars['String']['input']>;
  paymentStartDate?: InputMaybe<Scalars['String']['input']>;
  settleEndDate?: InputMaybe<Scalars['String']['input']>;
  settleStartDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSettleAllPriceArgs = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  goodsNm?: InputMaybe<Scalars['String']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  paymentEndDate?: InputMaybe<Scalars['String']['input']>;
  paymentStartDate?: InputMaybe<Scalars['String']['input']>;
  penNm?: InputMaybe<Scalars['String']['input']>;
  settleEndDate?: InputMaybe<Scalars['String']['input']>;
  settleStartDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSettleCountArgs = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  goodsNm?: InputMaybe<Scalars['String']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  paymentEndDate?: InputMaybe<Scalars['String']['input']>;
  paymentStartDate?: InputMaybe<Scalars['String']['input']>;
  penNm?: InputMaybe<Scalars['String']['input']>;
  settleEndDate?: InputMaybe<Scalars['String']['input']>;
  settleStartDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSettleListV2Args = {
  brandId?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  goodsNm?: InputMaybe<Scalars['String']['input']>;
  isFree?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  paymentEndDate?: InputMaybe<Scalars['String']['input']>;
  paymentStartDate?: InputMaybe<Scalars['String']['input']>;
  penNm?: InputMaybe<Scalars['String']['input']>;
  settleEndDate?: InputMaybe<Scalars['String']['input']>;
  settleStartDate?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetSettleV2Args = {
  goodsNo: Scalars['Int']['input'];
  orderNo: Scalars['String']['input'];
};

export type QueryGetTermArgs = {
  type: Scalars['String']['input'];
};

export type QueryGetTransGodoCategoryChildItemsArgs = {
  cateCds: Array<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetTransGodoCategoryItemsArgs = {
  cateCds: Array<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetValidationGoodsNoArgs = {
  godoGoodsNo?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGiftArgs = {
  id: Scalars['Float']['input'];
};

export type QueryGodoCometDealListArgs = {
  godoGoodsNo: Scalars['Int']['input'];
};

export type QueryGodoCosmicDealListArgs = {
  godoGoodsNo: Scalars['Int']['input'];
};

export type QueryGodoMyOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryGodoUserInvitationCodeArgs = {
  langCode: Scalars['String']['input'];
};

export type QueryHasOpenrunPromotedProductsArgs = {
  assetIds: Array<Scalars['Int']['input']>;
};

export type QueryHasOrderedArgs = {
  assetId: Scalars['Float']['input'];
};

export type QueryHaveRegisteredPromotionArgs = {
  id: Scalars['Int']['input'];
};

export type QueryHotProductsArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryHubDownloadArgs = {
  id: Scalars['String']['input'];
};

export type QueryIndustriesArgs = {
  condition: IndustriesInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: IndustriesInput_Sort;
};

export type QueryInquiriesArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  godoMemNo?: InputMaybe<Scalars['String']['input']>;
  isClipped?: InputMaybe<Scalars['Boolean']['input']>;
  isMember?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderCol?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryInquiryArgs = {
  id: Scalars['Int']['input'];
};

export type QueryInquiryCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  godoMemNo?: InputMaybe<Scalars['String']['input']>;
  isClipped?: InputMaybe<Scalars['Boolean']['input']>;
  isMember?: InputMaybe<Scalars['Boolean']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  orderCol?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Array<InputMaybe<InquiryStatusEnum>>>;
  subType?: InputMaybe<InquirySubTypeEnum>;
  type?: InputMaybe<InquiryTypeEnum>;
};

export type QueryInvitationLangCodeArgs = {
  code: Scalars['String']['input'];
};

export type QueryIsDuplicateBrandNameArgs = {
  lang: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type QueryIsEqualPasswordArgs = {
  password: Scalars['String']['input'];
};

export type QueryIsExistPromotionArgs = {
  godoGoodsNo: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};

export type QueryIsFirstDownloadArgs = {
  goodsNo: Scalars['Int']['input'];
  orderNo: Scalars['String']['input'];
};

export type QueryIsParticipatedEventArgs = {
  eventId: Scalars['Float']['input'];
};

export type QueryIsUpdateArgs = {
  goodsNo: Scalars['String']['input'];
  lang: Scalars['String']['input'];
};

export type QueryMainProductListV2Args = {
  lang: Scalars['String']['input'];
  sno: Scalars['Int']['input'];
};

export type QueryMemberEventPeriodPaidAmountArgs = {
  eventId: Scalars['Float']['input'];
};

export type QueryMemberPeriodPaidAmountArgs = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type QueryMyAssetsArgs = {
  daysAfterReleased?: InputMaybe<Scalars['Int']['input']>;
  includeFree?: InputMaybe<Scalars['Boolean']['input']>;
  includeIrregularPrice?: InputMaybe<Scalars['Boolean']['input']>;
  isIncludeBook?: InputMaybe<Scalars['Boolean']['input']>;
  isOnPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language_Code;
  onSaleOnly?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryNoticeArgs = {
  id: Scalars['Int']['input'];
};

export type QueryOpenrunPromotionsArgs = {
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrderGoodsWithExchangeRateArgs = {
  settlementEndDate: Scalars['String']['input'];
  settlementStartDate: Scalars['String']['input'];
};

export type QueryOrderLicenseArgs = {
  orderNo: Scalars['Float']['input'];
};

export type QueryOrderProductsArgs = {
  lang: Scalars['String']['input'];
  orderNo: Scalars['Float']['input'];
};

export type QueryOrderViewV2Args = {
  lang: Scalars['String']['input'];
  orderNo: Scalars['Float']['input'];
};

export type QueryOrderedProductCardsArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryOrderedProductsArgs = {
  condition: OrderedProductsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: OrderedProductsInput_Sort;
};

export type QueryOtherProductListArgs = {
  godoGoodsNo: Scalars['Int']['input'];
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isCount: Scalars['Boolean']['input'];
  isFresh?: InputMaybe<Scalars['Boolean']['input']>;
  lang: Scalars['String']['input'];
};

export type QueryPackageArgs = {
  id: Scalars['Float']['input'];
};

export type QueryPaginateProductCardsArgs = {
  brandCode?: InputMaybe<Scalars['String']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  filter: ProductListFilterInput;
  languageCode: LanguageCodeEnum;
  limit?: InputMaybe<Scalars['Int']['input']>;
  onlyAdult?: InputMaybe<Scalars['Boolean']['input']>;
  onlyDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  onlyFree?: InputMaybe<Scalars['Boolean']['input']>;
  onlyNew?: InputMaybe<Scalars['Boolean']['input']>;
  option?: InputMaybe<ProductListOptionInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductListSort>;
  type?: InputMaybe<Array<ProductTypes>>;
};

export type QueryPartnerArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPartnerAcceptStatusNumberArgs = {
  modifyStatus?: InputMaybe<AcceptStatusEnum>;
  status: AcceptStatusEnum;
};

export type QueryPartnerExcelDownloadHistoriesArgs = {
  documentClass: PartnerExcelDocumentClassEnum;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryPartnerSettleAdminArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPartnerSettlesArgs = {
  account?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  isOversea?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<PartnerSettlesSortCriteria>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  settlementStatuses?: InputMaybe<Array<PartnerSettleStatusEnum>>;
  userSubType?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<PartnerTypeEnum>;
};

export type QueryPartnerSettlesExcelArgs = {
  account?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  isOversea?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<PartnerSettlesSortCriteria>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  settlementStatuses?: InputMaybe<Array<PartnerSettleStatusEnum>>;
  userSubType?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<PartnerTypeEnum>;
};

export type QueryPartnersArgs = {
  acceptStatus?: InputMaybe<Array<AcceptStatusEnum>>;
  acceptedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  account?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  isOversea?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  plan?: InputMaybe<PartnerPlanInput>;
};

export type QueryPartnersExcelArgs = {
  acceptStatus?: InputMaybe<Array<AcceptStatusEnum>>;
  acceptedEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  acceptedStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  account?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
  brandName?: InputMaybe<Scalars['String']['input']>;
  isOversea?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  plan?: InputMaybe<PartnerPlanInput>;
};

export type QueryPopupArgs = {
  id: Scalars['Int']['input'];
};

export type QueryPopupCountArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  isStopped?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<PopupLocationV2>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  orderCol?: InputMaybe<PopupOrderCol>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<PopupStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPopupsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  isStopped?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location?: InputMaybe<PopupLocationV2>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<OrderBy>;
  orderCol?: InputMaybe<PopupOrderCol>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<PopupStatus>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPresetBrandsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  preset: Preset_Brands_Input_Preset;
};

export type QueryPresetProductsArgs = {
  language: Language_Code;
  pagination: PaginationInput;
  preset: Preset_Products_Preset;
};

export type QueryProductArgs = {
  id: Scalars['Float']['input'];
  language: Language_Code;
};

export type QueryProductCardsArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type QueryProductCardsSummaryArgs = {
  brandCode?: InputMaybe<Scalars['String']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ProductListSort>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProductContentArgs = {
  assetId: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
};

export type QueryProductExtensionsArgs = {
  condition: ProductExtensionsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
};

export type QueryProductTagsArgs = {
  condition: ProductTagsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
};

export type QueryProductsArgs = {
  condition: ProductsConditionInput;
  language: Language_Code;
  pagination: PaginationInput;
  sort: Sort;
};

export type QueryReceivedGiftArgs = {
  orderGiftId: Scalars['String']['input'];
};

export type QueryRefreshArgs = {
  account: Scalars['String']['input'];
};

export type QueryReviewArgs = {
  reviewId: Scalars['Int']['input'];
};

export type QueryReviewsArgs = {
  goodsNo: Scalars['String']['input'];
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
  lang: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  rating?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortType?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySasUrlArgs = {
  inputs: SasUrlInput;
};

export type QuerySearchApplicationsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']['input']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySearchBrandsV2Args = {
  https?: InputMaybe<Scalars['Boolean']['input']>;
  keyword: Scalars['String']['input'];
  language: Language_Code;
};

export type QuerySearchExtensionsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']['input']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySearchKeywordListArgs = {
  lang: Scalars['String']['input'];
};

export type QuerySearchProductsArgs = {
  condition: SearchProductsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: SearchProductsInput_Sort;
};

export type QuerySearchProductsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']['input']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySearchTagsV2Args = {
  condition: SearchProductsV2Input_Condition;
  https?: InputMaybe<Scalars['Boolean']['input']>;
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  sort: SearchProductsV2Input_Sort;
};

export type QuerySentGiftArgs = {
  orderGiftId: Scalars['String']['input'];
};

export type QuerySettlementArgs = {
  settlementEndDate: Scalars['String']['input'];
  settlementStartDate: Scalars['String']['input'];
};

export type QuerySettlementExcelArgs = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type QuerySettlementExportHistoriesArgs = {
  pagination: PaginationInput;
};

export type QuerySettlementOrderExcelArgs = {
  month: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type QuerySignArgs = {
  account: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type QuerySimilarProductListArgs = {
  godoGoodsNo: Scalars['Int']['input'];
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isCount: Scalars['Boolean']['input'];
  isFresh?: InputMaybe<Scalars['Boolean']['input']>;
  lang: Scalars['String']['input'];
};

export type QuerySyncCategoryArgs = {
  brandCd?: InputMaybe<Scalars['String']['input']>;
  lang: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTagsArgs = {
  condition: TagsInput_Condition;
  language: Language_Code;
  pagination: PaginationInput;
  sort: TagsInput_Sort;
};

export type QueryTempReviewsArgs = {
  condition: ReviewsInput_Condition;
  pagination: ReviewsInput_Pagination;
  sort: ReviewsInput_Sort;
};

export type QueryTogetherProductListRecommendationArgs = {
  godoGoodsNo: Scalars['Int']['input'];
  isCount: Scalars['Boolean']['input'];
  isFresh?: InputMaybe<Scalars['Boolean']['input']>;
  lang: Scalars['String']['input'];
};

export type QueryUnfilteredProductCardsArgs = {
  isAdult?: InputMaybe<Scalars['Boolean']['input']>;
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export enum Reviews_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Reviews_Input_Sort_Key {
  Id = 'ID',
  UpdatedAt = 'UPDATED_AT',
}

export type RecentOrder = {
  __typename?: 'RecentOrder';
  orderNo: Scalars['Float']['output'];
  orderedAt: Scalars['DateTime']['output'];
  orderedProducts: Array<RecentOrderedProduct>;
  settlePrice: Scalars['Float']['output'];
};

export type RecentOrderedProduct = {
  __typename?: 'RecentOrderedProduct';
  acon3dProduct: Acon3dProduct;
  godoGoodsNo: Scalars['Float']['output'];
  hasWrittenReview: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  product?: Maybe<Acon3dProduct>;
  reviewId?: Maybe<Scalars['Float']['output']>;
  reviewSno?: Maybe<Scalars['Float']['output']>;
  sno: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  statusString: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type RecentOrderedProductProductArgs = {
  langCode?: InputMaybe<Scalars['String']['input']>;
};

/** 결제-취소/환불(H1-H3) */
export type RefundAccountInput = {
  /** 예금주 */
  accountHolder: Scalars['String']['input'];
  /** 계좌번호 */
  accountNumber: Scalars['String']['input'];
  /** 은행명 */
  bankName: Scalars['String']['input'];
};

export type RegisterBrandPromotionInput = {
  items: Array<RegisterBrandPromotionInputItem>;
  promotionId: Scalars['Int']['input'];
};

export type RegisterBrandPromotionInputItem = {
  assetId: Scalars['Int']['input'];
  discountType: EPromotionDiscountType;
  discountValue: Scalars['Float']['input'];
};

export type RegisterOpenrunPromotionInput = {
  /** 브랜드 ID */
  brandId: Scalars['Int']['input'];
  /** document id(uuid) */
  id: Scalars['String']['input'];
};

export type RegisterPartnerMemoInput = {
  /** 메모 내용 */
  memo: Scalars['String']['input'];
  /** 분류(사용자, 정산) */
  memoType?: InputMaybe<PartnerMemoTypeEnum>;
};

export type RegisterPromotionInput = {
  /** 프로모션 컨셉 ID */
  conceptId: Scalars['Int']['input'];
  description: GqlRegionalInput;
  /** 할인 타입 */
  discountType: EPromotionDiscountType;
  /** 할인 금액(퍼센트인 경우는 퍼센트), decimal(10, 2) */
  discountValue: Scalars['Float']['input'];
  /** 프로모션 ID */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** 프로모션 상품이 프로모션의 대표 할인정보를 그대로 상속받아 가져가는지 여부, 생략하면 디폴트는 true */
  inheritance?: InputMaybe<Scalars['Boolean']['input']>;
  issuer: GqlRegionalInput;
  /** 에셋별 할인정보, 에셋 전체가 프로모션의 할인정보를 상속받을 경우 id만 채워도 된다. inheritance === true로 설정해둘 것 */
  items: Array<PromotionItemInput>;
  /** 할인 시작일자 */
  since: Scalars['DateTime']['input'];
  /** 프로모션 이름 */
  title: Scalars['String']['input'];
  /** 할인 종료일자  */
  until: Scalars['DateTime']['input'];
};

export type RegisterReviewInput = {
  contents: Scalars['String']['input'];
  godoGoodsNo: Scalars['Int']['input'];
  godoOrderNo: Scalars['String']['input'];
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  isSecret: Scalars['Boolean']['input'];
  languageCode: LanguageCodeEnum;
  rating: Scalars['Int']['input'];
};

export type RegisterSnsMemberOutput = {
  __typename?: 'RegisterSnsMemberOutput';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum RegularCategoryType {
  Game = 'GAME',
  Genre = 'GENRE',
  Lecture = 'LECTURE',
  Theme = 'THEME',
}

export type RejectOpenrunPromotionInput = {
  /** document id(uuid) */
  id: Scalars['String']['input'];
  /** 거절 사유, 승인상태가 거절시 필수 */
  rejectReason?: InputMaybe<Scalars['String']['input']>;
};

export type RelateProductListItem = {
  __typename?: 'RelateProductListItem';
  /** 브랜드 명 */
  brand: Scalars['String']['output'];
  /** 브랜드 코드 */
  brandCd: Scalars['String']['output'];
  /** 고도몰 상품번호 */
  godoGoodsNo: Scalars['Float']['output'];
  /** 이미지 URL */
  imageUrl: Scalars['String']['output'];
  /** 가격 */
  price: Scalars['String']['output'];
  /** 가격 */
  realPrice: Scalars['String']['output'];
  /** 제목 */
  title: Scalars['String']['output'];
};

export type RelateProductListOutput = {
  __typename?: 'RelateProductListOutput';
  /** 브랜드 코드 */
  brandCd?: Maybe<Scalars['String']['output']>;
  /** 카테고리 코드 */
  cateCd?: Maybe<Scalars['String']['output']>;
  /** 개수 */
  count?: Maybe<Scalars['Float']['output']>;
  /** 비슷한 작품 항목들 */
  items?: Maybe<Array<RelateProductListItem>>;
};

export type RemoveClipInput = {
  godoGoodsNo: Scalars['Int']['input'];
};

export type RemoveClipsInput = {
  godoGoodsNos: Array<Scalars['Int']['input']>;
};

export type RequestPartnerRevisionFileInput = {
  sign?: InputMaybe<StoredFileInput>;
};

export type RequestPartnerRevisionInput = {
  /** 브랜드 데이터 */
  brand?: InputMaybe<UpdatePartnerBrandInput>;
  /** 회사명 */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** 연락처 */
  contact?: InputMaybe<Scalars['String']['input']>;
  /** 국가 코드 */
  country?: InputMaybe<Scalars['String']['input']>;
  /** 계약/정산 관련 파일 */
  file?: InputMaybe<RequestPartnerRevisionFileInput>;
  /** 사용자명 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** 개인식별번호(like. 주민번호) */
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>;
  /** 참고 url */
  reference?: InputMaybe<Array<UpdatePartnerReferenceInput>>;
};

export type ResultAndUser = {
  __typename?: 'ResultAndUser';
  item?: Maybe<UserOutput>;
  result: Scalars['Boolean']['output'];
};

export type Review = {
  __typename?: 'Review';
  adminUpdateHistories?: Maybe<Array<ReviewAdminUpdateHistory>>;
  author: Scalars['String']['output'];
  commentsCount: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  favoriteCount?: Maybe<Scalars['Int']['output']>;
  godoGoodsNo: Scalars['Int']['output'];
  godoMemNo: Scalars['Int']['output'];
  godoOrderNo?: Maybe<Scalars['String']['output']>;
  godoUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /** 리뷰 대표이미지 */
  imageUrl?: Maybe<Scalars['String']['output']>;
  isBestReview: Scalars['Boolean']['output'];
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  isImage: Scalars['Boolean']['output'];
  isNew: Scalars['Boolean']['output'];
  isScrap?: Maybe<Scalars['Boolean']['output']>;
  isSecret: Scalars['Boolean']['output'];
  nonMaskedGodoUserId: Scalars['String']['output'];
  product?: Maybe<Acon3dProduct>;
  /** 리뷰 별점 */
  rating: Scalars['Int']['output'];
  reviewContentsItems: Array<ReviewContents>;
  reviewImages?: Maybe<Array<ReviewImage>>;
  reviewReplies?: Maybe<Array<ReviewReply>>;
  userUpdateHistories?: Maybe<Array<ReviewUpdateHistory>>;
};

export type ReviewProductArgs = {
  languageCode: LanguageCodeEnum;
};

export type ReviewReviewContentsItemsArgs = {
  isOriginal?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
};

export type ReviewAdminUpdateHistory = {
  __typename?: 'ReviewAdminUpdateHistory';
  adminId: Scalars['String']['output'];
  content: Scalars['String']['output'];
  date?: Maybe<Scalars['String']['output']>;
};

export type ReviewContents = {
  __typename?: 'ReviewContents';
  contents: Scalars['String']['output'];
  /** 원본 리뷰 여부 */
  isOriginal: Scalars['Boolean']['output'];
  languageCode: LanguageCodeEnum;
  reviewId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type ReviewEdge = {
  __typename?: 'ReviewEdge';
  cursor?: Maybe<Scalars['Int']['output']>;
  node?: Maybe<Review>;
};

export type ReviewImage = {
  __typename?: 'ReviewImage';
  imageNumber: Scalars['Int']['output'];
  imageUrl: Scalars['String']['output'];
};

export type ReviewReply = {
  __typename?: 'ReviewReply';
  adminUser: AdminUser;
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  reviewId: Scalars['Int']['output'];
  reviewReplyContents?: Maybe<Array<ReviewReplyContent>>;
};

export type ReviewReplyContent = {
  __typename?: 'ReviewReplyContent';
  content: Scalars['String']['output'];
  /** 원본 리뷰 여부 */
  isOriginal: Scalars['Boolean']['output'];
  languageCode: LanguageCodeEnum;
  reviewReplyId: Scalars['Int']['output'];
};

export type ReviewUpdateHistory = {
  __typename?: 'ReviewUpdateHistory';
  date?: Maybe<Scalars['String']['output']>;
};

export type ReviewsInput_Condition = {
  /** 상품 카테고리 */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 베스트 리뷰 여부 */
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  /** 성인상품 여부 */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 포토 리뷰 여부 */
  isPhotoReview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReviewsInput_Pagination = {
  /** 2. cursor */
  cursor?: InputMaybe<Scalars['Int']['input']>;
  /** 1. offset */
  offset?: InputMaybe<Scalars['Int']['input']>;
  /** take */
  take: Scalars['Int']['input'];
};

export type ReviewsInput_Sort = {
  criterion: Reviews_Input_Sort_Criterion;
  key: Reviews_Input_Sort_Key;
};

export enum Search_Products_Language {
  En = 'EN',
  Ja = 'JA',
  Ko = 'KO',
  Zh = 'ZH',
}

export enum Search_Products_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Search_Products_Sort_Key {
  Accuracy = 'ACCURACY',
  Id = 'ID',
  OrderCount = 'ORDER_COUNT',
  ReleasedAt = 'RELEASED_AT',
  SalePrice = 'SALE_PRICE',
}

export enum Search_Products_V2_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Search_Products_V2_Sort_Key {
  Accuracy = 'ACCURACY',
  Id = 'ID',
  OrderCount = 'ORDER_COUNT',
  ReleasedAt = 'RELEASED_AT',
  SalePrice = 'SALE_PRICE',
}

export type SasUrlInput = {
  /** upload file id */
  id: Scalars['Int']['input'];
};

export type SavePromotionInput = {
  /** 프로모션 컨셉 ID */
  conceptId: Scalars['Int']['input'];
  description?: InputMaybe<GqlRegionalInputNullable>;
  /** 할인 타입 */
  discountType?: InputMaybe<EPromotionDiscountType>;
  /** 할인 금액(퍼센트인 경우는 퍼센트), decimal(10, 2) */
  discountValue?: InputMaybe<Scalars['Float']['input']>;
  /** 프로모션 ID : 없으면 POST로 인식해 새로 생성하고, 있으면 PUT으로 인식해 기존 Promotion을 업데이트함 */
  id?: InputMaybe<Scalars['Int']['input']>;
  /** 프로모션 상품이 프로모션의 대표 할인정보를 그대로 상속받아 가져가는지 여부, 생략하면 디폴트는 true */
  inheritance?: InputMaybe<Scalars['Boolean']['input']>;
  issuer?: InputMaybe<GqlRegionalInputNullable>;
  /** 에셋별 할인정보, 에셋 전체가 프로모션의 할인정보를 상속받을 경우 id만 채워도 된다. inheritance === true로 설정해둘 것 */
  items?: InputMaybe<Array<PromotionItemInput>>;
  /** 할인 시작일자 */
  since?: InputMaybe<Scalars['DateTime']['input']>;
  /** 프로모션 이름 */
  title?: InputMaybe<Scalars['String']['input']>;
  /** 할인 종료일자 */
  until?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SearchKeyword = {
  __typename?: 'SearchKeyword';
  creator?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Float']['output'];
  keyword: Scalars['String']['output'];
  language?: Maybe<Language>;
  type: Scalars['String']['output'];
  updater?: Maybe<Scalars['Float']['output']>;
};

export type SearchKeywordListReturn = {
  __typename?: 'SearchKeywordListReturn';
  /** 키워드 */
  keyword: Scalars['String']['output'];
  /** 언어 코드 */
  lang: Scalars['String']['output'];
};

export type SearchProductsInput_Condition = {
  /** 성인상품 포함 여부; false와 null은 동일; */
  allowAdultContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 응용프로그램 ID 배열 */
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 브랜드 코드 배여 */
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 카테고리 코드 배열 */
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  /** 확장자 ID 배열 */
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 산업 구분 */
  industries?: InputMaybe<Array<Products_Input_Condition_Industry>>;
  /** 요청 검색어(필수) */
  input: Scalars['String']['input'];
  /** 에이콘 독점 상품 여부; isExclusive가 true인 경우 독점 상품만 가져옴; null인 경우 모든 상품을 가져옴; */
  isExclusive?: InputMaybe<Scalars['Boolean']['input']>;
  /** 펀딩상품 여부(종료된 펀딩상품 포함); null인 경우 모든 상품을 가져옴; */
  isFunding?: InputMaybe<Scalars['Boolean']['input']>;
  /** 성인상품 여부; null인 경우 모든 상품을 반환; */
  isMatureContent?: InputMaybe<Scalars['Boolean']['input']>;
  /** 신작 여부; null인 경우 모든 상품을 가져옴; */
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  /** 할인상태; null인 경우 모든 상품을 가져옴; */
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  /** 프로모션 컨셉 코드; isPromoted가 true인 경우에만 유효; null인 경우 모든 할인 상품을 가져옴;  */
  promotionConceptCode?: InputMaybe<Promotion_Concept_Codes>;
  /** 상품 판매가 범위 : 이상 */
  salePriceAbove?: InputMaybe<Scalars['Float']['input']>;
  /** 상품 판매가 범위 : 미만 */
  salePriceUnder?: InputMaybe<Scalars['Float']['input']>;
  /** 태그 ID 배열 */
  tags?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SearchProductsInput_Sort = {
  criterion: Search_Products_Sort_Criterion;
  key: Search_Products_Sort_Key;
};

export type SearchProductsV2Input_Condition = {
  /** 응용프로그램 ID 배열 */
  applications: Array<Scalars['Int']['input']>;
  /** 카테고리 ID 배열 */
  categories: Array<Scalars['Int']['input']>;
  /** 확장자 ID 배열 */
  extensions: Array<Scalars['Int']['input']>;
  /** 산업 ID 배열 */
  industries: Array<Scalars['Int']['input']>;
  /** 요청 검색어(필수) */
  keyword: Scalars['String']['input'];
  /** 최대 가격 */
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  /** 최소 가격 */
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  /** 태그 ID 배열 */
  tags: Array<Scalars['Int']['input']>;
  /** 성인용 포함 여부 */
  withAdultOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchProductsV2Input_Language = {
  /** 언어 코드 */
  code: Search_Products_Language;
};

export type SearchProductsV2Input_Pagination = {
  /** 페이지 번호 */
  page: Scalars['Int']['input'];
  /** 페이지 당 아이템 수 */
  size: Scalars['Int']['input'];
};

export type SearchProductsV2Input_Sort = {
  criterion: Search_Products_V2_Sort_Criterion;
  key: Search_Products_V2_Sort_Key;
};

export type SetCategoryFileInput = {
  /** 업로드 파일 key */
  key?: InputMaybe<Scalars['String']['input']>;
};

export type SetCategoryI18nInput = {
  /** 카테고리 URL */
  anchor?: InputMaybe<Scalars['String']['input']>;
  /** 카테고리 ID */
  categoryId: Scalars['Float']['input'];
  /** 색상 정보 */
  color?: InputMaybe<Scalars['String']['input']>;
  /** 작성자 */
  creator?: InputMaybe<Scalars['Float']['input']>;
  /** 파일 정보 */
  file?: InputMaybe<SetCategoryFileInput>;
  /** 새 창으로 열기 여부 */
  isBlankAnchor: Scalars['Float']['input'];
  /** 검색 결과 창 표시 여부 */
  isShowResultCount: Scalars['Float']['input'];
  /** 언어 코드 */
  lang: Scalars['String']['input'];
  /** map */
  map?: InputMaybe<Scalars['String']['input']>;
  /** 카테고리 이름 */
  name: Scalars['String']['input'];
  /** 수정자 */
  updater?: InputMaybe<Scalars['Float']['input']>;
};

export type Settle = {
  __typename?: 'Settle';
  /** 추가필드 */
  addField: Scalars['String']['output'];
  /** 혜택 부담도 */
  benefitBrandBurden: Scalars['String']['output'];
  /** 부담도 */
  brandBurden?: Maybe<Scalars['String']['output']>;
  /** 브랜드코드 */
  brandCd: Scalars['String']['output'];
  /** 쿠폰 상품 dc 가격 */
  couponGoodsDcPrice: Scalars['String']['output'];
  /** 상품할인가격 */
  goodsDcPrice: Scalars['String']['output'];
  /** 상품명 */
  goodsNm: Scalars['String']['output'];
  goodsNo?: Maybe<Scalars['Int']['output']>;
  /** 상품가격 */
  goodsPrice: Scalars['String']['output'];
  /** 라이센스 유형 */
  licenseType?: Maybe<Scalars['String']['output']>;
  /** 주문메모 */
  orderMemo: Scalars['String']['output'];
  /** 주문자명 */
  orderName: Scalars['String']['output'];
  /** 주문번호 */
  orderNo: Scalars['String']['output'];
  /** 지불일자 */
  paymentDt: Scalars['DateTime']['output'];
  /** 등록일 */
  regDt: Scalars['DateTime']['output'];
  /** 정산일자 */
  settleDt: Scalars['DateTime']['output'];
  /** sno */
  sno: Scalars['String']['output'];
};

export type SettleCount = {
  __typename?: 'SettleCount';
  /** 검색 합계 */
  searchCount: Scalars['Int']['output'];
  /** 총 합계 */
  totalCount: Scalars['Int']['output'];
};

export type SettleDetail = {
  __typename?: 'SettleDetail';
  /** 고도몰 추가필드 */
  addField?: Maybe<Scalars['String']['output']>;
  /** 혜택 브랜드 부담도 */
  benefitBrandBurden?: Maybe<Scalars['Int']['output']>;
  /** 브랜드 부담도 */
  brandBurden?: Maybe<Scalars['Int']['output']>;
  brandCd: Scalars['String']['output'];
  /** 구매한 회원의 국가정보 */
  country?: Maybe<Scalars['String']['output']>;
  couponGoodsDcPrice: Scalars['Int']['output'];
  goodsDcPrice: Scalars['Int']['output'];
  goodsNm: Scalars['String']['output'];
  goodsPrice: Scalars['Int']['output'];
  /** 주문한 쇼핑몰 국가코드 */
  languageCode: Scalars['String']['output'];
  /** 개인/공동 사용권명 */
  licenseName: Scalars['String']['output'];
  /** 사용권 유형 personal: 개인사용권, company: 공동사용권 */
  licenseType: Scalars['String']['output'];
  orderName: Scalars['String']['output'];
  orderNo: Scalars['String']['output'];
  paymentDt: Scalars['DateTime']['output'];
  /** 공동사용권 상품사용예정작품명 */
  projectName?: Maybe<Scalars['String']['output']>;
  regDt: Scalars['DateTime']['output'];
  settleDt: Scalars['DateTime']['output'];
};

export type SettlementExcelDownloadHistory = {
  __typename?: 'SettlementExcelDownloadHistory';
  /** 문서 분류 */
  documentClass: Scalars['String']['output'];
  /** 엑셀파일 다운로드 일자 */
  downloadDate: Scalars['DateTime']['output'];
  /** 정산구간: 종료일자 */
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  /** 정산구간: 시작일자 */
  startDate: Scalars['DateTime']['output'];
  /** 다운로드 유저 이름(ex. 미우) */
  userName: Scalars['String']['output'];
};

export type SettlementExportHistoryOutput = {
  __typename?: 'SettlementExportHistoryOutput';
  /** 내보낸 일시 */
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  /** 대상 월 */
  month: Scalars['Int']['output'];
  /** 요청자 이름 */
  name: Scalars['String']['output'];
  /** 문서 종류 */
  type: Scalars['String']['output'];
  /** 대상 년도 */
  year: Scalars['Int']['output'];
};

export type SettlementExportHistoryPaginatedOutput = {
  __typename?: 'SettlementExportHistoryPaginatedOutput';
  data: Array<SettlementExportHistoryOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type SettlementOutput = {
  __typename?: 'SettlementOutput';
  /** 에이콘 수수료율 */
  aconCommission: Scalars['Float']['output'];
  /** 에이콘 수수료 금액 */
  aconCommissionAmount: Scalars['Int']['output'];
  /** 혜택(프로모션) 부담 금액 */
  benefitBurdenAmount: Scalars['Int']['output'];
  /** 혜택(프로모션) 부담 금액(해외결제) */
  benefitBurdenAmountForeign: Scalars['Int']['output'];
  brandId: Scalars['Int']['output'];
  /** 추/차감 금액 */
  correctionAmount: Scalars['Int']['output'];
  /** 쿠폰 할인 부담 금액 */
  couponBurdenAmount: Scalars['Int']['output'];
  /** 쿠폰 할인 부담 금액(해외결제) */
  couponBurdenAmountForeign: Scalars['Int']['output'];
  /** 적용 환율 */
  exchangeRate: Scalars['Float']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  /** 독점 여부 */
  isExclusive: Scalars['Boolean']['output'];
  /** 지방세율 */
  localTax: Scalars['Float']['output'];
  /** 지방세 금액 */
  localTaxAmount: Scalars['Int']['output'];
  /** 월 */
  month: Scalars['Int']['output'];
  /** 국세율 */
  nationalTax: Scalars['Float']['output'];
  /** 국세 금액 */
  nationalTaxAmount: Scalars['Int']['output'];
  /** PG 수수료율 */
  pgCommission: Scalars['Float']['output'];
  /** PG 수수료 금액 */
  pgCommissionAmount: Scalars['Int']['output'];
  /** 파트너십 타입 */
  planType: Scalars['Int']['output'];
  /** 최종 정산 금액 */
  resultAmount: Scalars['Int']['output'];
  /** 판매금액 */
  saleAmount: Scalars['Int']['output'];
  /** 판매금액(해외결제) */
  saleAmountForeign: Scalars['Int']['output'];
  /** 판매수 */
  saleCount: Scalars['Int']['output'];
  /** 정산 기준 시작일 */
  settleSince: Scalars['DateTime']['output'];
  /** 정산 기준 종료일 */
  settleUntil: Scalars['DateTime']['output'];
  /** 정산 대상 금액 */
  settlementAmount: Scalars['Int']['output'];
  /** 결제 시작일 */
  since: Scalars['DateTime']['output'];
  /** 소득세율 */
  tax: Scalars['Float']['output'];
  /** 소득세 금액 */
  taxAmount: Scalars['Int']['output'];
  /** 총 혜택(프로모션) 부담 금액 */
  totalBenefitBurdenAmount: Scalars['Int']['output'];
  /** 총 쿠폰 할인 부담 금액 */
  totalCouponBurdenAmount: Scalars['Int']['output'];
  /** 총 판매금액 */
  totalSaleAmount: Scalars['Int']['output'];
  /** 총 부가세 금액 */
  totalVatAmount: Scalars['Int']['output'];
  /** 결제 종료일 */
  until: Scalars['DateTime']['output'];
  /** 부가세 금액 */
  vatAmount: Scalars['Int']['output'];
  /** 부가세 금액(해외결제) */
  vatAmountForeign: Scalars['Int']['output'];
  /** 년도 */
  year: Scalars['Int']['output'];
};

export type SettlementPaginatedOutput = {
  __typename?: 'SettlementPaginatedOutput';
  data: Array<SettlementOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type ShowAssetsInput = {
  /** asset id를 배열로 */
  assetIds: Array<Scalars['Int']['input']>;
  /** 메일 발송하지 않는 경우라면 null, 발송하는 경우 메모 내용을 그대로, 없다면 ""을 전송 */
  mailContent?: InputMaybe<Scalars['String']['input']>;
  /** 즉시 예약인 경우 현재 일자(new Date())를 넣어주시면 됩니다 */
  showingDate: Scalars['DateTime']['input'];
};

export type SingleDocumentBrandName = {
  __typename?: 'SingleDocumentBrandName';
  language?: Maybe<Language_Code>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SingleDocumentCategoryInfoInput = {
  categoryBranch?: InputMaybe<Array<Scalars['String']['input']>>;
  primary?: InputMaybe<Scalars['String']['input']>;
  secondary?: InputMaybe<Scalars['String']['input']>;
  tertiary?: InputMaybe<Scalars['String']['input']>;
};

export type SingleDocumentCategoryInfoOutput = {
  __typename?: 'SingleDocumentCategoryInfoOutput';
  categoryBranch?: Maybe<Array<Scalars['String']['output']>>;
  primary?: Maybe<Scalars['String']['output']>;
  secondary?: Maybe<Scalars['String']['output']>;
  tertiary?: Maybe<Scalars['String']['output']>;
};

export type SingleDocumentInput = {
  applications?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 고도몰상품번호 */
  assetId?: InputMaybe<Scalars['Int']['input']>;
  /** 전자책 상품의 정보 */
  book?: InputMaybe<DocumentBookInput>;
  /** 브랜드 ID */
  brandId?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<DocumentCategoryInput>>;
  contentBody?: InputMaybe<Scalars['String']['input']>;
  contentHead?: InputMaybe<Scalars['String']['input']>;
  copyright?: InputMaybe<DocumentCopyrightInput>;
  extensions?: InputMaybe<Array<Scalars['Int']['input']>>;
  fileComponents?: InputMaybe<Array<InputMaybe<DocumentFileComponentInput>>>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  filePath?: InputMaybe<Scalars['String']['input']>;
  game?: InputMaybe<Scalars['Boolean']['input']>;
  /** 다큐먼트 ID */
  id: Scalars['String']['input'];
  imgsrc?: InputMaybe<Scalars['Boolean']['input']>;
  /** 산업분야 목록; id만; */
  industries?: InputMaybe<Array<DocumentIndustryInput>>;
  isAdultOnly?: InputMaybe<Scalars['Boolean']['input']>;
  keywords?: InputMaybe<Array<Scalars['String']['input']>>;
  language?: InputMaybe<Language_Code>;
  lecture?: InputMaybe<Scalars['Boolean']['input']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: InputMaybe<Array<LicenseTypeOptionInput>>;
  mainCoordination?: InputMaybe<ImageCropCoordinationInput>;
  mainImage?: InputMaybe<Scalars['String']['input']>;
  mainOrigin?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  priceChanged?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<Document_Status>;
  subCoordination?: InputMaybe<ImageCropCoordinationInput>;
  subImage?: InputMaybe<Scalars['String']['input']>;
  subOrigin?: InputMaybe<Scalars['String']['input']>;
  /** 태그 목록; id만; */
  tags?: InputMaybe<Array<DocumentTagInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  webtoon?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SingleDocumentOutput = {
  __typename?: 'SingleDocumentOutput';
  /** 응용프로그램 목록 */
  applications: Array<Scalars['Int']['output']>;
  /** 고도몰상품번호 */
  assetId?: Maybe<Scalars['Int']['output']>;
  book?: Maybe<DocumentBookOutput>;
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Int']['output']>;
  brandNames?: Maybe<Array<Maybe<SingleDocumentBrandName>>>;
  /** 카테고리 정보 */
  categories: Array<CategoryOutputV4>;
  changeHistories?: Maybe<Array<Maybe<DocumentChangeHistoryOutput>>>;
  contentBody?: Maybe<Scalars['String']['output']>;
  contentHead?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<DocumentCopyrightOutput>;
  /** 확장자 목록 */
  extensions: Array<Scalars['Int']['output']>;
  fileComponents?: Maybe<Array<Maybe<DocumentFileComponentOutput>>>;
  fileName?: Maybe<Scalars['String']['output']>;
  filePath?: Maybe<Scalars['String']['output']>;
  game?: Maybe<Scalars['Boolean']['output']>;
  /** 다큐먼트 ID */
  id: Scalars['String']['output'];
  imgsrc?: Maybe<Scalars['Boolean']['output']>;
  /** 산업분야 목록 */
  industries: Array<IndustryOutput>;
  isAdultOnly?: Maybe<Scalars['Boolean']['output']>;
  keywords?: Maybe<Array<Scalars['String']['output']>>;
  language?: Maybe<Language_Code>;
  lecture?: Maybe<Scalars['Boolean']['output']>;
  /** 라이선스 옵션 목록 */
  licenseOptions?: Maybe<Array<LicenseTypeOptionOutput>>;
  mainCoordination?: Maybe<ImageCropCoordinationOutput>;
  mainImage?: Maybe<Scalars['String']['output']>;
  mainOrigin?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceChanged?: Maybe<Scalars['DateTime']['output']>;
  promotion?: Maybe<OpenrunPromotionHubOutput>;
  status?: Maybe<Document_Status>;
  subCoordination?: Maybe<ImageCropCoordinationOutput>;
  subImage?: Maybe<Scalars['String']['output']>;
  subOrigin?: Maybe<Scalars['String']['output']>;
  /** 태그 목록 */
  tags: Array<TagOutput>;
  title?: Maybe<Scalars['String']['output']>;
  webtoon?: Maybe<Scalars['Boolean']['output']>;
};

export enum SnsTypeEnum {
  Google = 'google',
  Naver = 'naver',
}

export type Sort = {
  /** 정렬 방법 */
  criterion: Products_Sort_Criterion;
  /** 정렬 기준 키 */
  key: Products_Sort_Key;
};

export type StateSpecificTotal = {
  __typename?: 'StateSpecificTotal';
  status: OpenrunStatusEnum;
  total: Scalars['Int']['output'];
};

export type StorePromotionConceptInput = {
  code: Scalars['String']['input'];
  title: GqlRegionalInput;
};

export type StoredFileInput = {
  /** 대상파일의 위치(path+name) */
  key: Scalars['String']['input'];
  /** 저장할 파일명 */
  name: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Float']['input']>;
};

export type SuspendPromotionInput = {
  id: Scalars['Int']['input'];
};

export enum Tags_Input_Sort_Criterion {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum Tags_Input_Sort_Key {
  Id = 'ID',
}

export type TagOutput = {
  __typename?: 'TagOutput';
  /** 상품 태그 수량(pagination이 걸린 검색의 경우 사용하지 않아야 할 필드) */
  count: Scalars['Int']['output'];
  /** 상품 태그 ID */
  id: Scalars['Int']['output'];
  /** 태그 이름 */
  name: Scalars['String']['output'];
};

export type TagPaginatedOutput = {
  __typename?: 'TagPaginatedOutput';
  data: Array<TagOutput>;
  pagination: PaginationOutput;
};

export type TagsInput_Condition = {
  /** 태그 ID 배열 */
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** 태그명; 요청 언어 기준으로 찾음; LIKE 조건임; */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TagsInput_Sort = {
  /** 요청 정렬 방법 */
  criterion: Tags_Input_Sort_Criterion;
  /** 요청 정렬 기준 */
  key: Tags_Input_Sort_Key;
};

/** 세금계산서(B2) */
export type TaxesInput = {
  /** 에이콘 캐시 결제금액 */
  aconCashPaymentAmountText?: InputMaybe<Scalars['String']['input']>;
  /** 에이콘 캐시 결제일 */
  aconCashPaymentDateText?: InputMaybe<Scalars['String']['input']>;
  /** 주문번호 */
  orderNoText?: InputMaybe<Scalars['String']['input']>;
  /** 사용자 ID */
  userId: Scalars['String']['input'];
};

export type TempAconProduct = {
  __typename?: 'TempAconProduct';
  goodsNo: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

export type TempProductPaginatedOutput = {
  __typename?: 'TempProductPaginatedOutput';
  allCount: Scalars['Int']['output'];
  data: Array<ProduktPublicOutput>;
  languageCode: LanguageCodeEnum;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
  sort: ProductListSort;
};

export type Term = {
  __typename?: 'Term';
  contents: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type Translated = {
  __typename?: 'Translated';
  lang: LanguageCodeEnum;
  text: Scalars['String']['output'];
};

export type Type = {
  __typename?: 'Type';
  /** 혜택 유형 코드 */
  code?: Maybe<Scalars['String']['output']>;
  /** 혜택 유형 코드 ID */
  id?: Maybe<Scalars['Float']['output']>;
  /** 혜택 유형 코드 명 */
  name?: Maybe<Scalars['String']['output']>;
};

export type UpdateApplicationInput = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type UpdateExtensionConfigInput = {
  id: Scalars['Int']['input'];
  /** 바꾸려는 이름 */
  name: Scalars['String']['input'];
};

export type UpdateGiftInput = {
  benefitDeposit?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode?: InputMaybe<LanguageCodeEnum>;
  price?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInquiryAnswerInput = {
  /** Inquiry answer content to update with */
  answer: Scalars['String']['input'];
  /** Inquiry ID */
  inquiryId: Scalars['Int']['input'];
};

export type UpdatePartnerAcceptInput = {
  /** 승인처리관련 메시지 */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** 상태(-1, 0, 1) */
  status?: InputMaybe<AcceptStatusEnum>;
};

export type UpdatePartnerAdminInput = {
  /** 승인 요청 정보 */
  accept?: InputMaybe<UpdatePartnerAcceptInput>;
  /** 사용자 계정(이메일) */
  account?: InputMaybe<Scalars['String']['input']>;
  /** 브랜드 정보 */
  brand?: InputMaybe<AdminUpdatePartnerBrandInput>;
  /** 회사명 */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** 연락처 */
  contact?: InputMaybe<Scalars['String']['input']>;
  /** 추가 연락처 */
  contactAdditional?: InputMaybe<Scalars['String']['input']>;
  /** 국가코드 */
  country?: InputMaybe<Scalars['String']['input']>;
  /** 사용자 이름 */
  name?: InputMaybe<Scalars['String']['input']>;
  /** 개인식별번호(주민번호) */
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>;
  /** 파트너십 정보 */
  plan?: InputMaybe<UpdatePartnerPlanInput>;
  /** 사업자 유형 */
  subType?: InputMaybe<Scalars['String']['input']>;
  /** 사용자 구분(개인, 회사) */
  type?: InputMaybe<PartnerTypeEnum>;
};

export type UpdatePartnerBrandInput = {
  cnName?: InputMaybe<Scalars['String']['input']>;
  enName?: InputMaybe<Scalars['String']['input']>;
  jpName?: InputMaybe<Scalars['String']['input']>;
  koName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerPlanInput = {
  commissionRate?: InputMaybe<Scalars['Float']['input']>;
  isExclusive: Scalars['Boolean']['input'];
  type: Scalars['Int']['input'];
};

export type UpdatePartnerReferenceInput = {
  url: Scalars['String']['input'];
};

export type UpdatePartnerSettleAdminFileInput = {
  bankBook?: InputMaybe<StoredFileInput>;
  idCard?: InputMaybe<StoredFileInput>;
  limitedTaxApl?: InputMaybe<StoredFileInput>;
};

export type UpdatePartnerSettleAdminInput = {
  files?: InputMaybe<UpdatePartnerSettleAdminFileInput>;
  /** 변경 항목 관련 내용 */
  memo: Scalars['String']['input'];
  settle: UpdatePartnerSettleInput;
};

export type UpdatePartnerSettleInput = {
  /** 계좌번호 */
  bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  /** 예금주 */
  bankAccountOwner?: InputMaybe<Scalars['String']['input']>;
  bankId?: InputMaybe<Scalars['Int']['input']>;
  /** 은행명 */
  bankName?: InputMaybe<Scalars['String']['input']>;
  /** 정산용 이메일 */
  settlementEmails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdatePopupInput = {
  /** 버튼 문구 */
  buttonText?: InputMaybe<Scalars['String']['input']>;
  /** 연결 URL */
  connectedUrl?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  exposeImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  /** 팝업 이미지 아이디 */
  image?: InputMaybe<PopupUploadFileInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  isStopped?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<LanguageCodeEnum>;
  location?: InputMaybe<PopupLocationV2>;
  noEndDate?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** 하위 페이지 url */
  subLocations?: InputMaybe<Array<PopupSubLocationInput>>;
  subText?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  urls?: InputMaybe<Array<CreatePopupUrlInput>>;
};

export type UpdatePromotionConceptInput = {
  code: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  title: GqlRegionalInput;
};

export type UpdatePromotionInput = {
  /** 프로모션 컨셉 ID */
  conceptId: Scalars['Int']['input'];
  description: GqlRegionalInput;
  /** 할인 타입 */
  discountType: EPromotionDiscountType;
  /** 할인 금액(퍼센트인 경우는 퍼센트), decimal(10, 2) */
  discountValue: Scalars['Float']['input'];
  /** 프로모션 ID */
  id: Scalars['Int']['input'];
  /** 프로모션 상품이 프로모션의 대표 할인정보를 그대로 상속받아 가져가는지 여부, 생략하면 디폴트는 true */
  inheritance?: InputMaybe<Scalars['Boolean']['input']>;
  issuer: GqlRegionalInput;
  /** 에셋별 할인정보, 에셋 전체가 프로모션의 할인정보를 상속받을 경우 id만 채워도 된다. inheritance === true로 설정해둘 것 */
  items: Array<PromotionItemInput>;
  /** 할인 시작일자 */
  since: Scalars['DateTime']['input'];
  /** 프로모션 이름 */
  title: Scalars['String']['input'];
  /** 할인 종료일자  */
  until: Scalars['DateTime']['input'];
};

export type UpdateReviewInput = {
  contents: Scalars['String']['input'];
  godoGoodsNo: Scalars['Int']['input'];
  godoOrderNo: Scalars['String']['input'];
  id: Scalars['Int']['input'];
  imageUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  isSecret: Scalars['Boolean']['input'];
  languageCode: LanguageCodeEnum;
  rating: Scalars['Int']['input'];
};

export type UploadBlobStorageInput = {
  /** 파일 이름 */
  fileName: Scalars['String']['input'];
  /** 저장될 폴더 이름 */
  targetDirectory: Scalars['String']['input'];
  /** 파일 경로 ex) tmp/file_name */
  temporaryFilePath: Scalars['String']['input'];
};

export type UploadFile = {
  __typename?: 'UploadFile';
  azureStoragePath: Scalars['String']['output'];
  creator?: Maybe<Scalars['Float']['output']>;
  fileName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
};

export type UploadFileClone = {
  __typename?: 'UploadFileClone';
  azureStoragePath: Scalars['String']['output'];
  creator?: Maybe<Scalars['Int']['output']>;
  fileName: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updater?: Maybe<Scalars['Int']['output']>;
};

export type UrlsInput = {
  isSubPageIncluded: Scalars['Boolean']['input'];
  url: Scalars['String']['input'];
};

export type UrlsOutput = {
  __typename?: 'UrlsOutput';
  isSubPageIncluded: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
};

export type UserAccept = {
  __typename?: 'UserAccept';
  /** 최초 승인날짜 */
  accepted?: Maybe<Scalars['DateTime']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  /** 정산 정보 수정 여부 */
  isModifyPayment?: Maybe<Scalars['Boolean']['output']>;
  /** 수정 - 승인 사유 */
  modifyApproveComment?: Maybe<Scalars['String']['output']>;
  /** 수정 - 반려 사유 */
  modifyRejectComment?: Maybe<Scalars['String']['output']>;
  modifyStatus: Scalars['Float']['output'];
  modifyStatusString: Scalars['String']['output'];
  status: Scalars['Float']['output'];
  statusString: Scalars['String']['output'];
  statusStringList: Array<Scalars['String']['output']>;
};

export type UserAcceptOutput = {
  __typename?: 'UserAcceptOutput';
  /** 최초 승인날짜 */
  accepted?: Maybe<Scalars['DateTime']['output']>;
  /** 검토 답변 */
  comment?: Maybe<Scalars['String']['output']>;
  /** 승인요청일시 */
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Float']['output'];
  /** 정산 정보 수정 여부 */
  isModifyPayment?: Maybe<Scalars['Boolean']['output']>;
  /** 퇴점일시 */
  leaved?: Maybe<Scalars['DateTime']['output']>;
  /** 수정 - 승인 사유 */
  modifyApproveComment?: Maybe<Scalars['String']['output']>;
  /** 수정 - 반려 사유 */
  modifyRejectComment?: Maybe<Scalars['String']['output']>;
  /** 수정상태(-1:반려, 0:요청, 1:승인) */
  modifyStatus: AcceptStatusEnum;
  /** 상태(-1:반려, 0:요청, 1:승인) */
  status: AcceptStatusEnum;
};

export type UserAcceptReference = {
  __typename?: 'UserAcceptReference';
  id: Scalars['Float']['output'];
  url: Scalars['String']['output'];
};

export type UserAcceptReferenceOutput = {
  __typename?: 'UserAcceptReferenceOutput';
  /** ID */
  id: Scalars['Int']['output'];
  /** 참조 url */
  url: Scalars['String']['output'];
};

export type UserExcelDownloadHistoryOutput = {
  __typename?: 'UserExcelDownloadHistoryOutput';
  /** 다운로드 받은 문서 종류 */
  documentClass: PartnerExcelDocumentClassEnum;
  /** 다운로드 받은 날짜 */
  downloadDate: Scalars['DateTime']['output'];
  /** 아이디 */
  id: Scalars['Int']['output'];
  /** 다운로드 받은 유저 */
  userName: Scalars['String']['output'];
};

export type UserExcelDownloadHistoryPaginatedOutput = {
  __typename?: 'UserExcelDownloadHistoryPaginatedOutput';
  data: Array<UserExcelDownloadHistoryOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type UserFileInfoOutput = {
  __typename?: 'UserFileInfoOutput';
  /** 브랜드 ID */
  brandId?: Maybe<Scalars['Float']['output']>;
  /** 업로드한 사람 */
  creator?: Maybe<Scalars['Float']['output']>;
  /** 파일 이름을 포함한 경로 */
  fullName: Scalars['String']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 원본 파일 이름 */
  path: Scalars['String']['output'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  /** 주소 */
  address: Scalars['String']['output'];
  /** 상세 주소 */
  addressSub: Scalars['String']['output'];
  /** 사업자 - 번호 */
  cashBusiNo: Scalars['String']['output'];
  /** 사업자 - 휴대폰 번호 */
  cashCellPhone: Scalars['String']['output'];
  /** 핸드폰 번호 */
  cellPhone: Scalars['String']['output'];
  /** 주문자 예치금 액수 */
  deposit: Scalars['Float']['output'];
  /** 주문자 이메일 */
  email: Scalars['String']['output'];
  /** 주문자 선물 예치금 액수 */
  giftDeposit: Scalars['Float']['output'];
  /** 주문자 명 */
  name: Scalars['String']['output'];
  /** 주문자 명 */
  orderName: Scalars['String']['output'];
  /** 필명 */
  penName: Scalars['String']['output'];
  /** 주문자 포인트 액수 */
  point: Scalars['Float']['output'];
  /** 세금 계산서 - 사업장 주소 */
  taxAddress: Scalars['String']['output'];
  /** 세금 계산서 - 사업장 상세 주소 */
  taxAddressSub: Scalars['String']['output'];
  /** 세금 계산서 - 사업자 번호 */
  taxBusiNo: Scalars['String']['output'];
  /** 세금 계산서 - 대표자 명 */
  taxCeoNm: Scalars['String']['output'];
  /** 세금 계산서 - 회사 명 */
  taxCompany: Scalars['String']['output'];
  /** 세금 계산서 - 이메일 */
  taxEmail: Scalars['String']['output'];
  /** 세금 계산서 - 종목 */
  taxItem: Scalars['String']['output'];
  /** 세금 계산서 - 업태 */
  taxService: Scalars['String']['output'];
  /** 세금 계산서 - 사업장 우편 코드 */
  taxZipcode: Scalars['String']['output'];
  /** 세금 계산서 - 사업장 지역 코드 */
  taxZonecode: Scalars['String']['output'];
  /** 우편 코드 */
  zipCode: Scalars['String']['output'];
  /** 지역 코드 */
  zoneCode: Scalars['String']['output'];
};

export type UserMemoModel = {
  __typename?: 'UserMemoModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']['output']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 메모 */
  memo: Scalars['String']['output'];
  /** 메모 타입 */
  memoType: Scalars['String']['output'];
  /** 수정 일자 */
  updated: Scalars['DateTime']['output'];
  /** 주문 사용권 ID */
  userId: Scalars['Float']['output'];
};

export type UserMemoOutput = {
  __typename?: 'UserMemoOutput';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']['output']>;
  adminUser?: Maybe<AdminUser>;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Int']['output'];
  /** 메모 */
  memo: Scalars['String']['output'];
  /** 메모 타입 */
  memoType: PartnerMemoTypeEnum;
  /** 수정 일자 */
  updated: Scalars['DateTime']['output'];
  /** 사용자 아이디 */
  userId: Scalars['Int']['output'];
};

export type UserOutput = {
  __typename?: 'UserOutput';
  accept: UserAcceptOutput;
  /** 아이디 */
  account: Scalars['String']['output'];
  /** 생년월일 */
  birth?: Maybe<Scalars['DateTime']['output']>;
  brand?: Maybe<BrandOutput>;
  brandId?: Maybe<Scalars['Int']['output']>;
  /** 변경기록 */
  changeLog?: Maybe<Scalars['String']['output']>;
  /** 상호 */
  companyName?: Maybe<Scalars['String']['output']>;
  /** 연락처 */
  contact?: Maybe<Scalars['String']['output']>;
  /** 추가 연락처 */
  contactAdditional?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  /** 첫 로그인일시 */
  firstLoginDate?: Maybe<Scalars['DateTime']['output']>;
  group: GroupOutput;
  histories: Array<UserSettleStatusHistoryOutput>;
  id: Scalars['Int']['output'];
  /** 성인 여부 */
  isAdult: Scalars['Boolean']['output'];
  /** 회원가입 언어, 현재는 무조건 ko 또는 en */
  joinLanguage: Scalars['String']['output'];
  /** 마지막 로그인일시 */
  lastLoginDate?: Maybe<Scalars['DateTime']['output']>;
  memos: Array<UserMemoOutput>;
  /** 이름 */
  name: Scalars['String']['output'];
  plan?: Maybe<UserPlanOutput>;
  reference: Array<UserAcceptReferenceOutput>;
  settle?: Maybe<UserSettleOutput>;
  /** sns 주소 */
  snsUrl?: Maybe<Scalars['DateTime']['output']>;
  /** 사업자 구분 */
  subType?: Maybe<Scalars['String']['output']>;
  /** 사업자 유형(human readable) */
  subTypeString?: Maybe<Scalars['String']['output']>;
  subTypeStringList: Array<Scalars['String']['output']>;
  termAgree: Array<UserTermAgreeOutput>;
  /** 0:일반/1:사업자 */
  type?: Maybe<PartnerTypeEnum>;
  /** 회원 유형(human readable) */
  typeString?: Maybe<Scalars['String']['output']>;
  typeStringList: Array<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type UserPaginatedOutput = {
  __typename?: 'UserPaginatedOutput';
  data: Array<UserOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export type UserPlan = {
  __typename?: 'UserPlan';
  commissionRate?: Maybe<Scalars['Float']['output']>;
  isExclusive: Scalars['Boolean']['output'];
  type: Scalars['Float']['output'];
  userId: Scalars['Float']['output'];
};

export type UserPlanOutput = {
  __typename?: 'UserPlanOutput';
  /** 수수료율 */
  commissionRate: Scalars['Int']['output'];
  /** 독점 여부 */
  isExclusive: Scalars['Boolean']['output'];
  /** 플랜 타입 */
  type: Scalars['Int']['output'];
  /** 사용자 아이디 */
  userId: Scalars['Int']['output'];
};

export type UserSettle = {
  __typename?: 'UserSettle';
  /** 승인일자 */
  approveDate?: Maybe<Scalars['DateTime']['output']>;
  bank?: Maybe<Bank>;
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  bankAccountOwner?: Maybe<Scalars['String']['output']>;
  bankBook?: Maybe<UploadFile>;
  bankId: Scalars['Float']['output'];
  bankName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['Float']['output'];
  idCard?: Maybe<UploadFile>;
  imageSign: Scalars['Float']['output'];
  limitedTaxApl?: Maybe<UploadFile>;
  nationalIdNumber?: Maybe<Scalars['String']['output']>;
  /** 반려일자 */
  rejectDate?: Maybe<Scalars['DateTime']['output']>;
  /** 요청일자 */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** 정산용 이메일 */
  settlementEmails: Array<Scalars['String']['output']>;
  sign?: Maybe<UploadFile>;
  signLang?: Maybe<Scalars['String']['output']>;
  /** 상태 */
  status: UserSettleStatusEnum;
};

export type UserSettleOutput = {
  __typename?: 'UserSettleOutput';
  /** 승인일자 */
  approveDate?: Maybe<Scalars['DateTime']['output']>;
  bank?: Maybe<BankOutput>;
  /** 계좌번호 */
  bankAccountNumber?: Maybe<Scalars['String']['output']>;
  /** 예금주 */
  bankAccountOwner?: Maybe<Scalars['String']['output']>;
  bankBook?: Maybe<FileOutput>;
  /** 은행 구분 번호 */
  bankId: Scalars['Int']['output'];
  /** 특별한 은행명 */
  bankName?: Maybe<Scalars['String']['output']>;
  /** 국가코드 */
  country?: Maybe<Scalars['String']['output']>;
  /** ID */
  id: Scalars['Int']['output'];
  idCard?: Maybe<FileOutput>;
  /** 통장 파일 아이디 */
  imageBankBook?: Maybe<Scalars['Int']['output']>;
  /** 신분증 파일 아이디 */
  imageIdCard?: Maybe<Scalars['Int']['output']>;
  /** 제한 세율 적용 신청서 파일 아이디 */
  imageLimitedTaxApl?: Maybe<Scalars['Int']['output']>;
  /** 서명 파일 아이디 */
  imageSign?: Maybe<Scalars['Int']['output']>;
  limitedTaxApl?: Maybe<FileOutput>;
  /** 개인식별번호(like. 주민번호) */
  nationalIdNumber?: Maybe<Scalars['String']['output']>;
  /** 반려일자 */
  rejectDate?: Maybe<Scalars['DateTime']['output']>;
  /** 요청일자 */
  requestDate?: Maybe<Scalars['DateTime']['output']>;
  /** 정산용 이메일 */
  settlementEmails: Array<Scalars['String']['output']>;
  sign?: Maybe<FileOutput>;
  /** 가입 언어 */
  signLang?: Maybe<Scalars['String']['output']>;
  /** 상태 */
  status: PartnerSettleStatusEnum;
  user: UserOutput;
};

export type UserSettlePaginatedOutput = {
  __typename?: 'UserSettlePaginatedOutput';
  data: Array<UserSettleOutput>;
  /** pagination 을 사용해주세요! */
  meta: Pagination;
  pagination: Pagination;
};

export enum UserSettleStatusEnum {
  Approve = 'approve',
  None = 'none',
  Reject = 'reject',
  Request = 'request',
  Rerequest = 'rerequest',
}

export type UserSettleStatusHistoryModel = {
  __typename?: 'UserSettleStatusHistoryModel';
  /** 어드민 고유 ID */
  adminId?: Maybe<Scalars['String']['output']>;
  adminUser?: Maybe<AdminUserModel>;
  /** 생성 일자 */
  created: Scalars['DateTime']['output'];
  /** 고유 ID */
  id: Scalars['Float']['output'];
  /** 메모 */
  memo?: Maybe<Scalars['String']['output']>;
  /** 상태 */
  status: UserSettleStatusEnum;
  /** 업데이트 일자 */
  updated: Scalars['DateTime']['output'];
  /** 사용자 ID */
  userId: Scalars['Float']['output'];
};

export type UserSettleStatusHistoryOutput = {
  __typename?: 'UserSettleStatusHistoryOutput';
  /** 관리자 아이디 */
  adminId?: Maybe<Scalars['String']['output']>;
  adminUser?: Maybe<AdminUser>;
  /** 생성일시 */
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  /** 메모 */
  memo?: Maybe<Scalars['String']['output']>;
  /** 상태값 */
  status: PartnerSettleStatusEnum;
  /** 사용자 아이디 */
  userId: Scalars['Int']['output'];
};

export type UserTermAgree = {
  __typename?: 'UserTermAgree';
  agreeAt: Scalars['DateTime']['output'];
  file?: Maybe<UploadFile>;
  id: Scalars['Float']['output'];
  uploadFileId?: Maybe<Scalars['Float']['output']>;
};

export type UserTermAgreeOutput = {
  __typename?: 'UserTermAgreeOutput';
  /** 동의 일시 */
  agreeAt: Scalars['DateTime']['output'];
  file?: Maybe<FileOutput>;
  id: Scalars['Int']['output'];
  /** 계약서 파일 아이디 */
  uploadFileId?: Maybe<Scalars['Int']['output']>;
  /** 사용자 아이디 */
  userId: Scalars['Int']['output'];
};

export type VBank = {
  __typename?: 'VBank';
  /** 예금주 명 */
  bankHolder?: Maybe<Scalars['String']['output']>;
  /** 입금 은행 명 */
  bankName?: Maybe<Scalars['String']['output']>;
  /** 가상계좌 번호 */
  bankNumber?: Maybe<Scalars['String']['output']>;
  /** 송금 기한 */
  remittanceDate?: Maybe<Scalars['DateTime']['output']>;
};

export const BrandFragmentDoc = gql`
  fragment brand on Acon3dBrand {
    code
    name
  }
`;
export const CategoriesFragmentDoc = gql`
  fragment categories on Acon3dCategory {
    code
    name
  }
`;
export const ModelConfigsFragmentDoc = gql`
  fragment modelConfigs on Acon3dModelConfig {
    id
    name
  }
`;
export const BannersFragmentDoc = gql`
  fragment banners on Acon3dBanner {
    id
    text
    href
    imageUrl
    type
    created
  }
`;
export const PromotionsBadgeFragmentDoc = gql`
  fragment promotionsBadge on Acon3dPromotionBadge {
    id
    type
    languageCode
    content
  }
`;
export const PromotionsFragmentDoc = gql`
  fragment promotions on Acon3dPromotion {
    id
    startDate
    currentRound
    remainingDays
    rounds {
      roundNo
      salePrice
      startDate
      endDate
    }
    badges {
      ...promotionsBadge
    }
  }
  ${PromotionsBadgeFragmentDoc}
`;
export const LinkedProductsFragmentDoc = gql`
  fragment linkedProducts on Acon3dLinkedProduct {
    id
    extensions
  }
`;
export const PackagesFragmentDoc = gql`
  fragment packages on Acon3dPackage {
    type
    parent {
      id
    }
    children {
      id
    }
  }
`;
export const FundingFragmentDoc = gql`
  fragment funding on Acon3dFunding {
    deliveryDate
    endDate
    goalAmount
    soldAmount
    soldVolume
    startDate
    status
  }
`;
export const PromotionInfoFragmentDoc = gql`
  fragment promotionInfo on PromotionOutput {
    discountValue
    concept {
      code
    }
    items {
      id
      discountValue
      since
      until
    }
  }
`;
export const ProductCardsFragmentDoc = gql`
  fragment productCards on Acon3dProduct {
    id
    languageCode
    title
    price
    salePrice
    onSale
    onDisplay
    promotionEndDate
    mainImageUrl
    subImageUrl
    isAconOnly
    isAdultOnly
    orderCount
    viewCount
    reviewCount
    created
    released
    updated
    badgeNames
    extensions
    isExclusive
    book {
      isbn
    }
  }
`;
export const LicensesFragmentDoc = gql`
  fragment licenses on AssetLicensePublicOutput {
    id
    licenseTypeId
    code
    usableCount
    price
  }
`;
export const ProductPriceInfoFragmentDoc = gql`
  fragment ProductPriceInfo on ProductPriceInfoOutput {
    price
    salePrice
  }
`;
export const ProductImageInfoFragmentDoc = gql`
  fragment ProductImageInfo on ProductImageInfoOutput {
    main {
      url
    }
    sub {
      url
    }
  }
`;
export const ProductPromotionInfoFragmentDoc = gql`
  fragment ProductPromotionInfo on ProductPromotionInfoOutput {
    until
    since
  }
`;
export const ProductBrandInfoFragmentDoc = gql`
  fragment ProductBrandInfo on BrandOutput {
    id
    code
    name
    isAcon3DExclusive
    update {
      type
      value
    }
    productCount
    vipInfo
    photoInfo {
      path
    }
  }
`;
export const ProductTagsFragmentDoc = gql`
  fragment ProductTags on TagOutput {
    id
    name
    count
  }
`;
export const ProductExtensionsFragmentDoc = gql`
  fragment ProductExtensions on ExtensionOutput {
    id
    name
    count
  }
`;
export const ProductApplicationsFragmentDoc = gql`
  fragment ProductApplications on AssetApplicationOutput {
    id
    name
  }
`;
export const ProductsPaginationFragmentDoc = gql`
  fragment ProductsPagination on PaginationOutput {
    totalCount
    currentPage
    perPage
    lastPage
  }
`;
export const ProductsDataFragmentDoc = gql`
  fragment ProductsData on ProductOutput {
    id
    title
    priceInfo {
      price
      salePrice
    }
    promotionInfo {
      since
      until
    }
    imageInfo {
      main {
        url
      }
      sub {
        url
      }
    }
    dateInfo {
      releasedAt
    }
    tags {
      id
      name
      count
    }
    brandInfo {
      id
      name
      isAcon3DExclusive
      vipInfo
      code
    }
    extensions {
      id
      name
      count
    }
    applications {
      id
      name
    }
  }
`;
export const CheckHasAdultProductsDocument = gql`
  query CheckHasAdultProducts($language: LANGUAGE_CODE!, $condition: CheckHasAdultProducts_Condition!) {
    checkHasAdultProducts(language: $language, condition: $condition)
  }
`;

/**
 * __useCheckHasAdultProductsQuery__
 *
 * To run a query within a React component, call `useCheckHasAdultProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckHasAdultProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckHasAdultProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      condition: // value for 'condition'
 *   },
 * });
 */
export function useCheckHasAdultProductsQuery(baseOptions: Apollo.QueryHookOptions<CheckHasAdultProductsQuery, CheckHasAdultProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckHasAdultProductsQuery, CheckHasAdultProductsQueryVariables>(CheckHasAdultProductsDocument, options);
}
export function useCheckHasAdultProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckHasAdultProductsQuery, CheckHasAdultProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckHasAdultProductsQuery, CheckHasAdultProductsQueryVariables>(CheckHasAdultProductsDocument, options);
}
export type CheckHasAdultProductsQueryHookResult = ReturnType<typeof useCheckHasAdultProductsQuery>;
export type CheckHasAdultProductsLazyQueryHookResult = ReturnType<typeof useCheckHasAdultProductsLazyQuery>;
export type CheckHasAdultProductsQueryResult = Apollo.QueryResult<CheckHasAdultProductsQuery, CheckHasAdultProductsQueryVariables>;
export const AssetFileInfoDocument = gql`
  query AssetFileInfo($id: Int!, $language: LANGUAGE_CODE!) {
    assetFileInfo(id: $id, language: $language) {
      i18ns {
        copyright {
          isOriginal
          warehouseSources
          commercialSources
          hasRealLogo
        }
        fileComponents {
          name
          size
        }
        fileUpdates {
          name
          date
        }
      }
    }
  }
`;

/**
 * __useAssetFileInfoQuery__
 *
 * To run a query within a React component, call `useAssetFileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetFileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetFileInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useAssetFileInfoQuery(baseOptions: Apollo.QueryHookOptions<AssetFileInfoQuery, AssetFileInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssetFileInfoQuery, AssetFileInfoQueryVariables>(AssetFileInfoDocument, options);
}
export function useAssetFileInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetFileInfoQuery, AssetFileInfoQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssetFileInfoQuery, AssetFileInfoQueryVariables>(AssetFileInfoDocument, options);
}
export type AssetFileInfoQueryHookResult = ReturnType<typeof useAssetFileInfoQuery>;
export type AssetFileInfoLazyQueryHookResult = ReturnType<typeof useAssetFileInfoLazyQuery>;
export type AssetFileInfoQueryResult = Apollo.QueryResult<AssetFileInfoQuery, AssetFileInfoQueryVariables>;
export const GetAttendanceEventDatesDocument = gql`
  query getAttendanceEventDates($eventId: Float!) {
    getAttendanceEventDates(eventId: $eventId)
  }
`;

/**
 * __useGetAttendanceEventDatesQuery__
 *
 * To run a query within a React component, call `useGetAttendanceEventDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceEventDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceEventDatesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetAttendanceEventDatesQuery(baseOptions: Apollo.QueryHookOptions<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>(GetAttendanceEventDatesDocument, options);
}
export function useGetAttendanceEventDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>(GetAttendanceEventDatesDocument, options);
}
export type GetAttendanceEventDatesQueryHookResult = ReturnType<typeof useGetAttendanceEventDatesQuery>;
export type GetAttendanceEventDatesLazyQueryHookResult = ReturnType<typeof useGetAttendanceEventDatesLazyQuery>;
export type GetAttendanceEventDatesQueryResult = Apollo.QueryResult<GetAttendanceEventDatesQuery, GetAttendanceEventDatesQueryVariables>;
export const AttendanceEventDocument = gql`
  mutation AttendanceEvent($eventId: Float!, $languageCode: String!, $isWeekend: Boolean) {
    attendanceEvent(eventId: $eventId, languageCode: $languageCode, isWeekend: $isWeekend)
  }
`;
export type AttendanceEventMutationFn = Apollo.MutationFunction<AttendanceEventMutation, AttendanceEventMutationVariables>;

/**
 * __useAttendanceEventMutation__
 *
 * To run a mutation, you first call `useAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendanceEventMutation, { data, loading, error }] = useAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *      isWeekend: // value for 'isWeekend'
 *   },
 * });
 */
export function useAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<AttendanceEventMutation, AttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttendanceEventMutation, AttendanceEventMutationVariables>(AttendanceEventDocument, options);
}
export type AttendanceEventMutationHookResult = ReturnType<typeof useAttendanceEventMutation>;
export type AttendanceEventMutationResult = Apollo.MutationResult<AttendanceEventMutation>;
export type AttendanceEventMutationOptions = Apollo.BaseMutationOptions<AttendanceEventMutation, AttendanceEventMutationVariables>;
export const MayAttendanceEventDocument = gql`
  mutation MayAttendanceEvent($eventId: Float!, $languageCode: String!) {
    mayAttendanceEvent(eventId: $eventId, languageCode: $languageCode)
  }
`;
export type MayAttendanceEventMutationFn = Apollo.MutationFunction<MayAttendanceEventMutation, MayAttendanceEventMutationVariables>;

/**
 * __useMayAttendanceEventMutation__
 *
 * To run a mutation, you first call `useMayAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMayAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mayAttendanceEventMutation, { data, loading, error }] = useMayAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useMayAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<MayAttendanceEventMutation, MayAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MayAttendanceEventMutation, MayAttendanceEventMutationVariables>(MayAttendanceEventDocument, options);
}
export type MayAttendanceEventMutationHookResult = ReturnType<typeof useMayAttendanceEventMutation>;
export type MayAttendanceEventMutationResult = Apollo.MutationResult<MayAttendanceEventMutation>;
export type MayAttendanceEventMutationOptions = Apollo.BaseMutationOptions<MayAttendanceEventMutation, MayAttendanceEventMutationVariables>;
export const JuneAttendanceEventDocument = gql`
  mutation JuneAttendanceEvent($eventId: Float!, $languageCode: String!) {
    juneAttendanceEvent(eventId: $eventId, languageCode: $languageCode)
  }
`;
export type JuneAttendanceEventMutationFn = Apollo.MutationFunction<JuneAttendanceEventMutation, JuneAttendanceEventMutationVariables>;

/**
 * __useJuneAttendanceEventMutation__
 *
 * To run a mutation, you first call `useJuneAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJuneAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [juneAttendanceEventMutation, { data, loading, error }] = useJuneAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useJuneAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<JuneAttendanceEventMutation, JuneAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JuneAttendanceEventMutation, JuneAttendanceEventMutationVariables>(JuneAttendanceEventDocument, options);
}
export type JuneAttendanceEventMutationHookResult = ReturnType<typeof useJuneAttendanceEventMutation>;
export type JuneAttendanceEventMutationResult = Apollo.MutationResult<JuneAttendanceEventMutation>;
export type JuneAttendanceEventMutationOptions = Apollo.BaseMutationOptions<JuneAttendanceEventMutation, JuneAttendanceEventMutationVariables>;
export const JulyAttendanceEventDocument = gql`
  mutation JulyAttendanceEvent($eventId: Float!, $languageCode: String!) {
    julyAttendanceEvent(eventId: $eventId, languageCode: $languageCode)
  }
`;
export type JulyAttendanceEventMutationFn = Apollo.MutationFunction<JulyAttendanceEventMutation, JulyAttendanceEventMutationVariables>;

/**
 * __useJulyAttendanceEventMutation__
 *
 * To run a mutation, you first call `useJulyAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJulyAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [julyAttendanceEventMutation, { data, loading, error }] = useJulyAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useJulyAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<JulyAttendanceEventMutation, JulyAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JulyAttendanceEventMutation, JulyAttendanceEventMutationVariables>(JulyAttendanceEventDocument, options);
}
export type JulyAttendanceEventMutationHookResult = ReturnType<typeof useJulyAttendanceEventMutation>;
export type JulyAttendanceEventMutationResult = Apollo.MutationResult<JulyAttendanceEventMutation>;
export type JulyAttendanceEventMutationOptions = Apollo.BaseMutationOptions<JulyAttendanceEventMutation, JulyAttendanceEventMutationVariables>;
export const AugustAttendanceEventDocument = gql`
  mutation AugustAttendanceEvent($eventId: Float!, $languageCode: String!) {
    augustAttendanceEvent(eventId: $eventId, languageCode: $languageCode)
  }
`;
export type AugustAttendanceEventMutationFn = Apollo.MutationFunction<AugustAttendanceEventMutation, AugustAttendanceEventMutationVariables>;

/**
 * __useAugustAttendanceEventMutation__
 *
 * To run a mutation, you first call `useAugustAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAugustAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [augustAttendanceEventMutation, { data, loading, error }] = useAugustAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useAugustAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<AugustAttendanceEventMutation, AugustAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AugustAttendanceEventMutation, AugustAttendanceEventMutationVariables>(AugustAttendanceEventDocument, options);
}
export type AugustAttendanceEventMutationHookResult = ReturnType<typeof useAugustAttendanceEventMutation>;
export type AugustAttendanceEventMutationResult = Apollo.MutationResult<AugustAttendanceEventMutation>;
export type AugustAttendanceEventMutationOptions = Apollo.BaseMutationOptions<AugustAttendanceEventMutation, AugustAttendanceEventMutationVariables>;
export const SeptemberAttendanceEventDocument = gql`
  mutation SeptemberAttendanceEvent($eventId: Float!, $languageCode: String!) {
    septemberAttendanceEvent(eventId: $eventId, languageCode: $languageCode)
  }
`;
export type SeptemberAttendanceEventMutationFn = Apollo.MutationFunction<SeptemberAttendanceEventMutation, SeptemberAttendanceEventMutationVariables>;

/**
 * __useSeptemberAttendanceEventMutation__
 *
 * To run a mutation, you first call `useSeptemberAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeptemberAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [septemberAttendanceEventMutation, { data, loading, error }] = useSeptemberAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useSeptemberAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<SeptemberAttendanceEventMutation, SeptemberAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SeptemberAttendanceEventMutation, SeptemberAttendanceEventMutationVariables>(SeptemberAttendanceEventDocument, options);
}
export type SeptemberAttendanceEventMutationHookResult = ReturnType<typeof useSeptemberAttendanceEventMutation>;
export type SeptemberAttendanceEventMutationResult = Apollo.MutationResult<SeptemberAttendanceEventMutation>;
export type SeptemberAttendanceEventMutationOptions = Apollo.BaseMutationOptions<SeptemberAttendanceEventMutation, SeptemberAttendanceEventMutationVariables>;
export const OctoberAttendanceEventDocument = gql`
  mutation OctoberAttendanceEvent($eventId: Float!, $languageCode: String!) {
    octoberAttendanceEvent(eventId: $eventId, languageCode: $languageCode)
  }
`;
export type OctoberAttendanceEventMutationFn = Apollo.MutationFunction<OctoberAttendanceEventMutation, OctoberAttendanceEventMutationVariables>;

/**
 * __useOctoberAttendanceEventMutation__
 *
 * To run a mutation, you first call `useOctoberAttendanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOctoberAttendanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [octoberAttendanceEventMutation, { data, loading, error }] = useOctoberAttendanceEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useOctoberAttendanceEventMutation(baseOptions?: Apollo.MutationHookOptions<OctoberAttendanceEventMutation, OctoberAttendanceEventMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OctoberAttendanceEventMutation, OctoberAttendanceEventMutationVariables>(OctoberAttendanceEventDocument, options);
}
export type OctoberAttendanceEventMutationHookResult = ReturnType<typeof useOctoberAttendanceEventMutation>;
export type OctoberAttendanceEventMutationResult = Apollo.MutationResult<OctoberAttendanceEventMutation>;
export type OctoberAttendanceEventMutationOptions = Apollo.BaseMutationOptions<OctoberAttendanceEventMutation, OctoberAttendanceEventMutationVariables>;
export const BestProductsDocument = gql`
  query BestProducts($type: BestProductType!, $categoryCode: String, $isFreeProductInclude: Boolean) {
    bestProducts(type: $type, categoryCode: $categoryCode, isFreeProductInclude: $isFreeProductInclude)
  }
`;

/**
 * __useBestProductsQuery__
 *
 * To run a query within a React component, call `useBestProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestProductsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      categoryCode: // value for 'categoryCode'
 *      isFreeProductInclude: // value for 'isFreeProductInclude'
 *   },
 * });
 */
export function useBestProductsQuery(baseOptions: Apollo.QueryHookOptions<BestProductsQuery, BestProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BestProductsQuery, BestProductsQueryVariables>(BestProductsDocument, options);
}
export function useBestProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BestProductsQuery, BestProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BestProductsQuery, BestProductsQueryVariables>(BestProductsDocument, options);
}
export type BestProductsQueryHookResult = ReturnType<typeof useBestProductsQuery>;
export type BestProductsLazyQueryHookResult = ReturnType<typeof useBestProductsLazyQuery>;
export type BestProductsQueryResult = Apollo.QueryResult<BestProductsQuery, BestProductsQueryVariables>;
export const BrandPickSummaryDocument = gql`
  query BrandPickSummary($brandCode: String) {
    brandPickSummary(brandCode: $brandCode) {
      products {
        id
      }
      toggle
    }
  }
`;

/**
 * __useBrandPickSummaryQuery__
 *
 * To run a query within a React component, call `useBrandPickSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandPickSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandPickSummaryQuery({
 *   variables: {
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useBrandPickSummaryQuery(baseOptions?: Apollo.QueryHookOptions<BrandPickSummaryQuery, BrandPickSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandPickSummaryQuery, BrandPickSummaryQueryVariables>(BrandPickSummaryDocument, options);
}
export function useBrandPickSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandPickSummaryQuery, BrandPickSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandPickSummaryQuery, BrandPickSummaryQueryVariables>(BrandPickSummaryDocument, options);
}
export type BrandPickSummaryQueryHookResult = ReturnType<typeof useBrandPickSummaryQuery>;
export type BrandPickSummaryLazyQueryHookResult = ReturnType<typeof useBrandPickSummaryLazyQuery>;
export type BrandPickSummaryQueryResult = Apollo.QueryResult<BrandPickSummaryQuery, BrandPickSummaryQueryVariables>;
export const BrandProductsWithCategoriesDocument = gql`
  query BrandProductsWithCategories($languageCode: LanguageCodeEnum!, $brandCode: String!) {
    brandProductsWithCategories(languageCode: $languageCode, brandCode: $brandCode) {
      id
      categories {
        code
      }
    }
  }
`;

/**
 * __useBrandProductsWithCategoriesQuery__
 *
 * To run a query within a React component, call `useBrandProductsWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandProductsWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandProductsWithCategoriesQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useBrandProductsWithCategoriesQuery(baseOptions: Apollo.QueryHookOptions<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>(BrandProductsWithCategoriesDocument, options);
}
export function useBrandProductsWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>(BrandProductsWithCategoriesDocument, options);
}
export type BrandProductsWithCategoriesQueryHookResult = ReturnType<typeof useBrandProductsWithCategoriesQuery>;
export type BrandProductsWithCategoriesLazyQueryHookResult = ReturnType<typeof useBrandProductsWithCategoriesLazyQuery>;
export type BrandProductsWithCategoriesQueryResult = Apollo.QueryResult<BrandProductsWithCategoriesQuery, BrandProductsWithCategoriesQueryVariables>;
export const BrandReviewsDocument = gql`
  query BrandReviews($brandId: Int!, $limit: Int, $offset: Int, $languageCode: LanguageCodeEnum!) {
    brandReviews(brandId: $brandId, limit: $limit, offset: $offset) {
      count
      rows {
        rating
        id
        rating
        author
        commentsCount
        isImage
        isNew
        isBestReview
        created
        reviewImages {
          imageNumber
          imageUrl
        }
        reviewContentsItems {
          reviewId
          languageCode
          isOriginal
          title
          contents
        }
        product(languageCode: $languageCode) {
          id
          title
          mainImageUrl
          subImageUrl
        }
      }
    }
  }
`;

/**
 * __useBrandReviewsQuery__
 *
 * To run a query within a React component, call `useBrandReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandReviewsQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useBrandReviewsQuery(baseOptions: Apollo.QueryHookOptions<BrandReviewsQuery, BrandReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandReviewsQuery, BrandReviewsQueryVariables>(BrandReviewsDocument, options);
}
export function useBrandReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandReviewsQuery, BrandReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandReviewsQuery, BrandReviewsQueryVariables>(BrandReviewsDocument, options);
}
export type BrandReviewsQueryHookResult = ReturnType<typeof useBrandReviewsQuery>;
export type BrandReviewsLazyQueryHookResult = ReturnType<typeof useBrandReviewsLazyQuery>;
export type BrandReviewsQueryResult = Apollo.QueryResult<BrandReviewsQuery, BrandReviewsQueryVariables>;
export const PresetBrandsDocument = gql`
  query PresetBrands($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $preset: PRESET_BRANDS_INPUT_PRESET!) {
    presetBrands(language: $language, pagination: $pagination, preset: $preset) {
      data {
        id
        code
        name
        productCount
        photoInfo {
          path
        }
        update {
          type
          value
        }
        vipInfo
      }
      pagination {
        totalCount
      }
    }
  }
`;

/**
 * __usePresetBrandsQuery__
 *
 * To run a query within a React component, call `usePresetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresetBrandsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      preset: // value for 'preset'
 *   },
 * });
 */
export function usePresetBrandsQuery(baseOptions: Apollo.QueryHookOptions<PresetBrandsQuery, PresetBrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PresetBrandsQuery, PresetBrandsQueryVariables>(PresetBrandsDocument, options);
}
export function usePresetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresetBrandsQuery, PresetBrandsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PresetBrandsQuery, PresetBrandsQueryVariables>(PresetBrandsDocument, options);
}
export type PresetBrandsQueryHookResult = ReturnType<typeof usePresetBrandsQuery>;
export type PresetBrandsLazyQueryHookResult = ReturnType<typeof usePresetBrandsLazyQuery>;
export type PresetBrandsQueryResult = Apollo.QueryResult<PresetBrandsQuery, PresetBrandsQueryVariables>;
export const SearchBrandsV2Document = gql`
  query SearchBrandsV2($language: LANGUAGE_CODE!, $keyword: String!, $https: Boolean) {
    searchBrandsV2(language: $language, keyword: $keyword, https: $https) {
      data {
        id
        code
        name
        productCount
        photoInfo {
          path
        }
      }
      pagination {
        totalCount
      }
    }
  }
`;

/**
 * __useSearchBrandsV2Query__
 *
 * To run a query within a React component, call `useSearchBrandsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchBrandsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBrandsV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      keyword: // value for 'keyword'
 *      https: // value for 'https'
 *   },
 * });
 */
export function useSearchBrandsV2Query(baseOptions: Apollo.QueryHookOptions<SearchBrandsV2Query, SearchBrandsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchBrandsV2Query, SearchBrandsV2QueryVariables>(SearchBrandsV2Document, options);
}
export function useSearchBrandsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBrandsV2Query, SearchBrandsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchBrandsV2Query, SearchBrandsV2QueryVariables>(SearchBrandsV2Document, options);
}
export type SearchBrandsV2QueryHookResult = ReturnType<typeof useSearchBrandsV2Query>;
export type SearchBrandsV2LazyQueryHookResult = ReturnType<typeof useSearchBrandsV2LazyQuery>;
export type SearchBrandsV2QueryResult = Apollo.QueryResult<SearchBrandsV2Query, SearchBrandsV2QueryVariables>;
export const CancelInquiryDocument = gql`
  mutation CancelInquiry($CancelInquiryInput: CancelInquiryInput!) {
    cancelInquiry(input: $CancelInquiryInput) {
      id
    }
  }
`;
export type CancelInquiryMutationFn = Apollo.MutationFunction<CancelInquiryMutation, CancelInquiryMutationVariables>;

/**
 * __useCancelInquiryMutation__
 *
 * To run a mutation, you first call `useCancelInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInquiryMutation, { data, loading, error }] = useCancelInquiryMutation({
 *   variables: {
 *      CancelInquiryInput: // value for 'CancelInquiryInput'
 *   },
 * });
 */
export function useCancelInquiryMutation(baseOptions?: Apollo.MutationHookOptions<CancelInquiryMutation, CancelInquiryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelInquiryMutation, CancelInquiryMutationVariables>(CancelInquiryDocument, options);
}
export type CancelInquiryMutationHookResult = ReturnType<typeof useCancelInquiryMutation>;
export type CancelInquiryMutationResult = Apollo.MutationResult<CancelInquiryMutation>;
export type CancelInquiryMutationOptions = Apollo.BaseMutationOptions<CancelInquiryMutation, CancelInquiryMutationVariables>;
export const CartDocument = gql`
  query Cart {
    cart {
      productNo
    }
  }
`;

/**
 * __useCartQuery__
 *
 * To run a query within a React component, call `useCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartQuery({
 *   variables: {
 *   },
 * });
 */
export function useCartQuery(baseOptions?: Apollo.QueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export function useCartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartQuery, CartQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CartQuery, CartQueryVariables>(CartDocument, options);
}
export type CartQueryHookResult = ReturnType<typeof useCartQuery>;
export type CartLazyQueryHookResult = ReturnType<typeof useCartLazyQuery>;
export type CartQueryResult = Apollo.QueryResult<CartQuery, CartQueryVariables>;
export const CategoriesV2Document = gql`
  query CategoriesV2($language: LANGUAGE_CODE!, $condition: CategoriesInput_Condition!, $sort: CategoriesInput_Sort!) {
    categoriesV2(language: $language, condition: $condition, sort: $sort) {
      id
      code
      name
      children {
        id
        code
        name
        children {
          id
          code
          name
        }
      }
    }
  }
`;

/**
 * __useCategoriesV2Query__
 *
 * To run a query within a React component, call `useCategoriesV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCategoriesV2Query(baseOptions: Apollo.QueryHookOptions<CategoriesV2Query, CategoriesV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoriesV2Query, CategoriesV2QueryVariables>(CategoriesV2Document, options);
}
export function useCategoriesV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesV2Query, CategoriesV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoriesV2Query, CategoriesV2QueryVariables>(CategoriesV2Document, options);
}
export type CategoriesV2QueryHookResult = ReturnType<typeof useCategoriesV2Query>;
export type CategoriesV2LazyQueryHookResult = ReturnType<typeof useCategoriesV2LazyQuery>;
export type CategoriesV2QueryResult = Apollo.QueryResult<CategoriesV2Query, CategoriesV2QueryVariables>;
export const CheckExistMemberDocument = gql`
  query CheckExistMember($accessToken: String!, $snsType: SnsTypeEnum!) {
    checkExistMember(accessToken: $accessToken, snsType: $snsType)
  }
`;

/**
 * __useCheckExistMemberQuery__
 *
 * To run a query within a React component, call `useCheckExistMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckExistMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckExistMemberQuery({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      snsType: // value for 'snsType'
 *   },
 * });
 */
export function useCheckExistMemberQuery(baseOptions: Apollo.QueryHookOptions<CheckExistMemberQuery, CheckExistMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckExistMemberQuery, CheckExistMemberQueryVariables>(CheckExistMemberDocument, options);
}
export function useCheckExistMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckExistMemberQuery, CheckExistMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckExistMemberQuery, CheckExistMemberQueryVariables>(CheckExistMemberDocument, options);
}
export type CheckExistMemberQueryHookResult = ReturnType<typeof useCheckExistMemberQuery>;
export type CheckExistMemberLazyQueryHookResult = ReturnType<typeof useCheckExistMemberLazyQuery>;
export type CheckExistMemberQueryResult = Apollo.QueryResult<CheckExistMemberQuery, CheckExistMemberQueryVariables>;
export const CheckIssuanceTempPasswordDocument = gql`
  query CheckIssuanceTempPassword($snsType: SnsTypeEnum!, $aconMemberId: Int!) {
    checkIssuanceTempPassword(snsType: $snsType, aconMemberId: $aconMemberId)
  }
`;

/**
 * __useCheckIssuanceTempPasswordQuery__
 *
 * To run a query within a React component, call `useCheckIssuanceTempPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIssuanceTempPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIssuanceTempPasswordQuery({
 *   variables: {
 *      snsType: // value for 'snsType'
 *      aconMemberId: // value for 'aconMemberId'
 *   },
 * });
 */
export function useCheckIssuanceTempPasswordQuery(baseOptions: Apollo.QueryHookOptions<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>(CheckIssuanceTempPasswordDocument, options);
}
export function useCheckIssuanceTempPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>(CheckIssuanceTempPasswordDocument, options);
}
export type CheckIssuanceTempPasswordQueryHookResult = ReturnType<typeof useCheckIssuanceTempPasswordQuery>;
export type CheckIssuanceTempPasswordLazyQueryHookResult = ReturnType<typeof useCheckIssuanceTempPasswordLazyQuery>;
export type CheckIssuanceTempPasswordQueryResult = Apollo.QueryResult<CheckIssuanceTempPasswordQuery, CheckIssuanceTempPasswordQueryVariables>;
export const GetClipsDocument = gql`
  query GetClips($language: LanguageCodeEnum!) {
    clips {
      id
      godoGoodsNo
      product(language: $language) {
        title
        priceInfo {
          salePrice
          price
        }
      }
    }
  }
`;

/**
 * __useGetClipsQuery__
 *
 * To run a query within a React component, call `useGetClipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClipsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClipsQuery(baseOptions: Apollo.QueryHookOptions<GetClipsQuery, GetClipsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClipsQuery, GetClipsQueryVariables>(GetClipsDocument, options);
}
export function useGetClipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClipsQuery, GetClipsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClipsQuery, GetClipsQueryVariables>(GetClipsDocument, options);
}
export type GetClipsQueryHookResult = ReturnType<typeof useGetClipsQuery>;
export type GetClipsLazyQueryHookResult = ReturnType<typeof useGetClipsLazyQuery>;
export type GetClipsQueryResult = Apollo.QueryResult<GetClipsQuery, GetClipsQueryVariables>;
export const GetClipsV2Document = gql`
  query GetClipsV2($sort: GetClipsSort!, $pagination: PaginationInput, $productsCondition: GetClipsProductsCondition, $language: LanguageCodeEnum!) {
    clipsV2(sort: $sort, pagination: $pagination, productsCondition: $productsCondition) {
      id
      godoGoodsNo
      product(language: $language) {
        bookInfo {
          isbn
        }
      }
    }
  }
`;

/**
 * __useGetClipsV2Query__
 *
 * To run a query within a React component, call `useGetClipsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetClipsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClipsV2Query({
 *   variables: {
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *      productsCondition: // value for 'productsCondition'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClipsV2Query(baseOptions: Apollo.QueryHookOptions<GetClipsV2Query, GetClipsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClipsV2Query, GetClipsV2QueryVariables>(GetClipsV2Document, options);
}
export function useGetClipsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClipsV2Query, GetClipsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClipsV2Query, GetClipsV2QueryVariables>(GetClipsV2Document, options);
}
export type GetClipsV2QueryHookResult = ReturnType<typeof useGetClipsV2Query>;
export type GetClipsV2LazyQueryHookResult = ReturnType<typeof useGetClipsV2LazyQuery>;
export type GetClipsV2QueryResult = Apollo.QueryResult<GetClipsV2Query, GetClipsV2QueryVariables>;
export const CreateInquiryDocument = gql`
  mutation CreateInquiry(
    $email: String
    $languageCode: LanguageCodeEnum!
    $type: InquiryTypeEnum!
    $subType: InquirySubTypeEnum
    $content: String
    $taxes: TaxesInput
    $changePenName: ChangePenNameInput
    $inquiryProduct: InquiryProductInput
    $inquiryOrders: [InquiryOrderInput]
    $refundAccount: RefundAccountInput
    $uploadFiles: [Int!]!
  ) {
    createInquiry(
      input: {
        email: $email
        languageCode: $languageCode
        type: $type
        subType: $subType
        content: $content
        taxes: $taxes
        changePenName: $changePenName
        inquiryProduct: $inquiryProduct
        inquiryOrders: $inquiryOrders
        refundAccount: $refundAccount
        uploadFiles: $uploadFiles
      }
    ) {
      id
    }
  }
`;
export type CreateInquiryMutationFn = Apollo.MutationFunction<CreateInquiryMutation, CreateInquiryMutationVariables>;

/**
 * __useCreateInquiryMutation__
 *
 * To run a mutation, you first call `useCreateInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInquiryMutation, { data, loading, error }] = useCreateInquiryMutation({
 *   variables: {
 *      email: // value for 'email'
 *      languageCode: // value for 'languageCode'
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      content: // value for 'content'
 *      taxes: // value for 'taxes'
 *      changePenName: // value for 'changePenName'
 *      inquiryProduct: // value for 'inquiryProduct'
 *      inquiryOrders: // value for 'inquiryOrders'
 *      refundAccount: // value for 'refundAccount'
 *      uploadFiles: // value for 'uploadFiles'
 *   },
 * });
 */
export function useCreateInquiryMutation(baseOptions?: Apollo.MutationHookOptions<CreateInquiryMutation, CreateInquiryMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInquiryMutation, CreateInquiryMutationVariables>(CreateInquiryDocument, options);
}
export type CreateInquiryMutationHookResult = ReturnType<typeof useCreateInquiryMutation>;
export type CreateInquiryMutationResult = Apollo.MutationResult<CreateInquiryMutation>;
export type CreateInquiryMutationOptions = Apollo.BaseMutationOptions<CreateInquiryMutation, CreateInquiryMutationVariables>;
export const DeleteCartCacheDocument = gql`
  mutation DeleteCartCache {
    deleteCartCache
  }
`;
export type DeleteCartCacheMutationFn = Apollo.MutationFunction<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>;

/**
 * __useDeleteCartCacheMutation__
 *
 * To run a mutation, you first call `useDeleteCartCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCartCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCartCacheMutation, { data, loading, error }] = useDeleteCartCacheMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteCartCacheMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>(DeleteCartCacheDocument, options);
}
export type DeleteCartCacheMutationHookResult = ReturnType<typeof useDeleteCartCacheMutation>;
export type DeleteCartCacheMutationResult = Apollo.MutationResult<DeleteCartCacheMutation>;
export type DeleteCartCacheMutationOptions = Apollo.BaseMutationOptions<DeleteCartCacheMutation, DeleteCartCacheMutationVariables>;
export const DeleteMyReviewDocument = gql`
  mutation DeleteMyReview($id: Int!, $languageCode: String!) {
    deleteMyReview(id: $id, languageCode: $languageCode)
  }
`;
export type DeleteMyReviewMutationFn = Apollo.MutationFunction<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>;

/**
 * __useDeleteMyReviewMutation__
 *
 * To run a mutation, you first call `useDeleteMyReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyReviewMutation, { data, loading, error }] = useDeleteMyReviewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useDeleteMyReviewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>(DeleteMyReviewDocument, options);
}
export type DeleteMyReviewMutationHookResult = ReturnType<typeof useDeleteMyReviewMutation>;
export type DeleteMyReviewMutationResult = Apollo.MutationResult<DeleteMyReviewMutation>;
export type DeleteMyReviewMutationOptions = Apollo.BaseMutationOptions<DeleteMyReviewMutation, DeleteMyReviewMutationVariables>;
export const DisplayAssetWithItemsDocument = gql`
  query DisplayAssetWithItems($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $type: String!) {
    displayAssetWithItems(language: $language, pagination: $pagination, type: $type) {
      assets {
        data {
          ...ProductsData
        }
        pagination {
          ...ProductsPagination
        }
      }
      displayInfo {
        title
      }
    }
  }
  ${ProductsDataFragmentDoc}
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __useDisplayAssetWithItemsQuery__
 *
 * To run a query within a React component, call `useDisplayAssetWithItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayAssetWithItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayAssetWithItemsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDisplayAssetWithItemsQuery(baseOptions: Apollo.QueryHookOptions<DisplayAssetWithItemsQuery, DisplayAssetWithItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisplayAssetWithItemsQuery, DisplayAssetWithItemsQueryVariables>(DisplayAssetWithItemsDocument, options);
}
export function useDisplayAssetWithItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayAssetWithItemsQuery, DisplayAssetWithItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisplayAssetWithItemsQuery, DisplayAssetWithItemsQueryVariables>(DisplayAssetWithItemsDocument, options);
}
export type DisplayAssetWithItemsQueryHookResult = ReturnType<typeof useDisplayAssetWithItemsQuery>;
export type DisplayAssetWithItemsLazyQueryHookResult = ReturnType<typeof useDisplayAssetWithItemsLazyQuery>;
export type DisplayAssetWithItemsQueryResult = Apollo.QueryResult<DisplayAssetWithItemsQuery, DisplayAssetWithItemsQueryVariables>;
export const DisplayBrandWithItemsDocument = gql`
  query DisplayBrandWithItems($language: LANGUAGE_CODE!, $type: String!, $pagination: PaginationInput!) {
    displayBrandWithItems(language: $language, type: $type, pagination: $pagination) {
      displayInfo {
        title
      }
      brands {
        data {
          id
          code
          name
          productCount
          photoInfo {
            path
          }
          update {
            type
            value
          }
          vipInfo
        }
        pagination {
          totalCount
        }
      }
    }
  }
`;

/**
 * __useDisplayBrandWithItemsQuery__
 *
 * To run a query within a React component, call `useDisplayBrandWithItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayBrandWithItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayBrandWithItemsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      type: // value for 'type'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDisplayBrandWithItemsQuery(baseOptions: Apollo.QueryHookOptions<DisplayBrandWithItemsQuery, DisplayBrandWithItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DisplayBrandWithItemsQuery, DisplayBrandWithItemsQueryVariables>(DisplayBrandWithItemsDocument, options);
}
export function useDisplayBrandWithItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayBrandWithItemsQuery, DisplayBrandWithItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DisplayBrandWithItemsQuery, DisplayBrandWithItemsQueryVariables>(DisplayBrandWithItemsDocument, options);
}
export type DisplayBrandWithItemsQueryHookResult = ReturnType<typeof useDisplayBrandWithItemsQuery>;
export type DisplayBrandWithItemsLazyQueryHookResult = ReturnType<typeof useDisplayBrandWithItemsLazyQuery>;
export type DisplayBrandWithItemsQueryResult = Apollo.QueryResult<DisplayBrandWithItemsQuery, DisplayBrandWithItemsQueryVariables>;
export const DocumentDocument = gql`
  query Document($documentId: String!) {
    document(id: $documentId) {
      id
      assetId
      brandId
      brandNames {
        language
        name
      }
      language
      status
      title
      contentHead
      contentBody
      price
      mainImage
      subImage
      fileName
      filePath
      categories {
        code
      }
      copyright {
        isOriginal
        warehouseSources
        commercialSources
        hasRealLogo
      }
      tags {
        id
        name
      }
    }
  }
`;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
}
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
}
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = Apollo.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const FaqAconTypesDocument = gql`
  query FaqAconTypes($lang: String!) {
    faqAconTypes(lang: $lang) {
      id
      code
      i18n {
        name
        language {
          code
        }
      }
    }
  }
`;

/**
 * __useFaqAconTypesQuery__
 *
 * To run a query within a React component, call `useFaqAconTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqAconTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqAconTypesQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useFaqAconTypesQuery(baseOptions: Apollo.QueryHookOptions<FaqAconTypesQuery, FaqAconTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FaqAconTypesQuery, FaqAconTypesQueryVariables>(FaqAconTypesDocument, options);
}
export function useFaqAconTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqAconTypesQuery, FaqAconTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FaqAconTypesQuery, FaqAconTypesQueryVariables>(FaqAconTypesDocument, options);
}
export type FaqAconTypesQueryHookResult = ReturnType<typeof useFaqAconTypesQuery>;
export type FaqAconTypesLazyQueryHookResult = ReturnType<typeof useFaqAconTypesLazyQuery>;
export type FaqAconTypesQueryResult = Apollo.QueryResult<FaqAconTypesQuery, FaqAconTypesQueryVariables>;
export const FaqAconsDocument = gql`
  query FaqAcons($lang: String, $typeId: Int) {
    faqAcons(lang: $lang, typeId: $typeId) {
      id
      title
      subtitle
      body
      type {
        id
        code
      }
      language {
        id
        code
      }
      order
      created
      updated
    }
  }
`;

/**
 * __useFaqAconsQuery__
 *
 * To run a query within a React component, call `useFaqAconsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqAconsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqAconsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useFaqAconsQuery(baseOptions?: Apollo.QueryHookOptions<FaqAconsQuery, FaqAconsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FaqAconsQuery, FaqAconsQueryVariables>(FaqAconsDocument, options);
}
export function useFaqAconsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FaqAconsQuery, FaqAconsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FaqAconsQuery, FaqAconsQueryVariables>(FaqAconsDocument, options);
}
export type FaqAconsQueryHookResult = ReturnType<typeof useFaqAconsQuery>;
export type FaqAconsLazyQueryHookResult = ReturnType<typeof useFaqAconsLazyQuery>;
export type FaqAconsQueryResult = Apollo.QueryResult<FaqAconsQuery, FaqAconsQueryVariables>;
export const FetchPromotionsDocument = gql`
  query FetchPromotions($search: FetchPromotionsSearchInput, $brandId: Int) {
    fetchPromotions(search: $search) {
      data {
        items(brandId: $brandId) {
          asset {
            id
          }
          suspendedAt
        }
        suspendedAt
        since
        until
      }
    }
  }
`;

/**
 * __useFetchPromotionsQuery__
 *
 * To run a query within a React component, call `useFetchPromotionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPromotionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPromotionsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useFetchPromotionsQuery(baseOptions?: Apollo.QueryHookOptions<FetchPromotionsQuery, FetchPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchPromotionsQuery, FetchPromotionsQueryVariables>(FetchPromotionsDocument, options);
}
export function useFetchPromotionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchPromotionsQuery, FetchPromotionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchPromotionsQuery, FetchPromotionsQueryVariables>(FetchPromotionsDocument, options);
}
export type FetchPromotionsQueryHookResult = ReturnType<typeof useFetchPromotionsQuery>;
export type FetchPromotionsLazyQueryHookResult = ReturnType<typeof useFetchPromotionsLazyQuery>;
export type FetchPromotionsQueryResult = Apollo.QueryResult<FetchPromotionsQuery, FetchPromotionsQueryVariables>;
export const IfPaidMemberDocument = gql`
  query IfPaidMember {
    ifPaidMember
  }
`;

/**
 * __useIfPaidMemberQuery__
 *
 * To run a query within a React component, call `useIfPaidMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useIfPaidMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIfPaidMemberQuery({
 *   variables: {
 *   },
 * });
 */
export function useIfPaidMemberQuery(baseOptions?: Apollo.QueryHookOptions<IfPaidMemberQuery, IfPaidMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IfPaidMemberQuery, IfPaidMemberQueryVariables>(IfPaidMemberDocument, options);
}
export function useIfPaidMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IfPaidMemberQuery, IfPaidMemberQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IfPaidMemberQuery, IfPaidMemberQueryVariables>(IfPaidMemberDocument, options);
}
export type IfPaidMemberQueryHookResult = ReturnType<typeof useIfPaidMemberQuery>;
export type IfPaidMemberLazyQueryHookResult = ReturnType<typeof useIfPaidMemberLazyQuery>;
export type IfPaidMemberQueryResult = Apollo.QueryResult<IfPaidMemberQuery, IfPaidMemberQueryVariables>;
export const IsParticipatedEventDocument = gql`
  query IsParticipatedEvent($eventId: Float!) {
    isParticipatedEvent(eventId: $eventId)
  }
`;

/**
 * __useIsParticipatedEventQuery__
 *
 * To run a query within a React component, call `useIsParticipatedEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsParticipatedEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsParticipatedEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useIsParticipatedEventQuery(baseOptions: Apollo.QueryHookOptions<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>(IsParticipatedEventDocument, options);
}
export function useIsParticipatedEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>(IsParticipatedEventDocument, options);
}
export type IsParticipatedEventQueryHookResult = ReturnType<typeof useIsParticipatedEventQuery>;
export type IsParticipatedEventLazyQueryHookResult = ReturnType<typeof useIsParticipatedEventLazyQuery>;
export type IsParticipatedEventQueryResult = Apollo.QueryResult<IsParticipatedEventQuery, IsParticipatedEventQueryVariables>;
export const IssueEventCouponDocument = gql`
  mutation IssueEventCoupon($issueEventCouponInput: IssueEventCouponInput!) {
    issueEventCoupon(issueEventCouponInput: $issueEventCouponInput)
  }
`;
export type IssueEventCouponMutationFn = Apollo.MutationFunction<IssueEventCouponMutation, IssueEventCouponMutationVariables>;

/**
 * __useIssueEventCouponMutation__
 *
 * To run a mutation, you first call `useIssueEventCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueEventCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueEventCouponMutation, { data, loading, error }] = useIssueEventCouponMutation({
 *   variables: {
 *      issueEventCouponInput: // value for 'issueEventCouponInput'
 *   },
 * });
 */
export function useIssueEventCouponMutation(baseOptions?: Apollo.MutationHookOptions<IssueEventCouponMutation, IssueEventCouponMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<IssueEventCouponMutation, IssueEventCouponMutationVariables>(IssueEventCouponDocument, options);
}
export type IssueEventCouponMutationHookResult = ReturnType<typeof useIssueEventCouponMutation>;
export type IssueEventCouponMutationResult = Apollo.MutationResult<IssueEventCouponMutation>;
export type IssueEventCouponMutationOptions = Apollo.BaseMutationOptions<IssueEventCouponMutation, IssueEventCouponMutationVariables>;
export const GetAconCashListDocument = gql`
  query getAconCashList($page: Int!, $type: String!, $startDate: String!, $endDate: String!) {
    getCashList(page: $page, type: $type, startDate: $startDate, endDate: $endDate) {
      totalCount
      items {
        deposit
        contents
        regDate
        afterDeposit
        payMethod
        status
        vBank {
          bankName
          bankHolder
          bankNumber
          remittanceDate
        }
      }
    }
  }
`;

/**
 * __useGetAconCashListQuery__
 *
 * To run a query within a React component, call `useGetAconCashListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAconCashListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAconCashListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      type: // value for 'type'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAconCashListQuery(baseOptions: Apollo.QueryHookOptions<GetAconCashListQuery, GetAconCashListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAconCashListQuery, GetAconCashListQueryVariables>(GetAconCashListDocument, options);
}
export function useGetAconCashListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAconCashListQuery, GetAconCashListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAconCashListQuery, GetAconCashListQueryVariables>(GetAconCashListDocument, options);
}
export type GetAconCashListQueryHookResult = ReturnType<typeof useGetAconCashListQuery>;
export type GetAconCashListLazyQueryHookResult = ReturnType<typeof useGetAconCashListLazyQuery>;
export type GetAconCashListQueryResult = Apollo.QueryResult<GetAconCashListQuery, GetAconCashListQueryVariables>;
export const GetApplicationsDocument = gql`
  query GetApplications {
    getApplications {
      id
      name
    }
  }
`;

/**
 * __useGetApplicationsQuery__
 *
 * To run a query within a React component, call `useGetApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(GetApplicationsDocument, options);
}
export function useGetApplicationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationsQuery, GetApplicationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationsQuery, GetApplicationsQueryVariables>(GetApplicationsDocument, options);
}
export type GetApplicationsQueryHookResult = ReturnType<typeof useGetApplicationsQuery>;
export type GetApplicationsLazyQueryHookResult = ReturnType<typeof useGetApplicationsLazyQuery>;
export type GetApplicationsQueryResult = Apollo.QueryResult<GetApplicationsQuery, GetApplicationsQueryVariables>;
export const SearchApplicationsV2Document = gql`
  query SearchApplicationsV2(
    $language: SearchProductsV2Input_Language!
    $pagination: SearchProductsV2Input_Pagination!
    $condition: SearchProductsV2Input_Condition!
    $sort: SearchProductsV2Input_Sort!
    $https: Boolean
  ) {
    searchApplicationsV2(language: $language, pagination: $pagination, condition: $condition, sort: $sort, https: $https) {
      data {
        id
        name
      }
      pagination {
        totalCount
      }
    }
  }
`;

/**
 * __useSearchApplicationsV2Query__
 *
 * To run a query within a React component, call `useSearchApplicationsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchApplicationsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchApplicationsV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *      https: // value for 'https'
 *   },
 * });
 */
export function useSearchApplicationsV2Query(baseOptions: Apollo.QueryHookOptions<SearchApplicationsV2Query, SearchApplicationsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchApplicationsV2Query, SearchApplicationsV2QueryVariables>(SearchApplicationsV2Document, options);
}
export function useSearchApplicationsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchApplicationsV2Query, SearchApplicationsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchApplicationsV2Query, SearchApplicationsV2QueryVariables>(SearchApplicationsV2Document, options);
}
export type SearchApplicationsV2QueryHookResult = ReturnType<typeof useSearchApplicationsV2Query>;
export type SearchApplicationsV2LazyQueryHookResult = ReturnType<typeof useSearchApplicationsV2LazyQuery>;
export type SearchApplicationsV2QueryResult = Apollo.QueryResult<SearchApplicationsV2Query, SearchApplicationsV2QueryVariables>;
export const BenefitProductsDocument = gql`
  query BenefitProducts($lang: String!, $productType: ProductType) {
    benefitProducts(lang: $lang, productType: $productType) {
      id
      extensions
      salePrice
      orderCount
      isAdultOnly
      applications {
        id
        name
      }
    }
  }
`;

/**
 * __useBenefitProductsQuery__
 *
 * To run a query within a React component, call `useBenefitProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenefitProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenefitProductsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      productType: // value for 'productType'
 *   },
 * });
 */
export function useBenefitProductsQuery(baseOptions: Apollo.QueryHookOptions<BenefitProductsQuery, BenefitProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BenefitProductsQuery, BenefitProductsQueryVariables>(BenefitProductsDocument, options);
}
export function useBenefitProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BenefitProductsQuery, BenefitProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BenefitProductsQuery, BenefitProductsQueryVariables>(BenefitProductsDocument, options);
}
export type BenefitProductsQueryHookResult = ReturnType<typeof useBenefitProductsQuery>;
export type BenefitProductsLazyQueryHookResult = ReturnType<typeof useBenefitProductsLazyQuery>;
export type BenefitProductsQueryResult = Apollo.QueryResult<BenefitProductsQuery, BenefitProductsQueryVariables>;
export const GetBrandDocument = gql`
  query GetBrand($lang: LanguageCodeEnum!, $brandCode: String!) {
    getBrand(lang: $lang, brandCode: $brandCode) {
      id
      name
      godoBrandCd
      useBrandPick
      brandPickIds
      brandPickCount
      photo {
        id
        azureStoragePath
        fileName
        size
        creator
        updater
      }
      introduction
      introductionLang
      showBrandReview
      modified
    }
  }
`;

/**
 * __useGetBrandQuery__
 *
 * To run a query within a React component, call `useGetBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetBrandQuery(baseOptions: Apollo.QueryHookOptions<GetBrandQuery, GetBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandQuery, GetBrandQueryVariables>(GetBrandDocument, options);
}
export function useGetBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandQuery, GetBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandQuery, GetBrandQueryVariables>(GetBrandDocument, options);
}
export type GetBrandQueryHookResult = ReturnType<typeof useGetBrandQuery>;
export type GetBrandLazyQueryHookResult = ReturnType<typeof useGetBrandLazyQuery>;
export type GetBrandQueryResult = Apollo.QueryResult<GetBrandQuery, GetBrandQueryVariables>;
export const GetCartsDocument = gql`
  query GetCarts($lang: String!, $noCache: Boolean) {
    carts(lang: $lang, noCache: $noCache) {
      sno
      goodsNo
      point
      title
      price
      realPrice
    }
  }
`;

/**
 * __useGetCartsQuery__
 *
 * To run a query within a React component, call `useGetCartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCartsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      noCache: // value for 'noCache'
 *   },
 * });
 */
export function useGetCartsQuery(baseOptions: Apollo.QueryHookOptions<GetCartsQuery, GetCartsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCartsQuery, GetCartsQueryVariables>(GetCartsDocument, options);
}
export function useGetCartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCartsQuery, GetCartsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCartsQuery, GetCartsQueryVariables>(GetCartsDocument, options);
}
export type GetCartsQueryHookResult = ReturnType<typeof useGetCartsQuery>;
export type GetCartsLazyQueryHookResult = ReturnType<typeof useGetCartsLazyQuery>;
export type GetCartsQueryResult = Apollo.QueryResult<GetCartsQuery, GetCartsQueryVariables>;
export const GetCategoriesV3Document = gql`
  query GetCategoriesV3($languageCode: LanguageCodeEnum!) {
    categoriesV3(languageCode: $languageCode) {
      type
      categories {
        primary {
          code
          name
        }
        secondaries {
          code
          name
        }
      }
    }
  }
`;

/**
 * __useGetCategoriesV3Query__
 *
 * To run a query within a React component, call `useGetCategoriesV3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesV3Query({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetCategoriesV3Query(baseOptions: Apollo.QueryHookOptions<GetCategoriesV3Query, GetCategoriesV3QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoriesV3Query, GetCategoriesV3QueryVariables>(GetCategoriesV3Document, options);
}
export function useGetCategoriesV3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesV3Query, GetCategoriesV3QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoriesV3Query, GetCategoriesV3QueryVariables>(GetCategoriesV3Document, options);
}
export type GetCategoriesV3QueryHookResult = ReturnType<typeof useGetCategoriesV3Query>;
export type GetCategoriesV3LazyQueryHookResult = ReturnType<typeof useGetCategoriesV3LazyQuery>;
export type GetCategoriesV3QueryResult = Apollo.QueryResult<GetCategoriesV3Query, GetCategoriesV3QueryVariables>;
export const GetCategoryDocument = gql`
  query GetCategory($godoCateCd: String!) {
    getCategory(godoCateCd: $godoCateCd) {
      type
      trans {
        lang
        visibleResultStats
        name
        banner {
          image
          anchor
          anchorTarget
          map
          color
        }
      }
    }
  }
`;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      godoCateCd: // value for 'godoCateCd'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
}
export function useGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, options);
}
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = Apollo.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>;
export const GetCouponListDocument = gql`
  query GetCouponList($lang: String!, $page: Int!, $startDate: String, $endDate: String, $state: String) {
    getCouponList(lang: $lang, page: $page, startDate: $startDate, endDate: $endDate, state: $state) {
      totalCount
      items {
        name
        couponKindType
        couponBenefit
        couponBenefitType
        couponMaxBenefit
        isCouponMaxBenefit
        couponApplyProductType
        couponApplyGoods {
          goodsNm
          goodsNo
        }
        couponApplyCategory {
          no
          name
        }
        couponMinOrderPrice
        couponMinOrderType
        isCouponApplyDuplicate
        memberCouponUsable
        startDate
        endDate
        regDt
      }
    }
  }
`;

/**
 * __useGetCouponListQuery__
 *
 * To run a query within a React component, call `useGetCouponListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponListQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      page: // value for 'page'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGetCouponListQuery(baseOptions: Apollo.QueryHookOptions<GetCouponListQuery, GetCouponListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCouponListQuery, GetCouponListQueryVariables>(GetCouponListDocument, options);
}
export function useGetCouponListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponListQuery, GetCouponListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCouponListQuery, GetCouponListQueryVariables>(GetCouponListDocument, options);
}
export type GetCouponListQueryHookResult = ReturnType<typeof useGetCouponListQuery>;
export type GetCouponListLazyQueryHookResult = ReturnType<typeof useGetCouponListLazyQuery>;
export type GetCouponListQueryResult = Apollo.QueryResult<GetCouponListQuery, GetCouponListQueryVariables>;
export const GetExchangeRateDocument = gql`
  query GetExchangeRate($languageCode: LanguageCodeEnum!) {
    exchangeRate(languageCode: $languageCode)
  }
`;

/**
 * __useGetExchangeRateQuery__
 *
 * To run a query within a React component, call `useGetExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExchangeRateQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<GetExchangeRateQuery, GetExchangeRateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExchangeRateQuery, GetExchangeRateQueryVariables>(GetExchangeRateDocument, options);
}
export function useGetExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExchangeRateQuery, GetExchangeRateQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExchangeRateQuery, GetExchangeRateQueryVariables>(GetExchangeRateDocument, options);
}
export type GetExchangeRateQueryHookResult = ReturnType<typeof useGetExchangeRateQuery>;
export type GetExchangeRateLazyQueryHookResult = ReturnType<typeof useGetExchangeRateLazyQuery>;
export type GetExchangeRateQueryResult = Apollo.QueryResult<GetExchangeRateQuery, GetExchangeRateQueryVariables>;
export const GetExtensionConfigsDocument = gql`
  query GetExtensionConfigs {
    getExtensionConfigs {
      id
      name
    }
  }
`;

/**
 * __useGetExtensionConfigsQuery__
 *
 * To run a query within a React component, call `useGetExtensionConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtensionConfigsQuery(baseOptions?: Apollo.QueryHookOptions<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>(GetExtensionConfigsDocument, options);
}
export function useGetExtensionConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>(GetExtensionConfigsDocument, options);
}
export type GetExtensionConfigsQueryHookResult = ReturnType<typeof useGetExtensionConfigsQuery>;
export type GetExtensionConfigsLazyQueryHookResult = ReturnType<typeof useGetExtensionConfigsLazyQuery>;
export type GetExtensionConfigsQueryResult = Apollo.QueryResult<GetExtensionConfigsQuery, GetExtensionConfigsQueryVariables>;
export const GetGodoBrandNameDocument = gql`
  query GetGodoBrandName($lang: String, $brandCode: String) {
    getGodoBrandName(brandCode: $brandCode, lang: $lang)
  }
`;

/**
 * __useGetGodoBrandNameQuery__
 *
 * To run a query within a React component, call `useGetGodoBrandNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGodoBrandNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGodoBrandNameQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useGetGodoBrandNameQuery(baseOptions?: Apollo.QueryHookOptions<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>(GetGodoBrandNameDocument, options);
}
export function useGetGodoBrandNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>(GetGodoBrandNameDocument, options);
}
export type GetGodoBrandNameQueryHookResult = ReturnType<typeof useGetGodoBrandNameQuery>;
export type GetGodoBrandNameLazyQueryHookResult = ReturnType<typeof useGetGodoBrandNameLazyQuery>;
export type GetGodoBrandNameQueryResult = Apollo.QueryResult<GetGodoBrandNameQuery, GetGodoBrandNameQueryVariables>;
export const GetGodoCategoriesDocument = gql`
  query GetGodoCategories($lang: String!, $cateCds: [String!]!) {
    getTransGodoCategoryItems(lang: $lang, cateCds: $cateCds) {
      name
      code
    }
  }
`;

/**
 * __useGetGodoCategoriesQuery__
 *
 * To run a query within a React component, call `useGetGodoCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGodoCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGodoCategoriesQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      cateCds: // value for 'cateCds'
 *   },
 * });
 */
export function useGetGodoCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>(GetGodoCategoriesDocument, options);
}
export function useGetGodoCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>(GetGodoCategoriesDocument, options);
}
export type GetGodoCategoriesQueryHookResult = ReturnType<typeof useGetGodoCategoriesQuery>;
export type GetGodoCategoriesLazyQueryHookResult = ReturnType<typeof useGetGodoCategoriesLazyQuery>;
export type GetGodoCategoriesQueryResult = Apollo.QueryResult<GetGodoCategoriesQuery, GetGodoCategoriesQueryVariables>;
export const GetGodoMyOrdersDocument = gql`
  query GetGodoMyOrders($limit: Int, $offset: Int) {
    godoMyOrders(limit: $limit, offset: $offset) {
      orderNo
      goodsNo
      regDt
      orderStatus
      isVirtualAccount
    }
  }
`;

/**
 * __useGetGodoMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetGodoMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGodoMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGodoMyOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetGodoMyOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>(GetGodoMyOrdersDocument, options);
}
export function useGetGodoMyOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>(GetGodoMyOrdersDocument, options);
}
export type GetGodoMyOrdersQueryHookResult = ReturnType<typeof useGetGodoMyOrdersQuery>;
export type GetGodoMyOrdersLazyQueryHookResult = ReturnType<typeof useGetGodoMyOrdersLazyQuery>;
export type GetGodoMyOrdersQueryResult = Apollo.QueryResult<GetGodoMyOrdersQuery, GetGodoMyOrdersQueryVariables>;
export const GetInquiriesDocument = gql`
  query GetInquiries($limit: Int, $offset: Int) {
    inquiries(limit: $limit, offset: $offset) {
      id
      type
      content
      status
      answer {
        answer
      }
      created
    }
  }
`;

/**
 * __useGetInquiriesQuery__
 *
 * To run a query within a React component, call `useGetInquiriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInquiriesQuery(baseOptions?: Apollo.QueryHookOptions<GetInquiriesQuery, GetInquiriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInquiriesQuery, GetInquiriesQueryVariables>(GetInquiriesDocument, options);
}
export function useGetInquiriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInquiriesQuery, GetInquiriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInquiriesQuery, GetInquiriesQueryVariables>(GetInquiriesDocument, options);
}
export type GetInquiriesQueryHookResult = ReturnType<typeof useGetInquiriesQuery>;
export type GetInquiriesLazyQueryHookResult = ReturnType<typeof useGetInquiriesLazyQuery>;
export type GetInquiriesQueryResult = Apollo.QueryResult<GetInquiriesQuery, GetInquiriesQueryVariables>;
export const GetInquiryDocument = gql`
  query GetInquiry($id: Int!) {
    inquiry(id: $id) {
      id
      godoMemNo
      godoUserId
      languageCode
      type
      status
      inquiryAcon3dProduct {
        id
        title
      }
      inquiryOrders {
        godoOrderNo
        godoGoodsDetails {
          godoGoodsNo
          orderStatus
        }
      }
      subType
      content
      answer {
        answer
        created
      }
      uploadFiles {
        azureStoragePath
        fileName
      }
      created
      updated
    }
  }
`;

/**
 * __useGetInquiryQuery__
 *
 * To run a query within a React component, call `useGetInquiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInquiryQuery(baseOptions: Apollo.QueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
}
export function useGetInquiryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInquiryQuery, GetInquiryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInquiryQuery, GetInquiryQueryVariables>(GetInquiryDocument, options);
}
export type GetInquiryQueryHookResult = ReturnType<typeof useGetInquiryQuery>;
export type GetInquiryLazyQueryHookResult = ReturnType<typeof useGetInquiryLazyQuery>;
export type GetInquiryQueryResult = Apollo.QueryResult<GetInquiryQuery, GetInquiryQueryVariables>;
export const GetInquiryListDocument = gql`
  query GetInquiryList($type: InquiryTypeEnum, $limit: Int, $offset: Int) {
    inquiries(type: $type, limit: $limit, offset: $offset) {
      id
      type
      content
      status
      answer {
        answer
      }
      created
    }
    inquiryCount(type: $type)
  }
`;

/**
 * __useGetInquiryListQuery__
 *
 * To run a query within a React component, call `useGetInquiryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInquiryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInquiryListQuery({
 *   variables: {
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetInquiryListQuery(baseOptions?: Apollo.QueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
}
export function useGetInquiryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInquiryListQuery, GetInquiryListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInquiryListQuery, GetInquiryListQueryVariables>(GetInquiryListDocument, options);
}
export type GetInquiryListQueryHookResult = ReturnType<typeof useGetInquiryListQuery>;
export type GetInquiryListLazyQueryHookResult = ReturnType<typeof useGetInquiryListLazyQuery>;
export type GetInquiryListQueryResult = Apollo.QueryResult<GetInquiryListQuery, GetInquiryListQueryVariables>;
export const GetIsFirstDownloadDocument = gql`
  query GetIsFirstDownload($goodsNo: Int!, $orderNo: String!) {
    isFirstDownload(goodsNo: $goodsNo, orderNo: $orderNo)
  }
`;

/**
 * __useGetIsFirstDownloadQuery__
 *
 * To run a query within a React component, call `useGetIsFirstDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIsFirstDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIsFirstDownloadQuery({
 *   variables: {
 *      goodsNo: // value for 'goodsNo'
 *      orderNo: // value for 'orderNo'
 *   },
 * });
 */
export function useGetIsFirstDownloadQuery(baseOptions: Apollo.QueryHookOptions<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>(GetIsFirstDownloadDocument, options);
}
export function useGetIsFirstDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>(GetIsFirstDownloadDocument, options);
}
export type GetIsFirstDownloadQueryHookResult = ReturnType<typeof useGetIsFirstDownloadQuery>;
export type GetIsFirstDownloadLazyQueryHookResult = ReturnType<typeof useGetIsFirstDownloadLazyQuery>;
export type GetIsFirstDownloadQueryResult = Apollo.QueryResult<GetIsFirstDownloadQuery, GetIsFirstDownloadQueryVariables>;
export const GetMyOrdersDocument = gql`
  query GetMyOrders($languageCode: LanguageCodeEnum!) {
    getMyOrders(languageCode: $languageCode) {
      godoOrderNo
      godoGoodsNo
      price
      orderGoodsStatus
      regDt
      product {
        title
        mainImageUrl
        subImageUrl
        extensions
        salePrice
        price
        book {
          isbn
        }
        brand {
          code
          name
        }
        funding {
          ...funding
        }
      }
      reviewId
      downloadCountInfo {
        limit
        remained
      }
    }
  }
  ${FundingFragmentDoc}
`;

/**
 * __useGetMyOrdersQuery__
 *
 * To run a query within a React component, call `useGetMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOrdersQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useGetMyOrdersQuery(baseOptions: Apollo.QueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export function useGetMyOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyOrdersQuery, GetMyOrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOrdersQuery, GetMyOrdersQueryVariables>(GetMyOrdersDocument, options);
}
export type GetMyOrdersQueryHookResult = ReturnType<typeof useGetMyOrdersQuery>;
export type GetMyOrdersLazyQueryHookResult = ReturnType<typeof useGetMyOrdersLazyQuery>;
export type GetMyOrdersQueryResult = Apollo.QueryResult<GetMyOrdersQuery, GetMyOrdersQueryVariables>;
export const GetMypageUserDataDocument = gql`
  query GetMypageUserData {
    hasNewPoint
    hasNewCoupon
    inquiryCount
  }
`;

/**
 * __useGetMypageUserDataQuery__
 *
 * To run a query within a React component, call `useGetMypageUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMypageUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMypageUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMypageUserDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>(GetMypageUserDataDocument, options);
}
export function useGetMypageUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>(GetMypageUserDataDocument, options);
}
export type GetMypageUserDataQueryHookResult = ReturnType<typeof useGetMypageUserDataQuery>;
export type GetMypageUserDataLazyQueryHookResult = ReturnType<typeof useGetMypageUserDataLazyQuery>;
export type GetMypageUserDataQueryResult = Apollo.QueryResult<GetMypageUserDataQuery, GetMypageUserDataQueryVariables>;
export const GetOrderBenefitPointDocument = gql`
  query GetOrderBenefitPoint($godoOrderNo: String!) {
    getOrderBenefitPoint(godoOrderNo: $godoOrderNo)
  }
`;

/**
 * __useGetOrderBenefitPointQuery__
 *
 * To run a query within a React component, call `useGetOrderBenefitPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderBenefitPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderBenefitPointQuery({
 *   variables: {
 *      godoOrderNo: // value for 'godoOrderNo'
 *   },
 * });
 */
export function useGetOrderBenefitPointQuery(baseOptions: Apollo.QueryHookOptions<GetOrderBenefitPointQuery, GetOrderBenefitPointQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderBenefitPointQuery, GetOrderBenefitPointQueryVariables>(GetOrderBenefitPointDocument, options);
}
export function useGetOrderBenefitPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderBenefitPointQuery, GetOrderBenefitPointQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderBenefitPointQuery, GetOrderBenefitPointQueryVariables>(GetOrderBenefitPointDocument, options);
}
export type GetOrderBenefitPointQueryHookResult = ReturnType<typeof useGetOrderBenefitPointQuery>;
export type GetOrderBenefitPointLazyQueryHookResult = ReturnType<typeof useGetOrderBenefitPointLazyQuery>;
export type GetOrderBenefitPointQueryResult = Apollo.QueryResult<GetOrderBenefitPointQuery, GetOrderBenefitPointQueryVariables>;
export const GetOrderNoWithoutReviewDocument = gql`
  query GetOrderNoWithoutReview($godoGoodsNo: String!) {
    getOrderNoWithoutReview(godoGoodsNo: $godoGoodsNo)
  }
`;

/**
 * __useGetOrderNoWithoutReviewQuery__
 *
 * To run a query within a React component, call `useGetOrderNoWithoutReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderNoWithoutReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderNoWithoutReviewQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *   },
 * });
 */
export function useGetOrderNoWithoutReviewQuery(baseOptions: Apollo.QueryHookOptions<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>(GetOrderNoWithoutReviewDocument, options);
}
export function useGetOrderNoWithoutReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>(GetOrderNoWithoutReviewDocument, options);
}
export type GetOrderNoWithoutReviewQueryHookResult = ReturnType<typeof useGetOrderNoWithoutReviewQuery>;
export type GetOrderNoWithoutReviewLazyQueryHookResult = ReturnType<typeof useGetOrderNoWithoutReviewLazyQuery>;
export type GetOrderNoWithoutReviewQueryResult = Apollo.QueryResult<GetOrderNoWithoutReviewQuery, GetOrderNoWithoutReviewQueryVariables>;
export const GetOrderViewV2Document = gql`
  query GetOrderViewV2($lang: String!, $orderNo: Float!) {
    orderViewV2(lang: $lang, orderNo: $orderNo) {
      receipt {
        cashFl
        limitDateFl
        particularFl
        periodDay
        periodFl
        taxFl
        taxInvoiceLimitDate
      }
      user {
        cellPhone
        email
        name
      }
      license {
        orderNo
        type
        names {
          name
        }
        projects {
          name
          goods {
            goodsNo
          }
        }
      }
      orderProducts {
        goodsNo
        price
        salePrice
        status
        statusStr
        imageUrl
        product {
          title
          brand {
            id
            name
            code
          }
          extensions
          mainImageUrl
          subImageUrl
        }
      }
      order {
        id
        status
        payment {
          payMethod
          receiptUrl
        }
      }
      orderDate
      settleReceipt
      settleName
      settleMethod
      status
      bank
      bankAccount
      bankSender
      bankHolder
      bankHolder
      pgFailReason
      settlePrice
      totalGoodsDcPrice
      totalCouponDeliveryDcPrice
      totalGoodsPrice
      totalCouponGoodsDcPrice
      totalCouponOrderDcPrice
      useDeposit
      useMileage
      receiptFl
      pgSettleCd
      pgSettleNm
      settleKind
      cash
      tax
    }
  }
`;

/**
 * __useGetOrderViewV2Query__
 *
 * To run a query within a React component, call `useGetOrderViewV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderViewV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderViewV2Query({
 *   variables: {
 *      lang: // value for 'lang'
 *      orderNo: // value for 'orderNo'
 *   },
 * });
 */
export function useGetOrderViewV2Query(baseOptions: Apollo.QueryHookOptions<GetOrderViewV2Query, GetOrderViewV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderViewV2Query, GetOrderViewV2QueryVariables>(GetOrderViewV2Document, options);
}
export function useGetOrderViewV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderViewV2Query, GetOrderViewV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderViewV2Query, GetOrderViewV2QueryVariables>(GetOrderViewV2Document, options);
}
export type GetOrderViewV2QueryHookResult = ReturnType<typeof useGetOrderViewV2Query>;
export type GetOrderViewV2LazyQueryHookResult = ReturnType<typeof useGetOrderViewV2LazyQuery>;
export type GetOrderViewV2QueryResult = Apollo.QueryResult<GetOrderViewV2Query, GetOrderViewV2QueryVariables>;
export const GetOtherProductListDocument = gql`
  query GetOtherProductList($godoGoodsNo: Int!, $lang: String!, $isCount: Boolean!) {
    otherProductList(godoGoodsNo: $godoGoodsNo, lang: $lang, isCount: $isCount) {
      brandCd
      cateCd
      items {
        godoGoodsNo
        realPrice
        price
        imageUrl
        title
        brand
        brandCd
      }
      count
    }
  }
`;

/**
 * __useGetOtherProductListQuery__
 *
 * To run a query within a React component, call `useGetOtherProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherProductListQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *      lang: // value for 'lang'
 *      isCount: // value for 'isCount'
 *   },
 * });
 */
export function useGetOtherProductListQuery(baseOptions: Apollo.QueryHookOptions<GetOtherProductListQuery, GetOtherProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOtherProductListQuery, GetOtherProductListQueryVariables>(GetOtherProductListDocument, options);
}
export function useGetOtherProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherProductListQuery, GetOtherProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOtherProductListQuery, GetOtherProductListQueryVariables>(GetOtherProductListDocument, options);
}
export type GetOtherProductListQueryHookResult = ReturnType<typeof useGetOtherProductListQuery>;
export type GetOtherProductListLazyQueryHookResult = ReturnType<typeof useGetOtherProductListLazyQuery>;
export type GetOtherProductListQueryResult = Apollo.QueryResult<GetOtherProductListQuery, GetOtherProductListQueryVariables>;
export const PhotoReviewDocument = gql`
  query PhotoReview($id: Int, $isBestReview: Boolean, $languageCode: LanguageCodeEnum!, $godoCateCd: Int) {
    getPhotoReview(id: $id, isBestReview: $isBestReview, godoCateCd: $godoCateCd) {
      nextCursor
      prevCursor
      nth
      node {
        imageUrl
        rating
        product(languageCode: $languageCode) {
          id
          title
          brand {
            code
            name
          }
        }
        godoUserId
        reviewContentsItems {
          isOriginal
          languageCode
          title
          contents
        }
        created
      }
    }
  }
`;

/**
 * __usePhotoReviewQuery__
 *
 * To run a query within a React component, call `usePhotoReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isBestReview: // value for 'isBestReview'
 *      languageCode: // value for 'languageCode'
 *      godoCateCd: // value for 'godoCateCd'
 *   },
 * });
 */
export function usePhotoReviewQuery(baseOptions: Apollo.QueryHookOptions<PhotoReviewQuery, PhotoReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhotoReviewQuery, PhotoReviewQueryVariables>(PhotoReviewDocument, options);
}
export function usePhotoReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhotoReviewQuery, PhotoReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhotoReviewQuery, PhotoReviewQueryVariables>(PhotoReviewDocument, options);
}
export type PhotoReviewQueryHookResult = ReturnType<typeof usePhotoReviewQuery>;
export type PhotoReviewLazyQueryHookResult = ReturnType<typeof usePhotoReviewLazyQuery>;
export type PhotoReviewQueryResult = Apollo.QueryResult<PhotoReviewQuery, PhotoReviewQueryVariables>;
export const GetPhotoReviewTotalCountDocument = gql`
  query GetPhotoReviewTotalCount($isBestReview: Boolean, $godoCateCd: Int) {
    getPhotoReviewTotalCount(isBestReview: $isBestReview, godoCateCd: $godoCateCd)
  }
`;

/**
 * __useGetPhotoReviewTotalCountQuery__
 *
 * To run a query within a React component, call `useGetPhotoReviewTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotoReviewTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotoReviewTotalCountQuery({
 *   variables: {
 *      isBestReview: // value for 'isBestReview'
 *      godoCateCd: // value for 'godoCateCd'
 *   },
 * });
 */
export function useGetPhotoReviewTotalCountQuery(baseOptions?: Apollo.QueryHookOptions<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>(GetPhotoReviewTotalCountDocument, options);
}
export function useGetPhotoReviewTotalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>(GetPhotoReviewTotalCountDocument, options);
}
export type GetPhotoReviewTotalCountQueryHookResult = ReturnType<typeof useGetPhotoReviewTotalCountQuery>;
export type GetPhotoReviewTotalCountLazyQueryHookResult = ReturnType<typeof useGetPhotoReviewTotalCountLazyQuery>;
export type GetPhotoReviewTotalCountQueryResult = Apollo.QueryResult<GetPhotoReviewTotalCountQuery, GetPhotoReviewTotalCountQueryVariables>;
export const PhotoReviewsDocument = gql`
  query PhotoReviews($isBestReview: Boolean, $take: Int, $cursor: Int, $godoCateCd: Int, $languageCode: String) {
    getPhotoReviews(isBestReview: $isBestReview, take: $take, cursor: $cursor, godoCateCd: $godoCateCd) {
      nodes {
        id
        godoUserId
        rating
        reviewContentsItems(languageCode: $languageCode) {
          title
          contents
          isOriginal
          languageCode
        }
        imageUrl
        created
      }
      edge {
        cursor
        node {
          id
        }
      }
    }
  }
`;

/**
 * __usePhotoReviewsQuery__
 *
 * To run a query within a React component, call `usePhotoReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePhotoReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePhotoReviewsQuery({
 *   variables: {
 *      isBestReview: // value for 'isBestReview'
 *      take: // value for 'take'
 *      cursor: // value for 'cursor'
 *      godoCateCd: // value for 'godoCateCd'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function usePhotoReviewsQuery(baseOptions?: Apollo.QueryHookOptions<PhotoReviewsQuery, PhotoReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PhotoReviewsQuery, PhotoReviewsQueryVariables>(PhotoReviewsDocument, options);
}
export function usePhotoReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PhotoReviewsQuery, PhotoReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PhotoReviewsQuery, PhotoReviewsQueryVariables>(PhotoReviewsDocument, options);
}
export type PhotoReviewsQueryHookResult = ReturnType<typeof usePhotoReviewsQuery>;
export type PhotoReviewsLazyQueryHookResult = ReturnType<typeof usePhotoReviewsLazyQuery>;
export type PhotoReviewsQueryResult = Apollo.QueryResult<PhotoReviewsQuery, PhotoReviewsQueryVariables>;
export const TempReviewsDocument = gql`
  query TempReviews($pagination: ReviewsInput_Pagination!, $condition: ReviewsInput_Condition!, $sort: ReviewsInput_Sort!, $languageCode: String) {
    tempReviews(pagination: $pagination, condition: $condition, sort: $sort) {
      nodes {
        id
        godoUserId
        rating
        reviewContentsItems(languageCode: $languageCode) {
          title
          contents
          isOriginal
          languageCode
        }
        imageUrl
        created
      }
      edge {
        cursor
        node {
          id
        }
      }
      total
    }
  }
`;

/**
 * __useTempReviewsQuery__
 *
 * To run a query within a React component, call `useTempReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTempReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTempReviewsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useTempReviewsQuery(baseOptions: Apollo.QueryHookOptions<TempReviewsQuery, TempReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TempReviewsQuery, TempReviewsQueryVariables>(TempReviewsDocument, options);
}
export function useTempReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TempReviewsQuery, TempReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TempReviewsQuery, TempReviewsQueryVariables>(TempReviewsDocument, options);
}
export type TempReviewsQueryHookResult = ReturnType<typeof useTempReviewsQuery>;
export type TempReviewsLazyQueryHookResult = ReturnType<typeof useTempReviewsLazyQuery>;
export type TempReviewsQueryResult = Apollo.QueryResult<TempReviewsQuery, TempReviewsQueryVariables>;
export const GetPopupsHereDocument = gql`
  query GetPopupsHere($url: String!, $origin: String!) {
    getPopupsHere(url: $url, origin: $origin) {
      id
      title
      image {
        azureStoragePath
      }
      text
      subText
      buttonText
      connectedUrl
    }
  }
`;

/**
 * __useGetPopupsHereQuery__
 *
 * To run a query within a React component, call `useGetPopupsHereQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopupsHereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopupsHereQuery({
 *   variables: {
 *      url: // value for 'url'
 *      origin: // value for 'origin'
 *   },
 * });
 */
export function useGetPopupsHereQuery(baseOptions: Apollo.QueryHookOptions<GetPopupsHereQuery, GetPopupsHereQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPopupsHereQuery, GetPopupsHereQueryVariables>(GetPopupsHereDocument, options);
}
export function useGetPopupsHereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopupsHereQuery, GetPopupsHereQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPopupsHereQuery, GetPopupsHereQueryVariables>(GetPopupsHereDocument, options);
}
export type GetPopupsHereQueryHookResult = ReturnType<typeof useGetPopupsHereQuery>;
export type GetPopupsHereLazyQueryHookResult = ReturnType<typeof useGetPopupsHereLazyQuery>;
export type GetPopupsHereQueryResult = Apollo.QueryResult<GetPopupsHereQuery, GetPopupsHereQueryVariables>;
export const GetPromotedProductsDocument = gql`
  query GetPromotedProducts($code: String!, $take: Int!, $lang: LanguageCodeEnum!) {
    getPromotedProducts(code: $code, take: $take, lang: $lang) {
      id
      languageCode
      isExclusive
      promotion {
        id
        concept {
          title
          code
          id
          titleRegional {
            KO
            EN
            JA
            ZH
          }
        }
        items {
          until
          since
          discountValue
          discountType
        }
        title
        discountType
        discountValue
        since
        until
        status
      }
      title
      price
      salePrice
      mainImageUrl
      brand {
        id
        name
        code
      }
      subImageUrl
      orderCount
      isAconOnly
      viewCount
      created
      reviewCount
      updated
      extensions
      badgeNames
      applications {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPromotedProductsQuery__
 *
 * To run a query within a React component, call `useGetPromotedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotedProductsQuery({
 *   variables: {
 *      code: // value for 'code'
 *      take: // value for 'take'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetPromotedProductsQuery(baseOptions: Apollo.QueryHookOptions<GetPromotedProductsQuery, GetPromotedProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromotedProductsQuery, GetPromotedProductsQueryVariables>(GetPromotedProductsDocument, options);
}
export function useGetPromotedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotedProductsQuery, GetPromotedProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromotedProductsQuery, GetPromotedProductsQueryVariables>(GetPromotedProductsDocument, options);
}
export type GetPromotedProductsQueryHookResult = ReturnType<typeof useGetPromotedProductsQuery>;
export type GetPromotedProductsLazyQueryHookResult = ReturnType<typeof useGetPromotedProductsLazyQuery>;
export type GetPromotedProductsQueryResult = Apollo.QueryResult<GetPromotedProductsQuery, GetPromotedProductsQueryVariables>;
export const GetPromotionProductsDocument = gql`
  query GetPromotionProducts($code: String!, $take: Int!, $lang: LanguageCodeEnum!) {
    getPromotionProducts(code: $code, take: $take, lang: $lang) {
      promotion {
        id
        concept {
          title
          code
          id
          titleRegional {
            KO
            EN
            JA
            ZH
          }
        }
        items {
          until
          since
          discountValue
          discountType
        }
        title
        discountType
        discountValue
        since
        until
        status
      }
      product {
        ...ProductsData
      }
    }
  }
  ${ProductsDataFragmentDoc}
`;

/**
 * __useGetPromotionProductsQuery__
 *
 * To run a query within a React component, call `useGetPromotionProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPromotionProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPromotionProductsQuery({
 *   variables: {
 *      code: // value for 'code'
 *      take: // value for 'take'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetPromotionProductsQuery(baseOptions: Apollo.QueryHookOptions<GetPromotionProductsQuery, GetPromotionProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPromotionProductsQuery, GetPromotionProductsQueryVariables>(GetPromotionProductsDocument, options);
}
export function useGetPromotionProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPromotionProductsQuery, GetPromotionProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPromotionProductsQuery, GetPromotionProductsQueryVariables>(GetPromotionProductsDocument, options);
}
export type GetPromotionProductsQueryHookResult = ReturnType<typeof useGetPromotionProductsQuery>;
export type GetPromotionProductsLazyQueryHookResult = ReturnType<typeof useGetPromotionProductsLazyQuery>;
export type GetPromotionProductsQueryResult = Apollo.QueryResult<GetPromotionProductsQuery, GetPromotionProductsQueryVariables>;
export const GetRecentOrdersV2Document = gql`
  query GetRecentOrdersV2($langCode: String!) {
    recentOrdersV2 {
      orderNo
      orderedAt
      orderedProducts {
        sno
        title
        godoGoodsNo
        price
        status
        statusString
        hasWrittenReview
        reviewSno
        reviewId
        product(langCode: $langCode) {
          title
          price
          salePrice
          extensions
          brand {
            code
            name
          }
          book {
            isbn
          }
          mainImageUrl
          subImageUrl
        }
      }
    }
  }
`;

/**
 * __useGetRecentOrdersV2Query__
 *
 * To run a query within a React component, call `useGetRecentOrdersV2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentOrdersV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentOrdersV2Query({
 *   variables: {
 *      langCode: // value for 'langCode'
 *   },
 * });
 */
export function useGetRecentOrdersV2Query(baseOptions: Apollo.QueryHookOptions<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>(GetRecentOrdersV2Document, options);
}
export function useGetRecentOrdersV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>(GetRecentOrdersV2Document, options);
}
export type GetRecentOrdersV2QueryHookResult = ReturnType<typeof useGetRecentOrdersV2Query>;
export type GetRecentOrdersV2LazyQueryHookResult = ReturnType<typeof useGetRecentOrdersV2LazyQuery>;
export type GetRecentOrdersV2QueryResult = Apollo.QueryResult<GetRecentOrdersV2Query, GetRecentOrdersV2QueryVariables>;
export const GetSimilarProductListDocument = gql`
  query GetSimilarProductList($godoGoodsNo: Int!, $lang: String!, $isCount: Boolean!) {
    similarProductList(godoGoodsNo: $godoGoodsNo, lang: $lang, isCount: $isCount) {
      cateCd
      items {
        godoGoodsNo
        title
        realPrice
        price
        imageUrl
        brand
        brandCd
      }
      count
    }
  }
`;

/**
 * __useGetSimilarProductListQuery__
 *
 * To run a query within a React component, call `useGetSimilarProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimilarProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimilarProductListQuery({
 *   variables: {
 *      godoGoodsNo: // value for 'godoGoodsNo'
 *      lang: // value for 'lang'
 *      isCount: // value for 'isCount'
 *   },
 * });
 */
export function useGetSimilarProductListQuery(baseOptions: Apollo.QueryHookOptions<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>(GetSimilarProductListDocument, options);
}
export function useGetSimilarProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>(GetSimilarProductListDocument, options);
}
export type GetSimilarProductListQueryHookResult = ReturnType<typeof useGetSimilarProductListQuery>;
export type GetSimilarProductListLazyQueryHookResult = ReturnType<typeof useGetSimilarProductListLazyQuery>;
export type GetSimilarProductListQueryResult = Apollo.QueryResult<GetSimilarProductListQuery, GetSimilarProductListQueryVariables>;
export const GetTransGodoCategoryChildItemsDocument = gql`
  query GetTransGodoCategoryChildItems($lang: String!, $cateCds: [String!]!, $type: String) {
    getTransGodoCategoryChildItems(lang: $lang, cateCds: $cateCds, type: $type) {
      name
      code
      isLink
      isVisible
    }
  }
`;

/**
 * __useGetTransGodoCategoryChildItemsQuery__
 *
 * To run a query within a React component, call `useGetTransGodoCategoryChildItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransGodoCategoryChildItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransGodoCategoryChildItemsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      cateCds: // value for 'cateCds'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetTransGodoCategoryChildItemsQuery(baseOptions: Apollo.QueryHookOptions<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>(GetTransGodoCategoryChildItemsDocument, options);
}
export function useGetTransGodoCategoryChildItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>(GetTransGodoCategoryChildItemsDocument, options);
}
export type GetTransGodoCategoryChildItemsQueryHookResult = ReturnType<typeof useGetTransGodoCategoryChildItemsQuery>;
export type GetTransGodoCategoryChildItemsLazyQueryHookResult = ReturnType<typeof useGetTransGodoCategoryChildItemsLazyQuery>;
export type GetTransGodoCategoryChildItemsQueryResult = Apollo.QueryResult<GetTransGodoCategoryChildItemsQuery, GetTransGodoCategoryChildItemsQueryVariables>;
export const GiftsDocument = gql`
  query Gifts {
    gifts {
      id
      price
      isOpen
    }
  }
`;

/**
 * __useGiftsQuery__
 *
 * To run a query within a React component, call `useGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGiftsQuery(baseOptions?: Apollo.QueryHookOptions<GiftsQuery, GiftsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GiftsQuery, GiftsQueryVariables>(GiftsDocument, options);
}
export function useGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftsQuery, GiftsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GiftsQuery, GiftsQueryVariables>(GiftsDocument, options);
}
export type GiftsQueryHookResult = ReturnType<typeof useGiftsQuery>;
export type GiftsLazyQueryHookResult = ReturnType<typeof useGiftsLazyQuery>;
export type GiftsQueryResult = Apollo.QueryResult<GiftsQuery, GiftsQueryVariables>;
export const ReceivedGiftsDocument = gql`
  query ReceivedGifts {
    receivedGifts {
      id
      gift {
        title
      }
      redemptionDate
      memberGiftHistories {
        created
        giftResidualAmount
        totalResidualAmount
      }
      created
    }
  }
`;

/**
 * __useReceivedGiftsQuery__
 *
 * To run a query within a React component, call `useReceivedGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedGiftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReceivedGiftsQuery(baseOptions?: Apollo.QueryHookOptions<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>(ReceivedGiftsDocument, options);
}
export function useReceivedGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>(ReceivedGiftsDocument, options);
}
export type ReceivedGiftsQueryHookResult = ReturnType<typeof useReceivedGiftsQuery>;
export type ReceivedGiftsLazyQueryHookResult = ReturnType<typeof useReceivedGiftsLazyQuery>;
export type ReceivedGiftsQueryResult = Apollo.QueryResult<ReceivedGiftsQuery, ReceivedGiftsQueryVariables>;
export const ReceivedGiftDocument = gql`
  query ReceivedGift($orderGiftId: String!) {
    receivedGift(orderGiftId: $orderGiftId) {
      id
      gift {
        title
      }
      redemptionDate
      expirationDate
      status
      memberGiftHistories {
        changeAmount
        created
        giftResidualAmount
        totalResidualAmount
        orderProduct {
          godoOrderId
        }
      }
    }
  }
`;

/**
 * __useReceivedGiftQuery__
 *
 * To run a query within a React component, call `useReceivedGiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useReceivedGiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReceivedGiftQuery({
 *   variables: {
 *      orderGiftId: // value for 'orderGiftId'
 *   },
 * });
 */
export function useReceivedGiftQuery(baseOptions: Apollo.QueryHookOptions<ReceivedGiftQuery, ReceivedGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReceivedGiftQuery, ReceivedGiftQueryVariables>(ReceivedGiftDocument, options);
}
export function useReceivedGiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReceivedGiftQuery, ReceivedGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReceivedGiftQuery, ReceivedGiftQueryVariables>(ReceivedGiftDocument, options);
}
export type ReceivedGiftQueryHookResult = ReturnType<typeof useReceivedGiftQuery>;
export type ReceivedGiftLazyQueryHookResult = ReturnType<typeof useReceivedGiftLazyQuery>;
export type ReceivedGiftQueryResult = Apollo.QueryResult<ReceivedGiftQuery, ReceivedGiftQueryVariables>;
export const SentGiftsDocument = gql`
  query SentGifts {
    sentGifts {
      id
      gift {
        title
      }
      created
      status
      orderPayment {
        vbankIssuedAt
      }
    }
  }
`;

/**
 * __useSentGiftsQuery__
 *
 * To run a query within a React component, call `useSentGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentGiftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSentGiftsQuery(baseOptions?: Apollo.QueryHookOptions<SentGiftsQuery, SentGiftsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SentGiftsQuery, SentGiftsQueryVariables>(SentGiftsDocument, options);
}
export function useSentGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SentGiftsQuery, SentGiftsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SentGiftsQuery, SentGiftsQueryVariables>(SentGiftsDocument, options);
}
export type SentGiftsQueryHookResult = ReturnType<typeof useSentGiftsQuery>;
export type SentGiftsLazyQueryHookResult = ReturnType<typeof useSentGiftsLazyQuery>;
export type SentGiftsQueryResult = Apollo.QueryResult<SentGiftsQuery, SentGiftsQueryVariables>;
export const SentGiftDocument = gql`
  query SentGift($orderGiftId: String!) {
    sentGift(orderGiftId: $orderGiftId) {
      id
      gift {
        title
      }
      inputRecipientName
      inputRecipientCellPhone
      sendMessage
      created
      updated
      status
      amount
      orderPayment {
        payMethod
        vbankCode
        vbankName
        vbankHolder
        vbankNum
        vbankIssuedAt
      }
    }
  }
`;

/**
 * __useSentGiftQuery__
 *
 * To run a query within a React component, call `useSentGiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useSentGiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentGiftQuery({
 *   variables: {
 *      orderGiftId: // value for 'orderGiftId'
 *   },
 * });
 */
export function useSentGiftQuery(baseOptions: Apollo.QueryHookOptions<SentGiftQuery, SentGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SentGiftQuery, SentGiftQueryVariables>(SentGiftDocument, options);
}
export function useSentGiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SentGiftQuery, SentGiftQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SentGiftQuery, SentGiftQueryVariables>(SentGiftDocument, options);
}
export type SentGiftQueryHookResult = ReturnType<typeof useSentGiftQuery>;
export type SentGiftLazyQueryHookResult = ReturnType<typeof useSentGiftLazyQuery>;
export type SentGiftQueryResult = Apollo.QueryResult<SentGiftQuery, SentGiftQueryVariables>;
export const HackOutDocument = gql`
  query hackOut {
    hackOut
  }
`;

/**
 * __useHackOutQuery__
 *
 * To run a query within a React component, call `useHackOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useHackOutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHackOutQuery({
 *   variables: {
 *   },
 * });
 */
export function useHackOutQuery(baseOptions?: Apollo.QueryHookOptions<HackOutQuery, HackOutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HackOutQuery, HackOutQueryVariables>(HackOutDocument, options);
}
export function useHackOutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HackOutQuery, HackOutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HackOutQuery, HackOutQueryVariables>(HackOutDocument, options);
}
export type HackOutQueryHookResult = ReturnType<typeof useHackOutQuery>;
export type HackOutLazyQueryHookResult = ReturnType<typeof useHackOutLazyQuery>;
export type HackOutQueryResult = Apollo.QueryResult<HackOutQuery, HackOutQueryVariables>;
export const HasOpenrunPromotedProductsDocument = gql`
  query HasOpenrunPromotedProducts($assetIds: [Int!]!) {
    hasOpenrunPromotedProducts(assetIds: $assetIds)
  }
`;

/**
 * __useHasOpenrunPromotedProductsQuery__
 *
 * To run a query within a React component, call `useHasOpenrunPromotedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasOpenrunPromotedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasOpenrunPromotedProductsQuery({
 *   variables: {
 *      assetIds: // value for 'assetIds'
 *   },
 * });
 */
export function useHasOpenrunPromotedProductsQuery(baseOptions: Apollo.QueryHookOptions<HasOpenrunPromotedProductsQuery, HasOpenrunPromotedProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasOpenrunPromotedProductsQuery, HasOpenrunPromotedProductsQueryVariables>(HasOpenrunPromotedProductsDocument, options);
}
export function useHasOpenrunPromotedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasOpenrunPromotedProductsQuery, HasOpenrunPromotedProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HasOpenrunPromotedProductsQuery, HasOpenrunPromotedProductsQueryVariables>(HasOpenrunPromotedProductsDocument, options);
}
export type HasOpenrunPromotedProductsQueryHookResult = ReturnType<typeof useHasOpenrunPromotedProductsQuery>;
export type HasOpenrunPromotedProductsLazyQueryHookResult = ReturnType<typeof useHasOpenrunPromotedProductsLazyQuery>;
export type HasOpenrunPromotedProductsQueryResult = Apollo.QueryResult<HasOpenrunPromotedProductsQuery, HasOpenrunPromotedProductsQueryVariables>;
export const HasOrderedDocument = gql`
  query HasOrdered($assetId: Float!) {
    hasOrdered(assetId: $assetId)
  }
`;

/**
 * __useHasOrderedQuery__
 *
 * To run a query within a React component, call `useHasOrderedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasOrderedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasOrderedQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useHasOrderedQuery(baseOptions: Apollo.QueryHookOptions<HasOrderedQuery, HasOrderedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasOrderedQuery, HasOrderedQueryVariables>(HasOrderedDocument, options);
}
export function useHasOrderedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasOrderedQuery, HasOrderedQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HasOrderedQuery, HasOrderedQueryVariables>(HasOrderedDocument, options);
}
export type HasOrderedQueryHookResult = ReturnType<typeof useHasOrderedQuery>;
export type HasOrderedLazyQueryHookResult = ReturnType<typeof useHasOrderedLazyQuery>;
export type HasOrderedQueryResult = Apollo.QueryResult<HasOrderedQuery, HasOrderedQueryVariables>;
export const HotProductsDocument = gql`
  query HotProducts($count: Int) {
    hotProducts(count: $count)
  }
`;

/**
 * __useHotProductsQuery__
 *
 * To run a query within a React component, call `useHotProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHotProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHotProductsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useHotProductsQuery(baseOptions?: Apollo.QueryHookOptions<HotProductsQuery, HotProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HotProductsQuery, HotProductsQueryVariables>(HotProductsDocument, options);
}
export function useHotProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HotProductsQuery, HotProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HotProductsQuery, HotProductsQueryVariables>(HotProductsDocument, options);
}
export type HotProductsQueryHookResult = ReturnType<typeof useHotProductsQuery>;
export type HotProductsLazyQueryHookResult = ReturnType<typeof useHotProductsLazyQuery>;
export type HotProductsQueryResult = Apollo.QueryResult<HotProductsQuery, HotProductsQueryVariables>;
export const MainProductListV2Document = gql`
  query MainProductListV2($sno: Int!, $lang: String!) {
    mainProductListV2(sno: $sno, lang: $lang) {
      sno
      title
      source
      productList
    }
  }
`;

/**
 * __useMainProductListV2Query__
 *
 * To run a query within a React component, call `useMainProductListV2Query` and pass it any options that fit your needs.
 * When your component renders, `useMainProductListV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainProductListV2Query({
 *   variables: {
 *      sno: // value for 'sno'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useMainProductListV2Query(baseOptions: Apollo.QueryHookOptions<MainProductListV2Query, MainProductListV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MainProductListV2Query, MainProductListV2QueryVariables>(MainProductListV2Document, options);
}
export function useMainProductListV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainProductListV2Query, MainProductListV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MainProductListV2Query, MainProductListV2QueryVariables>(MainProductListV2Document, options);
}
export type MainProductListV2QueryHookResult = ReturnType<typeof useMainProductListV2Query>;
export type MainProductListV2LazyQueryHookResult = ReturnType<typeof useMainProductListV2LazyQuery>;
export type MainProductListV2QueryResult = Apollo.QueryResult<MainProductListV2Query, MainProductListV2QueryVariables>;
export const MemberDepositDetailDocument = gql`
  query MemberDepositDetail {
    memberDepositDetail {
      purchaseDeposit
      giftDeposit
    }
  }
`;

/**
 * __useMemberDepositDetailQuery__
 *
 * To run a query within a React component, call `useMemberDepositDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberDepositDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberDepositDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useMemberDepositDetailQuery(baseOptions?: Apollo.QueryHookOptions<MemberDepositDetailQuery, MemberDepositDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberDepositDetailQuery, MemberDepositDetailQueryVariables>(MemberDepositDetailDocument, options);
}
export function useMemberDepositDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberDepositDetailQuery, MemberDepositDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberDepositDetailQuery, MemberDepositDetailQueryVariables>(MemberDepositDetailDocument, options);
}
export type MemberDepositDetailQueryHookResult = ReturnType<typeof useMemberDepositDetailQuery>;
export type MemberDepositDetailLazyQueryHookResult = ReturnType<typeof useMemberDepositDetailLazyQuery>;
export type MemberDepositDetailQueryResult = Apollo.QueryResult<MemberDepositDetailQuery, MemberDepositDetailQueryVariables>;
export const MoonGreetingPaidAmountDocument = gql`
  query MoonGreetingPaidAmount {
    memberMoonWelcomeEventPeriodPaidAmount
  }
`;

/**
 * __useMoonGreetingPaidAmountQuery__
 *
 * To run a query within a React component, call `useMoonGreetingPaidAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoonGreetingPaidAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoonGreetingPaidAmountQuery({
 *   variables: {
 *   },
 * });
 */
export function useMoonGreetingPaidAmountQuery(baseOptions?: Apollo.QueryHookOptions<MoonGreetingPaidAmountQuery, MoonGreetingPaidAmountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MoonGreetingPaidAmountQuery, MoonGreetingPaidAmountQueryVariables>(MoonGreetingPaidAmountDocument, options);
}
export function useMoonGreetingPaidAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MoonGreetingPaidAmountQuery, MoonGreetingPaidAmountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MoonGreetingPaidAmountQuery, MoonGreetingPaidAmountQueryVariables>(MoonGreetingPaidAmountDocument, options);
}
export type MoonGreetingPaidAmountQueryHookResult = ReturnType<typeof useMoonGreetingPaidAmountQuery>;
export type MoonGreetingPaidAmountLazyQueryHookResult = ReturnType<typeof useMoonGreetingPaidAmountLazyQuery>;
export type MoonGreetingPaidAmountQueryResult = Apollo.QueryResult<MoonGreetingPaidAmountQuery, MoonGreetingPaidAmountQueryVariables>;
export const FundingProductNosDocument = gql`
  query FundingProductNos {
    fundingProductNos
  }
`;

/**
 * __useFundingProductNosQuery__
 *
 * To run a query within a React component, call `useFundingProductNosQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingProductNosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingProductNosQuery({
 *   variables: {
 *   },
 * });
 */
export function useFundingProductNosQuery(baseOptions?: Apollo.QueryHookOptions<FundingProductNosQuery, FundingProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FundingProductNosQuery, FundingProductNosQueryVariables>(FundingProductNosDocument, options);
}
export function useFundingProductNosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundingProductNosQuery, FundingProductNosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FundingProductNosQuery, FundingProductNosQueryVariables>(FundingProductNosDocument, options);
}
export type FundingProductNosQueryHookResult = ReturnType<typeof useFundingProductNosQuery>;
export type FundingProductNosLazyQueryHookResult = ReturnType<typeof useFundingProductNosLazyQuery>;
export type FundingProductNosQueryResult = Apollo.QueryResult<FundingProductNosQuery, FundingProductNosQueryVariables>;
export const PaginateProductCardsDocument = gql`
  query PaginateProductCards(
    $languageCode: LanguageCodeEnum!
    $filter: ProductListFilterInput!
    $limit: Int
    $page: Int
    $type: [ProductTypes!]
    $onlyDiscount: Boolean
    $onlyFree: Boolean
    $onlyAdult: Boolean
    $onlyNew: Boolean
    $brandCode: String
    $categoryCode: String
    $sort: ProductListSort
    $option: ProductListOptionInput
  ) {
    paginateProductCards(
      languageCode: $languageCode
      filter: $filter
      type: $type
      onlyDiscount: $onlyDiscount
      onlyFree: $onlyFree
      onlyAdult: $onlyAdult
      onlyNew: $onlyNew
      brandCode: $brandCode
      categoryCode: $categoryCode
      sort: $sort
      limit: $limit
      page: $page
      option: $option
    ) {
      allCount
      sort
      meta {
        totalCount
        currentPage
        perPage
        lastPage
      }
      data {
        id
        title
        price
        salePrice
        onSale
        onDisplay
        promotionEndDate
        orderCount
        viewCount
        reviewCount
        mainImageUrl
        subImageUrl
        created
        updated
        isAdultOnly
        brand {
          id
          name
          code
          isExclusive
        }
        categories {
          id
          name
          code
        }
        badgeNames
        extensions
      }
    }
  }
`;

/**
 * __usePaginateProductCardsQuery__
 *
 * To run a query within a React component, call `usePaginateProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginateProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginateProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      type: // value for 'type'
 *      onlyDiscount: // value for 'onlyDiscount'
 *      onlyFree: // value for 'onlyFree'
 *      onlyAdult: // value for 'onlyAdult'
 *      onlyNew: // value for 'onlyNew'
 *      brandCode: // value for 'brandCode'
 *      categoryCode: // value for 'categoryCode'
 *      sort: // value for 'sort'
 *      option: // value for 'option'
 *   },
 * });
 */
export function usePaginateProductCardsQuery(baseOptions: Apollo.QueryHookOptions<PaginateProductCardsQuery, PaginateProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PaginateProductCardsQuery, PaginateProductCardsQueryVariables>(PaginateProductCardsDocument, options);
}
export function usePaginateProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginateProductCardsQuery, PaginateProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PaginateProductCardsQuery, PaginateProductCardsQueryVariables>(PaginateProductCardsDocument, options);
}
export type PaginateProductCardsQueryHookResult = ReturnType<typeof usePaginateProductCardsQuery>;
export type PaginateProductCardsLazyQueryHookResult = ReturnType<typeof usePaginateProductCardsLazyQuery>;
export type PaginateProductCardsQueryResult = Apollo.QueryResult<PaginateProductCardsQuery, PaginateProductCardsQueryVariables>;
export const ProductCardsDocument = gql`
  query ProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
    }
  }
  ${ProductCardsFragmentDoc}
`;

/**
 * __useProductCardsQuery__
 *
 * To run a query within a React component, call `useProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsQuery, ProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsQuery, ProductCardsQueryVariables>(ProductCardsDocument, options);
}
export function useProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsQuery, ProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsQuery, ProductCardsQueryVariables>(ProductCardsDocument, options);
}
export type ProductCardsQueryHookResult = ReturnType<typeof useProductCardsQuery>;
export type ProductCardsLazyQueryHookResult = ReturnType<typeof useProductCardsLazyQuery>;
export type ProductCardsQueryResult = Apollo.QueryResult<ProductCardsQuery, ProductCardsQueryVariables>;
export const ProductCardsWithBrandDocument = gql`
  query ProductCardsWithBrand($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
      brand {
        ...brand
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${BrandFragmentDoc}
`;

/**
 * __useProductCardsWithBrandQuery__
 *
 * To run a query within a React component, call `useProductCardsWithBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsWithBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsWithBrandQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsWithBrandQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>(ProductCardsWithBrandDocument, options);
}
export function useProductCardsWithBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>(ProductCardsWithBrandDocument, options);
}
export type ProductCardsWithBrandQueryHookResult = ReturnType<typeof useProductCardsWithBrandQuery>;
export type ProductCardsWithBrandLazyQueryHookResult = ReturnType<typeof useProductCardsWithBrandLazyQuery>;
export type ProductCardsWithBrandQueryResult = Apollo.QueryResult<ProductCardsWithBrandQuery, ProductCardsWithBrandQueryVariables>;
export const ProductCardsWithCategoriesDocument = gql`
  query ProductCardsWithCategories($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
      categories {
        ...categories
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${CategoriesFragmentDoc}
`;

/**
 * __useProductCardsWithCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCardsWithCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsWithCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsWithCategoriesQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsWithCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>(ProductCardsWithCategoriesDocument, options);
}
export function useProductCardsWithCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>(ProductCardsWithCategoriesDocument, options);
}
export type ProductCardsWithCategoriesQueryHookResult = ReturnType<typeof useProductCardsWithCategoriesQuery>;
export type ProductCardsWithCategoriesLazyQueryHookResult = ReturnType<typeof useProductCardsWithCategoriesLazyQuery>;
export type ProductCardsWithCategoriesQueryResult = Apollo.QueryResult<ProductCardsWithCategoriesQuery, ProductCardsWithCategoriesQueryVariables>;
export const ProductCardsWithBrandAndCategoriesDocument = gql`
  query ProductCardsWithBrandAndCategories($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
      brand {
        ...brand
      }
      categories {
        ...categories
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${BrandFragmentDoc}
  ${CategoriesFragmentDoc}
`;

/**
 * __useProductCardsWithBrandAndCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCardsWithBrandAndCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsWithBrandAndCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsWithBrandAndCategoriesQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsWithBrandAndCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>(ProductCardsWithBrandAndCategoriesDocument, options);
}
export function useProductCardsWithBrandAndCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>(ProductCardsWithBrandAndCategoriesDocument, options);
}
export type ProductCardsWithBrandAndCategoriesQueryHookResult = ReturnType<typeof useProductCardsWithBrandAndCategoriesQuery>;
export type ProductCardsWithBrandAndCategoriesLazyQueryHookResult = ReturnType<typeof useProductCardsWithBrandAndCategoriesLazyQuery>;
export type ProductCardsWithBrandAndCategoriesQueryResult = Apollo.QueryResult<ProductCardsWithBrandAndCategoriesQuery, ProductCardsWithBrandAndCategoriesQueryVariables>;
export const ProductCardContentDocument = gql`
  query ProductCardContent($assetId: Int!, $lang: String!) {
    productContent(assetId: $assetId, lang: $lang)
  }
`;

/**
 * __useProductCardContentQuery__
 *
 * To run a query within a React component, call `useProductCardContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardContentQuery({
 *   variables: {
 *      assetId: // value for 'assetId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useProductCardContentQuery(baseOptions: Apollo.QueryHookOptions<ProductCardContentQuery, ProductCardContentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardContentQuery, ProductCardContentQueryVariables>(ProductCardContentDocument, options);
}
export function useProductCardContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardContentQuery, ProductCardContentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardContentQuery, ProductCardContentQueryVariables>(ProductCardContentDocument, options);
}
export type ProductCardContentQueryHookResult = ReturnType<typeof useProductCardContentQuery>;
export type ProductCardContentLazyQueryHookResult = ReturnType<typeof useProductCardContentLazyQuery>;
export type ProductCardContentQueryResult = Apollo.QueryResult<ProductCardContentQuery, ProductCardContentQueryVariables>;
export const ProductCardsAllDocument = gql`
  query ProductCardsAll($languageCode: LanguageCodeEnum!, $productNos: [Int!], $isExperimental: Boolean) {
    productCards(languageCode: $languageCode, productNos: $productNos, isExperimental: $isExperimental) {
      ...productCards
      brand {
        ...brand
      }
      applications {
        name
      }
      linkedProducts {
        ...linkedProducts
      }
      categories {
        ...categories
      }
      modelConfigs {
        ...modelConfigs
      }
      banners {
        ...banners
      }
      packages {
        ...packages
      }
      funding {
        ...funding
      }
      licenses {
        ...licenses
      }
      promotionInfo {
        ...promotionInfo
      }
      category {
        id
        code
        name
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${BrandFragmentDoc}
  ${LinkedProductsFragmentDoc}
  ${CategoriesFragmentDoc}
  ${ModelConfigsFragmentDoc}
  ${BannersFragmentDoc}
  ${PackagesFragmentDoc}
  ${FundingFragmentDoc}
  ${LicensesFragmentDoc}
  ${PromotionInfoFragmentDoc}
`;

/**
 * __useProductCardsAllQuery__
 *
 * To run a query within a React component, call `useProductCardsAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsAllQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *      isExperimental: // value for 'isExperimental'
 *   },
 * });
 */
export function useProductCardsAllQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsAllQuery, ProductCardsAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsAllQuery, ProductCardsAllQueryVariables>(ProductCardsAllDocument, options);
}
export function useProductCardsAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsAllQuery, ProductCardsAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsAllQuery, ProductCardsAllQueryVariables>(ProductCardsAllDocument, options);
}
export type ProductCardsAllQueryHookResult = ReturnType<typeof useProductCardsAllQuery>;
export type ProductCardsAllLazyQueryHookResult = ReturnType<typeof useProductCardsAllLazyQuery>;
export type ProductCardsAllQueryResult = Apollo.QueryResult<ProductCardsAllQuery, ProductCardsAllQueryVariables>;
export const ProductCardsGa4EcommerceDocument = gql`
  query ProductCardsGA4Ecommerce($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      id
      title
      price
      salePrice
      brand {
        ...brand
      }
    }
  }
  ${BrandFragmentDoc}
`;

/**
 * __useProductCardsGa4EcommerceQuery__
 *
 * To run a query within a React component, call `useProductCardsGa4EcommerceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsGa4EcommerceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsGa4EcommerceQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsGa4EcommerceQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>(ProductCardsGa4EcommerceDocument, options);
}
export function useProductCardsGa4EcommerceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>(ProductCardsGa4EcommerceDocument, options);
}
export type ProductCardsGa4EcommerceQueryHookResult = ReturnType<typeof useProductCardsGa4EcommerceQuery>;
export type ProductCardsGa4EcommerceLazyQueryHookResult = ReturnType<typeof useProductCardsGa4EcommerceLazyQuery>;
export type ProductCardsGa4EcommerceQueryResult = Apollo.QueryResult<ProductCardsGa4EcommerceQuery, ProductCardsGa4EcommerceQueryVariables>;
export const DetailViewProductCardsDocument = gql`
  query DetailViewProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
      detailImageUrl
      brand {
        ...brand
      }
      applications {
        name
      }
      linkedProducts {
        ...linkedProducts
      }
      packages {
        ...packages
      }
      funding {
        ...funding
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${BrandFragmentDoc}
  ${LinkedProductsFragmentDoc}
  ${PackagesFragmentDoc}
  ${FundingFragmentDoc}
`;

/**
 * __useDetailViewProductCardsQuery__
 *
 * To run a query within a React component, call `useDetailViewProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailViewProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailViewProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useDetailViewProductCardsQuery(baseOptions: Apollo.QueryHookOptions<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>(DetailViewProductCardsDocument, options);
}
export function useDetailViewProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>(DetailViewProductCardsDocument, options);
}
export type DetailViewProductCardsQueryHookResult = ReturnType<typeof useDetailViewProductCardsQuery>;
export type DetailViewProductCardsLazyQueryHookResult = ReturnType<typeof useDetailViewProductCardsLazyQuery>;
export type DetailViewProductCardsQueryResult = Apollo.QueryResult<DetailViewProductCardsQuery, DetailViewProductCardsQueryVariables>;
export const OrderProductCardsDocument = gql`
  query OrderProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
      promotionInfo {
        ...promotionInfo
      }
      brand {
        ...brand
      }
      hasOrdered
      licenses {
        ...licenses
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${PromotionInfoFragmentDoc}
  ${BrandFragmentDoc}
  ${LicensesFragmentDoc}
`;

/**
 * __useOrderProductCardsQuery__
 *
 * To run a query within a React component, call `useOrderProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useOrderProductCardsQuery(baseOptions: Apollo.QueryHookOptions<OrderProductCardsQuery, OrderProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderProductCardsQuery, OrderProductCardsQueryVariables>(OrderProductCardsDocument, options);
}
export function useOrderProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderProductCardsQuery, OrderProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderProductCardsQuery, OrderProductCardsQueryVariables>(OrderProductCardsDocument, options);
}
export type OrderProductCardsQueryHookResult = ReturnType<typeof useOrderProductCardsQuery>;
export type OrderProductCardsLazyQueryHookResult = ReturnType<typeof useOrderProductCardsLazyQuery>;
export type OrderProductCardsQueryResult = Apollo.QueryResult<OrderProductCardsQuery, OrderProductCardsQueryVariables>;
export const PromotionProductCardsDocument = gql`
  query PromotionProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      ...productCards
      brand {
        ...brand
      }
      promotions {
        ...promotions
        badges {
          ...promotionsBadge
        }
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${BrandFragmentDoc}
  ${PromotionsFragmentDoc}
  ${PromotionsBadgeFragmentDoc}
`;

/**
 * __usePromotionProductCardsQuery__
 *
 * To run a query within a React component, call `usePromotionProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromotionProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromotionProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function usePromotionProductCardsQuery(baseOptions: Apollo.QueryHookOptions<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>(PromotionProductCardsDocument, options);
}
export function usePromotionProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>(PromotionProductCardsDocument, options);
}
export type PromotionProductCardsQueryHookResult = ReturnType<typeof usePromotionProductCardsQuery>;
export type PromotionProductCardsLazyQueryHookResult = ReturnType<typeof usePromotionProductCardsLazyQuery>;
export type PromotionProductCardsQueryResult = Apollo.QueryResult<PromotionProductCardsQuery, PromotionProductCardsQueryVariables>;
export const ProductCardsSummaryDocument = gql`
  query ProductCardsSummary($sort: ProductListSort, $categoryCode: String, $brandCode: String, $isNew: Boolean, $minPrice: Int, $maxPrice: Int) {
    productCardsSummary(sort: $sort, categoryCode: $categoryCode, brandCode: $brandCode, isNew: $isNew, minPrice: $minPrice, maxPrice: $maxPrice) {
      sort
      products {
        id
        released
        extensions
        salePrice
        orderCount
        isAdultOnly
        applications {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useProductCardsSummaryQuery__
 *
 * To run a query within a React component, call `useProductCardsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsSummaryQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      categoryCode: // value for 'categoryCode'
 *      brandCode: // value for 'brandCode'
 *      isNew: // value for 'isNew'
 *      minPrice: // value for 'minPrice'
 *      maxPrice: // value for 'maxPrice'
 *   },
 * });
 */
export function useProductCardsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>(ProductCardsSummaryDocument, options);
}
export function useProductCardsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>(ProductCardsSummaryDocument, options);
}
export type ProductCardsSummaryQueryHookResult = ReturnType<typeof useProductCardsSummaryQuery>;
export type ProductCardsSummaryLazyQueryHookResult = ReturnType<typeof useProductCardsSummaryLazyQuery>;
export type ProductCardsSummaryQueryResult = Apollo.QueryResult<ProductCardsSummaryQuery, ProductCardsSummaryQueryVariables>;
export const AdultProductCardsSummaryDocument = gql`
  query AdultProductCardsSummary($sort: ProductListSort, $categoryCode: String, $brandCode: String) {
    adultProductCardsSummary(sort: $sort, categoryCode: $categoryCode, brandCode: $brandCode) {
      toggle
      sort
      products {
        id
        released
        extensions
        salePrice
        orderCount
        isAdultOnly
        applications {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useAdultProductCardsSummaryQuery__
 *
 * To run a query within a React component, call `useAdultProductCardsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdultProductCardsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdultProductCardsSummaryQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      categoryCode: // value for 'categoryCode'
 *      brandCode: // value for 'brandCode'
 *   },
 * });
 */
export function useAdultProductCardsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<AdultProductCardsSummaryQuery, AdultProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdultProductCardsSummaryQuery, AdultProductCardsSummaryQueryVariables>(AdultProductCardsSummaryDocument, options);
}
export function useAdultProductCardsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdultProductCardsSummaryQuery, AdultProductCardsSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdultProductCardsSummaryQuery, AdultProductCardsSummaryQueryVariables>(AdultProductCardsSummaryDocument, options);
}
export type AdultProductCardsSummaryQueryHookResult = ReturnType<typeof useAdultProductCardsSummaryQuery>;
export type AdultProductCardsSummaryLazyQueryHookResult = ReturnType<typeof useAdultProductCardsSummaryLazyQuery>;
export type AdultProductCardsSummaryQueryResult = Apollo.QueryResult<AdultProductCardsSummaryQuery, AdultProductCardsSummaryQueryVariables>;
export const ProductExtensionsDocument = gql`
  query ProductExtensions($language: LANGUAGE_CODE!, $condition: ProductExtensionsInput_Condition!, $pagination: PaginationInput!) {
    productExtensions(language: $language, condition: $condition, pagination: $pagination) {
      data {
        id
        name
        count
      }
      pagination {
        totalCount
        currentPage
        perPage
        lastPage
      }
    }
  }
`;

/**
 * __useProductExtensionsQuery__
 *
 * To run a query within a React component, call `useProductExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductExtensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductExtensionsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      condition: // value for 'condition'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductExtensionsQuery(baseOptions: Apollo.QueryHookOptions<ProductExtensionsQuery, ProductExtensionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductExtensionsQuery, ProductExtensionsQueryVariables>(ProductExtensionsDocument, options);
}
export function useProductExtensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductExtensionsQuery, ProductExtensionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductExtensionsQuery, ProductExtensionsQueryVariables>(ProductExtensionsDocument, options);
}
export type ProductExtensionsQueryHookResult = ReturnType<typeof useProductExtensionsQuery>;
export type ProductExtensionsLazyQueryHookResult = ReturnType<typeof useProductExtensionsLazyQuery>;
export type ProductExtensionsQueryResult = Apollo.QueryResult<ProductExtensionsQuery, ProductExtensionsQueryVariables>;
export const SearchExtensionsV2Document = gql`
  query SearchExtensionsV2(
    $language: SearchProductsV2Input_Language!
    $pagination: SearchProductsV2Input_Pagination!
    $condition: SearchProductsV2Input_Condition!
    $sort: SearchProductsV2Input_Sort!
    $https: Boolean
  ) {
    searchExtensionsV2(language: $language, pagination: $pagination, condition: $condition, sort: $sort, https: $https) {
      data {
        id
        name
        count
      }
      pagination {
        totalCount
      }
    }
  }
`;

/**
 * __useSearchExtensionsV2Query__
 *
 * To run a query within a React component, call `useSearchExtensionsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchExtensionsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchExtensionsV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *      https: // value for 'https'
 *   },
 * });
 */
export function useSearchExtensionsV2Query(baseOptions: Apollo.QueryHookOptions<SearchExtensionsV2Query, SearchExtensionsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchExtensionsV2Query, SearchExtensionsV2QueryVariables>(SearchExtensionsV2Document, options);
}
export function useSearchExtensionsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchExtensionsV2Query, SearchExtensionsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchExtensionsV2Query, SearchExtensionsV2QueryVariables>(SearchExtensionsV2Document, options);
}
export type SearchExtensionsV2QueryHookResult = ReturnType<typeof useSearchExtensionsV2Query>;
export type SearchExtensionsV2LazyQueryHookResult = ReturnType<typeof useSearchExtensionsV2LazyQuery>;
export type SearchExtensionsV2QueryResult = Apollo.QueryResult<SearchExtensionsV2Query, SearchExtensionsV2QueryVariables>;
export const ProductTagsDocument = gql`
  query ProductTags($language: LANGUAGE_CODE!, $condition: ProductTagsInput_Condition!, $pagination: PaginationInput!) {
    productTags(language: $language, condition: $condition, pagination: $pagination) {
      data {
        id
        name
        count
      }
      pagination {
        totalCount
      }
    }
  }
`;

/**
 * __useProductTagsQuery__
 *
 * To run a query within a React component, call `useProductTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTagsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      condition: // value for 'condition'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useProductTagsQuery(baseOptions: Apollo.QueryHookOptions<ProductTagsQuery, ProductTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductTagsQuery, ProductTagsQueryVariables>(ProductTagsDocument, options);
}
export function useProductTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTagsQuery, ProductTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductTagsQuery, ProductTagsQueryVariables>(ProductTagsDocument, options);
}
export type ProductTagsQueryHookResult = ReturnType<typeof useProductTagsQuery>;
export type ProductTagsLazyQueryHookResult = ReturnType<typeof useProductTagsLazyQuery>;
export type ProductTagsQueryResult = Apollo.QueryResult<ProductTagsQuery, ProductTagsQueryVariables>;
export const SearchTagsV2Document = gql`
  query SearchTagsV2(
    $language: SearchProductsV2Input_Language!
    $pagination: SearchProductsV2Input_Pagination!
    $condition: SearchProductsV2Input_Condition!
    $sort: SearchProductsV2Input_Sort!
    $https: Boolean
  ) {
    searchTagsV2(language: $language, pagination: $pagination, condition: $condition, sort: $sort, https: $https) {
      data {
        id
        name
        count
      }
      pagination {
        totalCount
      }
    }
  }
`;

/**
 * __useSearchTagsV2Query__
 *
 * To run a query within a React component, call `useSearchTagsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchTagsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTagsV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *      https: // value for 'https'
 *   },
 * });
 */
export function useSearchTagsV2Query(baseOptions: Apollo.QueryHookOptions<SearchTagsV2Query, SearchTagsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchTagsV2Query, SearchTagsV2QueryVariables>(SearchTagsV2Document, options);
}
export function useSearchTagsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTagsV2Query, SearchTagsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchTagsV2Query, SearchTagsV2QueryVariables>(SearchTagsV2Document, options);
}
export type SearchTagsV2QueryHookResult = ReturnType<typeof useSearchTagsV2Query>;
export type SearchTagsV2LazyQueryHookResult = ReturnType<typeof useSearchTagsV2LazyQuery>;
export type SearchTagsV2QueryResult = Apollo.QueryResult<SearchTagsV2Query, SearchTagsV2QueryVariables>;
export const RegisterSnsMemberDocument = gql`
  mutation RegisterSnsMember($input: AconSnsMemberInput!) {
    registerSnsMember(input: $input)
  }
`;
export type RegisterSnsMemberMutationFn = Apollo.MutationFunction<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>;

/**
 * __useRegisterSnsMemberMutation__
 *
 * To run a mutation, you first call `useRegisterSnsMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSnsMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSnsMemberMutation, { data, loading, error }] = useRegisterSnsMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterSnsMemberMutation(baseOptions?: Apollo.MutationHookOptions<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>(RegisterSnsMemberDocument, options);
}
export type RegisterSnsMemberMutationHookResult = ReturnType<typeof useRegisterSnsMemberMutation>;
export type RegisterSnsMemberMutationResult = Apollo.MutationResult<RegisterSnsMemberMutation>;
export type RegisterSnsMemberMutationOptions = Apollo.BaseMutationOptions<RegisterSnsMemberMutation, RegisterSnsMemberMutationVariables>;
export const RegisterSnsMemberV2Document = gql`
  mutation RegisterSnsMemberV2($input: AconSnsMemberInput!) {
    registerSnsMemberV2(input: $input) {
      id
      email
      name
      mobile
    }
  }
`;
export type RegisterSnsMemberV2MutationFn = Apollo.MutationFunction<RegisterSnsMemberV2Mutation, RegisterSnsMemberV2MutationVariables>;

/**
 * __useRegisterSnsMemberV2Mutation__
 *
 * To run a mutation, you first call `useRegisterSnsMemberV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterSnsMemberV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerSnsMemberV2Mutation, { data, loading, error }] = useRegisterSnsMemberV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterSnsMemberV2Mutation(baseOptions?: Apollo.MutationHookOptions<RegisterSnsMemberV2Mutation, RegisterSnsMemberV2MutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterSnsMemberV2Mutation, RegisterSnsMemberV2MutationVariables>(RegisterSnsMemberV2Document, options);
}
export type RegisterSnsMemberV2MutationHookResult = ReturnType<typeof useRegisterSnsMemberV2Mutation>;
export type RegisterSnsMemberV2MutationResult = Apollo.MutationResult<RegisterSnsMemberV2Mutation>;
export type RegisterSnsMemberV2MutationOptions = Apollo.BaseMutationOptions<RegisterSnsMemberV2Mutation, RegisterSnsMemberV2MutationVariables>;
export const ReviewsDocument = gql`
  query reviews($goodsNo: String!, $lang: String!, $page: Int!, $limit: Int!, $rating: [Int!], $isExperimental: Boolean, $sortType: String) {
    reviews(goodsNo: $goodsNo, lang: $lang, page: $page, limit: $limit, rating: $rating, isExperimental: $isExperimental, sortType: $sortType) {
      id
      created
      rating
      isBestReview
      isNew
      isImage
      commentsCount
      author
      favoriteCount
      isFavorite
      godoMemNo
      imageUrl
      reviewImages {
        imageNumber
        imageUrl
      }
      reviewReplies {
        reviewReplyContents {
          languageCode
          content
          isOriginal
          reviewReplyId
        }
        reviewId
        created
        adminUser {
          name
          id
          updated
        }
      }
      reviewContentsItems {
        title
        reviewId
        contents
        isOriginal
        languageCode
      }
    }
  }
`;

/**
 * __useReviewsQuery__
 *
 * To run a query within a React component, call `useReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsQuery({
 *   variables: {
 *      goodsNo: // value for 'goodsNo'
 *      lang: // value for 'lang'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      rating: // value for 'rating'
 *      isExperimental: // value for 'isExperimental'
 *      sortType: // value for 'sortType'
 *   },
 * });
 */
export function useReviewsQuery(baseOptions: Apollo.QueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export function useReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsQuery, ReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewsQuery, ReviewsQueryVariables>(ReviewsDocument, options);
}
export type ReviewsQueryHookResult = ReturnType<typeof useReviewsQuery>;
export type ReviewsLazyQueryHookResult = ReturnType<typeof useReviewsLazyQuery>;
export type ReviewsQueryResult = Apollo.QueryResult<ReviewsQuery, ReviewsQueryVariables>;
export const SimpleReviewsDocument = gql`
  query simpleReviews($goodsNo: String!, $lang: String!, $page: Int!, $limit: Int!, $rating: [Int!], $isExperimental: Boolean) {
    reviews(goodsNo: $goodsNo, lang: $lang, page: $page, limit: $limit, rating: $rating, isExperimental: $isExperimental) {
      id
      created
      rating
      commentsCount
      author
      favoriteCount
      isFavorite
      isImage
      godoMemNo
      imageUrl
      reviewImages {
        imageUrl
      }
      reviewContentsItems(isOriginal: true) {
        contents
      }
    }
  }
`;

/**
 * __useSimpleReviewsQuery__
 *
 * To run a query within a React component, call `useSimpleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleReviewsQuery({
 *   variables: {
 *      goodsNo: // value for 'goodsNo'
 *      lang: // value for 'lang'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      rating: // value for 'rating'
 *      isExperimental: // value for 'isExperimental'
 *   },
 * });
 */
export function useSimpleReviewsQuery(baseOptions: Apollo.QueryHookOptions<SimpleReviewsQuery, SimpleReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimpleReviewsQuery, SimpleReviewsQueryVariables>(SimpleReviewsDocument, options);
}
export function useSimpleReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimpleReviewsQuery, SimpleReviewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimpleReviewsQuery, SimpleReviewsQueryVariables>(SimpleReviewsDocument, options);
}
export type SimpleReviewsQueryHookResult = ReturnType<typeof useSimpleReviewsQuery>;
export type SimpleReviewsLazyQueryHookResult = ReturnType<typeof useSimpleReviewsLazyQuery>;
export type SimpleReviewsQueryResult = Apollo.QueryResult<SimpleReviewsQuery, SimpleReviewsQueryVariables>;
export const ReviewDocument = gql`
  query review($reviewId: Int!, $languageCode: LanguageCodeEnum!) {
    review(reviewId: $reviewId) {
      author
      commentsCount
      created
      favoriteCount
      id
      imageUrl
      isBestReview
      isFavorite
      isImage
      isNew
      godoGoodsNo
      godoOrderNo
      isSecret
      reviewContentsItems {
        contents
        isOriginal
        languageCode
        reviewId
        title
      }
      rating
      reviewImages {
        imageNumber
        imageUrl
      }
      reviewReplies {
        adminUser {
          name
        }
        created
        id
        reviewReplyContents {
          content
          isOriginal
          languageCode
        }
      }
      product(languageCode: $languageCode) {
        id
        brand {
          id
          code
          name
        }
        title
      }
    }
  }
`;

/**
 * __useReviewQuery__
 *
 * To run a query within a React component, call `useReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewQuery({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useReviewQuery(baseOptions: Apollo.QueryHookOptions<ReviewQuery, ReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, options);
}
export function useReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewQuery, ReviewQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, options);
}
export type ReviewQueryHookResult = ReturnType<typeof useReviewQuery>;
export type ReviewLazyQueryHookResult = ReturnType<typeof useReviewLazyQuery>;
export type ReviewQueryResult = Apollo.QueryResult<ReviewQuery, ReviewQueryVariables>;
export const RegisterReviewDocument = gql`
  mutation RegisterReview($input: RegisterReviewInput!) {
    registerReviewV2(input: $input) {
      id
      created
    }
  }
`;
export type RegisterReviewMutationFn = Apollo.MutationFunction<RegisterReviewMutation, RegisterReviewMutationVariables>;

/**
 * __useRegisterReviewMutation__
 *
 * To run a mutation, you first call `useRegisterReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerReviewMutation, { data, loading, error }] = useRegisterReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterReviewMutation(baseOptions?: Apollo.MutationHookOptions<RegisterReviewMutation, RegisterReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterReviewMutation, RegisterReviewMutationVariables>(RegisterReviewDocument, options);
}
export type RegisterReviewMutationHookResult = ReturnType<typeof useRegisterReviewMutation>;
export type RegisterReviewMutationResult = Apollo.MutationResult<RegisterReviewMutation>;
export type RegisterReviewMutationOptions = Apollo.BaseMutationOptions<RegisterReviewMutation, RegisterReviewMutationVariables>;
export const UpdateReviewDocument = gql`
  mutation UpdateReview($input: UpdateReviewInput!) {
    updateReviewV2(input: $input) {
      id
    }
  }
`;
export type UpdateReviewMutationFn = Apollo.MutationFunction<UpdateReviewMutation, UpdateReviewMutationVariables>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReviewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReviewMutation, UpdateReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateReviewMutation, UpdateReviewMutationVariables>(UpdateReviewDocument, options);
}
export type UpdateReviewMutationHookResult = ReturnType<typeof useUpdateReviewMutation>;
export type UpdateReviewMutationResult = Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<UpdateReviewMutation, UpdateReviewMutationVariables>;
export const FavoriteReviewDocument = gql`
  mutation favoriteReview($reviewId: Int!) {
    setFavoriteReview(reviewId: $reviewId)
  }
`;
export type FavoriteReviewMutationFn = Apollo.MutationFunction<FavoriteReviewMutation, FavoriteReviewMutationVariables>;

/**
 * __useFavoriteReviewMutation__
 *
 * To run a mutation, you first call `useFavoriteReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFavoriteReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [favoriteReviewMutation, { data, loading, error }] = useFavoriteReviewMutation({
 *   variables: {
 *      reviewId: // value for 'reviewId'
 *   },
 * });
 */
export function useFavoriteReviewMutation(baseOptions?: Apollo.MutationHookOptions<FavoriteReviewMutation, FavoriteReviewMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FavoriteReviewMutation, FavoriteReviewMutationVariables>(FavoriteReviewDocument, options);
}
export type FavoriteReviewMutationHookResult = ReturnType<typeof useFavoriteReviewMutation>;
export type FavoriteReviewMutationResult = Apollo.MutationResult<FavoriteReviewMutation>;
export type FavoriteReviewMutationOptions = Apollo.BaseMutationOptions<FavoriteReviewMutation, FavoriteReviewMutationVariables>;
export const UpdateOrderLicenseDocument = gql`
  mutation UpdateOrderLicense($orderNo: Float!, $type: String!, $names: [String!]!, $projects: [OrderLicenseProjects!], $adminEmail: String) {
    updateOrderLicense(orderNo: $orderNo, type: $type, names: $names, projects: $projects, adminEmail: $adminEmail)
  }
`;
export type UpdateOrderLicenseMutationFn = Apollo.MutationFunction<UpdateOrderLicenseMutation, UpdateOrderLicenseMutationVariables>;

/**
 * __useUpdateOrderLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateOrderLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderLicenseMutation, { data, loading, error }] = useUpdateOrderLicenseMutation({
 *   variables: {
 *      orderNo: // value for 'orderNo'
 *      type: // value for 'type'
 *      names: // value for 'names'
 *      projects: // value for 'projects'
 *      adminEmail: // value for 'adminEmail'
 *   },
 * });
 */
export function useUpdateOrderLicenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderLicenseMutation, UpdateOrderLicenseMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderLicenseMutation, UpdateOrderLicenseMutationVariables>(UpdateOrderLicenseDocument, options);
}
export type UpdateOrderLicenseMutationHookResult = ReturnType<typeof useUpdateOrderLicenseMutation>;
export type UpdateOrderLicenseMutationResult = Apollo.MutationResult<UpdateOrderLicenseMutation>;
export type UpdateOrderLicenseMutationOptions = Apollo.BaseMutationOptions<UpdateOrderLicenseMutation, UpdateOrderLicenseMutationVariables>;
export const ProductDocument = gql`
  query Product($productId: Float!, $language: LANGUAGE_CODE!) {
    product(id: $productId, language: $language) {
      id
      title
      isAdultOnly
      priceInfo {
        ...ProductPriceInfo
      }
      imageInfo {
        ...ProductImageInfo
      }
      promotionInfo {
        ...ProductPromotionInfo
      }
      brandInfo {
        ...ProductBrandInfo
      }
      tags {
        ...ProductTags
      }
      extensions {
        ...ProductExtensions
      }
      applications {
        ...ProductApplications
      }
      dateInfo {
        releasedAt
      }
      bookInfo {
        isbn
      }
    }
  }
  ${ProductPriceInfoFragmentDoc}
  ${ProductImageInfoFragmentDoc}
  ${ProductPromotionInfoFragmentDoc}
  ${ProductBrandInfoFragmentDoc}
  ${ProductTagsFragmentDoc}
  ${ProductExtensionsFragmentDoc}
  ${ProductApplicationsFragmentDoc}
`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
}
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductCardsDetailDocument = gql`
  query ProductCardsDetail($languageCode: LanguageCodeEnum!, $productNos: [Int!]) {
    productCards(languageCode: $languageCode, productNos: $productNos) {
      id
      promotionEndDate
      reviewCount
      onSale
      licenses {
        ...licenses
      }
      promotionInfo {
        ...promotionInfo
      }
      banners {
        ...banners
      }
      packages {
        ...packages
      }
      funding {
        ...funding
      }
      category {
        id
        code
        name
      }
    }
  }
  ${LicensesFragmentDoc}
  ${PromotionInfoFragmentDoc}
  ${BannersFragmentDoc}
  ${PackagesFragmentDoc}
  ${FundingFragmentDoc}
`;

/**
 * __useProductCardsDetailQuery__
 *
 * To run a query within a React component, call `useProductCardsDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCardsDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCardsDetailQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *   },
 * });
 */
export function useProductCardsDetailQuery(baseOptions: Apollo.QueryHookOptions<ProductCardsDetailQuery, ProductCardsDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCardsDetailQuery, ProductCardsDetailQueryVariables>(ProductCardsDetailDocument, options);
}
export function useProductCardsDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCardsDetailQuery, ProductCardsDetailQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCardsDetailQuery, ProductCardsDetailQueryVariables>(ProductCardsDetailDocument, options);
}
export type ProductCardsDetailQueryHookResult = ReturnType<typeof useProductCardsDetailQuery>;
export type ProductCardsDetailLazyQueryHookResult = ReturnType<typeof useProductCardsDetailLazyQuery>;
export type ProductCardsDetailQueryResult = Apollo.QueryResult<ProductCardsDetailQuery, ProductCardsDetailQueryVariables>;
export const FundingProductCardsDocument = gql`
  query FundingProductCards($languageCode: LanguageCodeEnum!, $productNos: [Int!], $isIncludeNotForSale: Boolean) {
    productCards(languageCode: $languageCode, productNos: $productNos, isIncludeNotForSale: $isIncludeNotForSale) {
      ...productCards
      brand {
        ...brand
      }
      funding {
        ...funding
      }
    }
  }
  ${ProductCardsFragmentDoc}
  ${BrandFragmentDoc}
  ${FundingFragmentDoc}
`;

/**
 * __useFundingProductCardsQuery__
 *
 * To run a query within a React component, call `useFundingProductCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundingProductCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundingProductCardsQuery({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *      productNos: // value for 'productNos'
 *      isIncludeNotForSale: // value for 'isIncludeNotForSale'
 *   },
 * });
 */
export function useFundingProductCardsQuery(baseOptions: Apollo.QueryHookOptions<FundingProductCardsQuery, FundingProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FundingProductCardsQuery, FundingProductCardsQueryVariables>(FundingProductCardsDocument, options);
}
export function useFundingProductCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundingProductCardsQuery, FundingProductCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FundingProductCardsQuery, FundingProductCardsQueryVariables>(FundingProductCardsDocument, options);
}
export type FundingProductCardsQueryHookResult = ReturnType<typeof useFundingProductCardsQuery>;
export type FundingProductCardsLazyQueryHookResult = ReturnType<typeof useFundingProductCardsLazyQuery>;
export type FundingProductCardsQueryResult = Apollo.QueryResult<FundingProductCardsQuery, FundingProductCardsQueryVariables>;
export const ProductsDocument = gql`
  query Products($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: ProductsConditionInput!, $sort: Sort!) {
    products(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      data {
        ...ProductsData
      }
      pagination {
        ...ProductsPagination
      }
    }
  }
  ${ProductsDataFragmentDoc}
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProductsQuery(baseOptions: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const PresetProductsDocument = gql`
  query PresetProducts($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $preset: PRESET_PRODUCTS_PRESET!) {
    presetProducts(language: $language, pagination: $pagination, preset: $preset) {
      data {
        ...ProductsData
      }
      pagination {
        ...ProductsPagination
      }
    }
  }
  ${ProductsDataFragmentDoc}
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __usePresetProductsQuery__
 *
 * To run a query within a React component, call `usePresetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresetProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      preset: // value for 'preset'
 *   },
 * });
 */
export function usePresetProductsQuery(baseOptions: Apollo.QueryHookOptions<PresetProductsQuery, PresetProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PresetProductsQuery, PresetProductsQueryVariables>(PresetProductsDocument, options);
}
export function usePresetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PresetProductsQuery, PresetProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PresetProductsQuery, PresetProductsQueryVariables>(PresetProductsDocument, options);
}
export type PresetProductsQueryHookResult = ReturnType<typeof usePresetProductsQuery>;
export type PresetProductsLazyQueryHookResult = ReturnType<typeof usePresetProductsLazyQuery>;
export type PresetProductsQueryResult = Apollo.QueryResult<PresetProductsQuery, PresetProductsQueryVariables>;
export const OrderedProductsDocument = gql`
  query OrderedProducts($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: OrderedProductsInput_Condition!, $sort: OrderedProductsInput_Sort!) {
    orderedProducts(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      data {
        ...ProductsData
      }
      pagination {
        ...ProductsPagination
      }
    }
  }
  ${ProductsDataFragmentDoc}
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __useOrderedProductsQuery__
 *
 * To run a query within a React component, call `useOrderedProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderedProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderedProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOrderedProductsQuery(baseOptions: Apollo.QueryHookOptions<OrderedProductsQuery, OrderedProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderedProductsQuery, OrderedProductsQueryVariables>(OrderedProductsDocument, options);
}
export function useOrderedProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderedProductsQuery, OrderedProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderedProductsQuery, OrderedProductsQueryVariables>(OrderedProductsDocument, options);
}
export type OrderedProductsQueryHookResult = ReturnType<typeof useOrderedProductsQuery>;
export type OrderedProductsLazyQueryHookResult = ReturnType<typeof useOrderedProductsLazyQuery>;
export type OrderedProductsQueryResult = Apollo.QueryResult<OrderedProductsQuery, OrderedProductsQueryVariables>;
export const ProductsPaginationDocument = gql`
  query ProductsPagination($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: ProductsConditionInput!, $sort: Sort!) {
    products(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      pagination {
        ...ProductsPagination
      }
    }
  }
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __useProductsPaginationQuery__
 *
 * To run a query within a React component, call `useProductsPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsPaginationQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useProductsPaginationQuery(baseOptions: Apollo.QueryHookOptions<ProductsPaginationQuery, ProductsPaginationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsPaginationQuery, ProductsPaginationQueryVariables>(ProductsPaginationDocument, options);
}
export function useProductsPaginationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsPaginationQuery, ProductsPaginationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsPaginationQuery, ProductsPaginationQueryVariables>(ProductsPaginationDocument, options);
}
export type ProductsPaginationQueryHookResult = ReturnType<typeof useProductsPaginationQuery>;
export type ProductsPaginationLazyQueryHookResult = ReturnType<typeof useProductsPaginationLazyQuery>;
export type ProductsPaginationQueryResult = Apollo.QueryResult<ProductsPaginationQuery, ProductsPaginationQueryVariables>;
export const OtherProductsDocument = gql`
  query OtherProducts($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: ProductsConditionInput!, $sort: Sort!) {
    products(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      data {
        ...ProductsData
      }
    }
  }
  ${ProductsDataFragmentDoc}
`;

/**
 * __useOtherProductsQuery__
 *
 * To run a query within a React component, call `useOtherProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOtherProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOtherProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useOtherProductsQuery(baseOptions: Apollo.QueryHookOptions<OtherProductsQuery, OtherProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OtherProductsQuery, OtherProductsQueryVariables>(OtherProductsDocument, options);
}
export function useOtherProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OtherProductsQuery, OtherProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OtherProductsQuery, OtherProductsQueryVariables>(OtherProductsDocument, options);
}
export type OtherProductsQueryHookResult = ReturnType<typeof useOtherProductsQuery>;
export type OtherProductsLazyQueryHookResult = ReturnType<typeof useOtherProductsLazyQuery>;
export type OtherProductsQueryResult = Apollo.QueryResult<OtherProductsQuery, OtherProductsQueryVariables>;
export const SearchProductsDocument = gql`
  query SearchProducts($language: LANGUAGE_CODE!, $pagination: PaginationInput!, $condition: SearchProductsInput_Condition!, $sort: SearchProductsInput_Sort!) {
    searchProducts(language: $language, pagination: $pagination, condition: $condition, sort: $sort) {
      data {
        ...ProductsData
      }
      pagination {
        ...ProductsPagination
      }
    }
  }
  ${ProductsDataFragmentDoc}
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchProductsQuery(baseOptions: Apollo.QueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, options);
}
export function useSearchProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductsQuery, SearchProductsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(SearchProductsDocument, options);
}
export type SearchProductsQueryHookResult = ReturnType<typeof useSearchProductsQuery>;
export type SearchProductsLazyQueryHookResult = ReturnType<typeof useSearchProductsLazyQuery>;
export type SearchProductsQueryResult = Apollo.QueryResult<SearchProductsQuery, SearchProductsQueryVariables>;
export const SearchProductsV2Document = gql`
  query SearchProductsV2(
    $language: SearchProductsV2Input_Language!
    $pagination: SearchProductsV2Input_Pagination!
    $condition: SearchProductsV2Input_Condition!
    $sort: SearchProductsV2Input_Sort!
    $https: Boolean
  ) {
    searchProductsV2(language: $language, pagination: $pagination, condition: $condition, sort: $sort, https: $https) {
      data {
        ...ProductsData
      }
      pagination {
        ...ProductsPagination
      }
    }
  }
  ${ProductsDataFragmentDoc}
  ${ProductsPaginationFragmentDoc}
`;

/**
 * __useSearchProductsV2Query__
 *
 * To run a query within a React component, call `useSearchProductsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsV2Query({
 *   variables: {
 *      language: // value for 'language'
 *      pagination: // value for 'pagination'
 *      condition: // value for 'condition'
 *      sort: // value for 'sort'
 *      https: // value for 'https'
 *   },
 * });
 */
export function useSearchProductsV2Query(baseOptions: Apollo.QueryHookOptions<SearchProductsV2Query, SearchProductsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchProductsV2Query, SearchProductsV2QueryVariables>(SearchProductsV2Document, options);
}
export function useSearchProductsV2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchProductsV2Query, SearchProductsV2QueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchProductsV2Query, SearchProductsV2QueryVariables>(SearchProductsV2Document, options);
}
export type SearchProductsV2QueryHookResult = ReturnType<typeof useSearchProductsV2Query>;
export type SearchProductsV2LazyQueryHookResult = ReturnType<typeof useSearchProductsV2LazyQuery>;
export type SearchProductsV2QueryResult = Apollo.QueryResult<SearchProductsV2Query, SearchProductsV2QueryVariables>;
export type CheckHasAdultProductsQueryVariables = Exact<{
  language: Language_Code;
  condition: CheckHasAdultProducts_Condition;
}>;

export type CheckHasAdultProductsQuery = { __typename?: 'Query'; checkHasAdultProducts: boolean };

export type AssetFileInfoQueryVariables = Exact<{
  id: Scalars['Int']['input'];
  language: Language_Code;
}>;

export type AssetFileInfoQuery = {
  __typename?: 'Query';
  assetFileInfo: {
    __typename?: 'Asset';
    i18ns: Array<{
      __typename?: 'AssetI18n';
      copyright: {
        __typename?: 'DocumentCopyrightOutput';
        isOriginal?: boolean | null;
        warehouseSources?: string | null;
        commercialSources?: string | null;
        hasRealLogo?: string | null;
      };
      fileComponents: Array<{ __typename?: 'AssetI18nFileComponent'; name?: string | null; size?: string | null }>;
      fileUpdates: Array<{ __typename?: 'AssetI18nFileUpdate'; name?: string | null; date?: string | null }>;
    }>;
  };
};

export type GetAttendanceEventDatesQueryVariables = Exact<{
  eventId: Scalars['Float']['input'];
}>;

export type GetAttendanceEventDatesQuery = { __typename?: 'Query'; getAttendanceEventDates: Array<number> };

export type AttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
  isWeekend?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type AttendanceEventMutation = { __typename?: 'Mutation'; attendanceEvent: boolean };

export type MayAttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type MayAttendanceEventMutation = { __typename?: 'Mutation'; mayAttendanceEvent: boolean };

export type JuneAttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type JuneAttendanceEventMutation = { __typename?: 'Mutation'; juneAttendanceEvent: boolean };

export type JulyAttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type JulyAttendanceEventMutation = { __typename?: 'Mutation'; julyAttendanceEvent: boolean };

export type AugustAttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type AugustAttendanceEventMutation = { __typename?: 'Mutation'; augustAttendanceEvent: boolean };

export type SeptemberAttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type SeptemberAttendanceEventMutation = { __typename?: 'Mutation'; septemberAttendanceEvent: boolean };

export type OctoberAttendanceEventMutationVariables = Exact<{
  eventId: Scalars['Float']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type OctoberAttendanceEventMutation = { __typename?: 'Mutation'; octoberAttendanceEvent: boolean };

export type BestProductsQueryVariables = Exact<{
  type: BestProductType;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  isFreeProductInclude?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type BestProductsQuery = { __typename?: 'Query'; bestProducts: Array<number> };

export type BrandPickSummaryQueryVariables = Exact<{
  brandCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type BrandPickSummaryQuery = {
  __typename?: 'Query';
  brandPickSummary: { __typename?: 'ProductCardsSummary'; toggle?: boolean | null; products: Array<{ __typename?: 'Acon3dProduct'; id: number }> };
};

export type BrandProductsWithCategoriesQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  brandCode: Scalars['String']['input'];
}>;

export type BrandProductsWithCategoriesQuery = {
  __typename?: 'Query';
  brandProductsWithCategories: Array<{ __typename?: 'BrandProductsWithCategoriesOutput'; id: number; categories: Array<{ __typename?: 'Acon3dCategory'; code?: string | null }> }>;
};

export type BrandReviewsQueryVariables = Exact<{
  brandId: Scalars['Int']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  languageCode: LanguageCodeEnum;
}>;

export type BrandReviewsQuery = {
  __typename?: 'Query';
  brandReviews: {
    __typename?: 'PaginatedReviewsWithCount';
    count: number;
    rows: Array<{
      __typename?: 'Review';
      rating: number;
      id: number;
      author: string;
      commentsCount: number;
      isImage: boolean;
      isNew: boolean;
      isBestReview: boolean;
      created: any;
      reviewImages?: Array<{ __typename?: 'ReviewImage'; imageNumber: number; imageUrl: string }> | null;
      reviewContentsItems: Array<{ __typename?: 'ReviewContents'; reviewId: number; languageCode: LanguageCodeEnum; isOriginal: boolean; title: string; contents: string }>;
      product?: { __typename?: 'Acon3dProduct'; id: number; title: string; mainImageUrl: string; subImageUrl: string } | null;
    }>;
  };
};

export type PresetBrandsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  preset: Preset_Brands_Input_Preset;
}>;

export type PresetBrandsQuery = {
  __typename?: 'Query';
  presetBrands: {
    __typename?: 'BrandPaginatedOutput';
    data: Array<{
      __typename?: 'BrandOutput';
      id: number;
      code?: string | null;
      name: string;
      productCount: number;
      vipInfo?: Brand_Output_Brand_Vip_Type | null;
      photoInfo?: { __typename?: 'FileOutput'; path?: string | null } | null;
      update?: { __typename?: 'BrandOutputUpdateOutput'; type: Brand_Output_Update_Output_Type; value?: number | null } | null;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number };
  };
};

export type SearchBrandsV2QueryVariables = Exact<{
  language: Language_Code;
  keyword: Scalars['String']['input'];
  https?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchBrandsV2Query = {
  __typename?: 'Query';
  searchBrandsV2: {
    __typename?: 'BrandPaginatedOutput';
    data: Array<{
      __typename?: 'BrandOutput';
      id: number;
      code?: string | null;
      name: string;
      productCount: number;
      photoInfo?: { __typename?: 'FileOutput'; path?: string | null } | null;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number };
  };
};

export type CancelInquiryMutationVariables = Exact<{
  CancelInquiryInput: CancelInquiryInput;
}>;

export type CancelInquiryMutation = { __typename?: 'Mutation'; cancelInquiry: { __typename?: 'Inquiry'; id: number } };

export type CartQueryVariables = Exact<{ [key: string]: never }>;

export type CartQuery = { __typename?: 'Query'; cart: Array<{ __typename?: 'CartOutput'; productNo: number }> };

export type CategoriesV2QueryVariables = Exact<{
  language: Language_Code;
  condition: CategoriesInput_Condition;
  sort: CategoriesInput_Sort;
}>;

export type CategoriesV2Query = {
  __typename?: 'Query';
  categoriesV2: Array<{
    __typename?: 'CategoryOutputV4';
    id: number;
    code: string;
    name: string;
    children: Array<{
      __typename?: 'CategoryOutputV4';
      id: number;
      code: string;
      name: string;
      children: Array<{ __typename?: 'CategoryOutputV4'; id: number; code: string; name: string }>;
    }>;
  }>;
};

export type CheckExistMemberQueryVariables = Exact<{
  accessToken: Scalars['String']['input'];
  snsType: SnsTypeEnum;
}>;

export type CheckExistMemberQuery = { __typename?: 'Query'; checkExistMember: boolean };

export type CheckIssuanceTempPasswordQueryVariables = Exact<{
  snsType: SnsTypeEnum;
  aconMemberId: Scalars['Int']['input'];
}>;

export type CheckIssuanceTempPasswordQuery = { __typename?: 'Query'; checkIssuanceTempPassword: boolean };

export type GetClipsQueryVariables = Exact<{
  language: LanguageCodeEnum;
}>;

export type GetClipsQuery = {
  __typename?: 'Query';
  clips: Array<{
    __typename?: 'Clip';
    id: number;
    godoGoodsNo: number;
    product: { __typename?: 'ProductOutput'; title: string; priceInfo: { __typename?: 'ProductPriceInfoOutput'; salePrice: number; price: number } };
  }>;
};

export type GetClipsV2QueryVariables = Exact<{
  sort: GetClipsSort;
  pagination?: InputMaybe<PaginationInput>;
  productsCondition?: InputMaybe<GetClipsProductsCondition>;
  language: LanguageCodeEnum;
}>;

export type GetClipsV2Query = {
  __typename?: 'Query';
  clipsV2: Array<{
    __typename?: 'Clip';
    id: number;
    godoGoodsNo: number;
    product: { __typename?: 'ProductOutput'; bookInfo: { __typename?: 'ProductBookInfoOutput'; isbn?: string | null } };
  }>;
};

export type CreateInquiryMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>;
  languageCode: LanguageCodeEnum;
  type: InquiryTypeEnum;
  subType?: InputMaybe<InquirySubTypeEnum>;
  content?: InputMaybe<Scalars['String']['input']>;
  taxes?: InputMaybe<TaxesInput>;
  changePenName?: InputMaybe<ChangePenNameInput>;
  inquiryProduct?: InputMaybe<InquiryProductInput>;
  inquiryOrders?: InputMaybe<Array<InputMaybe<InquiryOrderInput>> | InputMaybe<InquiryOrderInput>>;
  refundAccount?: InputMaybe<RefundAccountInput>;
  uploadFiles: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type CreateInquiryMutation = { __typename?: 'Mutation'; createInquiry: { __typename?: 'Inquiry'; id: number } };

export type DeleteCartCacheMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteCartCacheMutation = { __typename?: 'Mutation'; deleteCartCache: boolean };

export type DeleteMyReviewMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  languageCode: Scalars['String']['input'];
}>;

export type DeleteMyReviewMutation = { __typename?: 'Mutation'; deleteMyReview: boolean };

export type DisplayAssetWithItemsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  type: Scalars['String']['input'];
}>;

export type DisplayAssetWithItemsQuery = {
  __typename?: 'Query';
  displayAssetWithItems: {
    __typename?: 'DisplayAssetWithItemsOutput';
    assets: {
      __typename?: 'ProductPaginatedOutput';
      data: Array<{
        __typename?: 'ProductOutput';
        id: number;
        title: string;
        priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
        promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
        imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
        dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
        tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
        brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
        extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
        applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
      }>;
      pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
    };
    displayInfo: { __typename?: 'DisplayAssetInfoOutput'; title: string };
  };
};

export type DisplayBrandWithItemsQueryVariables = Exact<{
  language: Language_Code;
  type: Scalars['String']['input'];
  pagination: PaginationInput;
}>;

export type DisplayBrandWithItemsQuery = {
  __typename?: 'Query';
  displayBrandWithItems: {
    __typename?: 'DisplayBrandWithItemsOutput';
    displayInfo: { __typename?: 'DisplayBrandInfoOutput'; title: string };
    brands: {
      __typename?: 'BrandPaginatedOutput';
      data: Array<{
        __typename?: 'BrandOutput';
        id: number;
        code?: string | null;
        name: string;
        productCount: number;
        vipInfo?: Brand_Output_Brand_Vip_Type | null;
        photoInfo?: { __typename?: 'FileOutput'; path?: string | null } | null;
        update?: { __typename?: 'BrandOutputUpdateOutput'; type: Brand_Output_Update_Output_Type; value?: number | null } | null;
      }>;
      pagination: { __typename?: 'PaginationOutput'; totalCount: number };
    };
  };
};

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['String']['input'];
}>;

export type DocumentQuery = {
  __typename?: 'Query';
  document: {
    __typename?: 'SingleDocumentOutput';
    id: string;
    assetId?: number | null;
    brandId?: number | null;
    language?: Language_Code | null;
    status?: Document_Status | null;
    title?: string | null;
    contentHead?: string | null;
    contentBody?: string | null;
    price?: number | null;
    mainImage?: string | null;
    subImage?: string | null;
    fileName?: string | null;
    filePath?: string | null;
    brandNames?: Array<{ __typename?: 'SingleDocumentBrandName'; language?: Language_Code | null; name?: string | null } | null> | null;
    categories: Array<{ __typename?: 'CategoryOutputV4'; code: string }>;
    copyright?: {
      __typename?: 'DocumentCopyrightOutput';
      isOriginal?: boolean | null;
      warehouseSources?: string | null;
      commercialSources?: string | null;
      hasRealLogo?: string | null;
    } | null;
    tags: Array<{ __typename?: 'TagOutput'; id: number; name: string }>;
  };
};

export type FaqAconTypesQueryVariables = Exact<{
  lang: Scalars['String']['input'];
}>;

export type FaqAconTypesQuery = {
  __typename?: 'Query';
  faqAconTypes: Array<{
    __typename?: 'FaqAconTypeModel';
    id: number;
    code: string;
    i18n?: Array<{ __typename?: 'FaqAconTypeI18nModel'; name?: string | null; language: { __typename?: 'Language'; code: string } }> | null;
  }>;
};

export type FaqAconsQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type FaqAconsQuery = {
  __typename?: 'Query';
  faqAcons: Array<{
    __typename?: 'FaqAconModel';
    id: number;
    title: string;
    subtitle: string;
    body: string;
    order: number;
    created?: any | null;
    updated?: any | null;
    type: { __typename?: 'FaqAconTypeModel'; id: number; code: string };
    language: { __typename?: 'Language'; id: number; code: string };
  }>;
};

export type FetchPromotionsQueryVariables = Exact<{
  search?: InputMaybe<FetchPromotionsSearchInput>;
  brandId?: InputMaybe<Scalars['Int']['input']>;
}>;

export type FetchPromotionsQuery = {
  __typename?: 'Query';
  fetchPromotions: {
    __typename?: 'PaginatedPromotionOutput';
    data: Array<{
      __typename?: 'PromotionOutput';
      suspendedAt?: any | null;
      since?: any | null;
      until?: any | null;
      items: Array<{ __typename?: 'PromotionItemOutput'; suspendedAt?: any | null; asset?: { __typename?: 'Asset'; id: number } | null }>;
    }>;
  };
};

export type IfPaidMemberQueryVariables = Exact<{ [key: string]: never }>;

export type IfPaidMemberQuery = { __typename?: 'Query'; ifPaidMember: boolean };

export type IsParticipatedEventQueryVariables = Exact<{
  eventId: Scalars['Float']['input'];
}>;

export type IsParticipatedEventQuery = { __typename?: 'Query'; isParticipatedEvent: boolean };

export type IssueEventCouponMutationVariables = Exact<{
  issueEventCouponInput: IssueEventCouponInput;
}>;

export type IssueEventCouponMutation = { __typename?: 'Mutation'; issueEventCoupon: boolean };

export type GetAconCashListQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
}>;

export type GetAconCashListQuery = {
  __typename?: 'Query';
  getCashList?: {
    __typename?: 'CashList';
    totalCount: number;
    items: Array<{
      __typename?: 'CashItem';
      deposit: number;
      contents: string;
      regDate: string;
      afterDeposit: number;
      payMethod?: string | null;
      status?: string | null;
      vBank?: { __typename?: 'VBank'; bankName?: string | null; bankHolder?: string | null; bankNumber?: string | null; remittanceDate?: any | null } | null;
    }>;
  } | null;
};

export type GetApplicationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetApplicationsQuery = { __typename?: 'Query'; getApplications: Array<{ __typename?: 'ApplicationEntity'; id: number; name: string }> };

export type SearchApplicationsV2QueryVariables = Exact<{
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  condition: SearchProductsV2Input_Condition;
  sort: SearchProductsV2Input_Sort;
  https?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchApplicationsV2Query = {
  __typename?: 'Query';
  searchApplicationsV2: {
    __typename?: 'ApplicationPaginatedOutput';
    data: Array<{ __typename?: 'ApplicationOutput'; id: number; name: string }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number };
  };
};

export type BenefitProductsQueryVariables = Exact<{
  lang: Scalars['String']['input'];
  productType?: InputMaybe<ProductType>;
}>;

export type BenefitProductsQuery = {
  __typename?: 'Query';
  benefitProducts: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    extensions: Array<string>;
    salePrice: number;
    orderCount: number;
    isAdultOnly: boolean;
    applications?: Array<{ __typename?: 'ApplicationEntity'; id: number; name: string }> | null;
  }>;
};

export type GetBrandQueryVariables = Exact<{
  lang: LanguageCodeEnum;
  brandCode: Scalars['String']['input'];
}>;

export type GetBrandQuery = {
  __typename?: 'Query';
  getBrand: {
    __typename?: 'BrandEntity';
    id: number;
    name?: string | null;
    godoBrandCd?: string | null;
    useBrandPick: boolean;
    brandPickIds: Array<number>;
    brandPickCount: number;
    introduction?: string | null;
    introductionLang?: LanguageCodeEnum | null;
    showBrandReview: boolean;
    modified?: any | null;
    photo?: {
      __typename?: 'UploadFileClone';
      id?: number | null;
      azureStoragePath: string;
      fileName: string;
      size?: number | null;
      creator?: number | null;
      updater?: number | null;
    } | null;
  };
};

export type GetCartsQueryVariables = Exact<{
  lang: Scalars['String']['input'];
  noCache?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCartsQuery = {
  __typename?: 'Query';
  carts: Array<{ __typename?: 'CartItem'; sno: number; goodsNo: number; point: number; title: string; price: number; realPrice: number }>;
};

export type GetCategoriesV3QueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;

export type GetCategoriesV3Query = {
  __typename?: 'Query';
  categoriesV3: Array<{
    __typename?: 'CategoryOutputV2';
    type: string;
    categories: Array<{
      __typename?: 'CategoryMolecule';
      primary: { __typename?: 'CategoryAtom'; code: string; name: string };
      secondaries: Array<{ __typename?: 'CategoryAtom'; code: string; name: string }>;
    }>;
  }>;
};

export type GetCategoryQueryVariables = Exact<{
  godoCateCd: Scalars['String']['input'];
}>;

export type GetCategoryQuery = {
  __typename?: 'Query';
  getCategory?: {
    __typename?: 'GodoCategory';
    type?: string | null;
    trans: Array<{
      __typename?: 'GodoTrans';
      lang: string;
      visibleResultStats: boolean;
      name: string;
      banner: { __typename?: 'GodoBanner'; image?: string | null; anchor?: string | null; anchorTarget: boolean; map?: string | null; color?: string | null };
    }>;
  } | null;
};

export type GetCouponListQueryVariables = Exact<{
  lang: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCouponListQuery = {
  __typename?: 'Query';
  getCouponList: {
    __typename?: 'CouponList';
    totalCount: number;
    items: Array<{
      __typename?: 'CouponItem';
      name: string;
      couponKindType: string;
      couponBenefit: number;
      couponBenefitType: string;
      couponMaxBenefit: number;
      isCouponMaxBenefit: boolean;
      couponApplyProductType: string;
      couponMinOrderPrice: number;
      couponMinOrderType?: string | null;
      isCouponApplyDuplicate: boolean;
      memberCouponUsable?: string | null;
      startDate: any;
      endDate: any;
      regDt: any;
      couponApplyGoods?: Array<{ __typename?: 'CouponApplyGoods'; goodsNm: string; goodsNo: string }> | null;
      couponApplyCategory?: Array<{ __typename?: 'CouponApplyCategory'; no: string; name: string }> | null;
    }>;
  };
};

export type GetExchangeRateQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;

export type GetExchangeRateQuery = { __typename?: 'Query'; exchangeRate: number };

export type GetExtensionConfigsQueryVariables = Exact<{ [key: string]: never }>;

export type GetExtensionConfigsQuery = { __typename?: 'Query'; getExtensionConfigs?: Array<{ __typename?: 'ExtensionConfig'; id: number; name: string }> | null };

export type GetGodoBrandNameQueryVariables = Exact<{
  lang?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetGodoBrandNameQuery = { __typename?: 'Query'; getGodoBrandName: string };

export type GetGodoCategoriesQueryVariables = Exact<{
  lang: Scalars['String']['input'];
  cateCds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetGodoCategoriesQuery = { __typename?: 'Query'; getTransGodoCategoryItems: Array<{ __typename?: 'GodoDisplayCategory'; name: string; code: string }> };

export type GetGodoMyOrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetGodoMyOrdersQuery = {
  __typename?: 'Query';
  godoMyOrders: Array<{ __typename?: 'IGodoOrderGoodsNo'; orderNo: string; goodsNo: string; regDt: string; orderStatus: OrderStatusEnum; isVirtualAccount: boolean }>;
};

export type GetInquiriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetInquiriesQuery = {
  __typename?: 'Query';
  inquiries: Array<{
    __typename?: 'Inquiry';
    id: number;
    type: InquiryTypeEnum;
    content: string;
    status: InquiryStatusEnum;
    created?: any | null;
    answer?: { __typename?: 'InquiryAnswer'; answer: string } | null;
  }>;
};

export type GetInquiryQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetInquiryQuery = {
  __typename?: 'Query';
  inquiry: {
    __typename?: 'Inquiry';
    id: number;
    godoMemNo?: string | null;
    godoUserId?: string | null;
    languageCode: LanguageCodeEnum;
    type: InquiryTypeEnum;
    status: InquiryStatusEnum;
    subType?: InquirySubTypeEnum | null;
    content: string;
    created?: any | null;
    updated?: any | null;
    inquiryAcon3dProduct?: { __typename?: 'Acon3dProduct'; id: number; title: string } | null;
    inquiryOrders?: Array<{
      __typename?: 'InquiryOrder';
      godoOrderNo: string;
      godoGoodsDetails: Array<{ __typename?: 'InquiryOrderGoodsDetail'; godoGoodsNo: string; orderStatus: number }>;
    } | null> | null;
    answer?: { __typename?: 'InquiryAnswer'; answer: string; created?: any | null } | null;
    uploadFiles: Array<{ __typename?: 'UploadFile'; azureStoragePath: string; fileName: string }>;
  };
};

export type GetInquiryListQueryVariables = Exact<{
  type?: InputMaybe<InquiryTypeEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetInquiryListQuery = {
  __typename?: 'Query';
  inquiryCount: number;
  inquiries: Array<{
    __typename?: 'Inquiry';
    id: number;
    type: InquiryTypeEnum;
    content: string;
    status: InquiryStatusEnum;
    created?: any | null;
    answer?: { __typename?: 'InquiryAnswer'; answer: string } | null;
  }>;
};

export type GetIsFirstDownloadQueryVariables = Exact<{
  goodsNo: Scalars['Int']['input'];
  orderNo: Scalars['String']['input'];
}>;

export type GetIsFirstDownloadQuery = { __typename?: 'Query'; isFirstDownload: boolean };

export type GetMyOrdersQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
}>;

export type GetMyOrdersQuery = {
  __typename?: 'Query';
  getMyOrders: Array<{
    __typename?: 'MyOrderReturn';
    godoOrderNo: number;
    godoGoodsNo: number;
    price: number;
    orderGoodsStatus: OrderDisplayStatusEnum;
    regDt: string;
    reviewId?: number | null;
    product?: {
      __typename?: 'Acon3dProduct';
      title: string;
      mainImageUrl: string;
      subImageUrl: string;
      extensions: Array<string>;
      salePrice: number;
      price: number;
      book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
      brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
      funding?: {
        __typename?: 'Acon3dFunding';
        deliveryDate: any;
        endDate: any;
        goalAmount: number;
        soldAmount: number;
        soldVolume: number;
        startDate: any;
        status: FundingStatus;
      } | null;
    } | null;
    downloadCountInfo?: { __typename?: 'DownloadCountInfo'; limit: number; remained: number } | null;
  }>;
};

export type GetMypageUserDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetMypageUserDataQuery = { __typename?: 'Query'; hasNewPoint: boolean; hasNewCoupon: boolean; inquiryCount: number };

export type GetOrderBenefitPointQueryVariables = Exact<{
  godoOrderNo: Scalars['String']['input'];
}>;

export type GetOrderBenefitPointQuery = { __typename?: 'Query'; getOrderBenefitPoint: number };

export type GetOrderNoWithoutReviewQueryVariables = Exact<{
  godoGoodsNo: Scalars['String']['input'];
}>;

export type GetOrderNoWithoutReviewQuery = { __typename?: 'Query'; getOrderNoWithoutReview?: string | null };

export type GetOrderViewV2QueryVariables = Exact<{
  lang: Scalars['String']['input'];
  orderNo: Scalars['Float']['input'];
}>;

export type GetOrderViewV2Query = {
  __typename?: 'Query';
  orderViewV2: {
    __typename?: 'OrderViewDto';
    orderDate: any;
    settleReceipt: string;
    settleName: string;
    settleMethod: string;
    status: string;
    bank: string;
    bankAccount: string;
    bankSender: string;
    bankHolder: string;
    pgFailReason: string;
    settlePrice: number;
    totalGoodsDcPrice: number;
    totalCouponDeliveryDcPrice: number;
    totalGoodsPrice: number;
    totalCouponGoodsDcPrice: number;
    totalCouponOrderDcPrice: number;
    useDeposit: number;
    useMileage: number;
    receiptFl: string;
    pgSettleCd: Array<string>;
    pgSettleNm: Array<string>;
    settleKind: string;
    cash?: string | null;
    tax?: string | null;
    receipt?: {
      __typename?: 'OrderViewReceiptDto';
      cashFl?: string | null;
      limitDateFl?: string | null;
      particularFl?: string | null;
      periodDay?: string | null;
      periodFl?: string | null;
      taxFl?: string | null;
      taxInvoiceLimitDate?: string | null;
    } | null;
    user: { __typename?: 'OrderViewUserInfoDto'; cellPhone: string; email: string; name: string };
    license?: {
      __typename?: 'OrderLicenseModel';
      orderNo: string;
      type: string;
      names?: Array<{ __typename?: 'OrderLicenseNameModel'; name: string }> | null;
      projects?: Array<{ __typename?: 'OrderLicenseProjectModel'; name: string; goods?: Array<{ __typename?: 'OrderLicenseProjectGoodsModel'; goodsNo: number }> | null }> | null;
    } | null;
    orderProducts: Array<{
      __typename?: 'OrderViewProductDto';
      goodsNo: number;
      price: number;
      salePrice: number;
      status: string;
      statusStr: string;
      imageUrl: string;
      product?: {
        __typename?: 'Acon3dProduct';
        title: string;
        extensions: Array<string>;
        mainImageUrl: string;
        subImageUrl: string;
        brand: { __typename?: 'Acon3dBrand'; id?: number | null; name?: string | null; code?: string | null };
      } | null;
    }>;
    order?: {
      __typename?: 'OrderDto';
      id: string;
      status?: string | null;
      payment?: { __typename?: 'OrderPaymentDto'; payMethod: string; receiptUrl?: string | null } | null;
    } | null;
  };
};

export type GetOtherProductListQueryVariables = Exact<{
  godoGoodsNo: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
  isCount: Scalars['Boolean']['input'];
}>;

export type GetOtherProductListQuery = {
  __typename?: 'Query';
  otherProductList: {
    __typename?: 'RelateProductListOutput';
    brandCd?: string | null;
    cateCd?: string | null;
    count?: number | null;
    items?: Array<{
      __typename?: 'RelateProductListItem';
      godoGoodsNo: number;
      realPrice: string;
      price: string;
      imageUrl: string;
      title: string;
      brand: string;
      brandCd: string;
    }> | null;
  };
};

export type PhotoReviewQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  languageCode: LanguageCodeEnum;
  godoCateCd?: InputMaybe<Scalars['Int']['input']>;
}>;

export type PhotoReviewQuery = {
  __typename?: 'Query';
  getPhotoReview: {
    __typename?: 'PaginatedDetailReview';
    nextCursor?: number | null;
    prevCursor?: number | null;
    nth?: number | null;
    node?: {
      __typename?: 'Review';
      imageUrl?: string | null;
      rating: number;
      godoUserId?: string | null;
      created: any;
      product?: { __typename?: 'Acon3dProduct'; id: number; title: string; brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null } } | null;
      reviewContentsItems: Array<{ __typename?: 'ReviewContents'; isOriginal: boolean; languageCode: LanguageCodeEnum; title: string; contents: string }>;
    } | null;
  };
};

export type GetPhotoReviewTotalCountQueryVariables = Exact<{
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  godoCateCd?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPhotoReviewTotalCountQuery = { __typename?: 'Query'; getPhotoReviewTotalCount: number };

export type PhotoReviewsQueryVariables = Exact<{
  isBestReview?: InputMaybe<Scalars['Boolean']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['Int']['input']>;
  godoCateCd?: InputMaybe<Scalars['Int']['input']>;
  languageCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type PhotoReviewsQuery = {
  __typename?: 'Query';
  getPhotoReviews: {
    __typename?: 'PaginatedReview';
    nodes?: Array<{
      __typename?: 'Review';
      id: number;
      godoUserId?: string | null;
      rating: number;
      imageUrl?: string | null;
      created: any;
      reviewContentsItems: Array<{ __typename?: 'ReviewContents'; title: string; contents: string; isOriginal: boolean; languageCode: LanguageCodeEnum }>;
    }> | null;
    edge?: { __typename?: 'ReviewEdge'; cursor?: number | null; node?: { __typename?: 'Review'; id: number } | null } | null;
  };
};

export type TempReviewsQueryVariables = Exact<{
  pagination: ReviewsInput_Pagination;
  condition: ReviewsInput_Condition;
  sort: ReviewsInput_Sort;
  languageCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type TempReviewsQuery = {
  __typename?: 'Query';
  tempReviews: {
    __typename?: 'PaginatedReview';
    total?: number | null;
    nodes?: Array<{
      __typename?: 'Review';
      id: number;
      godoUserId?: string | null;
      rating: number;
      imageUrl?: string | null;
      created: any;
      reviewContentsItems: Array<{ __typename?: 'ReviewContents'; title: string; contents: string; isOriginal: boolean; languageCode: LanguageCodeEnum }>;
    }> | null;
    edge?: { __typename?: 'ReviewEdge'; cursor?: number | null; node?: { __typename?: 'Review'; id: number } | null } | null;
  };
};

export type GetPopupsHereQueryVariables = Exact<{
  url: Scalars['String']['input'];
  origin: Scalars['String']['input'];
}>;

export type GetPopupsHereQuery = {
  __typename?: 'Query';
  getPopupsHere: Array<{
    __typename?: 'PopupV2';
    id: number;
    title: string;
    text?: string | null;
    subText?: string | null;
    buttonText?: string | null;
    connectedUrl?: string | null;
    image?: { __typename?: 'UploadFile'; azureStoragePath: string } | null;
  }>;
};

export type GetPromotedProductsQueryVariables = Exact<{
  code: Scalars['String']['input'];
  take: Scalars['Int']['input'];
  lang: LanguageCodeEnum;
}>;

export type GetPromotedProductsQuery = {
  __typename?: 'Query';
  getPromotedProducts: Array<{
    __typename?: 'PromotedProductOutput';
    id: number;
    languageCode: LanguageCodeEnum;
    isExclusive: boolean;
    title: string;
    price: number;
    salePrice: number;
    mainImageUrl: string;
    subImageUrl: string;
    orderCount: number;
    isAconOnly: boolean;
    viewCount: number;
    created: any;
    reviewCount: number;
    updated?: any | null;
    extensions: Array<string>;
    badgeNames: Array<string | null>;
    promotion: {
      __typename?: 'PromotionOutput';
      id: number;
      title?: string | null;
      discountType?: EPromotionDiscountType | null;
      discountValue?: number | null;
      since?: any | null;
      until?: any | null;
      status?: EPromotionStatus | null;
      concept: {
        __typename?: 'PromotionConceptOutput';
        title?: string | null;
        code: string;
        id: number;
        titleRegional?: { __typename?: 'GqlRegional'; KO?: string | null; EN?: string | null; JA?: string | null; ZH?: string | null } | null;
      };
      items: Array<{ __typename?: 'PromotionItemOutput'; until?: any | null; since?: any | null; discountValue?: number | null; discountType?: EPromotionDiscountType | null }>;
    };
    brand: { __typename?: 'Acon3dBrand'; id?: number | null; name?: string | null; code?: string | null };
    applications?: Array<{ __typename?: 'ApplicationEntity'; id: number; name: string }> | null;
  }>;
};

export type GetPromotionProductsQueryVariables = Exact<{
  code: Scalars['String']['input'];
  take: Scalars['Int']['input'];
  lang: LanguageCodeEnum;
}>;

export type GetPromotionProductsQuery = {
  __typename?: 'Query';
  getPromotionProducts: Array<{
    __typename?: 'PromotionProductOutput';
    promotion: {
      __typename?: 'PromotionOutput';
      id: number;
      title?: string | null;
      discountType?: EPromotionDiscountType | null;
      discountValue?: number | null;
      since?: any | null;
      until?: any | null;
      status?: EPromotionStatus | null;
      concept: {
        __typename?: 'PromotionConceptOutput';
        title?: string | null;
        code: string;
        id: number;
        titleRegional?: { __typename?: 'GqlRegional'; KO?: string | null; EN?: string | null; JA?: string | null; ZH?: string | null } | null;
      };
      items: Array<{ __typename?: 'PromotionItemOutput'; until?: any | null; since?: any | null; discountValue?: number | null; discountType?: EPromotionDiscountType | null }>;
    };
    product: {
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    };
  }>;
};

export type GetRecentOrdersV2QueryVariables = Exact<{
  langCode: Scalars['String']['input'];
}>;

export type GetRecentOrdersV2Query = {
  __typename?: 'Query';
  recentOrdersV2: Array<{
    __typename?: 'RecentOrder';
    orderNo: number;
    orderedAt: any;
    orderedProducts: Array<{
      __typename?: 'RecentOrderedProduct';
      sno: number;
      title: string;
      godoGoodsNo: number;
      price: number;
      status: string;
      statusString: string;
      hasWrittenReview: boolean;
      reviewSno?: number | null;
      reviewId?: number | null;
      product?: {
        __typename?: 'Acon3dProduct';
        title: string;
        price: number;
        salePrice: number;
        extensions: Array<string>;
        mainImageUrl: string;
        subImageUrl: string;
        brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
        book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
      } | null;
    }>;
  }>;
};

export type GetSimilarProductListQueryVariables = Exact<{
  godoGoodsNo: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
  isCount: Scalars['Boolean']['input'];
}>;

export type GetSimilarProductListQuery = {
  __typename?: 'Query';
  similarProductList: {
    __typename?: 'RelateProductListOutput';
    cateCd?: string | null;
    count?: number | null;
    items?: Array<{
      __typename?: 'RelateProductListItem';
      godoGoodsNo: number;
      title: string;
      realPrice: string;
      price: string;
      imageUrl: string;
      brand: string;
      brandCd: string;
    }> | null;
  };
};

export type GetTransGodoCategoryChildItemsQueryVariables = Exact<{
  lang: Scalars['String']['input'];
  cateCds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetTransGodoCategoryChildItemsQuery = {
  __typename?: 'Query';
  getTransGodoCategoryChildItems: Array<{ __typename?: 'GodoCategoryOutput'; name: string; code: string; isLink: boolean; isVisible: boolean }>;
};

export type GiftsQueryVariables = Exact<{ [key: string]: never }>;

export type GiftsQuery = { __typename?: 'Query'; gifts: Array<{ __typename?: 'Gift'; id: number; price: number; isOpen: boolean }> };

export type ReceivedGiftsQueryVariables = Exact<{ [key: string]: never }>;

export type ReceivedGiftsQuery = {
  __typename?: 'Query';
  receivedGifts: Array<{
    __typename?: 'OrderGift';
    id: string;
    redemptionDate?: any | null;
    created: any;
    gift: { __typename?: 'Gift'; title: string };
    memberGiftHistories: Array<{ __typename?: 'MemberGiftHistory'; created: any; giftResidualAmount: number; totalResidualAmount: number }>;
  }>;
};

export type ReceivedGiftQueryVariables = Exact<{
  orderGiftId: Scalars['String']['input'];
}>;

export type ReceivedGiftQuery = {
  __typename?: 'Query';
  receivedGift: {
    __typename?: 'OrderGift';
    id: string;
    redemptionDate?: any | null;
    expirationDate: any;
    status: string;
    gift: { __typename?: 'Gift'; title: string };
    memberGiftHistories: Array<{
      __typename?: 'MemberGiftHistory';
      changeAmount: number;
      created: any;
      giftResidualAmount: number;
      totalResidualAmount: number;
      orderProduct?: { __typename?: 'OrderProductClone'; godoOrderId: string } | null;
    }>;
  };
};

export type SentGiftsQueryVariables = Exact<{ [key: string]: never }>;

export type SentGiftsQuery = {
  __typename?: 'Query';
  sentGifts: Array<{
    __typename?: 'OrderGift';
    id: string;
    created: any;
    status: string;
    gift: { __typename?: 'Gift'; title: string };
    orderPayment: { __typename?: 'OrderPayment'; vbankIssuedAt?: any | null };
  }>;
};

export type SentGiftQueryVariables = Exact<{
  orderGiftId: Scalars['String']['input'];
}>;

export type SentGiftQuery = {
  __typename?: 'Query';
  sentGift: {
    __typename?: 'OrderGift';
    id: string;
    inputRecipientName: string;
    inputRecipientCellPhone: string;
    sendMessage: string;
    created: any;
    updated: any;
    status: string;
    amount: number;
    gift: { __typename?: 'Gift'; title: string };
    orderPayment: {
      __typename?: 'OrderPayment';
      payMethod: string;
      vbankCode?: string | null;
      vbankName?: string | null;
      vbankHolder?: string | null;
      vbankNum?: string | null;
      vbankIssuedAt?: any | null;
    };
  };
};

export type HackOutQueryVariables = Exact<{ [key: string]: never }>;

export type HackOutQuery = { __typename?: 'Query'; hackOut: boolean };

export type HasOpenrunPromotedProductsQueryVariables = Exact<{
  assetIds: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;

export type HasOpenrunPromotedProductsQuery = { __typename?: 'Query'; hasOpenrunPromotedProducts: boolean };

export type HasOrderedQueryVariables = Exact<{
  assetId: Scalars['Float']['input'];
}>;

export type HasOrderedQuery = { __typename?: 'Query'; hasOrdered: boolean };

export type HotProductsQueryVariables = Exact<{
  count?: InputMaybe<Scalars['Int']['input']>;
}>;

export type HotProductsQuery = { __typename?: 'Query'; hotProducts: Array<number> };

export type MainProductListV2QueryVariables = Exact<{
  sno: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
}>;

export type MainProductListV2Query = {
  __typename?: 'Query';
  mainProductListV2: { __typename?: 'MainProductListV2Return'; sno: string; title: string; source: string; productList: Array<number> };
};

export type MemberDepositDetailQueryVariables = Exact<{ [key: string]: never }>;

export type MemberDepositDetailQuery = { __typename?: 'Query'; memberDepositDetail: { __typename?: 'MemberDepositDetailOutput'; purchaseDeposit: number; giftDeposit: number } };

export type MoonGreetingPaidAmountQueryVariables = Exact<{ [key: string]: never }>;

export type MoonGreetingPaidAmountQuery = { __typename?: 'Query'; memberMoonWelcomeEventPeriodPaidAmount: number };

export type FundingProductNosQueryVariables = Exact<{ [key: string]: never }>;

export type FundingProductNosQuery = { __typename?: 'Query'; fundingProductNos: Array<number> };

export type PaginateProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  filter: ProductListFilterInput;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Array<ProductTypes> | ProductTypes>;
  onlyDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  onlyFree?: InputMaybe<Scalars['Boolean']['input']>;
  onlyAdult?: InputMaybe<Scalars['Boolean']['input']>;
  onlyNew?: InputMaybe<Scalars['Boolean']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ProductListSort>;
  option?: InputMaybe<ProductListOptionInput>;
}>;

export type PaginateProductCardsQuery = {
  __typename?: 'Query';
  paginateProductCards: {
    __typename?: 'TempProductPaginatedOutput';
    allCount: number;
    sort: ProductListSort;
    meta: { __typename?: 'Pagination'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
    data: Array<{
      __typename?: 'ProduktPublicOutput';
      id: number;
      title: string;
      price: number;
      salePrice: number;
      onSale: boolean;
      onDisplay: boolean;
      promotionEndDate?: any | null;
      orderCount: number;
      viewCount: number;
      reviewCount: number;
      mainImageUrl: string;
      subImageUrl: string;
      created: any;
      updated?: any | null;
      isAdultOnly: boolean;
      badgeNames: Array<string>;
      extensions: Array<string>;
      brand: { __typename?: 'BrandPublicOutput'; id: number; name: string; code: string; isExclusive: boolean };
      categories: Array<{ __typename?: 'CategoryPublicOutput'; id: number; name: string; code: string }>;
    }>;
  };
};

export type BrandFragment = { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };

export type CategoriesFragment = { __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null };

export type ModelConfigsFragment = { __typename?: 'Acon3dModelConfig'; id?: number | null; name?: string | null };

export type BannersFragment = {
  __typename?: 'Acon3dBanner';
  id?: number | null;
  text?: string | null;
  href?: string | null;
  imageUrl?: string | null;
  type?: string | null;
  created?: any | null;
};

export type PromotionsBadgeFragment = {
  __typename?: 'Acon3dPromotionBadge';
  id?: number | null;
  type?: string | null;
  languageCode?: LanguageCodeEnum | null;
  content?: string | null;
};

export type PromotionsFragment = {
  __typename?: 'Acon3dPromotion';
  id: number;
  startDate?: any | null;
  currentRound?: number | null;
  remainingDays?: number | null;
  rounds?: Array<{ __typename?: 'Acon3dPromotionRound'; roundNo?: number | null; salePrice?: number | null; startDate?: any | null; endDate?: any | null }> | null;
  badges?: Array<{ __typename?: 'Acon3dPromotionBadge'; id?: number | null; type?: string | null; languageCode?: LanguageCodeEnum | null; content?: string | null }> | null;
};

export type LinkedProductsFragment = { __typename?: 'Acon3dLinkedProduct'; id: number; extensions: Array<string> };

export type PackagesFragment = {
  __typename?: 'Acon3dPackage';
  type: string;
  parent?: { __typename?: 'Acon3dProduct'; id: number } | null;
  children: Array<{ __typename?: 'Acon3dProduct'; id: number }>;
};

export type FundingFragment = {
  __typename?: 'Acon3dFunding';
  deliveryDate: any;
  endDate: any;
  goalAmount: number;
  soldAmount: number;
  soldVolume: number;
  startDate: any;
  status: FundingStatus;
};

export type PromotionInfoFragment = {
  __typename?: 'PromotionOutput';
  discountValue?: number | null;
  concept: { __typename?: 'PromotionConceptOutput'; code: string };
  items: Array<{ __typename?: 'PromotionItemOutput'; id: number; discountValue?: number | null; since?: any | null; until?: any | null }>;
};

export type ProductCardsFragment = {
  __typename?: 'Acon3dProduct';
  id: number;
  languageCode: LanguageCodeEnum;
  title: string;
  price: number;
  salePrice: number;
  onSale: boolean;
  onDisplay: boolean;
  promotionEndDate?: any | null;
  mainImageUrl: string;
  subImageUrl: string;
  isAconOnly: boolean;
  isAdultOnly: boolean;
  orderCount: number;
  viewCount: number;
  reviewCount: number;
  created: any;
  released?: any | null;
  updated?: any | null;
  badgeNames: Array<string | null>;
  extensions: Array<string>;
  isExclusive: boolean;
  book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
};

export type LicensesFragment = { __typename?: 'AssetLicensePublicOutput'; id: number; licenseTypeId: number; code: string; usableCount: number; price: number };

export type ProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type ProductCardsQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductCardsWithBrandQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type ProductCardsWithBrandQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductCardsWithCategoriesQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type ProductCardsWithCategoriesQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    categories?: Array<{ __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null }> | null;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductCardsWithBrandAndCategoriesQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type ProductCardsWithBrandAndCategoriesQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    categories?: Array<{ __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null }> | null;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductCardContentQueryVariables = Exact<{
  assetId: Scalars['Int']['input'];
  lang: Scalars['String']['input'];
}>;

export type ProductCardContentQuery = { __typename?: 'Query'; productContent: string };

export type ProductCardsAllQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ProductCardsAllQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    applications?: Array<{ __typename?: 'ApplicationEntity'; name: string }> | null;
    linkedProducts?: Array<{ __typename?: 'Acon3dLinkedProduct'; id: number; extensions: Array<string> }> | null;
    categories?: Array<{ __typename?: 'Acon3dCategory'; code?: string | null; name?: string | null }> | null;
    modelConfigs?: Array<{ __typename?: 'Acon3dModelConfig'; id?: number | null; name?: string | null }> | null;
    banners?: Array<{
      __typename?: 'Acon3dBanner';
      id?: number | null;
      text?: string | null;
      href?: string | null;
      imageUrl?: string | null;
      type?: string | null;
      created?: any | null;
    }> | null;
    packages?: Array<{
      __typename?: 'Acon3dPackage';
      type: string;
      parent?: { __typename?: 'Acon3dProduct'; id: number } | null;
      children: Array<{ __typename?: 'Acon3dProduct'; id: number }>;
    } | null> | null;
    funding?: {
      __typename?: 'Acon3dFunding';
      deliveryDate: any;
      endDate: any;
      goalAmount: number;
      soldAmount: number;
      soldVolume: number;
      startDate: any;
      status: FundingStatus;
    } | null;
    licenses: Array<{ __typename?: 'AssetLicensePublicOutput'; id: number; licenseTypeId: number; code: string; usableCount: number; price: number } | null>;
    promotionInfo?: {
      __typename?: 'PromotionOutput';
      discountValue?: number | null;
      concept: { __typename?: 'PromotionConceptOutput'; code: string };
      items: Array<{ __typename?: 'PromotionItemOutput'; id: number; discountValue?: number | null; since?: any | null; until?: any | null }>;
    } | null;
    category?: { __typename?: 'CategoryOutputV4'; id: number; code: string; name: string } | null;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductCardsGa4EcommerceQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type ProductCardsGa4EcommerceQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    title: string;
    price: number;
    salePrice: number;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
  } | null>;
};

export type DetailViewProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type DetailViewProductCardsQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    detailImageUrl?: string | null;
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    applications?: Array<{ __typename?: 'ApplicationEntity'; name: string }> | null;
    linkedProducts?: Array<{ __typename?: 'Acon3dLinkedProduct'; id: number; extensions: Array<string> }> | null;
    packages?: Array<{
      __typename?: 'Acon3dPackage';
      type: string;
      parent?: { __typename?: 'Acon3dProduct'; id: number } | null;
      children: Array<{ __typename?: 'Acon3dProduct'; id: number }>;
    } | null> | null;
    funding?: {
      __typename?: 'Acon3dFunding';
      deliveryDate: any;
      endDate: any;
      goalAmount: number;
      soldAmount: number;
      soldVolume: number;
      startDate: any;
      status: FundingStatus;
    } | null;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type OrderProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type OrderProductCardsQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    hasOrdered?: boolean | null;
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    promotionInfo?: {
      __typename?: 'PromotionOutput';
      discountValue?: number | null;
      concept: { __typename?: 'PromotionConceptOutput'; code: string };
      items: Array<{ __typename?: 'PromotionItemOutput'; id: number; discountValue?: number | null; since?: any | null; until?: any | null }>;
    } | null;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    licenses: Array<{ __typename?: 'AssetLicensePublicOutput'; id: number; licenseTypeId: number; code: string; usableCount: number; price: number } | null>;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type PromotionProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type PromotionProductCardsQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    promotions?: Array<{
      __typename?: 'Acon3dPromotion';
      id: number;
      startDate?: any | null;
      currentRound?: number | null;
      remainingDays?: number | null;
      badges?: Array<{ __typename?: 'Acon3dPromotionBadge'; id?: number | null; type?: string | null; languageCode?: LanguageCodeEnum | null; content?: string | null }> | null;
      rounds?: Array<{ __typename?: 'Acon3dPromotionRound'; roundNo?: number | null; salePrice?: number | null; startDate?: any | null; endDate?: any | null }> | null;
    }> | null;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductCardsSummaryQueryVariables = Exact<{
  sort?: InputMaybe<ProductListSort>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ProductCardsSummaryQuery = {
  __typename?: 'Query';
  productCardsSummary: {
    __typename?: 'ProductCardsSummary';
    sort?: ProductListSort | null;
    products: Array<{
      __typename?: 'Acon3dProduct';
      id: number;
      released?: any | null;
      extensions: Array<string>;
      salePrice: number;
      orderCount: number;
      isAdultOnly: boolean;
      applications?: Array<{ __typename?: 'ApplicationEntity'; id: number; name: string }> | null;
    }>;
  };
};

export type AdultProductCardsSummaryQueryVariables = Exact<{
  sort?: InputMaybe<ProductListSort>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  brandCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type AdultProductCardsSummaryQuery = {
  __typename?: 'Query';
  adultProductCardsSummary: {
    __typename?: 'ProductCardsSummary';
    toggle?: boolean | null;
    sort?: ProductListSort | null;
    products: Array<{
      __typename?: 'Acon3dProduct';
      id: number;
      released?: any | null;
      extensions: Array<string>;
      salePrice: number;
      orderCount: number;
      isAdultOnly: boolean;
      applications?: Array<{ __typename?: 'ApplicationEntity'; id: number; name: string }> | null;
    }>;
  };
};

export type ProductExtensionsQueryVariables = Exact<{
  language: Language_Code;
  condition: ProductExtensionsInput_Condition;
  pagination: PaginationInput;
}>;

export type ProductExtensionsQuery = {
  __typename?: 'Query';
  productExtensions: {
    __typename?: 'ExtensionPaginatedOutput';
    data: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
  };
};

export type SearchExtensionsV2QueryVariables = Exact<{
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  condition: SearchProductsV2Input_Condition;
  sort: SearchProductsV2Input_Sort;
  https?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchExtensionsV2Query = {
  __typename?: 'Query';
  searchExtensionsV2: {
    __typename?: 'ExtensionPaginatedOutput';
    data: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number };
  };
};

export type ProductTagsQueryVariables = Exact<{
  language: Language_Code;
  condition: ProductTagsInput_Condition;
  pagination: PaginationInput;
}>;

export type ProductTagsQuery = {
  __typename?: 'Query';
  productTags: {
    __typename?: 'TagPaginatedOutput';
    data: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number };
  };
};

export type SearchTagsV2QueryVariables = Exact<{
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  condition: SearchProductsV2Input_Condition;
  sort: SearchProductsV2Input_Sort;
  https?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchTagsV2Query = {
  __typename?: 'Query';
  searchTagsV2: {
    __typename?: 'TagPaginatedOutput';
    data: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number };
  };
};

export type RegisterSnsMemberMutationVariables = Exact<{
  input: AconSnsMemberInput;
}>;

export type RegisterSnsMemberMutation = { __typename?: 'Mutation'; registerSnsMember: boolean };

export type RegisterSnsMemberV2MutationVariables = Exact<{
  input: AconSnsMemberInput;
}>;

export type RegisterSnsMemberV2Mutation = {
  __typename?: 'Mutation';
  registerSnsMemberV2: { __typename?: 'RegisterSnsMemberOutput'; id: number; email: string; name: string; mobile?: string | null };
};

export type ReviewsQueryVariables = Exact<{
  goodsNo: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  rating?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
  sortType?: InputMaybe<Scalars['String']['input']>;
}>;

export type ReviewsQuery = {
  __typename?: 'Query';
  reviews: Array<{
    __typename?: 'Review';
    id: number;
    created: any;
    rating: number;
    isBestReview: boolean;
    isNew: boolean;
    isImage: boolean;
    commentsCount: number;
    author: string;
    favoriteCount?: number | null;
    isFavorite?: boolean | null;
    godoMemNo: number;
    imageUrl?: string | null;
    reviewImages?: Array<{ __typename?: 'ReviewImage'; imageNumber: number; imageUrl: string }> | null;
    reviewReplies?: Array<{
      __typename?: 'ReviewReply';
      reviewId: number;
      created: any;
      reviewReplyContents?: Array<{ __typename?: 'ReviewReplyContent'; languageCode: LanguageCodeEnum; content: string; isOriginal: boolean; reviewReplyId: number }> | null;
      adminUser: { __typename?: 'AdminUser'; name: string; id: string; updated?: any | null };
    }> | null;
    reviewContentsItems: Array<{ __typename?: 'ReviewContents'; title: string; reviewId: number; contents: string; isOriginal: boolean; languageCode: LanguageCodeEnum }>;
  }>;
};

export type SimpleReviewsQueryVariables = Exact<{
  goodsNo: Scalars['String']['input'];
  lang: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  rating?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  isExperimental?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SimpleReviewsQuery = {
  __typename?: 'Query';
  reviews: Array<{
    __typename?: 'Review';
    id: number;
    created: any;
    rating: number;
    commentsCount: number;
    author: string;
    favoriteCount?: number | null;
    isFavorite?: boolean | null;
    isImage: boolean;
    godoMemNo: number;
    imageUrl?: string | null;
    reviewImages?: Array<{ __typename?: 'ReviewImage'; imageUrl: string }> | null;
    reviewContentsItems: Array<{ __typename?: 'ReviewContents'; contents: string }>;
  }>;
};

export type ReviewQueryVariables = Exact<{
  reviewId: Scalars['Int']['input'];
  languageCode: LanguageCodeEnum;
}>;

export type ReviewQuery = {
  __typename?: 'Query';
  review?: {
    __typename?: 'Review';
    author: string;
    commentsCount: number;
    created: any;
    favoriteCount?: number | null;
    id: number;
    imageUrl?: string | null;
    isBestReview: boolean;
    isFavorite?: boolean | null;
    isImage: boolean;
    isNew: boolean;
    godoGoodsNo: number;
    godoOrderNo?: string | null;
    isSecret: boolean;
    rating: number;
    reviewContentsItems: Array<{ __typename?: 'ReviewContents'; contents: string; isOriginal: boolean; languageCode: LanguageCodeEnum; reviewId: number; title: string }>;
    reviewImages?: Array<{ __typename?: 'ReviewImage'; imageNumber: number; imageUrl: string }> | null;
    reviewReplies?: Array<{
      __typename?: 'ReviewReply';
      created: any;
      id: number;
      adminUser: { __typename?: 'AdminUser'; name: string };
      reviewReplyContents?: Array<{ __typename?: 'ReviewReplyContent'; content: string; isOriginal: boolean; languageCode: LanguageCodeEnum }> | null;
    }> | null;
    product?: {
      __typename?: 'Acon3dProduct';
      id: number;
      title: string;
      brand: { __typename?: 'Acon3dBrand'; id?: number | null; code?: string | null; name?: string | null };
    } | null;
  } | null;
};

export type RegisterReviewMutationVariables = Exact<{
  input: RegisterReviewInput;
}>;

export type RegisterReviewMutation = { __typename?: 'Mutation'; registerReviewV2?: { __typename?: 'Review'; id: number; created: any } | null };

export type UpdateReviewMutationVariables = Exact<{
  input: UpdateReviewInput;
}>;

export type UpdateReviewMutation = { __typename?: 'Mutation'; updateReviewV2?: { __typename?: 'Review'; id: number } | null };

export type FavoriteReviewMutationVariables = Exact<{
  reviewId: Scalars['Int']['input'];
}>;

export type FavoriteReviewMutation = { __typename?: 'Mutation'; setFavoriteReview?: number | null };

export type UpdateOrderLicenseMutationVariables = Exact<{
  orderNo: Scalars['Float']['input'];
  type: Scalars['String']['input'];
  names: Array<Scalars['String']['input']> | Scalars['String']['input'];
  projects?: InputMaybe<Array<OrderLicenseProjects> | OrderLicenseProjects>;
  adminEmail?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateOrderLicenseMutation = { __typename?: 'Mutation'; updateOrderLicense: boolean };

export type ProductPriceInfoFragment = { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };

export type ProductImageInfoFragment = {
  __typename?: 'ProductImageInfoOutput';
  main: { __typename?: 'ProductImageOutput'; url: string };
  sub: { __typename?: 'ProductImageOutput'; url: string };
};

export type ProductPromotionInfoFragment = { __typename?: 'ProductPromotionInfoOutput'; until: any; since: any };

export type ProductBrandInfoFragment = {
  __typename?: 'BrandOutput';
  id: number;
  code?: string | null;
  name: string;
  isAcon3DExclusive: boolean;
  productCount: number;
  vipInfo?: Brand_Output_Brand_Vip_Type | null;
  update?: { __typename?: 'BrandOutputUpdateOutput'; type: Brand_Output_Update_Output_Type; value?: number | null } | null;
  photoInfo?: { __typename?: 'FileOutput'; path?: string | null } | null;
};

export type ProductTagsFragment = { __typename?: 'TagOutput'; id: number; name: string; count: number };

export type ProductExtensionsFragment = { __typename?: 'ExtensionOutput'; id: number; name: string; count: number };

export type ProductApplicationsFragment = { __typename?: 'AssetApplicationOutput'; id: number; name: string };

export type ProductQueryVariables = Exact<{
  productId: Scalars['Float']['input'];
  language: Language_Code;
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product: {
    __typename?: 'ProductOutput';
    id: number;
    title: string;
    isAdultOnly: boolean;
    priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
    imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
    promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; until: any; since: any } | null;
    brandInfo: {
      __typename?: 'BrandOutput';
      id: number;
      code?: string | null;
      name: string;
      isAcon3DExclusive: boolean;
      productCount: number;
      vipInfo?: Brand_Output_Brand_Vip_Type | null;
      update?: { __typename?: 'BrandOutputUpdateOutput'; type: Brand_Output_Update_Output_Type; value?: number | null } | null;
      photoInfo?: { __typename?: 'FileOutput'; path?: string | null } | null;
    };
    tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
    extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
    applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
    bookInfo: { __typename?: 'ProductBookInfoOutput'; isbn?: string | null };
  };
};

export type ProductCardsDetailQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
}>;

export type ProductCardsDetailQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    promotionEndDate?: any | null;
    reviewCount: number;
    onSale: boolean;
    licenses: Array<{ __typename?: 'AssetLicensePublicOutput'; id: number; licenseTypeId: number; code: string; usableCount: number; price: number } | null>;
    promotionInfo?: {
      __typename?: 'PromotionOutput';
      discountValue?: number | null;
      concept: { __typename?: 'PromotionConceptOutput'; code: string };
      items: Array<{ __typename?: 'PromotionItemOutput'; id: number; discountValue?: number | null; since?: any | null; until?: any | null }>;
    } | null;
    banners?: Array<{
      __typename?: 'Acon3dBanner';
      id?: number | null;
      text?: string | null;
      href?: string | null;
      imageUrl?: string | null;
      type?: string | null;
      created?: any | null;
    }> | null;
    packages?: Array<{
      __typename?: 'Acon3dPackage';
      type: string;
      parent?: { __typename?: 'Acon3dProduct'; id: number } | null;
      children: Array<{ __typename?: 'Acon3dProduct'; id: number }>;
    } | null> | null;
    funding?: {
      __typename?: 'Acon3dFunding';
      deliveryDate: any;
      endDate: any;
      goalAmount: number;
      soldAmount: number;
      soldVolume: number;
      startDate: any;
      status: FundingStatus;
    } | null;
    category?: { __typename?: 'CategoryOutputV4'; id: number; code: string; name: string } | null;
  } | null>;
};

export type FundingProductCardsQueryVariables = Exact<{
  languageCode: LanguageCodeEnum;
  productNos?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  isIncludeNotForSale?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FundingProductCardsQuery = {
  __typename?: 'Query';
  productCards: Array<{
    __typename?: 'Acon3dProduct';
    id: number;
    languageCode: LanguageCodeEnum;
    title: string;
    price: number;
    salePrice: number;
    onSale: boolean;
    onDisplay: boolean;
    promotionEndDate?: any | null;
    mainImageUrl: string;
    subImageUrl: string;
    isAconOnly: boolean;
    isAdultOnly: boolean;
    orderCount: number;
    viewCount: number;
    reviewCount: number;
    created: any;
    released?: any | null;
    updated?: any | null;
    badgeNames: Array<string | null>;
    extensions: Array<string>;
    isExclusive: boolean;
    brand: { __typename?: 'Acon3dBrand'; code?: string | null; name?: string | null };
    funding?: {
      __typename?: 'Acon3dFunding';
      deliveryDate: any;
      endDate: any;
      goalAmount: number;
      soldAmount: number;
      soldVolume: number;
      startDate: any;
      status: FundingStatus;
    } | null;
    book?: { __typename?: 'Acon3dBook'; isbn?: string | null } | null;
  } | null>;
};

export type ProductsPaginationFragment = { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };

export type ProductsDataFragment = {
  __typename?: 'ProductOutput';
  id: number;
  title: string;
  priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
  promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
  imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
  dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
  tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
  brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
  extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
  applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
};

export type ProductsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: ProductsConditionInput;
  sort: Sort;
}>;

export type ProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductPaginatedOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
  };
};

export type PresetProductsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  preset: Preset_Products_Preset;
}>;

export type PresetProductsQuery = {
  __typename?: 'Query';
  presetProducts: {
    __typename?: 'ProductPaginatedOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
  };
};

export type OrderedProductsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: OrderedProductsInput_Condition;
  sort: OrderedProductsInput_Sort;
}>;

export type OrderedProductsQuery = {
  __typename?: 'Query';
  orderedProducts: {
    __typename?: 'ProductPaginatedOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
  };
};

export type ProductsPaginationQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: ProductsConditionInput;
  sort: Sort;
}>;

export type ProductsPaginationQuery = {
  __typename?: 'Query';
  products: { __typename?: 'ProductPaginatedOutput'; pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number } };
};

export type OtherProductsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: ProductsConditionInput;
  sort: Sort;
}>;

export type OtherProductsQuery = {
  __typename?: 'Query';
  products: {
    __typename?: 'ProductPaginatedOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    }>;
  };
};

export type SearchProductsQueryVariables = Exact<{
  language: Language_Code;
  pagination: PaginationInput;
  condition: SearchProductsInput_Condition;
  sort: SearchProductsInput_Sort;
}>;

export type SearchProductsQuery = {
  __typename?: 'Query';
  searchProducts: {
    __typename?: 'ProductPaginatedOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
  };
};

export type SearchProductsV2QueryVariables = Exact<{
  language: SearchProductsV2Input_Language;
  pagination: SearchProductsV2Input_Pagination;
  condition: SearchProductsV2Input_Condition;
  sort: SearchProductsV2Input_Sort;
  https?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SearchProductsV2Query = {
  __typename?: 'Query';
  searchProductsV2: {
    __typename?: 'ProductPaginatedOutput';
    data: Array<{
      __typename?: 'ProductOutput';
      id: number;
      title: string;
      priceInfo: { __typename?: 'ProductPriceInfoOutput'; price: number; salePrice: number };
      promotionInfo?: { __typename?: 'ProductPromotionInfoOutput'; since: any; until: any } | null;
      imageInfo: { __typename?: 'ProductImageInfoOutput'; main: { __typename?: 'ProductImageOutput'; url: string }; sub: { __typename?: 'ProductImageOutput'; url: string } };
      dateInfo: { __typename?: 'ProductDateInfoOutput'; releasedAt?: any | null };
      tags: Array<{ __typename?: 'TagOutput'; id: number; name: string; count: number }>;
      brandInfo: { __typename?: 'BrandOutput'; id: number; name: string; isAcon3DExclusive: boolean; vipInfo?: Brand_Output_Brand_Vip_Type | null; code?: string | null };
      extensions: Array<{ __typename?: 'ExtensionOutput'; id: number; name: string; count: number }>;
      applications: Array<{ __typename?: 'AssetApplicationOutput'; id: number; name: string }>;
    }>;
    pagination: { __typename?: 'PaginationOutput'; totalCount: number; currentPage: number; perPage: number; lastPage: number };
  };
};
